import React from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { ArrowLeftCircle, Flag } from 'react-feather';
import Store from '../../../../Store';
import { ScheduleApp } from '../../../../Actions/ScheduleApp';
import Loader from '../../Loader';
import { REACT_APP_API_URL } from '../../../processENV';
import { withRouter } from 'react-router-dom';

class DateApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            date: [],
            spots : [],
        };
    }
    getMode=(key)=>{
        let item=key
        if(item){
            let prodId =item.app_prod_grp_key
            let empId = item.doc_app_id
            this.setState({prodId, empId})
            let data = {
                "employee_n_key":empId,
                'app_prod_grp_key':prodId,
            }
            this.setState({loading:true})
            fetch(`${REACT_APP_API_URL}/app_rescheduled_date/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
                },
                body: JSON.stringify(data),
                }).then(response => response.json())
                .then(async (res)=>{
                    console.log("appoint date",res)
                    if(res.status === "success"){
                        this.setState({loading:false, date:res.data, spots:res.count})
                    }else{
                        this.setState({loading:false,error:'Some error has occured please try again'})
                    }
                })
        }
       
    }
   
    componentDidMount() {
        console.log(this.props)
        if(this.props?.item){
            this.getMode(this.props?.item) 
        }
    }

    selectDate = ( value) =>{
            Store.dispatch(ScheduleApp({
                scheduleAppstep:1,
                scheduleAppCurrentStep:1,
                date: value,
                empId: this.state.empId
            }));
        
    }
    
    goBack = () =>{
      window.location.replace('/home')
    }

    render() {
        const { date, loading, spots } = this.state;
        return (
            <>
                 {loading && <Loader />}
                <div className="col-md-8 mx-auto px-0 pt-5">
                <button onClick={this.goBack} className="btn btn-sm mt-5 mt-md-1 border-none">
                <ArrowLeftCircle color="#215352" size={25} />
                </button>
                    <div className="d-flex mx-auto mt-1 px-0">
                    <Col className="mx-auto text-center">
                        <p
                        style={{ fontSize: "26px" }}
                        className="h4 text-left _text_color text-md-center header_font_ font-weight-bold"
                        >
                            You can schedule your appointments now based on your convenience
                             </p>
                            
                             <div className="col-md-6 px-0 mt-md-5 mt-2 mx-auto d-flex flex-column">                             
                                {date && date.map((item, i)=>{
                                    return(
                                        <button
                                        style={{border:'1px solid #ddefe4', fontFamily: "Hanken Grotesk", fontSize: "20px" }}
                                        className={"p-3 rounded bg-white _text_color text_p_ position-relative shadow-sm text_medium_ my-2"}
                                        key={item}
                                        onClick={() => this.selectDate(item)}
                                        >
                                        {item}
                                        <br />
                                        <small
                                            style={{ fontSize: "12px", right: 5, bottom: 3 }}
                                            className="text-right position-absolute font-weight-bold"
                                        >
                                            {spots[i]} slots{" "}
                                        </small>
                                        </button>
                                    ) 
                                })}
                            </div></Col>

                    </div>
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData,
        ScheduleAppData: state.ScheduleAppData,
    }
}

export default withRouter(connect(mapStateToProps, null)(DateApp));
