import React from 'react';
// import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import Store from '../../Store';
import { LoginStateAction } from '../../Actions/LoginAction';
import Swal from 'sweetalert2/dist/sweetalert2.js'

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Progress
} from 'reactstrap';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import caremelogo from '../../assets/images/caremelogo.png'
import profile from '../../assets/images/profilepic.png'
import { Settings, LogOut, User,Users, Send, FileText, CreditCard, Globe, MessageSquare, Home } from 'react-feather';
import { Link } from "react-router-dom";
import { showFeedbackModal } from '../../Actions/PopUp';
import { CDN_URL, REACT_APP_API_URL, REACT_APP_BU_API_URL, SITE_URL } from '../processENV';
// import Slider from "react-slick";
import Topbar from './Home/Topbar';
import blog from '../../assets/images/menu/blog.png'
import Therapy from '../../assets/images/menu/Therapy.png'
import Document from '../../assets/images/menu/Document.png'
import Community from '../../assets/images/menu/Community.png'
import home from '../../assets/images/menu/home.png'
import team from '../../assets/images/menu/team.png'
import user from '../../assets/images/menu/user.png'
import logout from '../../assets/images/menu/logout.png'
import goal from '../../assets/images/goal.png'
import medicalreport from '../../assets/images/medicalreport.png' 
import exc from '../../assets/images/exc.png' 
import { Icon } from '@material-ui/core';
import {IoIosArrowForward} from 'react-icons/io'

class CustomNavbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            show:false,
            compeletionPercentage: 0,
            _isAdmin:false,
            org_id:'',
        };
    }

    componentDidMount() {
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        fetch(`${REACT_APP_BU_API_URL}/bu_org_details/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
        }).then(response => response.json())
            .then(async (res) => {
                if (res) {
                    this.setState({ _isAdmin:res.admin,bu_org_id:res.bu_details?.bu_org_id })
                    localStorage._b=res.bu_details?.bu_org_id
                } else {
                    this.setState({ error: 'Some error has occured please try again' })
                }
            })
            .catch(err => console.log(err))

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.loginData !== this.props.loginData) {
            this.setState({
                step: this.props.loginData.data?.step,
                mobileNumber: this.props.loginData.data?.mobileNumber,
                countryCode: this.props.loginData.data?.countryCode
            })
        }
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    };
    alertPop = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to logout",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#441f4b',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Logout!'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.clear();
                Swal.fire(
                    'Logged Out!',
                    'Successfully',
                    'success'
                )
                setTimeout(function () { 
                    window.location.href='https://app.careme.health/'}, 1000);
            }
        })

    }


    render() {
        const { isOpen, compeletionPercentage,_isAdmin,bu_org_id } = this.state;
        const settings = {
            dots: false,
            infinite: true,
            slidesToScroll: 3,
            slidesToShow: 3,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 2000,
            cssEase: "linear",
            vertical: true
        };
        return (
                     <>
                <div className={"login-main customnav d-none d-sm-block"}>
                    {/* <Topbar compeletionPercentage={compeletionPercentage} /> */}
                    <SideNav
                        onSelect={(selected) => {
                        }}
                        onToggle={()=>this.setState({isOpen:!this.state.isOpen})}
                        expanded={this.state.isOpen}
                    >
                        <SideNav.Toggle />
                        <SideNav.Nav defaultSelected="home">
                        <NavItem  eventKey="home">
                                <NavIcon><Link to="/home">  <img src={home} width='30px' height='30px' />
                                </Link>
                                </NavIcon>
                                <NavText>
                                    <Link to="/home">
                                       Home
                                 </Link>
                                </NavText>
                            </NavItem>
                          {_isAdmin?  <NavItem  eventKey="admin-panel">
                                <NavIcon><Link to="/admin-panel">  <img src={`${CDN_URL}/Wellbeing/admingreen2.png`} width='25px' height='25px' />
                                </Link>
                                </NavIcon>
                                <NavText>
                                    <Link to="/admin-panel">
                                       Admin-Panel
                                 </Link>
                                </NavText>
                            </NavItem>:null}
                            {_isAdmin?   <NavItem  eventKey="dashboard">
                                <NavIcon><Link to="/dashboard">  <img src={`${CDN_URL}/Wellbeing/dashboardgreen_.png`} width='22px' height='22px' />
                                </Link>
                                </NavIcon>
                                <NavText>
                                    <Link to="/dashboard">
                                       Dashboard
                                 </Link>
                                </NavText>
                            </NavItem>:null}
                            {bu_org_id&&bu_org_id==9?   <NavItem  eventKey="dashboard-nm">
                                <NavIcon><Link to="/dashboard-nm">  <img src={`${CDN_URL}/Wellbeing/dashboardgreen_.png`} width='22px' height='22px' />
                                </Link>
                                </NavIcon>
                                <NavText>
                                    <Link to="/dashboard-nm">
                                      Wellbeing Dashboard
                                 </Link>
                                </NavText>
                            </NavItem>:null}
                            <NavItem eventKey="community">
                                <NavIcon>  <Link to="/offerings"> <img src={Therapy} width='40px' height='40px' />
                                </Link>
                                </NavIcon>
                                <NavText>
                                    <Link to="/offerings">
                                        Therapy
                                 </Link>
                                </NavText>
                            </NavItem>
                            <NavItem  eventKey="Workbook">
                                <NavIcon> 
                                <Link to='/workbook'> 
                                 <img src={`${CDN_URL}/Websiteicons/notebook.png`} width='29px' height='29px' />
                                </Link>
                                </NavIcon>
                                <NavText>
                                    <Link to='/workbook'>
                                        Assessments
                                 </Link>
                               
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="exercise">
                                <NavIcon> <Link to="/exercise">  <img src={exc} width='25px' height='25px' />
                                </Link>
                                </NavIcon>
                                <NavText>
                                    <Link to="/exercise">
                                        Exercise
                                 </Link>
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="assesments">
                                <NavIcon> <Link to="/goals">  <img src={goal} width='24px' height='24px' />
                                </Link>
                                </NavIcon>
                                <NavText>
                                    <Link to="/goals">
                                        Goals
                                 </Link>
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="chatdetails">
                                <NavIcon> <Link to="/chatdetails">  <img src={`${CDN_URL}/Websiteicons/chatweb.png`} width='25px' height='25px' />
                                </Link>
                                </NavIcon>
                                <NavText>
                                    <Link to="/chatdetails">
                                       Chat Details
                                 </Link>
                                </NavText>
                            </NavItem>
                           
                           
                            <NavItem eventKey="billing">
                                <NavIcon> <Link to="/billing">  <img src={`${CDN_URL}/Websiteicons/bill.png`} width='25px' height='25px' />
                                </Link>
                                </NavIcon>
                                <NavText>
                                    <Link to="/billing">
                                       Billing
                                 </Link>
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="document">
                                <NavIcon> <Link to="/user_documents">  <img src={Document} width='40px' height='40px' />
                                </Link>
                                </NavIcon>
                                <NavText>
                                    <Link to="/user_documents">
                                       Upload Documents
                                 </Link>
                                </NavText>
                            </NavItem>
                            
                            <NavItem eventKey="prescription">
                                <NavIcon> <Link to="/prescription">  <img src={medicalreport} width='29px' height='29px' />
                                </Link>
                                </NavIcon>
                                <NavText>
                                    <Link to="/prescription">
                                        Prescriptions
                                 </Link>
                                </NavText>
                            </NavItem>
                                                     
                
                            <NavItem eventKey="document">
                                <NavIcon> <a href="https://careme.health/blog" target='_blank'>  <img src={blog} width='40px' height='40px' />
                                </a>
                                </NavIcon>
                                <NavText>
                                    <a href="https://careme.health/blog" target='_blank'>
                                        Blogs
                                 </a>
                                </NavText>
                            </NavItem>

                            <NavItem eventKey="comm" onClick={()=>{window.location.replace(`${SITE_URL}/community`)}}>
                            <NavIcon>
                                    <img src={Community} width='40px' height='40px' />
                            </NavIcon>
                            <NavText>
                                    Community
                            </NavText>
                        </NavItem>

                            <NavItem eventKey="wellbeing-user-profile">
                                <NavIcon><Link to="/wellbeing-user-profile"> <img src={user} width='30px' height='30px' />
                                </Link>
                                </NavIcon>
                                <NavText>
                                    <Link to="/wellbeing-user-profile">
                                       Account Details
                                 </Link>
                                </NavText>
                            </NavItem>
                            {/* <NavItem eventKey="appointment">
                                <NavIcon>                <Link to="/history/appointment">                   <FileText size={20} className="mr-2" />
                                </Link>
                                </NavIcon>
                                <NavText>
                                    <Link to="/history/appointment">
                                        Appointment History
                                 </Link>
                                </NavText>
                            </NavItem> */}
                            {/* <NavItem eventKey="language">
                                <NavIcon>                <Link to="/changelanguage">                   <Globe size={20} className="mr-2" />
                                </Link>
                                </NavIcon>
                                <NavText>
                                    <Link to="/changelanguage">
                                        Change Language
                                 </Link>
                                </NavText>
                            </NavItem> */}
                            {/* <NavItem eventKey="chattherapy">
                                <NavIcon>                <Link to="/chattherapy">                   <MessageSquare size={20} className="mr-2" />
                                </Link>
                                </NavIcon>
                                <NavText>
                                    <Link to="/chattherapy">
                                        Chat Therapy
                                 </Link>
                                </NavText>
                            </NavItem> */}
                            {/* <NavItem eventKey="feedback" onClick={() => Store.dispatch(showFeedbackModal({ show: true }))}>
                                <NavIcon>                          <Send size={20} className="mr-2" />

                                </NavIcon>
                                <NavText>
                                    Feedback
                                </NavText>
                            </NavItem> */}
                            {/* 
                            <NavItem eventKey="settings" to="/settings">
                                <NavIcon>
                                    <Settings size={20} className="mr-2" />

                                </NavIcon>
                                <NavText>

                                    Settings
                                 </NavText>
                            </NavItem> */}
                          
                            <NavItem   eventKey="logout" onClick={() => this.alertPop()}>
                                <NavIcon>
                                <img src={logout} width='30px' height='30px' />
                                </NavIcon>
                                <NavText>
                                    Logout
                                </NavText>
                            </NavItem>


                        </SideNav.Nav>
                    </SideNav>
                </div>

            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(CustomNavbar);
