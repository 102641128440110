import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import LeftContent from './LeftContent';
import ChooseLanguage from './ChooseLanguage';
import Store from '../../../Store';
import SignUp from '../Login/SignUp';
import TalktoExpert from './TalktoExpert';
import { GetCareStateAction } from '../../../Actions/GetCareAction';
import SelectDate from './SelectDate';
import SelectTime from './SelectTime';
import Payment  from './Payment';

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            getCareStep:0,
            getCareCurrentStep:1,
            date:'',
            time:'',
            language:'english',
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.getCareData !== this.props.getCareData) {
            this.setState({
                getCareStep: this.props.getCareData.data?.getCareStep,
                date: this.props.getCareData.data?.date,
                time: this.props.getCareData.data?.time,
                language: this.props.getCareData.data?.language
            })
        }
      }
    
    navigateComponent = (id) =>{
        this.setState({getCareStep:id})
        Store.dispatch(GetCareStateAction({
            getCareStep:id,
            getCareCurrentStep:id,
            // currentStep:localStorage.getItem('_currentStep') !==null || localStorage.getItem('_currentStep') !== undefined  ? localStorage.getItem('_currentStep') : 0,
            date: this.props.getCareData.data?.date,
            time: this.props.getCareData.data?.time,
            language: this.props.getCareData.data?.language
        }));
    }


    render() {
        let { getCareStep } = this.state;
        return (
            <>
            {
                <div className={"login-main"}>
                    <Row className='m-0'>
                        <Col md={8} className="col-sm-push-12 order-lg-12  px-0 px-md-auto pr-0">
                            
                            {getCareStep === 0 && <TalktoExpert navigateComponent={this.navigateComponent}/>}
                            {getCareStep === 1 && <ChooseLanguage  />}
                            {getCareStep === 2 && <SelectDate/>}
                            {getCareStep === 3 && <SelectTime/>}
                            {getCareStep === 4 && <Payment/>}

                        </Col>
                        <Col md={4} className="col-sm-push-1 order-lg-1   px-0">
                            <LeftContent currentStep={this.props.getCareData.data?.getCareCurrentStep} step={getCareStep} navigateComponent={this.navigateComponent} />
                        </Col>

                    </Row>
                </div>
                }
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        getCareData: state.GetCareData
    }
}

export default connect(mapStateToProps, null)(Index);
