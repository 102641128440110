import React from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { ArrowLeftCircle, Flag } from 'react-feather';
import Store from '../../../../Store';
import { ScheduleApp } from '../../../../Actions/ScheduleApp';
import Loader from '../../Loader';
import { REACT_APP_API_URL } from '../../../processENV';

class TimeApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            time: [],
            
        };
    }
    getMode=()=>{
        let data = {
            "employee_n_key":this.props.ScheduleAppData.data?.empId,
            "rescheduled_date": this.props.ScheduleAppData.data?.date,
            'app_prod_grp_key':this.props?.item?.app_prod_grp_key,
        }

        this.setState({loading:true})
        fetch(`${REACT_APP_API_URL}/app_rescheduled_time/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
                console.log("re appoint time",res.data)
                if(res.status === "success"){
                    this.setState({loading:false, time:res.data})
                }else{
                    this.setState({loading:false,error:'Some error has occured please try again'})
                }
            })
    }
    componentDidMount() {
       this.getMode() 
       console.log(this.props)
    }
    appointDoctor = (val) =>{

        let therapyDetails = JSON.parse(localStorage.getItem('therapy_details'))
        let { selectedDate, selectedTime, empId }  =  this.state;

        let data =this.props.item.previous_book=='Yes'?{
                "patient_n_key":localStorage.getItem('_patientKey'),
                "appointment_date":this.props.ScheduleAppData.data?.date,
                "appointment_time":val,
                "product":this.props.item.product,
                "app_payment_n_key":this.props.item.app_payment_n_key,
                "doc_app_id":this.props.ScheduleAppData.data?.empId,
                "app_prod_n_key":this.props.item.app_prod_n_key,
                "mode_of_telehealth":null,
                'app_prod_grp_key':this.props?.item?.app_prod_grp_key,
                'app_payment_n_key':this.props.item?.app_payment_n_key,
                total_session:1,
                addon:this.props.ScheduleAppData.data?.addon||false,

             }:{
                "patient_n_key":localStorage.getItem('_patientKey'),
                "appointment_date":this.props.ScheduleAppData.data?.date,
                "appointment_time":val,
                "product":this.props.item.product,
                "app_payment_n_key":this.props.item.app_payment_n_key,
                "doc_app_id":this.props.ScheduleAppData.data?.empId,
                "app_prod_n_key":this.props.item.app_prod_n_key,
                "mode_of_telehealth":null,
                'app_prod_grp_key':this.props?.item?.app_prod_grp_key,

             }

        
        fetch(`${REACT_APP_API_URL}/careteam_book_appointment/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(async (res) => {
                console.log("session_appointment", res)
                this.setState({
                    appointment:res.data
                })
                if (res.status === "success") {
                    this.setState({transactionStatus:res.data.overall_status})
                           window.location.replace('/home')
                } else {
                    this.setState({ loading: false, error: 'Some error has occured please try again' })
                }
            })
            .catch(err => console.log(err))
    }

    selecttime = ( value) =>{
        this.setState({loading:true})
            Store.dispatch(ScheduleApp({
               time:value,
               date:this.props.ScheduleAppData.data?.date,
            }));
           this.appointDoctor(value)
    }
    
    goBack = () =>{
        Store.dispatch(ScheduleApp({
            scheduleAppstep:0,
            scheduleCurrentStep:0,
         }));
    }

    render() {
        let {  time, loading,  selectedTime, spots } = this.state;
        return (
            <>
                {loading && <Loader />}
                <div className="col-md-8 mx-auto px-0 pt-5">
                <button onClick={this.goBack} className="btn btn-sm mt-5 mt-md-1 border-none">
                <ArrowLeftCircle color="#215352" size={25} />
                </button>
                <div className="d-flex mx-auto mt-1 px-0">
                    <Col className="mx-auto text-center">
                        <p
                        style={{ fontSize: "26px" }}
                        className="h4 text-left _text_color text-md-center header_font_ font-weight-bold"
                        >
                            You can schedule your appointments now based on your convenience
                             </p>
                            
                             <div className="col-md-6 px-0 mt-md-5 mt-2 mx-auto d-flex flex-column">
                                {time && time.map((item, i)=>{
                                    return (
                                        <button
                                            style={{border:'1px solid #ddefe4', fontFamily: "Hanken Grotesk", fontSize: "20px" }}
                                            className={"p-3 rounded bg-white _text_color text_p_ shadow-sm text_medium_ my-2"}
                                            key={item}
                                            onClick={() => this.selecttime(item)}
                                        >
                                        {item}
                                        </button>
                                    )
                                })}
                            </div></Col>

                    </div>
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData,
        ScheduleAppData: state.ScheduleAppData
    }
}

export default connect(mapStateToProps, null)(TimeApp);
