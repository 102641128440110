import React from 'react';

class Loader extends React.Component {

    render() {
        return (
            <>
            <div className="customloading">
            <div className="spinner-border"></div>

            </div>
            </>
        )
    }
}

export default Loader;