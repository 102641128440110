
import React from 'react';
import DatePicker from "react-datepicker";


import Flatpickr from "react-flatpickr";
class DateCustom extends React.Component {
    render() {
        let { startDate, showDate } = this.props;

        return (
            <div className="w-100">
                {showDate &&
                    // <DatePicker

                    //     selected={startDate}
                    //     onChange={date => this.props.onInputChange(date)}
                    //     inline
                    //     />
                    <Flatpickr
                        className="form-control w-100"
                        selected={startDate}
                        options={{ maxDate: new Date() }}

                        onChange={date => this.props.onInputChange(date)}
                    />
                }
            </div>
        )
    }

}


export default DateCustom
