import React from 'react';
import { connect } from 'react-redux'; 
import {withRouter} from 'react-router-dom'
import OtpInput from 'react-otp-input';
import { Container, Row, Col } from 'reactstrap';
import Store from '../../../Store';
import { LoginStateAction } from '../../../Actions/LoginAction';
import { REACT_APP_API_URL, REACT_APP_BU_API_URL } from '../../processENV';
import Loader from '../Loader';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { toast, ToastContainer } from 'react-toastify';


const createHistory = require("history").createBrowserHistory;
export const history = createHistory();
// import {logo,DownArrow,loaderSVG} from '../../assets/images';

class OtpValidation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            otp: '',
            otpError:false,
            errorMessage:'',
            loading:false,
            sendNew:false,
        };
    }
    handleChange = otp => this.setState({ otp });
  
    resendOtp = () => {

        let data = {
            "email_id": this.props.loginData.data.email,
            // "dial_code": this.props.loginData.data.countryCode
           }
            this.setState({ loading:true, otp:'', otpError:false })
            fetch(`${REACT_APP_BU_API_URL}/sendotp/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
                },
                body: JSON.stringify(data),
            }).then(response => response.json())
                .then(async (res) => {
                    if(res.status === "success"){
                        console.log("OTP resend  reponse", res)
                        localStorage.setItem('_currentStep',2)
                        Store.dispatch(LoginStateAction({
                            step:2,
                            currentStep:2,
                            email:this.props.loginData.data.email,
                            countryCode:this.props.loginData.data.countryCode,
                            // detail:res,
                            language:this.props.loginData.data.language
                        }));
                        this.setState({loading:false})
                    }else{
                        this.setState({loading:false})
                        console.log("OTP resend error",res)
                    }
                })
            .catch(err => {
                this.setState({loading:false})
                console.log("OTP resend  reponse error",err)
            })
    }


    verifyOtp = (e) => {
        
        if (e) {
            e.preventDefault();
        }

        let { otp } = this.state;
        let data = {
            "phone_number": this.props.loginData.data.email,
            // "sendotp":this.props.loginData.data.detail.data.verify_id,
            "typedotp": otp,
            "dial_code":this.props.loginData.data.countryCode,
            "app_language": this.props.loginData.data.language
        }
        
        if (!otp) {
            this.setState({ otpError: true, otpError:false })
        }  else {
            this.setState({ otpError: false,loading:true })
            fetch(`${REACT_APP_API_URL}/register_patient/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
                },
                body: JSON.stringify(data),
            }).then(response => response.json())
                .then(async (res) => {
                    console.log(res)
                    global.GetSocialSDK.Auth.authenticate({
                        identity_type: 'phone',
                        token: res.data.jwt_token,
                        // userId:`${this.props.loginData.data.countryCode +" "+ this.props.loginData.data.mobileNumber}`
                    })
                    .then(function (response) {
                        console.log('res',response)
                        console.log(`User ${response.user.displayName} is authenticated`);
                    })
                    .catch((err)=>console.log(err));   
                    if(res.status == 'success' || res.status == 'old_patient' || res.status == 'new_patient' ){
                        console.log(res.login_type)
                        if(res.login_type=='Employee'){
                            console.log("OTP reponse", res)
                            localStorage.setItem('_userData',JSON.stringify(res.data))
                            localStorage.setItem('_patientKey',res.data.patient_n_key)
                            localStorage.setItem('_firstName',res.data.first_name)
                            localStorage.setItem('_mobileNumber',res.data.phone_number)
                            localStorage.setItem('_emailId',res.data.email)
                            localStorage.setItem('status','completed')
                            this.setState({loading:false})
                            this.props.history.push('/home')
                        }
                        else{
                            window.location.assign(`https://app.careme.health/${res.data.patient_n_key}/${res.data.jwt_token}/auth/user`)
                                } 
                            }
                    else if(res.status === "failed"){
                        this.setState({loading:false,otpError:true,errorMessage:res.data})
                    }else{
                        this.setState({loading:false})
                        console.log("OTP error",res)
                    }
                })
                .catch(err => {
                    this.setState({loading:false})
                    console.log("mobile number reponse error",err)
                })
        }
    }

handleTime=()=>{
    setTimeout(() => {
        this.setState({sendNew:true}) 
        console.log('cece')
     }, 60000);    
}

    componentDidMount(){
            <Helmet> 
            <script src="https://websdk.getsocial.im/getsocial.min.js"></script>
        </Helmet>

         this.handleTime() 
    }
    updateUserDetails = async () => {
        global.GetSocialSDK.getCurrentUser().then((currentUser) => {
            if (currentUser.isAnonymous()) {
              console.log('anonymous')
            } else {
              console.log(currentUser)
            }
            if (currentUser.avatarUrl !== '') {
                // global.userInfoComponentRef.current.setState({userAvatarUrl: currentUser.avatarUrl});
            } else {
                // global.userInfoComponentRef.current.setState({userAvatarUrl: undefined});
            }
    
            // global.userInfoComponentRef.current.setState({userDisplayName: currentUser.displayName});
        });
    }
    switchUser = async (customIdentity, fetchFBDetails) => {
        // showLoading();
        global.GetSocialSDK.switchUser(customIdentity).then(() => {
            // hideLoading();
            // Alert.alert('Switch User', 'Successfully switched user.');
           console.log('Switch User', 'Successfully switched user.');
            if (fetchFBDetails) {
                // this.fetchUserProfile();
                console.log('fb')
            } else {
                this.updateUserDetails();
            }
        }, (error) => {
            // hideLoading();
       alert('Error', error.message);
        });
    }
      callAddIdentity = async (access) => {
        const accessToken='secret';
        console.log(global.GetSocialSDK)
        const userId = `${this.props.loginData.data.countryCode +" "+ this.props.loginData.data.mobileNumber}`;
        const customIdentity =  global.GetSocialSDK.Identity.createCustomIdentity('phone',userId , accessToken);
       let user =  global.GetSocialSDK.GetSocial.getCurrentUser()
        user.addIdentity(customIdentity)
        .then((res)=>{
            console.log(res)
            .catch((err)=>{
                console.log(err)
            })
            
        })
                // (e) => {
                //     // hideLoading();
                //     console.log(e)
                //     alert('Custom Identity', 'Custom Identity successfully added.');
                //     this.updateUserDetails();
                // },
                // (conflictUser) => {
                //     // hideLoading();
                //     // Alert.alert('Custom Identity', 'User conflict with remote user', [
                //         // {text: 'Use Remote (' + conflictUser.displayName + ')', onPress: () => {
                //          this.switchUser(customIdentity, false);
                //          console.log('conflict User',conflictUser)
                //         // }},
                //         // {text: 'Use Current', onPress: () => {/* use current user */}},
                //         // {text: 'Cancel', onPress: () => {/* do nothing */}},
                //     // ]);
                // },
                // (error) => {
                //   console.log(error)
                //     // hideLoading();
                //     // Alert.alert('Error', error.message);
                // });
        // });
    }
    render() {
        let { loading, errorMessage, otpError,sendNew } = this.state
        let { t } = this.props;
        return (
            <>
                <div className="choose-language-outer">
                <ToastContainer />
                {loading &&  <Loader />}
                <div className="d-flex">
                        <Col md={6} className="mx-auto">
                            <div className="choose-language-inner">
                                <div className="otp_sec">
                                    <p>Code sent!</p>
                                    <h6>
                                   Verify Your Phone
                                </h6>
                                <span>Enter the code we just sent to<br/>
                                {this.props.loginData.data.email}
                                </span>
                                <form onSubmit={this.verifyOtp}>
                                    <div className="mx-auto col-md-10 reactinput mt-4 ">
                                        <OtpInput
                                            value={this.state.otp}
                                            onChange={this.handleChange}
                                            numInputs={6}
                                            separator={<span> </span>}
                                        /></div>
                                        {otpError && <p className="errorMesage mt-3">{errorMessage}</p>}
                                        <h4 className="mt-md-4 mt-3">
                                  Your code may take up to a minute to arrive.{sendNew?<span onClick={()=>this.resendOtp()}>Send New Code!</span>:<span style={{color:'grey'}}>Send New Code!</span>}
                                     </h4>
                                    <button type="submit" className="btn btn-primary w-100  mt-5"> {t('Verify')}  </button>
                                    </form>
                                   
                                    {/* <div className='resend-link mt-md-4  mt-3'>
                                        <a href="#">{t('Receive code in a call |')} </a>
                                         <button onClick={this.resendOtp}>{t('Resend the code')} </button>

                                    </div> */}
                                </div>
                            </div></Col>

                    </div>
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(withTranslation()(withRouter(OtpValidation)));
