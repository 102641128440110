import React from "react";
import { ArrowLeftCircle } from "react-feather";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import { ScheduleAction } from "../../../../Actions/ScheduleAction";
import Store from "../../../../Store";
import { REACT_APP_API_URL } from "../../../processENV";
import Loader from "../../Loader";

class Problems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      mode: [],
      spots: [],
    };
  }

  componentDidMount() {
    this.getMode();
    console.log(this.props);
  }
  getMode = () => {
    const scheduleData = this.props?.scheduleData?.data;
      let data = {
        patient_n_key: localStorage.getItem("_patientKey"),
        app_prod_n_key: scheduleData?.problem?.app_prod_n_key,
        doc_app_id: scheduleData?.therapist?.employee_n_key,
      };
      fetch(`${REACT_APP_API_URL}/mode_followup/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("selected service", res.data);
          if (res.status === "success") {
            this.setState({ mode: res.data });
          } else {
            console.log("selected service", res.data);
          }
        });
  };
  selectmode = (value) => {
    const scheduleData = this.props?.scheduleData?.data;
    Store.dispatch(
      ScheduleAction({
        mode: value,
        problem: scheduleData?.problem,
        language: scheduleData?.language,
        therapist: scheduleData?.therapist,
        app_payment_n_key:scheduleData?.app_payment_n_key,
        scheduleStep: 4,
        scheduleCurrentStep: 4,
      })
    );
  };
  goBack = () => {
    const scheduleData = this.props?.scheduleData?.data;
    const item = this.props?.item;
    Store.dispatch(
      ScheduleAction({
        scheduleStep: 2,
        scheduleCurrentStep: 2,
        mode: scheduleData?.mode,
        problem: scheduleData?.problem,
        language: scheduleData?.language,
        gender: scheduleData?.gender,
        date: scheduleData?.date,
        therapist: scheduleData?.therapist,
        app_payment_n_key:scheduleData?.app_payment_n_key,
      })
    );
  };

  render() {
    const { mode, loading } = this.state;
    return (
      <div className="col-md-8 mx-auto px-0 pt-5">
         <button onClick={this.goBack} className="btn btn-sm mt-5 mt-md-1 border-none">
          <ArrowLeftCircle color="#215352" size={25} />
        </button>
        {loading && <Loader />}
        <div className="d-flex mx-auto mt-1 px-0">
          <Col className="mx-auto text-center">
            <p
              style={{ fontSize: "26px" }}
              className="h4 text-left _text_color text-md-center header_font_ font-weight-bold"
            >
              Choose Your Mode of Appointment
            </p>
            <p
              style={{ fontSize: "16px" }}
              className="h4 text-left _text_color pt-2 text-md-center header_font_ font-weight-bold"
            >
              {" "}
              We are Providing both Chat and Video/Audio Therapy sessions
            </p>
            <div  className="col-md-6 px-0 mt-md-5 mt-2 mx-auto d-flex flex-column">
              {mode.length>0 &&
                mode.map((item, i) => {
                  return (
                    <button
                    style={{border:'1px solid #ddefe4', fontFamily: "Hanken Grotesk", fontSize: "20px" }}
                    className={"p-3 rounded bg-white _text_color text_p_ shadow-sm text_medium_ my-2"}
                    key={item}
                      onClick={() => this.selectmode(item)}
                    >
                      {item}
                    </button>
                  );
                })}
            </div>
          </Col>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginData: state.LoginData,
    scheduleData: state.ScheduleData,
  };
};

export default connect(mapStateToProps, null)(Problems);
