import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, FormGroup, Label } from 'reactstrap';

class Select extends React.Component {
    render() {
        let { label, name, currentValue, heightOrWeight, selectedValue } = this.props;

        return (
            <>
                {
                    currentValue.length !== 0 ?
                    currentValue.map((item, i) => {
                        return (
                            <FormGroup  className="custom-radio">
                                    <input type="radio" id={item} name={heightOrWeight} checked={selectedValue === item ? true : false}  onClick={(e)=>this.props.onInputChange(e)} value={item}/>{' '}
                                    <Label for={item}>   {item}                            </Label>
                            </FormGroup>
                        )
                    }):''
                }

            </>
        )
    }

}


export default Select
