import React from 'react';
import { connect } from 'react-redux';
import Loader from '../Loader';
import { REACT_APP_API_URL } from '../../processENV';

class Archive extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        let { patientKey, appointmentKey} = this.state;

        let data = {
            "patient_n_key": patientKey,
            "appointment_n_key": appointmentKey
        }

        this.setState({loading:true})
        fetch(`${REACT_APP_API_URL}/patient_update/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
        }).then(response => response.json())
            .then(async (res) => {
                let { questiondata, currentAnswer, currentValue } = this.state;
                let qlen = questiondata.length;
                if (res.status === "success") {
                    console.log("First question", res)
                    console.log(currentValue,'currentValue')
                    if(questiondata[questiondata.length - 1 ]?.ques_id === res.data.ques_id){
                        this.setState({loading:false, currentValue:res.data.values})
                        return true
                    }   
                    else{
                        questiondata[qlen] = res.data
                        questiondata[qlen]['ans'] = ''
                        currentAnswer.key = res.data.parameter
                        currentAnswer.placeholder = res.data.place_holder
                        currentAnswer.type = res.data.field
                        localStorage.setItem('questiondata',JSON.stringify(questiondata))
                        localStorage.setItem('currentAnswer',JSON.stringify(currentAnswer))
                        this.setState({ questiondata: questiondata, loading: false, currentAnswer: currentAnswer,currentValue:res.data.values})
                    }
                    
                }else if(res.status === "completed"){
                    this.setState({loading:false})
                    localStorage.setItem('status','completed')
                    this.props.history.push('/home')
                } else {
                    this.setState({loading:false})
                    console.log("First question error", res)
                }
            })
            .catch(err => {
                this.setState({loading:false})
                console.log("mobile number reponse error",err)
            })
    }


    render() {
        let { loading } = this.state;
        return (
            <>
                <div>
                {loading &&  <Loader />}
                    <p>Archive</p>
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(Archive);
