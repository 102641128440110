
 import React from 'react';

import Loader from 'react-loader-spinner';


 export default class LoaderComponent extends React.Component {
  //other logic
    render() {
     return(
       <div className={"loader-cls"}>  
      <Loader
         type="Puff"
         color="#FFF"
         height={100}
         width={100}
         //timeout={10000} //3 secs
 
      />
      </div>
     );
    }
    
 }