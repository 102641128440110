import React from "react";
import { ArrowLeftCircle } from "react-feather";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import { ScheduleAction } from "../../../../Actions/ScheduleAction";
import Store from "../../../../Store";
import { REACT_APP_API_URL } from "../../../processENV";
import Loader from "../../Loader";

class DateSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      date: [],
      spots: [],
    };
  }

  componentDidMount() {
    this.getMode();
    console.log(this.props);
  }

  getMode = () => {
    const scheduleData = this.props?.scheduleData?.data;
    const item = this.props?.item;
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
      app_prod_n_key: scheduleData?.problem?.app_prod_n_key,
      mode_of_telehealth: scheduleData?.mode,
      preferred_language: scheduleData?.language,
      therapist_gender: scheduleData?.therapist?.gender,
      app_prod_grp_key: item?.app_prod_grp_key,
      employee_n_key: scheduleData?.therapist?.employee_n_key,
    };
    fetch(`${REACT_APP_API_URL}/app_rescheduled_date/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("selected service", res.data);
        if (res.status === "success") {
          this.setState({ date: res.data, spots: res.count });
        } else {
          console.log("selected service", res.data);
        }
      });
  };

  switchStep=(title)=>{
    switch (title) {
      case "Couple Therapy":
        return 4;
      case "Psychiatric Consultation":
        return 3;
      default:
        return 5;
    }
  }
  selectlanguage = (value) => {
    const scheduleData = this.props?.scheduleData?.data;
    const item = this.props?.item;
    Store.dispatch(
      ScheduleAction({
        date: value,
        mode: scheduleData?.mode,
        problem: scheduleData?.problem,
        language: scheduleData?.language,
        gender: scheduleData?.gender,
        therapist:scheduleData?.therapist,
        scheduleStep:this.switchStep(item?.title),
        scheduleCurrentStep:this.switchStep(item?.title),
        app_payment_n_key:scheduleData?.app_payment_n_key,
        pt_key:scheduleData?.pt_key,
      })
    );
  };

  goBack = () => {
    const scheduleData = this.props?.scheduleData?.data;
    const item = this.props?.item;
    Store.dispatch(
      ScheduleAction({
        mode: scheduleData?.mode,
        problem: scheduleData?.problem,
        language: scheduleData?.language,
        gender: scheduleData?.gender,
        date: scheduleData?.date,
        app_payment_n_key:scheduleData?.app_payment_n_key,
        pt_key:scheduleData?.pt_key,
        therapist: scheduleData?.therapist,
        scheduleStep:
          item?.title === "Couple Therapy"?2:
          item?.title === "Psychiatric Consultation"
            ? 1
            : 3,
        scheduleCurrentStep:
          item?.title === "Couple Therapy"?2:
          item?.title === "Psychiatric Consultation"
            ? 1
            : 3,
      })
    );
  };

  render() {
    const { date, loading, spots } = this.state;
    return (
      <div className="col-md-8 mx-auto px-0 pt-5">
          <button onClick={this.goBack} className="btn btn-sm mt-5 mt-md-1 border-none">
          <ArrowLeftCircle color="#215352" size={25} />
        </button>
        {loading && <Loader />}
        <div className="d-flex mx-auto mt-1 px-0">
          <Col className="mx-auto text-center">
            <p
              style={{ fontSize: "26px" }}
              className="h4 text-left _text_color text-md-center header_font_ font-weight-bold"
            >
              Select Date
            </p>
            <p
              style={{ fontSize: "16px" }}
              className="h4 text-left _text_color pt-2 text-md-center header_font_ font-weight-bold"
            >
              You can schedule your appointments now based on your convenience
            </p>
            <div className="col-md-6 px-0 mt-md-5 mt-2 mx-auto d-flex flex-column">
              {date.length > 0 &&
                date.map((item, i) => {
                  return (
                    <button
                      style={{border:'1px solid #ddefe4', fontFamily: "Hanken Grotesk", fontSize: "20px" }}
                      className={"p-3 rounded bg-white _text_color text_p_ position-relative shadow-sm text_medium_ my-2"}
                      key={item}
                      onClick={() => this.selectlanguage(item)}
                    >
                      {item}
                      <br />
                      <small
                        style={{ fontSize: "12px", right: 5, bottom: 3 }}
                        className="text-right position-absolute font-weight-bold"
                      >
                        {spots[i]} slots{" "}
                      </small>
                    </button>
                  );
                })}
            </div>
          </Col>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginData: state.LoginData,
    scheduleData: state.ScheduleData,
  };
};

export default connect(mapStateToProps, null)(DateSelect);
