import React from 'react';
import { connect } from 'react-redux';
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Collapse
} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
    FormGroup,
    Label,
} from 'reactstrap';
import Loader from '../../Layouts/Loader';
import { REACT_APP_API_URL } from '../../processENV';
import Flatpickr from "react-flatpickr";
import { MinusCircle, PlusCircle, XCircle } from 'react-feather';
import { withTranslation } from 'react-i18next';

class Alcohol extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            youAre: '',
            typeOf: '',
            quitting: '',
            stoppedOn: '',
            frequency: '',
            per: '',
            loading: false,
            isOpen: false,
            comments: '',
            alcoholList: []
        };
    }

    componentDidMount() {
        this.getAlcoholList();
    }

    getAlcoholList = () => {
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        fetch(`${REACT_APP_API_URL}/alcohol_details_list/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
        }).then(response => response.json())
            .then(async (res) => {
                console.log('alcohol list', res)
                this.setState({ alcoholList: res })

            })
            .catch(err => console.log('alcohol list error', err))
    }

    deleteAlcohol = (id) => {
        fetch(`${REACT_APP_API_URL}/alcoholhistory/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            }
        }).then(response => response.json())
            .then(async (res) => {
                console.log('delete alcohol', res)
                this.getAlcoholList()
            })
            .catch(err => console.log('alcohol list error', err))
    }

    alcoholInputHandler = (e) => {
        let id = e.target.name;
        this.setState({
            [id]: e.target.value,
            error:''
        })
    }

    collapsePlan = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    submitAlcoholHistory = (e) => {
        let { youAre, typeOf, quitting, stoppedOn, comments, frequency, per } = this.state;
        var today = new Date();
        let dd = (today.getDate.toString().length === 1) ? ('0' + today.getDate()) : today.getDate();
        let mm = (today.getMonth().toString().length === 1) ? ('0' + (today.getMonth() + 1)) : (today.getMonth() + 1);
        let yy = (today.getFullYear().toString().length === 1) ? ('0' + today.getFullYear()) : today.getFullYear();
        let date = yy + '-' + mm + '-' + dd;

        if(!youAre){
            this.setState({
                error : "please fill all the fields"
            })
        }
        else if(youAre === "Drinker" && (!per || !frequency || !quitting || !comments || !typeOf)){ 
            this.setState({
                error : "please fill all the fields"
            })
        }else if(youAre === "Ex-Drinker" && (!per || !frequency || !stoppedOn || !comments || !typeOf)){
            this.setState({
                error : "please fill all the fields"
            })
        } else {
            let data = {}
            if (youAre === "Non-Drinker" || youAre === "Drinker") {
                data = {
                    "patient_n_key": localStorage.getItem('_patientKey'),
                    "appointment_n_key": localStorage.getItem('_appointmentKey'),
                    "use": youAre,
                    "per": per,
                    "type": typeOf,
                    "frequency": frequency,
                    "stopped_on": date,
                    "comments": comments,
                    "intrested_in_quitting": quitting,
                    "assessment_date": date
                }
            } else {
                data = {
                    "patient_n_key": localStorage.getItem('_patientKey'),
                    "appointment_n_key": localStorage.getItem('_appointmentKey'),
                    "use": youAre,
                    "per": per,
                    "type": typeOf,
                    "frequency": frequency,
                    "stopped_on": stoppedOn,
                    "comments": comments,
                    "intrested_in_quitting": quitting,
                    "assessment_date": date
                }
            }
            this.setState({ loading: true, error: '' })
            console.log('smoking data', data)
            fetch(`${REACT_APP_API_URL}/alcoholhistory/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
                },
                body: JSON.stringify(data),
            }).then(response => response.json())
                .then(async (res) => {
                    if (res.status === "success") {
                        this.getAlcoholList()
                        this.setState({
                            loading: false,
                            youAre: '',
                            typeOf: '',
                            quitting: '',
                            stoppedOn: '',
                            comments: '',
                            per: '',
                            frequency: '',
                            loading: false
                        })
                    } else {
                        this.setState({ loading: false, error: 'Some error has occured please try again' })
                    }
                })
        }
    }

    handleDateChange = ddd => {

        if (ddd === null || ddd === "") {
            this.setState({
                stoppedOn: null
            });
        } else {
            let date = new Date(ddd);

            let dd = (date.getDate.toString().length === 1) ? ('0' + date.getDate()) : date.getDate();
            let mm = (date.getMonth().toString().length === 1) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1);
            let yy = (date.getFullYear().toString().length === 1) ? ('0' + date.getFullYear()) : date.getFullYear();

            let fromDate = yy + "-" + mm + "-" + dd;
            this.setState({
                stoppedOn: fromDate
            });

        }
    };

    render() {
        let { loading, stoppedOn, error, youAre, typeOf, quitting, isOpen, alcoholList, per, comments, frequency } = this.state;
        let { t } = this.props;

        return (
            <>
                <Col md={8} xs={12} className="mb-2 px-0">
                    {loading && <Loader />}
                    <Card >
                        <CardSubtitle tag="h5" className="mt-2  text-center text-muted"> {t('Alcohol Habit')}<span className="cusicon" onClick={this.collapsePlan}> {isOpen ? <MinusCircle /> : <PlusCircle />}</span></CardSubtitle>
                        <Collapse isOpen={isOpen}>

                            <CardBody className="col-md-9 mx-auto">
                                <Formik
                                    initialValues={{
                                        name: '',
                                        email: '',
                                    }}
                                    // validationSchema={BasicProfile}
                                    onSubmit={this.submitAlcoholHistory}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <Row >
                                                <FormGroup className="w-100 m-0">
                                                    <label>{t('ALCOHOL HISTORY')}</label>

                                                </FormGroup>
                                                <div className="d-flex col-md-12 px-md-0">

                                                    <FormGroup className="mr-3" >
                                                        <Label check>
                                                            <input type="radio" checked={youAre === "Drinker" ? true : false} value="Drinker" name="youAre" onChange={(e) => this.alcoholInputHandler(e)} />{' '}
                                                            {t('Drinker')}</Label>
                                                    </FormGroup>
                                                    <FormGroup className="mr-3" >
                                                        <Label check>
                                                            <input type="radio" checked={youAre === "Non-Drinker" ? true : false} value="Non-Drinker" name="youAre" onChange={(e) => this.alcoholInputHandler(e)} />{' '}
                                                            {t('Non Drinker')}</Label>
                                                    </FormGroup>
                                                    <FormGroup className="mr-3" >
                                                        <Label check>
                                                            <input type="radio" checked={youAre === "Ex-Drinker" ? true : false} value="Ex-Drinker" name="youAre" onChange={(e) => this.alcoholInputHandler(e)} />{' '}
                                                            {t('Ex Drinker')}</Label>
                                                    </FormGroup>  </div>
                                                {youAre !== "Non-Drinker" ?
                                                    <React.Fragment>
                                                        <FormGroup className="pl-md-0 w-100">
                                                            <label>Frequency</label>
                                                            <Field as="select" name="frequency" className="form-control" onChange={(e) => this.alcoholInputHandler(e)}>
                                                                <option value="">Select Type</option>
                                                                <option value="Less than month">Less than month</option>
                                                                <option value="1-2days a month">1-2days a month</option>
                                                                <option value="1-2days a week">1-2days a week</option>
                                                                <option value="3-4days a week">3-4days a month</option>
                                                            </Field>
                                                            {errors.para && touched.para ? (
                                                                <ErrorMessage name="frequency" />
                                                            ) : null}
                                                        </FormGroup>

                                                        <FormGroup className="w-100 m-0">
                                                            <label>Per</label>

                                                        </FormGroup>
                                                        <div className="d-flex col-md-12 px-md-0">

                                                            <FormGroup className="mr-3" >
                                                                <Label check>
                                                                    <input type="radio" checked={per === "Day" ? true : false} value="Day" name="per" onChange={(e) => this.alcoholInputHandler(e)} />{' '}
                                                                    Day</Label>
                                                            </FormGroup>
                                                            <FormGroup className="mr-3" >
                                                                <Label check>
                                                                    <input type="radio" checked={per === "Week" ? true : false} value="Week" name="per" onChange={(e) => this.alcoholInputHandler(e)} />{' '}
                                                                    Week</Label>
                                                            </FormGroup>
                                                            <FormGroup className="mr-3" >
                                                                <Label check>
                                                                    <input type="radio" checked={per === "Month" ? true : false} value="Month" name="per" onChange={(e) => this.alcoholInputHandler(e)} />{' '}
                                                                    Month</Label>
                                                            </FormGroup>
                                                        </div>

                                                        {youAre !== "Ex-Drinker" ?
                                                            <React.Fragment>
                                                                <FormGroup className="w-100 m-0">
                                                                <label>{t('Interested in Quitting')}?</label>
                                                                </FormGroup>
                                                                <div className="d-flex col-md-12 px-md-0">
                                                                    <FormGroup className="mr-3" >
                                                                        <Label check>
                                                                            <input type="radio" checked={quitting === "Yes" ? true : false} value="Yes" name="quitting" onChange={(e) => this.alcoholInputHandler(e)} />{' '}
                                                                            {t('Yes')}</Label>
                                                                    </FormGroup>
                                                                    <FormGroup className="mr-3" >
                                                                        <Label check>
                                                                            <input type="radio" checked={quitting === "No" ? true : false} value="No" name="quitting" onChange={(e) => this.alcoholInputHandler(e)} />{' '}
                                                                            {t('No')}</Label>
                                                                    </FormGroup>
                                                                </div>
                                                            </React.Fragment> :
                                                            <></>}

                                                        <FormGroup className="pl-md-0 w-100">
                                                            <label>Type</label>
                                                            <Field as="select" name="typeOf" value={typeOf} className="form-control" onChange={(e) => this.alcoholInputHandler(e)}>
                                                            <option value="">Select Type</option>
                                                            <option value="social">{t('Social')}</option>
                                                            <option value="light">{t('Light')}</option>
                                                            <option value="heavy">{t('Heavy')}</option>
                                                            <option value="moderate">{t('Moderate')}</option>
                                                            </Field>
                                                            {errors.para && touched.para ? (
                                                                <ErrorMessage name="para" />
                                                            ) : null}
                                                        </FormGroup>
                                                        {youAre !== "Drinker" ? <FormGroup className="w-100 mx-0" >
                                                            <Label check className="w-100">  Stopped On
                                                                        <Flatpickr
                                                                    className="form-control w-100"
                                                                    selected={stoppedOn}
                                                                    value={stoppedOn}
                                                                    options={{ maxDate: new Date() }}

                                                                    onChange={date => this.handleDateChange(date)}
                                                                />
                                                            </Label>
                                                        </FormGroup>
                                                            : <></>}
                                                        <FormGroup className="w-100 mx-0" >
                                                            <Label check className="w-100"> Comments
                                                                        <Field name="comments" value={comments} className="form-control" placeholder="Comments" onChange={(e) => this.alcoholInputHandler(e)} />
                                                                {errors.name && touched.name ? (
                                                                    <ErrorMessage name="fname" />

                                                                ) : null}
                                                            </Label>
                                                        </FormGroup>
                                                    </React.Fragment>
                                                    :
                                                    <></>}

                                            </Row>
                                            <div className="text-center">
                                                {error && <p className="errorMesage mt-3">{error}</p>}
                                                <button type="submit" className="btn btn-primary mx-auto">{t('UPDATE')}</button>


                                            </div>
                                        </Form>
                                    )}
                                </Formik>                                </CardBody>
                        </Collapse>
                        {alcoholList && alcoholList.length !== 0
                            ? alcoholList.map(item => {
                                return <div className="allhistory"><Col md={8} xs={12} className="mb-2 mx-auto indhistory">
                                    <div className="tagsec">
                                        <p><h6 className="m-0">{item.use}</h6><a onClick={() => this.deleteAlcohol(item.alcohol_history_id)}><XCircle /></a></p>

                                        <p> <span>{item.type}</span>
                                        </p>
                                    </div>
                                </Col>                                                         </div>


                            })
                            : <></>}
                    </Card>
                </Col>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(withTranslation()(Alcohol));
