import React from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShowMoreText from "react-show-more-text";
import { CDN_URL, REACT_APP_API_URL } from "../../processENV";
import { useEffect } from 'react';
import { useState } from 'react';
import { saveAs } from "file-saver";
import { ArrowDown, Download, Play,  ArrowRight,
} from "react-feather";
import {
  AiFillEye,
  AiOutlineCloudDownload,
  AiOutlineDownload,
} from "react-icons/ai";
import dwnic from '../../../assets/images/dwnic.png';
import vwic from '../../../assets/images/vwic.png';

const Exercise = (props) => {
const[documentList,setDocumentList]=useState([]);
const[url,setUrl]=useState([
  `${CDN_URL}/APP/untitled-1/1.png`,
   `${CDN_URL}/APP/untitled-1/3.png`,
  `${CDN_URL}/APP/untitled-1/4.png`,
  `${CDN_URL}/APP/untitled-1/5.png`,

]); 
const [urlss,setUrlss]=useState([
  `${CDN_URL}/APP/untitled-1/6.png`,
  `${CDN_URL}/APP/untitled-1/7.png`,
  `${CDN_URL}/APP/untitled-1/8.png`,
  `${CDN_URL}/APP/untitled-1/9.png`,
]);

 const  downld = async (document_attachment, document_name) => {
    saveAs(document_attachment, document_name);
  };
 const getDocumentList =async() => {
    let data = {patient_n_key: localStorage.getItem("_patientKey")};
    fetch(`${REACT_APP_API_URL}/list_exerciseapp/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("document list", res);
        setDocumentList(res.data);
      })
      .catch((err) => console.log("exercise list error", err));
  };
  useEffect(()=>{
getDocumentList()
  },[])
  return (
    <div className='container'>
    <div className="col-md-8 mx-auto mt-5  pt-5">
    <ToastContainer />
    <h3 style={{textDecorationLine:'underline'}} className='header_font_ _text_color text-center'>Therapy Exercise Planner</h3>
    {documentList.length !== 0 ? (
      <div className='mt-5'> 
        {documentList.map((i, indx) => {
          return (
            <div style={{border:'.2px solid #215352'}} className="row shadow-sm col-md-7  mt-3  d-flex mx-auto flex-row  position-relative  rounded">
            <div className=" p-2 row">
                 <img src={indx%2 == 0 ? url[Math.floor(Math.random() * url.length)]:urlss[Math.floor(Math.random() * urlss.length)] }
                  style={{width:'60px',height:'60px'}}
                  />
               <div className='col p-2'>
                  <p className='header_font_ _text_color'> {i.exe_name}</p>
                          <ShowMoreText
                            className='mt-n3 _text_color text_p_'
                            lines={2}
                            more="Show more"
                            less="Show less"
                            expanded={false}
                            truncatedEndingComponent={"... "}
                            >
                            <p className='mt-n3' >{i.overview}</p>
                          </ShowMoreText>
                 </div>
                 </div>
                  <div style={{top:20,right:10}} className=" p-2  position-absolute">  {i.attachment_type == 'Link' ? 
                    <a href={i.url} target="_blank">
                    <img src={vwic} style={{width:'20px',height:'20px'}}  className="dow_ic_exc" />{" "}
                    </a>:
                    <img
                    style={{width:'20px',height:'20px'}} 
                    src={dwnic}
                  onClick={() =>
                    downld(i.document, i.exe_name)
                  }
                  className="dow_ic_exc"
                 
                />}</div>
              </div>
          );
        })}
      </div>
    ) : (
      <div
      className="row col-md-6 mx-auto my-auto mt-md-5"
      // onClick={() => window.location.replace("offerings")}
    >
      <div className="mt-md-5">
        <h4 className="text-center font-weight-bold h3">We Can’t wait to meet you!</h4>
      <p className="text-center  mt-4 h5">Schedule your 1st appointment to get connected to your CareMe Therapist.</p>
      <p className="text-center mt-5 h5">
        <span className="h5">Need help getting started?</span><br/>
        Reach out to <a href=''>care@careme.health</a> and a team member will respond as soon as possible.</p>
        
    </div>
    <div className="text-center mx-auto mt-4">
        <button style={{background:'#215352',color:'#fff',fontWeight:'bold'}} className="btn btn-md btn-block " onClick={() => window.location.replace("offerings")}>Book your first session <ArrowRight /> </button>
    </div>
    </div>
    )}
  </div>
  </div>

  )
}

export default Exercise;