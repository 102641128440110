import axios from "axios";
import { API_V_URL, CURE_URL, REACT_APP_API_URL, REACT_APP_BU_API_URL } from "../Components/processENV";

export const headers = {
  'Content-Type': 'application/json',
  'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29',
};

export const headerss= {
  "Authorization":"Token 70c48f814c3a6cc1b4e1dcb0ea4b2cc0713c90bf"
}

const handleSuccess = (response) => response.data;

const handleError = (error) => {
  console.error('API Request Error:', error);
  throw error; // Re-throw the error to propagate it further
};

export const synapostApi = (url, data) => {
  return axios.post(CURE_URL + url, data, {headers:headerss })
    .then(handleSuccess)
    .catch(handleError);
};

export const postapi = (url, data) => {
    return axios.post(REACT_APP_API_URL + url, data, { headers })
      .then(handleSuccess)
      .catch(handleError);
};

export const bupostapi = (url, data) => {
  return axios.post(REACT_APP_BU_API_URL + url, data, { headers })
    .then(handleSuccess)
    .catch(handleError);
};

export const synapgetApi = (url, params) => {
  return axios.get(CURE_URL + url, { params, headers })
    .then(handleSuccess)
    .catch(handleError);
};

export const bugetApi = (url, params) => {
  return axios.get(REACT_APP_BU_API_URL + url, { params, headers })
    .then(handleSuccess)
    .catch(handleError);
};
export const getapi = (url, params) => {
  return axios.get(REACT_APP_API_URL + url, { params, headers })
    .then(handleSuccess)
    .catch(handleError);
};

export const updateapi = (url, data) => {
  return axios.put(REACT_APP_API_URL + url, data, { headers })
    .then(handleSuccess)
    .catch(handleError);
};

export const patchapi = (url, data) => {
  return axios.patch(REACT_APP_API_URL + url, data, { headers })
    .then(handleSuccess)
    .catch(handleError);
};

export const deleteapi = (url) => {
  return axios.delete(REACT_APP_API_URL + url, { headers })
    .then(handleSuccess)
    .catch(handleError);
};


export const postdata = (url, data) => {
  return axios.post(API_V_URL + url, data, { headers })
    .then(handleSuccess)
    .catch(handleError);
};
export const getdata = (url, params) => {
  return axios.get(API_V_URL + url, { params, headers })
    .then(handleSuccess)
    .catch(handleError);
};

export const getConfirmAppointment = (key)=> {
  let JsonData = {patient_n_key:key};
  const res = postapi('upcoming_appoints/',JsonData)
  return res  
}
export const patientResponse =async(data)=>{
  let JsonData = {patient_n_key:data}
  const resp = await postapi('/patientedit_app/', JsonData);
    if (resp.status === 'success') {
      return resp.data;
    }
    return null;
} 