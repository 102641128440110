import React from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { ArrowLeftCircle, Flag } from 'react-feather';
import Store from '../../../../Store';
import { ScheduleAction } from '../../../../Actions/ScheduleAction';
import Loader from '../../Loader';
import { REACT_APP_API_URL, REACT_APP_BU_API_URL } from '../../../processENV';
class Gender extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            gender: [],
           
        };
    }
    getMode=()=>{
        let key=this.props?.item.app_prod_n_key
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey'),
            "app_prod_n_key":this.props.scheduleData.data.problem.app_prod_n_key||this.props.scheduleData.data.data1.app_prod_n_key,
            "mode_of_telehealth":this.props.scheduleData.data.mode||'Video',
            "preferred_language":this.props.scheduleData.data.language,
            "app_prod_grp_key":this.props.scheduleData.data.data1.app_prod_grp_key

        }
        fetch(`${REACT_APP_API_URL}/book_gender/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
            console.log('selected service',res.data)
              if(res.status === "success"){
                  if(res.data.length ===1){
                    Store.dispatch(ScheduleAction({
                        gender:res.data[0],
                        mode:this.props.scheduleData.data.mode,
                        problem:this.props.scheduleData.data.problem,
                        language:this.props.scheduleData.data.language,
                        scheduleStep:5,
                        scheduleCurrentStep:5,
                        data1:this.props.scheduleData.data.data1,
                       app_payment_n_key:this.props.scheduleData.data.app_payment_n_key,
                     }));
                  }
                  else{
                this.setState({gender:res.data})
                  }
              }else{
                console.log('selected service',res.data)
              }
          })
    }
   
    componentDidMount() {
        console.log(this.props)
       this.getMode() 
    }

    selectlanguage = ( value) =>{
            Store.dispatch(ScheduleAction({
               gender:value,
               mode:this.props.scheduleData.data.mode,
               problem:this.props.scheduleData.data.problem,
               language:this.props.scheduleData.data.language,
               scheduleStep:5,
               scheduleCurrentStep:5,
               data1:this.props.scheduleData.data.data1,
               app_payment_n_key:this.props.scheduleData.data.app_payment_n_key,
            }));
          
    }
    
    goBack = () =>{
        Store.dispatch(ScheduleAction({
            scheduleStep:3,
            scheduleCurrentStep:3,
            mode:this.props.scheduleData.data.mode,
            problem:this.props.scheduleData.data.problem,
            language:this.props.scheduleData.data.problem,
            data1:this.props.scheduleData.data.data1,
            app_payment_n_key:this.props.scheduleData.data.app_payment_n_key,
         }));
    }

    render() {
        let { gender, time, loading,  selectedTime, spots } = this.state;
        return (
            <>
                <div className="col-md-8 mx-auto bg-none mt-5 pt-5">
                <a onClick={this.goBack}className="p-4 m-4">
                <ArrowLeftCircle size={25} /></a>
                     {loading && <Loader />}
                    <div className="d-flex mx-auto mt-5">
                        <Col className="mx-auto text-center">
                            <p style={{fontSize:'26px'}} className='h4 header_font_ font-weight-bold'>We speak your language
                             </p>
                            <p style={{fontSize:'16px'}} className='h4 header_font_ font-weight-bold'>
                            Choose a language and we’ll match you with a consultant suitable for you

                            </p>
                            <div className=" mt- 5 pt-5 col-md-6 mx-auto">
                                {gender && gender.map((item, i)=>{
                                    return <p style={{fontFamily:'Hanken Grotesk',fontSize:'20px'}} className={'p-3 border text_p_ shadow-sm text_medium_'} key={i} onClick={()=>this.selectlanguage(item)}>{item}
                                    </p>
                                })}
                            </div></Col>

                    </div>
                </div>

            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData,
        scheduleData: state.ScheduleData
    }
}

export default connect(mapStateToProps, null)(Gender);
