import React from "react";
import {
  Container, Row, Col, Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle
} from 'reactstrap';
import profile from '../../../../assets/images/profilepic.png'
import emergency from '../../../../assets/images/doc.png'

import { ChevronRight } from 'react-feather';
import { Link } from "react-router-dom";

const ServiceCard = props => {
  let { name, subhead, prodId, productImage } = props;
  return (

    <Col className="service_card col-sm-6" md="4" sm="6">
      {/* <p>Service {name}</p> */}
      <Card>
        <Link to={"/service/" + prodId}>
          <Row>
            <Col md="3" className="px-2  col-3 ">
              <img src={productImage} />
            </Col>
            <Col md="7" className="px-2 col-7" >
              <CardTitle tag="h5" > {name}</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">{subhead}</CardSubtitle>
            </Col>
            <Col md="2" className="pl-0  col-2">
              <ChevronRight size={40} />
            </Col>
          </Row>          </Link>

      </Card>
    </Col>
  )
};

export default ServiceCard;