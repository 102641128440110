import React from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { Flag } from 'react-feather';
import Store from '../../../Store';
import { GetCareStateAction } from '../../../Actions/GetCareAction';
import Loader from '../Loader';
import { REACT_APP_API_URL } from '../../processENV';

class SelectDate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            date: [],
            selectedDate:'',
            spots : []
        };
    }

    componentDidMount() {
        let data = {
            "preferred_language":this.props.getCareData.data?.language,
            "app_prod_n_key":"APP_PROD-1"
        }
        this.setState({loading:true})
        fetch(`${REACT_APP_API_URL}/available_date/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
                console.log("appoint date",res)
                if(res.status === "success"){
                    this.setState({loading:false, date:res.data, spots:res.count})
                }else{
                    this.setState({loading:false,error:'Some error has occured please try again'})
                }
            })
    }

    selectDate = ( value) =>{
        this.setState({selectedDate:value},()=>{
            Store.dispatch(GetCareStateAction({
                getCareStep:3,
                getCareCurrentStep:3,
                date: this.state.selectedDate,
                time: this.props.getCareData.data?.time,
                language: this.props.getCareData.data?.language
            }));
        })
        
    }

    getTime = () =>{

        
    }

    render() {
        let { date, time, loading, selectedDate, selectedTime, spots } = this.state;
        return (
            <>
                <div className="choose-language-outer bg-none">
                    {loading && <Loader />}
                    <div className="d-flex">
                        <Col md={6} className="mx-auto text-center choose-language-inner px-0">
                            <p>You can schedule your appointment date now based on your convenience and check your meeting schedule on your home screen. </p>
                            {selectedDate && <p>{selectedDate}</p>}
                            <div className="choose-date-container">
                                {date && date.map((item, i)=>{
                                    return <p className={'date-time-card'} key={i} onClick={()=>this.selectDate(item)}>{item}
                                     <span>{spots[i]} spots </span>
                                    </p>
                                })}
                            </div></Col>

                    </div>
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData,
        getCareData: state.GetCareData
    }
}

export default connect(mapStateToProps, null)(SelectDate);
