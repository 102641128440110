import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import  slide1 from '../../../assets/images/slide1.png';
import  slide2 from '../../../assets/images/slide2.png';
import  slide3 from '../../../assets/images/slide3.png';
import  slide4 from '../../../assets/images/slide4.png';
import  slide5 from '../../../assets/images/slide5.png';
import Slider from "react-slick";

import { withTranslation } from 'react-i18next';
import { ArrowRight } from 'react-feather';

class SignUp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            mobileNumber: '',
            countryCode: ''
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.loginData !== this.props.loginData) {
            this.setState({
                step: this.props.loginData.data?.step,
                mobileNumber: this.props.loginData.data?.mobileNumber,
                countryCode: this.props.loginData.data?.countryCode
            })
        }
    }


    render() {
        let { step, mobileNumber, countryCode } = this.state;
        let { t } = this.props;
        const testimonials = {
            dots: true,
            // infinite: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            // autoplay: true,
            speed: 1000,
            arrows:false,   
            // autoplaySpeed: 2000,
            cssEase: "linear",
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <>
                <div className={"signup-main"}>

                    <Row className='m-0'>

                        <Col md={8} className="col-sm-push-12 order-lg-12  px-0 px-md-auto pr-0">
                            {/* <Col md={12} className="col-sm-push-12 order-lg-12 pt-4 text-right">
                    </Col> */}
                            <div className="signup-inner ">
                                <h2>Four steps away from<br /> reaching us</h2>

                                <div className="signup-card col-md-5 mx-auto">
                                    <Slider {...testimonials}>
                                             <Card>
                                                <CardBody>
                                                    <img src={slide1} alt=""/>
                                                    {/* <h3>{t('Determine your problem')} </h3>
                                                    <h6>{t('Get diagnosed, anywhere & anytime')} </h6> */}
                                              </CardBody>
                                            </Card> 
                                            <Card>
                                                <CardBody>
                                                    <img src={slide2} alt=""/>
                                                    {/* <h3>{t('Get expert opinion')} </h3>
                                                    <h6>{t('Talk to experts from around the world')} </h6> */}
                                              </CardBody>
                                            </Card> 
                                            <Card>
                                                <CardBody>
                                                    <img src={slide3} alt=""/>
                                                    {/* <h3>{t('Skip unnecessary hospital visits')} </h3>
                                                    <h6>{t('Because everything you need is just a click away')} </h6> */}
                                      
                                              </CardBody>
                                            </Card> 
                                            <Card>
                                                <CardBody>
                                                    <img src={slide4} alt=""/>
                                                    {/* <h3>{t('100% secure connection')} </h3>
                                                    <h6>{t('Your records are safe with us')} </h6> */}
                                              </CardBody>
                                            </Card> 
                                            <Card>
                                                <CardBody>
                                                    <img src={slide5} alt=""/>
                                                    {/* <h3>{t('100% secure connection')} </h3>
                                                    <h6>{t('Your records are safe with us')} </h6> */}
                                              </CardBody>
                                            </Card> 
                                    </Slider>

                                </div>
                                <button className="btn btn-primary d-md-none d-sm-none d-xl-none d-block mx-auto mt-5"   onClick={() => this.props.navigateComponent(0)}>{t('Get Started')}<ArrowRight size={25} /> </button>
                                <button className="btn btn-primary d-md-block d-sm-block d-xl-block d-none mx-auto mt-5" onClick={() => this.props.navigateComponent(0)}>{t('Get Started')}<ArrowRight /></button>

                            </div>
                        </Col>
                        <Col md={4} className="col-sm-push-1 order-lg-1 px-0 bg-background align-items-center ">
                            <div className={`left-content-outer mx-auto`} >
                                <div className="left-content-inner">
                                    <div className="">
                                        <h1>CareMe Health</h1>
                                        <h5>Your personal healthcare assistant</h5>
                                        <img className='slide_img' src={slide2} />
                                        {this.props.step === 3 ? <h2 className="mt-4"> Knowing more about <br /> you means helping  <br /> you better. Talk to Cici </h2> : ''}
                                        {this.props.step !== 3 ? <h4>Determine your problem</h4> : ''}
                                        {this.props.step !== 3 ? <h6>Get diagnosed, <br /> anywhere & anytime</h6> : ''}
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(withTranslation()(SignUp));
