import React from 'react';
import Confetti from 'react-confetti';

const FullScreenConfetti = () => {
  return (
    <div className='full-screen-confetti'>
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        numberOfPieces={240}
      />
    </div>
  );
};

export default FullScreenConfetti;
