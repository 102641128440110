import React from 'react'
import { Modal,ModalBody } from 'reactstrap';
import cml from '../../assets/images/cml.gif'

const Caremeloader = (props) => {
  return (
    <div>
        <Modal centered size='md' backdrop='static' keyboard={false} isOpen={true}   modalClassName="modal-fullscreen">
        <ModalBody >
          <div className='text-center py-5'>
          <img 
            className='img-fluid text-center py-5'
            src={cml}
            style={{width:'140px',height:'240px'}}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default Caremeloader;