import React from 'react';
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle
} from 'reactstrap';
import { connect } from 'react-redux';

import {
    FormGroup,
    Label,
} from 'reactstrap';
import Loader from '../Loader';
import { XCircle } from 'react-feather';
import { REACT_APP_API_URL } from '../../processENV';
import Obstetric from './Obstetric';
import { withTranslation } from 'react-i18next';

class History extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            allergyList:[],
            alcoholList:[],
            smokingList:[],
             obstetricList:[],
         };
    }

    componentDidMount() {
        this.getAllergyList();
        this.getSmokingList();
        this.getAlcoholList();
        this.getMenstrualList();

    }

    getAllergyList = () =>{
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        fetch(`${REACT_APP_API_URL}/allergy_details_list/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('allergy list',res)
             this.setState({allergyList: res})
                  
          })
          .catch(err => console.log('allergy list error',err))
      }

      deleteAllergy = (id) =>{
        fetch(`${REACT_APP_API_URL}/allergichistory/${id}/`, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          }
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('delete allergy',res)
             this.getAllergyList()
          })
          .catch(err => console.log('allergy list error',err))
      }

      getSmokingList = () =>{
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        fetch(`${REACT_APP_API_URL}/tobacco_details_list/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('smoking list',res)
             this.setState({smokingList: res})
                  
          })
          .catch(err => console.log('smoking list error',err))
      }

      deleteSmoking = (id) =>{
        fetch(`${REACT_APP_API_URL}/tobaccohistory/${id}/`, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          }
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('delete smoking',res)
             this.getSmokingList()
          })
          .catch(err => console.log('smoking list error',err))
      }


      getAlcoholList = () =>{
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        fetch(`${REACT_APP_API_URL}/alcohol_details_list/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('alcohol list',res)
             this.setState({alcoholList: res})
                  
          })
          .catch(err => console.log('alcohol list error',err))
      }

      deleteAlcohol = (id) =>{
        fetch(`${REACT_APP_API_URL}/alcoholhistory/${id}/`, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          }
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('delete alcohol',res)
             this.getAlcoholList()
          })
          .catch(err => console.log('alcohol list error',err))
      }
  
      deleteMenstrual = (id) =>{
        fetch(`${REACT_APP_API_URL}/menstrual_history//${id}/`, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          }
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('delete Menstrual',res)
             this.toastError(res.data)
             this.getMenstrualList()
          })
          .catch(err => console.log('alcohol list error',err))
      }

      getMenstrualList = () =>{
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        fetch(`${REACT_APP_API_URL}/menstrual_list/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('Menstrual list',res)
             this.setState({obstetricList: res})
                  
          })
          .catch(err => console.log('allergy list error',err))
      }

    render() {
        let { loading, allergyList, alcoholList, smokingList, obstetricList, menstrualList, gender } = this.state;
        let { t } = this.props;

        return (
            <>
               <Col md={10} xs={12} className="mb-2 px-0 allhistory">
                    {loading && <Loader/>}
                <Card >
                    <CardSubtitle tag="h5" className="mt-2  text-center text-muted">{t('History')}</CardSubtitle>
                    <CardBody className="col-md-8 col-12  mx-auto">
                    {allergyList.length !== 0 ?  <Col md={10} xs={12} className="mb-2 mx-auto indhistory">
                        <h5><span>{t('YOUR ALLERGIES')}</span></h5>
                         {allergyList && allergyList.map(item =>{
                             return <div className="tagsec">
                             <p> <h6 className="m-0">{item.substance}</h6> <a onClick={() => this.deleteAllergy(item.allergic_his_id)}><XCircle /></a></p> 
                          <p>  <span>{item.comments} </span>
                              </p>  
                          </div>
                         }) }
                    </Col>:''}
                    {smokingList.length !== 0 ?     <Col md={10} xs={12} className="mb-2 mx-auto indhistory">
                        <h5><span>{t('TOBACCO HISTORY')}</span></h5>
                          {smokingList && smokingList.map(item =>{
                             return <div className="tagsec">
                             <p><h6 className="m-0">{item.tobacco_use}</h6><a onClick={() => this.deleteSmoking(item.tobacco_history_id)}><XCircle /></a></p>
                                <p> {item.tobacco_use !== "Non-smoker" && <span>{item.no_of_packs_perday}  Pack for {item.no_of_years} years </span>}
                            </p> 
                        </div>
                         }) }
                    </Col>:''}
                  {alcoholList.length !== 0 ?    <Col md={10} xs={12} className="mb-2 mx-auto indhistory">
                        <h5><span>{t('ALCOHOL HISTORY')}</span></h5>
                          {alcoholList && alcoholList.map(item =>{
                             return <div className="tagsec">
                             <p><h6 className="m-0">{item.use}</h6><a onClick={() => this.deleteAlcohol(item.alcohol_history_id)}><XCircle /></a></p>
                                <p> <span>{item.type}</span>
                            </p> 
                        </div>
                         }) }
                    </Col>:''}
                    {obstetricList.length !== 0 && gender =="Female" ?    <Col md={10} xs={12} className="mb-2 mx-auto indhistory">
                        <h5><span>{t('OBSTETRIC HISTORY')}</span></h5>
                          {obstetricList && obstetricList.map(item =>{
                             return <div className="tagsec">
                            <p><h6 className="m-0 obs">{item.obstetric_his_para} Pregrancies beyond 24 weeks with {item.obstetric_his_abortion} abortions</h6>
                             <a onClick={() => this.deleteMenstrual(item.menstrual_history_id)}><XCircle /></a></p>
                             <h5>{item.obstetric_his_gravida} Pregnancies with {item.obstetric_his_living} living</h5>

                                <p> <span>{item.type}</span>
                            </p> 
                        </div>
                         }) }
                    </Col>:''}
                    {obstetricList.length !== 0 && gender =="Female" ?    <Col md={10} xs={12} className="mb-2 mx-auto indhistory">
                        <h5><span>{t('MENSTRUAL HISTORY')}</span></h5>
                          {obstetricList && obstetricList.map(item =>{
                             return <div className="tagsec">
                             <p><h6 className="m-0">{item.periodicity}</h6><a onClick={() => this.deleteAlcohol(item.menstrual_history_id)}><XCircle /></a></p>
                                <p> <span>{item.type}</span>
                            </p> 
                        </div>
                         }) }
                    </Col>:''}

                     </CardBody>
                </Card>
            </Col> 
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(withTranslation()(History));
