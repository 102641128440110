import React from "react";
import { ArrowLeftCircle } from "react-feather";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import { ScheduleAction } from "../../../../Actions/ScheduleAction";
import Store from "../../../../Store";
import { REACT_APP_API_URL } from "../../../processENV";
import Loader from "../../Loader";

class Mode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      problems: [],
    };
  }
  componentDidMount() {
    console.log("cla");
    this.getMode();
    console.log(this.props);
  }

  getMode = () => {
    let key = this.props?.item.app_prod_n_key;
    let data = {patient_n_key: localStorage.getItem("_patientKey")};
    fetch(`${REACT_APP_API_URL}/book_app_product/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("selected service", res.data);
        if (res.status === "success") {
          this.setState({ problems: res.data });
        } else {
          console.log("selected service", res.data);
        }
      });
  };

  selectproblem = (value) => {
    const scheduleData = this.props.scheduleData?.data
    Store.dispatch(
      ScheduleAction({
        problem: value,
        scheduleStep: 1,
        scheduleCurrentStep: 1,
        app_payment_n_key:scheduleData?.app_payment_n_key
      })
    );
  };

  goBack = () => {
    window.location.href='/home'
  };

  render() {
    const { problems,loading,} = this.state;
    return (
      <div className="col-md-8 mx-auto px-0  pt-5">
        <button onClick={this.goBack} className="btn btn-sm mt-5 mt-md-1 border-none">
          <ArrowLeftCircle color="#215352" size={25} />
        </button>
        {loading && <Loader />}
        <div className="d-flex mx-auto mt-1 px-0">
          <Col className="mx-auto text-center">
            <p
              style={{ fontSize: "26px" }}
              className="h4 text-left text-md-center _text_color header_font_ font-weight-bold"
            >
              Choose What Bothers You
            </p>
            <p
              style={{ fontSize: "16px" }}
              className="h4 text-left pt-2 text-md-center _text_color header_font_ font-weight-bold"
            >
              We can provide the best therapy for your problem
            </p>
            <div className="col-md-6 px-0 mt-md-5 mt-2 mx-auto d-flex flex-column">
              {problems.length>0 && problems.map((item, i) => {
                  return (
                    <button
                      style={{border:'1px solid #ddefe4',fontFamily: "Hanken Grotesk", fontSize: "20px" }}
                      className={"p-3 rounded bg-white _text_color text_p_ shadow-sm text_medium_ my-2"}
                      key={item.product_title}
                      onClick={() => this.selectproblem(item)}
                    >
                      {item.product_title}
                    </button>
                  );
                })}
            </div>
          </Col>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginData: state.LoginData,
    scheduleData: state.ScheduleData,
  };
};

export default connect(mapStateToProps, null)(Mode);
