import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import LeftContent from './LeftContent';
import ChooseLanguage from './ChooseLanguage';
import MobileNumber from './MobileNumber';
import OtpValidation from './OtpValidation';
import Store from '../../../Store';
import { LoginStateAction } from '../../../Actions/LoginAction';
import RegistrationForm from './RegistrationForm';
import SignUp from './SignUp';

// import {logo,DownArrow,loaderSVG} from '../../assets/images';
// import { LoginStateAction } from '../../Actions/LoginAction';
// import Store from '../../Store';
// import { REACT_APP_API_URL } from './../processENV';
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
// import { encryption,decrypt } from '../WebChat/WebChatEncryptDecrypt';


class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            step: "",
            mobileNumber: '',
            countryCode: ''
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.loginData !== this.props.loginData) {
            this.setState({
                step: this.props.loginData.data?.step,
                mobileNumber: this.props.loginData.data?.mobileNumber,
                countryCode: this.props.loginData.data?.countryCode
            })
        }
      }
    
    navigateComponent = (id) =>{
        this.setState({step:id})
        Store.dispatch(LoginStateAction({
            step:id,
            currentStep:localStorage.getItem('_currentStep') !==null || localStorage.getItem('_currentStep') !== undefined  ? localStorage.getItem('_currentStep') : 0,
            mobileNumber:this.props.loginData.data.mobileNumber,
            countryCode:this.props.loginData.data.countryCode,
            otp:this.props.loginData.data.otp,
            language:this.props.loginData.data.language,
            detail:this.props.loginData.data.detail
        }));
    }

    setMobileNumber = () => {

    }


    render() {
        let { step } = this.state;
        return (
            <>
            {
            step === "" ? 
            <SignUp navigateComponent={this.navigateComponent} />
            :
                <div className={"login-main"}>
                    <Row className='m-0'>
                        <Col md={8} className="col-sm-push-12 order-lg-12  px-0 px-md-auto pr-0">
                            
                            {/* {step === 0 && <ChooseLanguage />} */}
                            {step === 0 && <MobileNumber />}
                            {step === 1 && <OtpValidation />}
                            {step === 2 && <RegistrationForm />}
                        </Col>
                        <Col md={4} className={step === 2 ? "col-sm-push-1 order-lg-1   px-0 register-left" : "col-sm-push-1 order-lg-1   px-0"}>
                            <LeftContent currentStep={this.props.loginData.data?.currentStep} step={step} navigateComponent={this.navigateComponent} />
                        </Col>

                    </Row>
                </div>
                }
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(Login);
