import React from 'react';
import { connect } from 'react-redux';
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle
} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
    FormGroup,
    Label,
} from 'reactstrap';
import Loader from '../../Layouts/Loader';
import { REACT_APP_API_URL,getCurrencySymbol } from '../../processENV';
import Document from '../../../assets/images/Document.png'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
class BillingHistory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            paymentHistory: []
        };
    }

    componentDidMount() {
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        this.setState({ loading: true })
        fetch(`${REACT_APP_API_URL}/app_payment_history/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
        }).then(response => response.json())
            .then(async (res) => {
                if (res.status === "success") {
                    this.setState({ loading: false, paymentHistory: res.data })
                    console.log("Appointment History", res)
                } else {
                    this.setState({ loading: false, error: 'Some error has occured please try again' })

                }
            })
    }

    render() {

        let { loading, paymentHistory } = this.state

        return (
            <>
                <div className={"mainsec"}>
                    {loading && <Loader />}

                    <Container className="mb-4">
                        <h2 className="text-center pt-2"> Payment History</h2>
                        {paymentHistory.length !== 0 ?
                            <Row className="profilecard billinghistory">

                                {paymentHistory.map(item => {
                                    return <Col xs="6" sm="6" md="5"  className="mb-4 mx-auto">
                                        <Card>
                                            <Row className="m-0">
                                                <Col xs="12" sm="4" md="4"  className="mb-2 pricesec">
                                                    <h6>Amount Paid :</h6>
                                                    <p> {getCurrencySymbol(item?.currency)} <span>{item.amount}</span> </p>
                                                </Col>
                                                <Col xs="12" sm="8" md="8"  className="pt-2 ">
                                                    <ul>
                                                        <li>Paid On:</li>
                                                        <li>{item.created_on}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Invoice No:</li>
                                                        <li>{item.invoice_no}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Encounter Id:</li>
                                                        <li>{item.encounter_id}</li>
                                                    </ul>
                                                </Col>
                                                <Col md="12" sm="12" xs="12" className="py-1">
                                                    <ul>
                                                        <li>Product Name:</li>
                                                        <li>{item.product_name}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Doctor Name:</li>
                                                        <li>{item.doctor_name}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Discount :</li>
                                                        <li>{item.d_amt}</li>
                                                    </ul>
                                                </Col></Row>
                                        </Card>
                                    </Col>
                                })}

                            </Row>
                            :
                            <Col className="text-center py-4">
                                <img src={Document} alt="" />
                                <h5 className="mt-3"> You haven't made a purchase on CareMe yet </h5>
                            </Col>}

                    </Container>
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(BillingHistory);
