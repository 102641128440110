import React from 'react';
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Collapse
} from 'reactstrap';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
    FormGroup,
    Label,
} from 'reactstrap';
import { REACT_APP_API_URL } from '../../processENV';
import Loader from '../Loader';
import { MinusCircle, PlusCircle, XCircle } from 'react-feather';
import { withTranslation } from 'react-i18next';


class Allergy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            substance:'',
            allergen:'',
            isOpen:false,
            allergyList : []
        };
    }

    allergyInputHandler = (e) =>{
        let id = e.target.name;
        this.setState({
            [id] : e.target.value
        })
    }

    collapsePlan = () => {
        this.setState({
          isOpen: !this.state.isOpen
        })
      }

      componentDidMount(){
        this.getAllergyList();
      }
      
      getAllergyList = () =>{
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        fetch(`${REACT_APP_API_URL}/allergy_details_list/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('allergy list',res)
             this.setState({allergyList: res})
                  
          })
          .catch(err => console.log('allergy list error',err))
      }

      deleteAllergy = (id) =>{
        fetch(`${REACT_APP_API_URL}/allergichistory/${id}/`, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          }
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('delete allergy',res)
             this.getAllergyList()
          })
          .catch(err => console.log('allergy list error',err))
      }
    
    submitAllergyHistory = (e) => {
        let { substance, allergen } = this.state;
        var today = new Date();
        let dd = (today.getDate.toString().length === 1) ? ('0' + today.getDate()) : today.getDate();
        let mm = (today.getMonth().toString().length === 1) ? ('0' + (today.getMonth() + 1)) : (today.getMonth() + 1);
        let yy = (today.getFullYear().toString().length === 1) ? ('0' + today.getFullYear()) : today.getFullYear();
        let date = yy + '-' + mm + '-' + dd;

        if(!substance || !allergen ){ 
            this.setState({
                error : "please fill all the fields"
            })
        }else{
            let data = {
                "patient_n_key": localStorage.getItem('_patientKey'),
                "appointment_n_key": localStorage.getItem('_appointmentKey'),
                "types": "Allergy",
                "substance": substance,
                "assessment_date": date,
                "comments": allergen,
            }

            this.setState({loading:true,error:''})
            console.log('smoking data',data)
            fetch(`${REACT_APP_API_URL}/allergichistory/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
                },
                body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
                if(res.status === "success"){
                    this.getAllergyList()
                    this.setState({loading:false,
                                    substance:'',
                                    allergen:'',
                                    loading:false})
                }else{
                    this.setState({loading:false,error:'Some error has occured please try again'})
                }
            })
        }
    }


    render() {
        let { substance, allergen, loading, isOpen, allergyList } = this.state;
        let { t } = this.props;

        return (
            <>
                <Col md={8} xs={12} className="mb-2 px-0">
                    {loading && <Loader/>}
                                            <Card >
                                                <CardSubtitle tag="h5" className="mt-2  text-center text-muted">{t('ADD YOUR ALLERGIES')}<span className="cusicon" onClick={this.collapsePlan}> {isOpen ? <MinusCircle />: <PlusCircle />}</span></CardSubtitle>
                                                <Collapse isOpen={isOpen}>
                                                <CardBody className="col-md-8 col-12  mx-auto">
                                                    <Formik
                                                        initialValues={{
                                                            name: '',
                                                            email: '',
                                                        }}
                                                        onSubmit={this.submitAllergyHistory}
                                                    >
                                                        {({ errors, touched }) => (
                                                            <Form>
                                                                    <FormGroup className="w-100 m-0">
                                                                        <label>{t('Substance')}</label>

                                                                    </FormGroup>
                                                                    <div className="d-flex col-md-12 px-0" >
                                                                 <FormGroup className="mr-3" >
                                                                        <Label check>
                                                                            <input type="radio" name="substance" checked={substance === "Drug" ? true : false } value="Drug"  onChange={(e)=> this.allergyInputHandler(e)}/>{' '}
                                                                            {t('Drug')}</Label>
                                                                    </FormGroup>
                                                                    <FormGroup className="mr-3" >
                                                                        <Label check>
                                                                            <input type="radio" name="substance" checked={substance === "Food" ? true : false } value="Food"  onChange={(e)=> this.allergyInputHandler(e)}/>{' '}
                                                                            {t('Food')}</Label>
                                                                    </FormGroup>
                                                                    </div>
                                                                    <FormGroup className="pl-md-0 w-100">
                                                                        <label>{t('Name of the Allergen')}</label>
                                                                        <Field name="allergen" className="form-control" placeholder={t('Name of the Allergen')} value={allergen}  onChange={(e)=> this.allergyInputHandler(e)}/>
                                                                        {errors.name && touched.name ? (
                                                                            <ErrorMessage name="allergen" />
                                                                        ) : null}
                                                                    </FormGroup>

                                                       
                                                                <div className="text-center">
                                                                    <button type="submit" className="btn btn-primary mx-auto">{t('ADD ALLERGY')}</button>

                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>                              
                                                    </CardBody>

                                                    </Collapse>
                                                    {allergyList && allergyList.length !== 0
                                                    ? allergyList.map( item =>{
                                                        return <div className="allhistory"><Col md={7} xs={12} className="mb-2 mx-auto indhistory">
                                                        <div className="tagsec">
                                                           <p> <h6 className="m-0">{item.substance}</h6> <a onClick={() => this.deleteAllergy(item.allergic_his_id)}><XCircle /></a></p> 
                                                        <p>  <span>{item.comments} </span>
                                                            </p>  
                                                        </div>
                                                    </Col> </div>
                                                    })
                                                :<></>  }
                                            </Card>
                                            
                                        </Col> 
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(withTranslation()(Allergy));
