import { useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { ChevronDown } from "react-feather";



const CustomCheckboxDropdown = ({
  title,
  items,
  save,
  clear,
  handleChange,
  disable,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleCheckbox = (index) => {
    const updatedItems = [...items];
    updatedItems[index].checked = !updatedItems[index].checked;
    handleChange(index);
  };
  const handleClear = () => {
    clear();
  };
  const handleSave = () => {
    setIsOpen(false);
    save();
  };
console.log(items)
  return (
    <Dropdown
      show={isOpen}
      onMouseEnter={() =>disable?null:setIsOpen(true)}
      onMouseLeave={() =>disable?null:setIsOpen(false)}
    >
      <Dropdown.Toggle style={{border:'.4px solid #215352',cursor:disable?'not-allowed':'auto'}}
      variant="light" className={`${disable?'bg-gray':'bg-white '} mx-1 rounded  text_p_ _text_color`} id="dropdown-basic">
        {title}
        {/* <ChevronDown className="" size={12} /> */}
      </Dropdown.Toggle>

      <Dropdown.Menu   style={{ maxHeight: '500px', overflowY: 'auto' }} className="py-2">
        {items.map((item, index) => (
          <Dropdown.Item onClick={() => handleToggleCheckbox(index)} key={item.name}>
            <label onClick={() => handleToggleCheckbox(index)} className="d-flex align-items-center text-sm font-normal text-gray-900 md:px-1 lg:px-3 w-full">
              <input
                type="checkbox"
                checked={item.checked}
                onChange={() => handleToggleCheckbox(index)}
                className="form-checkbox h-3 w-3 text-indigo-600 transition duration-150 ease-in-out"
              />
              <span style={{fontSize:'15px'}} className="ml-1 text_p_">{item.name}</span>
            </label>
          </Dropdown.Item>
        ))}
        {/* Close and Save buttons */}
        <div className="d-flex justify-content-between mt-4">
          <Button
            variant="outline-danger"
            className="m-2 py-1"
            onClick={() => handleClear()}
          >
            Clear
          </Button>
          <Button
            variant="success"
            className="m-2 py-1"
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomCheckboxDropdown;
