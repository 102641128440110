import React from 'react';
import { connect } from 'react-redux'; 
import {Redirect, withRouter} from 'react-router-dom'
import { FormGroup, Label } from 'reactstrap';
import { REACT_APP_API_URL } from '../../processENV';
import Loader from '../Loader';

class TalktoExpert extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            ans1:'',
            ans2:'',
            ans3:'',
            step:1,
            interested:false,
            reason:'',
            showOthers:false,
            error:''

        };
    }

    setValue = (name,value) =>{
        if(value === "Talk to experts" || value === "I am in"){
            this.props.navigateComponent(1)
        }else if(value === 'Others'){
            console.log("Others")
            this.setState({[name]:value, showOthers:true})
        }else{
            console.log("else")
            this.setState({ [name]:value, step: this.state.step + 1})
        }
    }

    othersHandler = (e) =>{
        this.setState({reason:e.target.value})
    }

    generateRadio = (name, value) =>{
        return value.length !== 0 ?
            value.map((item, i) => {
                return (
                        <p className="getCare-choice" onClick={()=>this.setValue(name, item)}>
                            {item}
                        </p>
                )
            }):''
    }

    sendReason = (e) =>{
        let { reason } = this.state;
        if(e){
            e.preventDefault();
        }


        if(!reason){
            this.setState({
                error : "please fill in some reason"
            })
        }else{
            let data = {
                "patient_n_key": localStorage.getItem('_patientKey'),
                "comments": reason
            }
            this.setState({loading:true,error:''})
            fetch(`${REACT_APP_API_URL}/feedback_post/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
                },
                body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
                if(res.status === "success"){
                    this.setState({loading:false,reason:''})
                    window.location.replace('/home')
                }else{
                    this.setState({loading:false,reason:'',error:'Some error occured.Please try again after sometime'})
                }
            })
        }
    }

    render() {
        let { loading, ans1, ans2, ans3, step, reason, interested, showOthers } = this.state;
        return (
            <>
            <div>
                {loading && <Loader/>}
            <div className="register getcare">
            <div className="head_question">
            <div className="question_layout col-md-5">
                    <p className="d-inline-block pl-2">Hi, {localStorage.getItem('_fullName')} <br/>Welcome to CareMe Health </p>
             </div> 
            <div className="question_layout col-md-5 text-left">
                     <p className="d-inline-block pl-2">We know this is tough time we are here to help you in all the possible best ways</p>
             </div> 
            <div className="question_layout col-md-5">
                     <p className="d-inline-block pl-2">We understand how difficult this is why can have a video call right away so that we can understand you better and serve you better </p>
            </div> 
            {ans1 && <div className="question_layout col-md-5 ml-auto text-right"> <div className='ansdiv col-auto'>
                                    <p className="d-inline pl-2">{ans1}</p>
            </div></div>}
            {!ans1 && <div className={`bottomsec 'd-block'}`}>
                {this.generateRadio('ans1',['Talk to experts', 'Nah! I will try later'])}
            </div>}

            { step >= 2 && <div>
                 <div className="question_layout col-md-5">
                    <p className="d-inline-block pl-2">Are you sure? Because, like we said, we don't mind if you have not taken a bath or brushed your teeth</p>
            </div> 
            {ans2 && <div className="question_layout col-md-5 ml-auto text-right"><div className='ansdiv col-auto'>
                                    <p className="d-inline-block pl-2">{ans2}</p>
            </div></div>}
            {!ans2 && <div className={`bottomsec 'd-block'}`}>
                {this.generateRadio('ans2',['I am in', 'Still not interested'])}
            </div>}  
            </div>}
            { step >= 3 && <div>
                 <div className="question_layout col-md-5">
                    <p className="d-inline-block pl-2">Can you tell us what stops you from taking the call?</p>
            </div> 
            {ans3 && <div className="question_layout col-md-5 ml-auto text-right"> <div className='ansdiv col-auto'>
                                    <p className="d-inline-block pl-2">{ans3}</p>
            </div></div>}
            {!ans3 && <div className={`bottomsec 'd-block'}`}>
                {this.generateRadio('ans3',['I am at work', 'I dont have time to talk now','I am in the middle of something', 'I look messy', 'I am just not interested', 'I am having second thoughts about therapy','Others'])}
            </div>}  
            </div>}
                { showOthers && <form className="my-3 d-flex" onSubmit={this.sendReason}>
                    <input type="text" name="reason" className="form-control mt-2" value={reason} onChange={(e)=>this.othersHandler(e)}/>
                    <button style={{display:'block'}} className="btn btn-primary" type="submit">submit</button>
                </form> 
                }
            </div>
            </div>
            
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        getCareData: state.GetCareData
    }
}

export default connect(mapStateToProps, null)(withRouter(TalktoExpert));
