import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FaCameraRetro } from "react-icons/fa";
import ShowMoreText from "react-show-more-text";
import {FaHeart} from 'react-icons/fa'
import {FaRegHeart} from 'react-icons/fa';
import {FaRegCommentAlt} from 'react-icons/fa';
import { CURE_URL, REACT_APP_API_URL } from '../processENV';
import { useParams, withRouter } from 'react-router-dom';
import {IoMdSend} from 'react-icons/io'
import {BsShareFill} from 'react-icons/bs';
import ReactPlayer from 'react-player';
import { AiFillCloseSquare } from "react-icons/ai";
import axios from 'axios';
import ProgressBar from "react-bootstrap/ProgressBar";
import Threedots from '../../assets/images/Threedots.gif'
import cam from '../../assets/images/cam.png'
import uer from '../../assets/images/uer.png'
import { Modal } from 'react-bootstrap';


const CommentScreen = (props) => {

  console.log(props)
const[post, setPost]=useState();
const [disable, setDisable]=useState(true);
const[file, setFile]=useState();
const[reply, setReply]=useState();
const[image, setImage]=useState(null);
const[video, setVideo]=useState(null);
const[comment, setComment]=useState();
const [transferred, setTransferred]=useState();
const[uploading, setUploading]=useState();



useEffect(()=>{
  let key=props?.location.state.id.id
  let item = props?.location.state.id
  setPost(item)
  const getPosts = () => {
    const data = {
      user_key: localStorage._patientKey||'',
      activity_id:key
    }
        fetch(`${CURE_URL}/community/get_activity/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
            console.log("community post",res)
              if(res){
                 let list = res.data.comments_data.map((item) => {
          return {
            id: item.activity_id,
            userName: item.user_name,
            role: item.role,
            userImg: item.user_profile,
            postTime: item.created_on,
            title: item.topic,
            post: item.content.text,
            postImg: item.content.attachments[0]
              ? item.content.attachments[0].image
              : null,
            mediaType: item.content.attachments[0]
              ? item.content.attachments[0].type
              : null,
          };
        });
                setComment(list)
              }
              else{
                window.location.replace('/communities')
                 console.log('error')
              }
          })
          .catch((err)=>console.log(err))
    }
    getPosts()
},[])

const checkFile=(e)=>{
  console.log(e)
}
const handleComment=(e)=>{
  let val = e
  if(val.length >=6){
    setReply(val)
    setDisable(false)
  }
  else{
    setDisable(true)
  }

}
const postComment=()=>{
    console.log('clikc')
    let key=props?.location.state.id.id
    const config = {
      onUploadProgress: function(progressEvent) {
        const {loaded, total}=progressEvent
        var percent = Math.floor((loaded * 100) / total)
        console.log(`${loaded}kb of ${total}kb | ${percent}%`)
        if(percent < 100){
          setTransferred(percent)
        }
      }
    }
    setUploading(true)
    let useData = JSON.stringify({
      user_key:  localStorage._patientKey,
      text: reply,
      activity_id: key,
    });
    let mediatype=file && file.type.split('/')[0];
    var form = new FormData();
    form.append('data', useData);
    form.append('media', mediatype);
    form.append(mediatype, file);
    console.log(form)
    axios
      .post(`${CURE_URL}/community/activity_comment/`, form,config,{
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'a7143772a09f993125901ea752bfd4fcf4b31d29',
        },
      })
      .then((resp) => {
        console.log(resp);
        setTransferred(100)
          setTimeout(()=>{
            setTransferred(0)
          },1000)
        if(resp.status===200){
          setUploading(true)
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log('error is', error);
      });
}

useEffect(() => {
  if (file && file.size >= 20971520) {
    alert(`file size shouldn't exceed 20MB`);
  } else {
    var type = file && file.type.split("/")[0];
    if (file && file.type && type === "video") {
      let Uri = URL.createObjectURL(file);
      setVideo(Uri);
    }
    if (file && file.type && type === "image") {
      let Uri = URL.createObjectURL(file);
      setImage(Uri);
    }
  }
}, [file && file]);
  return (
    <div className='col-md-10 mx-auto p-md-2 p-1'>
     
     <Modal centered show={uploading && transferred}>
       { uploading && transferred && (
        <div className="p-5">
      <ProgressBar color="#215350" className="mt-3" now={transferred} label={`${transferred}%`} />
      <p className="text-center">Adding Comment <img src={Threedots} alt="" width={20} height={10} /></p>
      </div>
      )}</Modal>
   
        {post && 
          <div style={{background:'#f7f7f7'}} className={`col-md-8 mx-auto my-md-5 my-4 py-2 rounded  position-relative`}>
            <div className="d-flex flex-row mt-3 mt-md-4">
              <div> 
          {post.userImg ?
           <img className="rounded-circle" src={post.userImg} alt="" width='50' height='50' /> :
           <img className="rounded-circle"  src={uer} alt="" width="50" height="50" />
          }
          </div>
          <div className="d-flex flex-column ml-2">
          <h4 style={{fontSize:"20px"}} className="text-left font-weight-bold">{post.userName}  {post.role?post.role!=='User'?<span style={{background:'#ed9d34',color:'#fff',fontWeight:'bold',padding:'5px',fontSize:'11px'}}>{post.role}</span>:null:''}</h4> 
           <h5 style={{fontSize:"12px"}} className="text-left">{moment(post.postTime).fromNow()}</h5>
           </div>
           </div>
            <div className='post_text'><p> 
            <ShowMoreText
                /* Default options */
                lines={3}
                more="Show more"
                less="Show less"
                // className="content-css"
                // anchorClass="my-anchor-css-class"
                // onClick={this.executeOnClick}
                expanded={false}
                // width={280}
                truncatedEndingComponent={"... "}
            >{post.post}</ShowMoreText>
              
</p></div>
          </div>
        
     }
     <h3 style={{textDecoration:'underline'}} className='ml-md-5 pl-md-5'>Comments:</h3>
     <div className='mx-auto pb-5'>

     {comment?.length>0 ? comment.map((post, index)=>{
       return(
        <div style={{background:'#f7f7f7'}} className={`col-md-8 mx-auto my-md-5 my-4  rounded py-2 position-relative`}>
        <div className="d-flex flex-row mt-3 mt-md-4"> 
        <div>
      {post?.userImg ?
       <img className="rounded-circle"  src={post.userImg} alt="" width='50' height='50' /> :
       <img className="rounded-circle"  src={uer} alt="" width="50" height="50" />
      }
      </div>
      <div className="d-flex flex-column ml-2">
      <h4 style={{fontSize:"20px"}} className="text-left font-weight-bold">{post.userName} {post?.role? post?.role=='User'? '':<span style={{background:'#ed9d34',color:'#fff',fontWeight:'bold',padding:'5px',fontSize:'11px'}}>{post?.role}</span>:null}</h4> 
       <h5 style={{fontSize:"12px"}} className="text-left">{moment(post.created_at).fromNow()}</h5>
       </div>
       </div>
        <div className='post_text'><p> 
        <ShowMoreText
            lines={3}
            more="Show more"
            less="Show less"
            expanded={false}
            truncatedEndingComponent={"... "}
        >{post.post}
        </ShowMoreText>
          </p>
          </div>
         </div>
       )
     }):<div className='text-center py-5 font-weight-bold'>Be the first to comment...</div>}
         </div>
         <div className='mx-auto p-0'>
      <div style={{bottom:'3px'}} className='col-md-10 position-fixed ml-md-n2 ml-n1 m-0 p-md-2 p-0'>
        <div className='col-md-8 mx-auto d-flex flex-row position-relative pt-3 pb-5 shadow border rounded bg-white bg-bottom px-0'>
          <div style={{left:'0px',width:'75%'}} className='position-absolute'>
          <input className='form-control' type='text' placeholder='Share your thoughts..'  onChange={(e)=>{handleComment(e.target.value)}} />
          </div>

          <div style={{right:'70px'}} className='position-absolute'>
          {!image && !video &&<>
        <label for="file-input">  
           <img  src={cam} style={{}} height="26px" width="26px" />
        </label>
            <input type="file" id="file-input" className='d-none' onChange={(e)=>{setFile(e.target.files[0])}} /> </>}
            
            {image && (
          <div  className='comment_img'>
            <img src={image} width="30" height="30"  />
            <AiFillCloseSquare onClick={()=>setImage(null)} className="comment_close_img" color='red' size={15} />
          </div>
        )}

        {video && (
            <div >
             <ReactPlayer controls={true} style={{marginLeft:10}} width={50} height={50} playIcon url={video} />
              <AiFillCloseSquare onClick={()=>setVideo(null)} className="comment_close_img" color='red' size={15} />
            </div>
        )}

          </div>

          <div style={{right:'10px'}} className={`position-absolute`}>
          <IoMdSend size={30} disabled={disable} color={disable?'#e1ece3':'#215352'} onClick={()=>{postComment()}} />
          </div>

      </div>
      </div>
     </div>
     </div>
  )
}

export default withRouter(CommentScreen);