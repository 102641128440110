import React from 'react';
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,Collapse
} from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ShowMoreText from "react-show-more-text";
import { connect } from 'react-redux';
import { DownloadCloud, Eye, MinusCircle, Play, PlusCircle } from 'react-feather';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { REACT_APP_API_URL } from '../../processENV';
import { ToastContainer, toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import {AiOutlineDownload} from 'react-icons/ai'
import {
    FormGroup,
    Label,
} from 'reactstrap';
import Loader from '../Loader';
import { XCircle } from 'react-feather';
import { img, pdf } from '../../../assets/images';
import axios from 'axios';
import {saveAs} from 'file-saver'
import { withRouter, history } from 'react-router-dom';


export  const data2 = [
    {title:'Test1',createdBy:'Therapist', desc:'Anxiety is a feeling of fear, dread, and uneasiness. It might cause you to sweat, feel restless and tense, and have a rapid heartbeat. It can be a normal reaction to stress. For example, you might feel anxious when faced with a difficult problem at work, before taking a test, or before making an important decision',content:'https://www.medicalnewstoday.com/articles/323454',status:'completed'},
    {title:'Test2', createdBy:'Therapist1',desc:'Anxiety is a feeling of fear, dread, and uneasiness. It might cause you to sweat, feel restless and tense, and have a rapid heartbeat. It can be a normal reaction to stress. For example, you might feel anxious when faced with a difficult problem at work, before taking a test, or before making an important decision',content:'https://www.medicalnewstoday.com/articles/323454',status:'Incomplete'},
    {title:'Test3',createdBy:'Therapist2', desc:'Anxiety is a feeling of fear, dread, and uneasiness. It might cause you to sweat, feel restless and tense, and have a rapid heartbeat. It can be a normal reaction to stress. For example, you might feel anxious when faced with a difficult problem at work, before taking a test, or before making an important decision',content:'https://www.medicalnewstoday.com/articles/323454',status:'Incomplete'},
    {title:'Test4',createdBy:'Therapist3', desc:'Anxiety is a feeling of fear, dread, and uneasiness. It might cause you to sweat, feel restless and tense, and have a rapid heartbeat. It can be a normal reaction to stress. For example, you might feel anxious when faced with a difficult problem at work, before taking a test, or before making an important decision',content:'https://www.medicalnewstoday.com/articles/323454',status:'Incomplete'},
    {title:'Test5',createdBy:'Therapist4', desc:'Anxiety is a feeling of fear, dread, and uneasiness. It might cause you to sweat, feel restless and tense, and have a rapid heartbeat. It can be a normal reaction to stress. For example, you might feel anxious when faced with a difficult problem at work, before taking a test, or before making an important decision',content:'https://www.medicalnewstoday.com/articles/323454',status:'Incomplete'},
  ]
class Assesments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            isOpen:false,
            docname:'',
            file:'',
            doctype:'',
            doctypeenter:'',
            documentList:[],
            modal:false

        };
    }

    componentDidMount() {
        this.getDocumentList()
    }
    Modalopen=(e)=>{
        if(e !== null && e !== ''){
        this.setState({
            modal:true,
            documentview:e
        })      }  
    }

    toastSuccess = (e) => toast.success(e, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        });
        toastError = (e) => toast.error(e, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            }); collapsePlan = () => {
        this.setState({
          isOpen: !this.state.isOpen
        })
      }
      documentInputHandler = (e) =>{
        let id = e.target.name;
        this.setState({
            [id] : e.target.value
        })
    }
  

    toggle = () =>{
        this.setState({modal: !this.state.modal,feedback:''})
        
    }
    getDocumentList = () =>{
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        fetch(`${REACT_APP_API_URL}/home_therapyforms/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('document list',res)
             this.setState({documentList: res.data})
                  
          })
          .catch(err => console.log('alcohol list error',err))
      }

    render() {
        const {documentList} =this.state
        let { t } = this.props;

        return (
            <div style={{overflowY:'scroll',marginTop:'20px', marginBottom:'100px'}}>
            {documentList.length !==0?
                <>
             <div className='card_doc_ases'>
                {documentList.map((i,indx)=>{
                    return(
                            <div className='inner_card_doc_ases'>
                            <p style={{ color:'#215352',textAlign:'center',fontWeight:"bold"}}>{i.form_name}</p>
                            <p style={{ color:'#215352',marginTop:-10,textAlign:'center',  fontWeight:"bold"}}>Expires on: {i.end_date}</p>
                            <button onClick={()=>this.props.history.push('careme-assesment',{key:i.order_form_id})}>Take Now</button>
                            </div>
                    )
                })}
             </div>
                </>
            :<div className='no_document' onClick={()=>window.location.replace('offerings')}>
                <div className='inner_no_document' >
                    <h4>Book a session  </h4><span><Play color="#215352" /></span> 
                </div>
                <p>You don’t have any documents to view</p>
            </div>}
            </div>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default withRouter(connect(mapStateToProps, null)(withTranslation()(Assesments)));
