import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { REACT_APP_API_URL } from '../processENV';
import Input from './Inputcomponents/Input';
import Loader from './Loader';
import Store from '../../Store';
import { showFeedbackModal } from '../../Actions/PopUp';

import { withTranslation } from 'react-i18next';

class Feedback extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            feedback:'',
            loading:false,
            error:'',
            modal:false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.feedbackPopup !== prevProps.feedbackPopup){
            this.setState({modal:this.props.feedbackPopup.data.show})
        }
    }

    feedbackInputHandler = (e) =>{
        let id = e.target.name;
        this.setState({
            [id] : e.target.value
        })
    }

    submitFeedback = (e) => {
        let { feedback } = this.state;
        if(e){
            e.preventDefault();
        }
        if(!feedback){
            this.setState({
                error : "please fill in some feedback"
            })
        }else{
            let data = {
                "patient_n_key": localStorage.getItem('_patientKey'),
                "comments": feedback
            }
            console.log(data);
            this.setState({loading:true,error:''})
            fetch(`${REACT_APP_API_URL}/feedback_post/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
                },
                body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
                if(res.status === "success"){
                    this.toggle()
                }else{
                    this.setState({loading:false,feedback:'',error:'Some error occured.Please try again after sometime'})
                }
            })
        }
    }
    toggle = () =>{
        this.setState({modal: !this.state.modal,feedback:''},()=>Store.dispatch(showFeedbackModal({show:this.state.modal})))
        
    }

    render() {
        let { loading, error, modal } = this.state;
        let { t } = this.props;
        return (
            <>
            <div>
                <Modal centered isOpen={modal} toggle={this.toggle} >
                {loading && <Loader/>}
                    <form onSubmit={this.submitFeedback}>
                    <ModalHeader toggle={this.toggle}>{t('Feedback')}</ModalHeader>
                    <ModalBody>
                    <textarea label={"Enter your Feedback"} name={"feedback"}      className="form-control" rows={4} type={"textarea"} placeholder={t('Feedback')} defaultValue={this.state.feedback} onChange={this.feedbackInputHandler}/>
                    {error && <p className="errorMesage mt-3">{error}</p>}
                    </ModalBody>
                    <ModalFooter>
                    <Button type="submit" color="primary" onClick={this.submitFeedback}>{t('Submit')}</Button>
                    <Button color="secondary" onClick={this.toggle}>{t('Cancel')}</Button>
                    
                    </ModalFooter>
                    </form>
                </Modal>
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData,
        feedbackPopup: state.feedbackPopup
    }
}

export default connect(mapStateToProps, null)(withTranslation()(Feedback));
