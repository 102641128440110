import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { CDN_URL, REACT_APP_BU_API_URL } from '../processENV'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    LabelList,
    ComposedChart,
    Line,
    Cell,
    Area,
    ResponsiveContainer,
  } from "recharts";
  import moment from 'moment'
import { AnnouncementSharp } from '@material-ui/icons'



const Dashboard = (props) => {

    const [activitydata, setActivityData]=useState([])
    const [employeedata, setEmployeeData]=useState()
    const [selfcaredata, setSelfcareData]=useState([])
    const [messagedata, setMessageData]=useState([])
    const [bardata1,setBardata1]=useState([])
    const [progressData1,setProgressData1]=useState([])
    const [labeldata1,setLabel1]=useState([])
    const [labeldata2, setLabel2]=useState([])
    const [percent,setPercent]=useState(0)
    const getData=async()=>{
        const headers={ 
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          }
        axios.post(REACT_APP_BU_API_URL+'/business_dashboard/',{"bu_org_id":localStorage._b},{headers}).then((res)=>{
            console.log('dashboard_data',res)
            if(res.data.status=='success'){
                setActivityData([res.data.activity_data])
                setEmployeeData(res.data.employee_data)
                setSelfcareData([res.data.selfcare_data])
                setMessageData(res.data.other_activity)
                const currentMonth = moment().format("MMMM");
                let st=res.data.activity_data.filter(i=>i.month_name==currentMonth)[0].active_percentage
                console.log(st,currentMonth)
                setPercent(st)
                let d1=res.data.activity_data.map(i=>{return({x:i.month_name,y:i.total_users,z:i.active_users,w:i.active_percentage})}).reverse()
                let lb1=d1.map((d) => ({
                    x: d.x,
                    y: d.y,
                    label: d.y.toString()})).reverse()
                setLabel1(lb1)
                setBardata1(d1)
                let d2=res.data.activity_data.map(i=>{return({x:i.month_name,y:i.active_users})}).reverse()
                let lb2=d2.map((d) => ({
                    x: d.x,
                    y: d.y,
                    label: d.y.toString()})).reverse()
                setLabel2(lb2)
                let p1=res.data.selfcare_data.map(i=>{return({x:i.course_id,y:i.count,z:i.course_usage_percentage})}).sort((a, b) => a.course_usage_percentage - b.course_usage_percentage)
                setProgressData1(p1)
            }
        }).catch((err)=>{console.log(err)})
    }
    useEffect(()=>{
        getData()
    },[])
    useEffect(()=>{
      let data = {
        "patient_n_key": localStorage.getItem('_patientKey')
    }
      fetch(`${REACT_APP_BU_API_URL}/bu_org_details/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
        },
        body: JSON.stringify(data),
    }).then(response => response.json())
        .then(async (res) => {
            if (res?.admin) {
             console.log('_')
            } else {
              window.location.href='/home'
            }
        })
        .catch(err => console.log(err))
    },[])
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="custom-tooltip">
              <p className="label">{`${label}`}</p>
              <p className="label">{`Total Users: ${payload[0].value}`}</p>
              <p className="desc">{`Active Users: ${payload[1].value}`}</p>
            </div>
          );
        }
      
        return null;
      };
      const renderCustomizedLabel = (props) => {
        const { x, y, width, value } = props;
        const radius = 10;
      
        return (
          <g>
            <text
              x={x + width / 2}
              y={y - radius}
              fill="#8884d8"
              textAnchor="middle"
              dominantBaseline="middle"
              style={{fontWeight:'Bold'}}
            >
              {value}
            </text>
          </g>
        );
      };
      const renderCustomizedLabela = (props) => {
        const { x, y, width, value } = props;
        const radius=10
        return (
          <g>
            <text
              x={x + width / 2}
              y={y - radius}
              fill="#82ca9d"
              textAnchor="middle"
              dominantBaseline="middle"
              style={{fontWeight:'Bold'}}

            >
              {value}
            </text>
          </g>
        );
      };
      const CustomXAxisTick = ({ x, y, payload }) => {
        const { x: tickX, z } = payload;
        return (
          <g transform={`translate(${x},${y})`}>
            <text x={0} y={-10} dy={16} fontSize={14} textAnchor="middle" fill="#666">
            {z}
            </text>
            <text x={0} y={0} dy={16} fontSize={14} textAnchor="middle" fill="#666">
            {payload.value}
            </text>
          </g>
        );
      };
      
const CustomTooltips = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p>{`${payload[0].value}%`}</p>
      </div>
    );
  }

  return null;
};
const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
  return (
    <div className='.container'>
        <div className="col-md-10 ml-auto mt-md-5 mt-5">
                <h1 className='header_font_ text-left _text_color pt-md-5 pt-5'>Employee Wellbeing Dashboard </h1>
                <p className='text_p_ _text_color col-md-10 mr-auto mt-md-4 h4'>👋 Welcome to the Employee Wellbeing Dashboard! Here, you can gain valuable insights into your team's engagement with Careme Health and identify the most frequently addressed topics during Careme sessions. 📈💻💬</p>
        
                <div className='mt-md-4 p-md-5 mt-4'>
                    <h2 className='header_font_ '>General Overview</h2>
                    <div className=' mt-md-3 d-flex flex-md-row flex-column justify-content-between'>
                        <div className='mt-md-5 pt-md-3'>
                            <h3 className='h5 header_font_ '>Employee Headcount </h3>
                            <div className='d-flex flex-row justify-content-center'>
                                <h3 style={{fontSize:'80px'}} className='header_font_'>{employeedata?.total_employee}</h3>
                                <p style={{background:'#ddefe5'}} className='mt-auto p-1 _text_color mb-4'>{employeedata?.total_employee_increase_percentage}%</p>
                            </div>
                            <p className='text_p_ text-center lessopacity mt-n3 ml-2'>{employeedata?.total_employee_last} last month</p>
                        </div>
                        <div className='mt-md-5 pt-md-3'>
                        <h3 className='h5 header_font_ '>Total Active Members </h3>
                            <div className='d-flex flex-row justify-content-center'>
                                <h3 style={{fontSize:'80px'}} className='header_font_'>{employeedata?.total_employee_active}%</h3>
                                <p style={{background:'#ddefe5'}} className='mt-auto p-1 _text_color mb-4'>{employeedata?.total_active_increase_percentage}%</p>
                            </div>
                            <p className='text_p_ text-center lessopacity mt-n3 ml-2'>{employeedata?.total_employee_last_active}% last month</p>
                        </div>
                        <div className='mt-md-1  mr-md-n5'>
                           <img className='img-fluid mt-md-n5 ' style={{maxHeight:'320px'}} src={`${CDN_URL}/Webpages/DisplaybannerIcon/Strugglingtofocus.png`} />
                        </div>
                    </div>
                    <div className='d-flex flex-row col-md-10 mx-auto mt-md-5 pt-md-5 mt-4'>
                        <div className='text_p_ lessopacity col-md-8 ml-md-5 ml-n4'>
                        Ensure the accuracy of your report by regularly updating the employee headcount and extending invitations to new members who join your organization each month.
                        </div>
                        <div>
                            <button className='welladdbtn_'>Add Members</button>
                        </div>
                    </div>
                </div>

                <div>
                    <h2 className='mt-md-5 mt-4 header_font_ _text_color'>Employee Mood Landscape: </h2>
                    <p className='text_p_ _text_color col-md-10 mr-auto mt-md-4 h5'>Explore the emotional well-being of your team by examining the Mood Landscape graph. This interactive visualization provides insights into the distribution and intensity of various emotions experienced by your employees over time. Utilize this data to identify trends, address potential concerns, and foster a supportive and positive work environment. </p>
                   
                </div>

                <div>
                <h2 className='mt-md-5 header_font_ _text_color mt-4'>CareMe Activity:</h2>
                <p className='text_p_ _text_color col-md-10 mr-auto mt-md-4 h5'>With our well-being activity, you can take small, actionable steps towards improving your emotional well-being, such as practicing mindfulness, setting healthy boundaries, and connecting with a support network. </p>

                {bardata1.length>0?<div className='ml-md-0'>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                    className='col-md-10'
                        width={1000}
                        height={400}
                        data={bardata1}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                        >
                        <XAxis  
                        tick={<CustomXAxisTick />} dataKey="x" />
                        <Tooltip content={<CustomTooltip />} />     
                       <Bar radius={[2,2,0,0]} barSize={12} dataKey="y" fill="#8884d8" minPointSize={5}>
                            <LabelList dataKey="y" content={renderCustomizedLabel} />
                        </Bar>
                        <Bar radius={[2,2,0,0]} barSize={12} dataKey="z" fill="#82ca9d" minPointSize={10} >
                        <LabelList dataKey="z" content={renderCustomizedLabela} />
                        </Bar>
                        </BarChart>
                        </ResponsiveContainer>

                        {percent<=0?<div className='col-md-8 mx-auto text_p_ lessopacity h5 mt-md-5'>
                      "Let's get started! While there is currently no engagement with Careme Health, there's no better time to begin improving your team's wellbeing. Let's work together to increase engagement and promote a healthy workplace."
                    </div>:null}
                    {percent>0&&percent<=25?<div className='col-md-8 mx-auto text_p_ lessopacity h5 mt-md-5'>
                    25% Engagement: "Off to a great start! With 25% engagement, Careme Health is beginning to impact your team's wellbeing. Keep up the momentum and strive for higher engagement!" 
                    </div>:null}
                    {percent>25&&percent<=50? <div>
                    50% Engagement: "Nice one! You've reached 50% engagement, indicating that Careme Health is truly making a difference in your teammates' wellbeing. Keep pushing forward to unlock even greater benefits!" 
                    </div>:null}
                    {percent>50&&percent<=75? <div>
                    75% Engagement: "Fantastic progress! Achieving 75% engagement demonstrates that Careme Health is significantly enhancing your team's wellbeing. Continue fostering a supportive and healthy work environment!" 
                    </div>:null}
                    {percent>75&&percent<=90? <div>
                    90% Engagement: "Outstanding! With 90% engagement, Careme Health is effectively promoting a positive and healthy atmosphere for your employees. Keep up the excellent work and aim for full engagement!" 
                    </div>:null}
                   {percent>90?  <div>
                    100% Engagement: "Congratulations! You've achieved 100% engagement, a testament to your commitment to employee wellbeing. Careme Health is now fully integrated into your workplace, fostering a healthy and thriving environment for your team!" 
                    </div>:null}
                    </div>:
                    <div className='col-md-10 mx-auto ml-md-0 mt-md-5 mt-4 chart-wrapper'>
                      <div className='content'>
                      <h4 className='header_font_ font-weight-bold p-5 mx-auto text-center '> We're sorry, but there is no data available at the moment to display the careme activity by employers in your organization. Please check back later as the information is updated regularly. </h4> 
                     </div>
                      <ResponsiveContainer className='chart' width="100%" height={400}>
                        <BarChart
                         className='col-md-10 '
                            width={1000}
                            height={300}
                            data={data}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Bar radius={[2,2,0,0]} barSize={12} dataKey="pv" fill="#8884d8" />
                        <Bar radius={[2,2,0,0]} barSize={12} dataKey="uv" fill="#82ca9d" />
                         </BarChart>
                        </ResponsiveContainer>
                        </div>
                    }
                 
                </div>

                <div>
                    <h2 className='mt-md-5 mt-4 header_font_ _text_color ml-2'>Most Accessed Topic by Employers </h2>
                    <p className='text_p_ _text_color col-md-10 mr-auto mt-md-4 h5'>Find out what's currently trending and piquing the interest of employers in your organization. This section offers an overview of the most frequently accessed topic and helps you stay informed and aligned with your colleagues. </p>
                    {progressData1.length>0?<div className='ml-md-5 mt-md-5 mt-4'>                                 
                            <div className='col-md-10 mx-auto'>
                              {progressData1.map((item,indx)=>{
                                return(
                                  <div key={indx} className="mb-5">
                                  <div className="d-flex justify-content-between mb-2">
                                    <div className='header_font_ h5'>{item.x}</div>
                                    <div>{item.z}%</div>
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{ width: `${item.z}%` }}
                                      aria-valuenow={item.z}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </div>
                                )
                              })}
                        </div>
                    <p className='text-center text_p_ mt-md-5 lessopacity'><AnnouncementSharp /> Please note that the displayed information is updated regularly to reflect real-time data and trends. </p>
                    </div>:

                    <div className='col-md-10 mx-auto ml-md-5 mt-md-5 mt-4 chart-wrapper'>
                      <div className="progress chart">
                        <div className="progress-bar p-2" role="progressbar" style={{width: "45%"}} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <div className="progress chart">
                        <div className="progress-bar p-2" role="progressbar" style={{width: "15%"}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <div className="progress chart">
                        <div className="progress-bar p-2" role="progressbar" style={{width: "45%"}} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <div className="progress chart">
                       <div className="progress-bar p-2" role="progressbar" style={{width: "65%"}} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <div className="progress chart">
                        <div className="progress-bar p-2" role="progressbar" style={{width: "45%"}} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <div className='content shadow-sm'>
                      <h4 className='header_font_ font-weight-bold p-5 mx-auto text-center '> We're sorry, but there is no data available at the moment to display the most accessed topic by employers in your organization. Please check back later as the information is updated regularly. </h4> 
                     </div>
                     
                   </div>
                    }
                    
                </div>

                <div>
                    <h2 className='mt-md-5 mt-4 header_font_ _text_color'>Insights at a Glance</h2>
                    <p className='text_p_ _text_color text-center mx-auto col-md-10 mr-auto mt-md-4 h3'>Stay informed with real-time data on engagement and activity</p> 
                   {messagedata&&messagedata?
                   <>
                    <div className='d-flex flex-md-row  flex-column justify-content-around flex-wrap my-md-5'>
                    <div className='shadow-sm p-5 mt-md-0 mt-2 rounded d-flex flex-column' style={{background:'#a4dbd5'}}>
                    <h3 className='header_font_ text-center'> Therapy Sessions</h3>
                    <h1 style={{fontSize:'60px'}} className='header_font_ text-center mt-2'>{messagedata?.therapy_session}</h1>
                      </div>
                    <div className='shadow-sm p-5 mt-md-0 mt-2  rounded d-flex flex-column' style={{background:'#f2eaa2'}}>
                      <h3 className='header_font_ text-center'>Connected With Coach</h3>
                      <h1 style={{fontSize:'60px'}} className='header_font_ text-center mt-2'>{messagedata?.total_coach_chat}</h1>
                      </div>                    
                    <div className='shadow-sm p-5 mt-md-0 mt-2  rounded d-flex flex-column' style={{background:'#c2caed'}}>
                    <h3 className='header_font_ text-center'> Community Queries</h3>
                    <h1 style={{fontSize:'60px'}} className='header_font_ text-center mt-2'>{messagedata?.community_query}</h1>
                      </div>
                    
                    </div>
                    <div className='d-flex flex-md-row  flex-column  justify-content-center mb-md-4'>
                      <div className='shadow-sm p-5 mx-4  mt-2 mt-md-4 justify-content-center rounded d-flex flex-column' style={{background:'#d5ccdb'}}>
                      <h3 className='header_font_ text-center px-2'>Care Coach Messages</h3>
                      <h1 style={{fontSize:'60px'}} className='header_font_ text-center mt-2'>{messagedata?.no_of_coach_messages}</h1>
                      </div>
                      <div className='shadow-sm p-5 mx-4  mt-2 mt-md-4 justify-content-center rounded d-flex flex-column' style={{background:'#9fd6bb'}}>
                      <h3 className='header_font_ text-center px-2' style={{maxWidth:'265px'}}>Users Taken Wellbeing Assessment </h3>
                      <h1 style={{fontSize:'60px'}} className='header_font_ text-center mt-2'>{messagedata?.welbeing_assessment_count}</h1>
                      </div>
                      </div>
                    <p className='text-center text_p_ mt-md-2 mt-3 lessopacity'><AnnouncementSharp />Please note that the displayed information is updated regularly to reflect real-time data and trends. </p>
                    </>
                    :
                    <div className='col-md-10 d-flex flex-md-row ml-md-5 flex-column justify-content-around flex-wrap my-md-5 chart-wrapper'>
                      <div className='shadow-sm p-5 mt-md-0 mt-2 rounded d-flex flex-column chart' style={{background:'#a4dbd5'}}>
                    <h3 className='header_font_ text-center'> Therapy Sessions</h3>
                    <h1 style={{fontSize:'60px'}} className='header_font_ text-center mt-2'>...</h1>
                      </div>
                    <div className='shadow-sm p-5 mt-md-0 mt-2  rounded d-flex flex-column chart' style={{background:'#f2eaa2'}}>
                      <h3 className='header_font_ text-center'>Connected With Coach</h3>
                      <h1 style={{fontSize:'60px'}} className='header_font_ text-center mt-2'>...</h1>
                      </div>                    
                    <div className='shadow-sm p-5 mt-md-0 mt-2  rounded d-flex flex-column chart' style={{background:'#c2caed'}}>
                    <h3 className='header_font_ text-center'> Community Queries</h3>
                    <h1 style={{fontSize:'60px'}} className='header_font_ text-center mt-2'>...</h1>
                      </div>
                      <div className='shadow-sm p-5 mt-2 mt-md-5 ml-md-n4 justify-content-center chart rounded d-flex flex-column' style={{background:'#d5ccdb'}}>
                      <h3 className='header_font_ text-center px-2'>Care Coach Messages</h3>
                      <h1 style={{fontSize:'60px'}} className='header_font_ text-center mt-2'>...</h1>
                      </div>
                      
                      <div className='content shadow-sm'>
                      <h4 className='header_font_ font-weight-bold p-5 mx-auto text-center '> We're sorry, but there is no data available at the moment to display the Insights by employers in your organization. Please check back later as the information is updated regularly. </h4> 
                     </div>
                   </div>
                    }
                </div>
        </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)