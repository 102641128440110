import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import axios from "axios";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import {
    AiFillEye,
    AiOutlineCloudDownload,
    AiOutlineDownload,
  } from "react-icons/ai";
  import {
    DownloadCloud,
    Eye,
    MinusCircle,
    Play,
    PlusCircle,
    Download,
    ArrowRight
  } from "react-feather";
import { REACT_APP_BU_API_URL, getCurrencySymbol } from '../processENV';
import moment from 'moment';
class AppointmentDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            documentList: [], 
        };
    }

    componentDidMount() {
        this.getDocumentList();
      }
      getDocumentList = () => {
        let data = {
          patient_n_key: localStorage.getItem("_patientKey"),
        };
        console.log(data);
        fetch(`${REACT_APP_BU_API_URL}/payment_history/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then(async (res) => {
            console.log("document list", res);
            this.setState({ documentList: res.data });
          })
          .catch((err) => console.log("alcohol list error", err));
      };
    render() {
        let { documentList } = this.state;
        return (
           
          <div className="col-md-12 m-0 p-0 mt-5 pt-5">
                                <h3 style={{textDecorationLine:'underline'}} className='header_font_ _text_color text-center'>Billing Statement History</h3>

          <div className='col-md-6 mx-auto'>
           {documentList.length !== 0 ? (
<>
  {documentList.map((i, indx) => {
    return (
      <div  className=" col-md-7 border rounded shadow-sm mx-auto my-4 px-0 py-0">
        <div  style={{background:'#ddefe5'}} className="d-flex flex-row">
          <p style={{minWidth:'170px'}} className="pt-3 pl-3">Amount Paid</p>
          <p className="pt-3 pl-3"><span style={{color:'#215352'}} className='text-right'>{getCurrencySymbol(i&&i.currency)} {i.paid_amount}</span></p>
        </div>
        <div className='d-flex flex-column ml-3 pt-3' >
        <div className='d-flex flex-row'><p style={{minWidth:'170px'}} className=" ">Product Name:</p>
        <p><span style={{color:'#215352'}} className='text-right'>{i.product}</span></p></div>
        <div className='d-flex flex-row'>
        <p style={{minWidth:'170px'}} className="">Paid on:</p>
        <p> <span style={{color:'#215352'}} className='text-right'>{i.created_on}</span></p>
         </div>
         <div className='d-flex flex-row'>
          <p style={{minWidth:'170px'}} className="">Product Amount:</p>
          <p><span style={{color:'#215352'}} className='text-right'>{getCurrencySymbol(i&&i.currency)} {i.product_amount}</span></p>
          </div>
          <div className='d-flex flex-row'>
            <p style={{minWidth:'170px'}} className="">Discount Percentage: </p>
            <p><span style={{color:'#215352'}} className='text-right'>{i.discount}%</span></p>
        </div>
        </div>
      </div>
    );
  })}
</>
) : (
<div
className="row col-md-6 mx-auto my-auto mt-md-5"
// onClick={() => window.location.replace("offerings")}
>
<div className="mt-md-5">
<h4 className="text-center font-weight-bold h3">We Can’t wait to meet you!</h4>
<p className="text-center  mt-4 h5">Schedule your 1st appointment to get connected to your CareMe Therapist.</p>
<p className="text-center mt-5 h5">
<span className="h5">Need help getting started?</span><br/>
Reach out to <a href=''>care@careme.health</a> and a team member will respond as soon as possible.</p>

</div>
<div className="text-center mx-auto mt-4">
<button style={{background:'#215352',color:'#fff',fontWeight:'bold'}} className="btn btn-md btn-block " onClick={() => window.location.replace("offerings")}>Book your first session <ArrowRight /> </button>
</div>
</div>
)}</div>
</div>

              
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        getCareData: state.GetCareData
    }
}

export default connect(mapStateToProps, null)(AppointmentDetails);
