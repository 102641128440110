import React from 'react'
// import '../../assets/css/bootstrap.min.css';
import './waitingscreen.css';
import axios from 'axios';
import Loader from './loader';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import logo from '../../../assets/images/logo.png'
import { REACT_APP_API_URL } from '../../processENV';
import moment from 'moment'
import { withRouter } from 'react-router-dom';

class WaitingScreen extends React.Component {

  
  constructor(props) {
    super(props)
    
    this.state = {
      appointmentData : "",
      isReady:false,
      currentUser: '',
      loader: false,
      errorMessage : '',
      serverTime:new Date(),
      day:'',
      hour:'',
      min:'',
      sec:'',
    }

    this.notify = this.notify.bind(this);
   
  }

  notify( min) {
     this.setState({isReady: min});
  }
getOrgTime=()=>{
  console.log(REACT_APP_API_URL+'/org_current_time/')
  axios.get(REACT_APP_API_URL+'/org_current_time/',{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
  },
  }).then((res)=>{
    this.setState({serverTime:res.data.data})
  })
}

  //localhost:3000/value1/value2
  componentDidMount() {
   
        this.interval = setInterval(() =>  {this.getOrgTime();this.getTotalTime()}, 500);

  }
  componentDidUpdate() {
    // rerendering
    
  }

  componentWillUnmount () {
    clearInterval(this.interval);

  }
getTotalTime=()=>{
  console.log('my props', this.props);
  let app_date=this.props?.location?.state?.item?.appointment_date
        var currentDate = moment(this.state.serverTime).valueOf();
        console.log(currentDate, moment(this.state.serverTime).valueOf())
        // var dateTime = app_date + 'T' + this.state.appointmentData.appointment_time;
         //var dateTime = '2021-03-16' + 'T' + '22:30:10';
         var appointmentDate = moment(app_date).valueOf();
        console.log(appointmentDate)

        var distance = appointmentDate - currentDate;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.setState({
          day:days,
          hour:hours,
          min:minutes,
          sec:seconds
        })
        var getMinutes =  10*60*1000;
        console.log(distance < getMinutes)
       if(distance < getMinutes ){
        let item=this.props?.location?.state?.item
        let JsonData1 ={
          channelName:item.appointment_n_key,
          uid: localStorage._patientKey,
        };
        fetch(REACT_APP_API_URL+'/agora_rtctoken/', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(JsonData1),
          }).then(response => response.json())
          .then(async (resp)=>{
            if(resp.status=='success'){
        localStorage.uid=localStorage._patientKey
        localStorage.channelName=item.appointment_n_key
        this.props.history.push('/chatscreen',{
            channelId: item.appointment_n_key,
            empKey: item?.doc_app_id,
            token: resp.rtm_data,
            uid: item.patient_n_key,
            mode: item.mode_of_telehealth,
            typeofchat: 'TeleHealth',
            planitem: item,
            doctor_photo:item.doctor_profile,
            doctor_name:item.doctor_name,
        })
      }})
       }   
}

  render() {

    const { isReady, currentUser, loader, day, min, sec, hour } = this.state;
   
    return (
      
     <div className="wait-screen-wrapper container-fluid">
      <img src={logo} className="logo_wait ml-4" />
      { loader ?  <Loader />:
      <div id="waitingscreen" className="row align-items-center">
        
        <div className="col-md-5 timerwrapper mx-auto">  
        <div className="row m-0">
          <div className="wait-timer col-sm-12 col-12 col-lg-10 px-0 mx-auto col-sm-push-12 order-lg-12  " >
          <table>
           <tr id="clk-div">
            <td> <p id="days"> {day}  </p> </td>
            <td id="hours"> {hour}</td>
            <td id="minutes"> {min}</td>
            <td id="seconds">{sec}</td>
          </tr>

          
            <tr id="clockdiv">
    
            <td className="days">
  
            Days
  
            </td>
  
            <td className="hours">
  
Hours
  
            </td>
  
            <td  className="minutes">
  
           Mins
  
            </td>
  
            <td  className="seconds">
  
           Sec
  
          </td>
          </tr>
          </table>
        
          </div>
          
          <div className="textWrapper col-sm-12 col-lg-12 px-0 col-sm-push-1 order-lg-1">
          
          {/* <p>Good things happen to those who wait </p> */}
    
          <p>Your Appoinment is about to begin in</p>                              
       </div>
       </div>
           
        </div>
{/*         
          <div className="col-md-3  ">        
              <img src={countdownimg} alt=""/>
          </div> */}
        </div>
       }
      </div>
      
    )
  }
}



export default withRouter(WaitingScreen);


const getTimer = (history,appointmentInfo,user, notify, encryptkey, serverTime)=>{

  //debugger;
  var appKey = appointmentInfo.appointment_n_key;
// Set the date we're counting down to
var dateTime = appointmentInfo.appointment_date + 'T' + appointmentInfo.appointment_time;
//var dateTime = '2021-02-11' + ' ' + '15:00:00'
console.log("History ",appKey,'Date and Time',dateTime, serverTime);

//var dateTime = '2020-12-14' + 'T' + '10:10:00';
var countDownDate = new Date(dateTime).getTime();

// Update the count down every 1 second
var x = setInterval(function() {

  // Get today's date and time
  var now = new Date(serverTime).getTime();
  // var now = moment(serverTime).format()
  // Find the distance between now and the count down date
  var distance = countDownDate - now;
  console.log("History 1 ",countDownDate , 'now date',new Date());
  console.log("now time "+now);
  console.log("distance "+distance);

  
 

  // // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);
  
  
  var getMinutes =  10*60*1000;
  if(distance < getMinutes){
    clearInterval(x);
    history.push("/meeting/"+user +"/"+encryptkey , { appointmentData: appointmentInfo, user: user});
     //notify(true);
  }

  

  // Display the result in the element with id="demo"
  // document.getElementById("demo").innerHTML = days + "d " + hours + "h "
  // + minutes + "m " + seconds + "s ";

  // If the count down is finished, write some text
  if (distance < 0) {
    clearInterval(x);
    console.log('History psh' + history + appKey,appointmentInfo);
    // this.props.history.push('/agorademo/sjd@5dfkjsd@j')
    // history.push("/sessionscreen", {appointmentData: appointmentData, user: user});
    
    history.push("/meeting/"+user +"/"+encryptkey , { appointmentData: appointmentInfo, user: user});

    // document.getElementById("demo").innerHTML = "EXPIRED";
  }

   

  if(days.toString().length < 2){
    if(document.getElementById("days").innerHTML !== null){
      document.getElementById("days").innerHTML  = '0'+days;
    }
    
  }else{
    if(document.getElementById("days") !== null){
     document.getElementById("days").innerHTML  = days;
    }
  }
  
  if(hours.toString().length < 2){
    if(document.getElementById("hours") !== null){
    document.getElementById("hours").innerHTML  = '0'+hours;
    }
  }else{
    if(document.getElementById("hours") !== null){
    document.getElementById("hours").innerHTML  = hours;
    }
  }

  if(minutes.toString().length < 2){
    if(document.getElementById("minutes")!== null){
    document.getElementById("minutes").innerHTML  = '0'+minutes;
    }
  }else{
    if(document.getElementById("minutes") !== null){
    document.getElementById("minutes").innerHTML  = minutes;
    }
  }

  if(seconds.toString().length < 2){
    if(document.getElementById("seconds") !== null){
    document.getElementById("seconds").innerHTML  = '0'+seconds;
    }
  }else{
    if(document.getElementById("seconds") !== null){
      document.getElementById("seconds").innerHTML  = seconds;
    }
  }

  document.getElementById("hours").innerHTML  = hours;
  document.getElementById("minutes").innerHTML  = minutes;
  document.getElementById("seconds").innerHTML  = seconds;
   
  //return countdown;

}, 1000);

}
