import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import LeftContentS from './LeftContent';
import Store from '../../../../Store';
// import { ScheduleAction } from './../../../Actions/ScheduleAction';
import { ScheduleAction } from '../../../../Actions/ScheduleAction';
import {REACT_APP_API_URL} from '../../../processENV';
import Mode from './Mode';
import { withRouter } from "react-router";
import Problems from './Problems';
import Language from './Language';
import Gender from './Gender';
import SelectDate from '../SelectDate';
import Date from './Date';
import Time from './Time';
import Confirm from './Confirm';
import Asses from './Asses';
import CoupleQues from './CoupleQues';
import TherapistFilter from './TherapistFilter';

class SIndex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           problem:'',
           mode:'',
           language:'',
           gender:'',
            date:'',
            time:'',
            item:'',
            scheduleStep:0,
            show:false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.scheduleData !== this.props.scheduleData) {
            this.setState({
                scheduleStep: this.props.scheduleData.data?.scheduleStep,
                date: this.props.scheduleData.data?.date,
                time: this.props.scheduleData.data?.time
            })
        }
      }
     
      componentDidMount(){
          console.log(this.props)
          let data = this.props?.scheduleData?.data?.data1
          if(data !==null || ''){
            this.setState({
                item:data,
            }, ()=>setTimeout(()=>{this.setState({show:true})},1000))
          }
                            
            if(this.props?.history?.location?.state?._fol_therapist){
                setTimeout(()=>{
                    this.setState({scheduleStep:data?.title=='Individual Therapy'?2:data?.title=='Couple Therapy'?1:0})
                },1400)
            }
      }
    navigateComponent = (id) =>{
        this.setState({scheduleStep:id})
        Store.dispatch(ScheduleAction({
            scheduleStep:id,
            scheduleCurrentStep:id,
            date: this.props.scheduleData.data?.date,
            time: this.props.scheduleData.data?.time,
        }));
    }


    render() {
        const {item,show } = this.state;
        const scheduleStep = this.props?.scheduleData?.data?.scheduleStep
        return (
                 <div style={{backgroundColor:'#f7f7f7',height:'100%'}} className={"login-main col-md-12 px-0"}>
                 <Row className='m-0 px-1'>
                     <Col className="px-0">
                        {item?.title === 'Individual Therapy' ? <>  
                           {scheduleStep === 0 &&  show && <Problems item={item}/>}
                           {scheduleStep === 1 &&  show && <Language item={item}/>}
                           {scheduleStep === 2 &&  show && <TherapistFilter item={item}/>}
                           {scheduleStep === 3 &&  show && <Mode item={item} />}
                           {scheduleStep === 4 &&  show && <Date item={item}/>}
                           {scheduleStep === 5 &&  show && <Time item={item}/>} 
                           {scheduleStep === 6 &&  show && <Confirm _fol={this.props?.history?.location?.state?._fol_therapist} item={item}/>} 
                          </>  :null}
                           {item?.title === 'Couple Therapy' ? <>  
                           {scheduleStep === 0 &&  show &&<CoupleQues item={item} navigateComponent={this.navigateComponent}/>}
                           {scheduleStep === 1 &&  show && <Language item={item}/>}
                           {scheduleStep === 2 &&  show && <TherapistFilter item={item}/>}
                           {scheduleStep === 3 &&  show && <Date item={item}/>}
                           {scheduleStep === 4 &&  show && <Time item={item}/>} 
                           {scheduleStep === 5 &&  show && <Confirm _fol={this.props?.history?.location?.state?._fol_therapist} item={item}/>} 
                           </>  :null}
                           {item?.title === 'Psychiatric Consultation' ? <>  
                           {scheduleStep === 0 &&  show &&<Language item={item} navigateComponent={this.navigateComponent}/>}
                           {scheduleStep === 1 &&  show &&<TherapistFilter item={item}/>}
                           {scheduleStep === 2 &&  show && <Date item={item}/>}
                           {scheduleStep === 3 &&  show && <Time item={item}/>} 
                           {scheduleStep === 4 &&  show && <Confirm _fol={this.props?.history?.location?.state?._fol_therapist} item={item}/>} 
                           </>  :null}
                        </Col>
                    </Row>
                </div>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        scheduleData: state.ScheduleData,
    }
}

export default  withRouter(connect(mapStateToProps, null)(SIndex));
