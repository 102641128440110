import React from 'react';
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle
} from 'reactstrap';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
    FormGroup,
    TabPane,
    Label,
    TabContent,
} from 'reactstrap';
import Flatpickr from "react-flatpickr";
import { REACT_APP_API_URL } from '../../processENV';
import Loader from '../Loader';
import { user } from '../../../assets/images';
import { withTranslation } from 'react-i18next';

class FamilyProfile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            userdata:JSON.parse(localStorage.getItem('_familyUserDetails')),
            activeTab: '1',
            firstName:'',
            lastName:'',
            dob:'',
            age:'',
            gender:'',
            relationship:'',
            error:'',
            loading:false,
            mainUser:[],
            familyUser:[],
            address:'',
            city:'',
            state:'',
            pincode:''
        };
    }

    verticaltoggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidMount(){
        this.setState({loading:true},()=>{
            let userData = JSON.parse(localStorage.getItem('_familyUserDetails'));
            this.setState({
                firstName: userData.member_name,
                lastName:userData.member_last_name,
                dob:userData.date_of_birth,
                age:userData.member_age,
                gender:userData.member_gender,
                relationship:userData.member_relationship,
                // address:userData.address,
                // city:userData.,
                // state:userData.,
                // pincode:userData.,
                loading:false
            })
        })
      }

      getUserDetails = () => {
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        this.setState({loading:true})
        
        fetch(`${REACT_APP_API_URL}/total_users_list/`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
              console.log("user details",res)
              if(res.main_user){
                  this.setState({
                    loading:false,
                    mainUser:res.main_user,
                    familyUser:res.family_user,
                    firstName:res.main_user[0].first_name,
                    lastName:res.main_user[0].last_name,
                    dob:res.main_user[0].date_of_birth,
                    gender:res.main_user[0].gender,
                    maritalStatus:res.main_user[0].marital_status,
                    age:res.main_user[0].age,
                  })
              }else{
                  this.setState({loading:false,error:'Some error has occured please try again'})
              }
          })
      }
     

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.loginData !== this.props.loginData) {
            this.setState({
                step: this.props.loginData.data?.step,
                mobileNumber: this.props.loginData.data?.mobileNumber,
                countryCode: this.props.loginData.data?.countryCode
            })
        }
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    };

    profileInputHandler = (e) =>{
        let id = e.target.name;
        console.log('profile input handler', e.target.name, e.target.value)
        this.setState({
            [id] : e.target.value
        })
    }

    handleDateChange = ddd => {

        if (ddd === null || ddd === "") {
            this.setState({
                dob: null
            });
        } else {
            let date = new Date(ddd);

            let dd = (date.getDate.toString().length === 1) ? ('0' + date.getDate()) : date.getDate();
            let mm = (date.getMonth().toString().length === 1) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1);
            let yy = (date.getFullYear().toString().length === 1) ? ('0' + date.getFullYear()) : date.getFullYear();
            
           let fromDate = yy + "-" + mm + "-" + dd ;
            this.setState({
                dob:fromDate
            });
            
        }
    };

    profileSubmitHandler = () =>{
        let { firstName, lastName, dob, gender, age, address, city, state, pincode, relationship  } = this.state;
        if(!firstName || !lastName || !dob || !gender || !relationship){
            console.log('family profile data missing',firstName, lastName,relationship,dob, gender,address, city, state, pincode)
            this.setState({error:'please fill all the fields'})
        }else{
            let data = {
                "patient_n_key": localStorage.getItem('_patientKey'),
                "first_name":firstName,
                "last_name":lastName,
                "age":age,
                "date_of_birth":dob,
                "gender":gender ,
                'address_line_one':address,
                'city':city,
                'state':state,
                'pincode':pincode
            }
            this.setState({loading:true,error:''})
            console.log('smoking data',data)
            fetch(`${REACT_APP_API_URL}/patientedit_app/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
                },
                body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
                if(res.status === "success"){
                    this.setState({loading:false,
                                    firstName:'',
                                    lastName:'',
                                    dob:'',
                                    gender:'',
                                    maritalStatus:'',
                                    age:'',
                                    error:''})
                                    this.getUserDetails()
                }else{
                    this.setState({loading:false,error:'Some error has occured please try again'})
                }
            })
        }
    }

    render() {
        let { firstName, lastName, dob, gender, age , relationship, error, loading, userdata, address, city, state, pincode  } = this.state;
        let { t } = this.props;

        return (
            <>
                <div className={"mainsec"}>
                    <Container>
                        <Row className="profilecard">
                        {loading && <Loader/>}
                            <Col xs="6" sm="9" md="9" xs={12} className="mb-2">
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <Row>
                                            {/* <Col md={4} xs={12} className="mb-2">
                                                <imageCroppie/>
                                            </Col> */}
                                            <Col md={8} xs={12} className="mb-2">
                                                <Card >
                                                    <CardSubtitle tag="h5" className="mt-2  text-center text-muted">{t('Add your family/friends profile')}</CardSubtitle>
                                                    <CardBody className="col-md-8 mx-auto">
                                                        <Formik
                                                            initialValues={{
                                                                name: '',
                                                                email: '',
                                                            }}
                                                            // validationSchema={BasicProfile}
                                                            onSubmit={this.profileSubmitHandler}
                                                        >
                                                            {({ errors, touched }) => (
                                                                <Form>
                                                                      <Row >
                                                                        <FormGroup className="col-md-6 pl-md-0">
                                                                        <label>{t('First Name')}</label>
                                                                            <Field name="firstName" className="form-control" placeholder={t('First Name')}value={firstName ? firstName : userdata.member_name} onChange={(e)=>this.profileInputHandler(e)}/>
                                                                            {errors.name && touched.name ? (
                                                                                <ErrorMessage name="firstName" />

                                                                            ) : null}
                                                                        </FormGroup>

                                                                        <FormGroup className="col-md-6 pr-md-0">
                                                                        <label>{t('Last Name')}</label>

                                                                            <Field name="lastName" className="form-control" placeholder="Last Name" value={lastName ? lastName : userdata.member_last_name} onChange={(e)=>this.profileInputHandler(e)}/>
                                                                            {errors.email && touched.email ? (
                                                                                <ErrorMessage name="lastName" />
                                                                            ) : null}

                                                                        </FormGroup>
                                                                        <FormGroup className="w-100 col-md-12 px-md-0">
                                                                        <label>{t('Date of Birth')}</label>
                                                                            <Flatpickr
                                                                                className="form-control w-100"
                                                                                placeholder={t('Date of Birth')}
                                                                                selected={dob}
                                                                                value={dob}
                                                                                options={{ maxDate: new Date() }}

                                                                                onChange={date => this.handleDateChange(date)}
                                                                            />
                                                                        </FormGroup>
                                                                        <FormGroup className="pl-md-0 w-100">
                                                                        <label>{t('Relation Type')}</label>
                                                                            <Field as="select" name="relationship" id="relationship" value={relationship ? relationship : userdata.member_relationship } className="form-control"  onChange={(e)=> this.profileInputHandler(e)}>
                                                                            <option value="">{t('Choose')}</option>
                                                                                <option value="Father">{t('Father')}</option>
                                                                                <option value="Mother">{t('Mother')}</option>
                                                                                <option value="Husband">{t('Husband')}</option>
                                                                                <option value="Wife">{t('Wife')}</option>
                                                                                <option value="Daughter">{t('Daughter')}</option>
                                                                                <option value="Brother">{t('Brother')}</option>
                                                                                <option value="Sister">{t('Sister')}</option>
                                                                                <option value="Grand Father">{t('Grandfather')}</option>
                                                                                <option value="Grand Mother">{t('Grandmother')}</option>
                                                                                <option value="Friend">{t('Friend')}</option>
                                                                                <option value="Cousin">{t('Cousin')}</option>
                                                                                <option value="Father-in-law">{t('Father-in-law')}</option>
                                                                                <option value="Mother-in-law">{t('Mother-in-law')}</option>
                                                                                <option value="Daughter-in-law">{t('Daughter-in-law')}</option>
                                                                                <option value="Brother-in-law">{t('Brother-in-law')}</option>
                                                                                <option value="Sister-in-law">{t('Sister-in-law')}</option>
                                                                                <option value="Nephew">{t('Nephew')}</option>
                                                                                <option value="Niece">{t('Niece')}</option>
                                                                                <option value="Paternal Uncle">{t('Paternal Uncle')}</option>
                                                                                <option value="Paternal Aunt">{t('Paternal Aunt')}</option>
                                                                                <option value="Maternal Aunt">{t('Maternal Aunt')}</option>
                                                                                <option value="Twin Brother">{t('Twin Brother')}</option>
                                                                                <option value="Twin Sister">{t('Twin Sister')}</option>
                                                                                <option value="StepFather">{t('StepFather')}</option>
                                                                                <option value="StepMother">{t('StepMother')}</option>
                                                                                <option value="StepBrother">{t('StepBrother')}</option>
                                                                                <option value="Grand daughter">{t('Grand daughter')}</option>
                                                                                <option value="partner">{t('partner')}</option>

                                                                                <option value="Other">{t('Other')}</option>
                                                                                </Field>
                                                                            {errors.para && touched.para ? (
                                                                                <ErrorMessage name="para" />
                                                                            ) : null}
                                                                        </FormGroup>
                                                                        <FormGroup className="w-100 col-md-12 px-md-0 m-0">
                                                                        <label>{t('Sex')}</label>

                                                                        </FormGroup>
                                                                        <div className="d-flex col-md-12 px-md-0">

                                                                            <FormGroup className="mr-3" >
                                                                                <Label check>
                                                                                    <input type="radio" name="gender" checked={gender ? (gender === "Male" ? true : false) : userdata.member_gender === "Male" ? true : false } value={"Male"} onChange={(e)=>this.profileInputHandler(e)}/>{' '}
                                                                                    {t('MALE')}</Label>
                                                                            </FormGroup>
                                                                            <FormGroup className="mr-3" >
                                                                                <Label check>
                                                                                    <input type="radio" name="gender" checked={ gender ? (gender === "Female" ? true : false) : userdata.member_gender === "Female" ? true : false } value={"Female"} onChange={(e)=>this.profileInputHandler(e)}/>{' '}
                                                                                    {t('FEMALE')}</Label>
                                                                            </FormGroup>
                                                                            <FormGroup className="mr-3" >
                                                                                <Label check>
                                                                                    <input type="radio" name="gender" checked={gender ? (gender === "Transgender" ? true : false) : userdata.member_gender === "Transgender" ? true : false } value={"Transgender"} onChange={(e)=>this.profileInputHandler(e)}/>{' '}
                                                                                    {t('TRANSGENDER')}</Label>
                                                                            </FormGroup> 
                                                                             </div>

                                                                             <FormGroup className="col-md-6 pl-md-0">
                                                                                <label>{t('Address')}</label>
                                                                                <Field name="address" className="form-control" placeholder="Address" value={userdata?.address} onChange={(e) => this.profileInputHandler(e)} />
                                                                                {errors.name && touched.name ? (
                                                                                    <ErrorMessage name="Address" />

                                                                                ) : null}
                                                                            </FormGroup>
                                                                            <FormGroup className="col-md-6 pl-md-0">
                                                                                <label>{t('City')}</label>
                                                                                <Field name="city" className="form-control" placeholder="City" value={userdata?.city} onChange={(e) => this.profileInputHandler(e)} />
                                                                                {errors.name && touched.name ? (
                                                                                    <ErrorMessage name="City" />

                                                                                ) : null}
                                                                            </FormGroup>
                                                                            <FormGroup className="col-md-6 pl-md-0">
                                                                                <label>{t('State')}</label>
                                                                                <Field name="state" className="form-control" placeholder="State" value={userdata?.state} onChange={(e) => this.profileInputHandler(e)} />
                                                                                {errors.name && touched.name ? (
                                                                                    <ErrorMessage name="State" />

                                                                                ) : null}
                                                                            </FormGroup>
                                                                            <FormGroup className="col-md-6 pl-md-0">
                                                                                <label>{t('PinCode')}</label>
                                                                                <Field name="pincode" className="form-control" placeholder="PinCode" value={userdata?.pincode} onChange={(e) => this.profileInputHandler(e)} />
                                                                                {errors.name && touched.name ? (
                                                                                    <ErrorMessage name="PinCode" />

                                                                                ) : null}
                                                                            </FormGroup>
                                                                    </Row>
                                                                    <div className="text-center">
                                                                        <button type="submit" className="btn btn-primary mx-auto">UPDATE</button>

                                                                    </div>
                                                                </Form>
                                                            )}
                                                        </Formik>                                </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </Container>
                </div >

            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(withTranslation()(FamilyProfile));
