import React from 'react';
import { connect } from 'react-redux';
import { REACT_APP_API_URL, REACT_APP_BASE_URL } from '../../processENV';
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Media, Collapse, Label
} from 'reactstrap';
import 'react-accessible-accordion/dist/fancy-example.css';
import Loader from '../Loader';
import Store from '../../../Store';
import { GetCareStateAction } from '../../../Actions/GetCareAction';

var base64 = require('base-64');

class Payment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            productDetails: null,
            paymentType: '',
            loading: true,
            paymentId:'',
            secretKey:'',
            paymentToken:'',
            selectedPayment:'',
            prodId:'',
            paymentId:'',
            discountAmount:'',
            p_dial_code:'',

        };
    }
    getName=async()=>{
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        fetch(`${REACT_APP_API_URL}/patientedit_app/`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data), 
        }).then(response => response.json())
        .then(async (res)=>{
          console.log('patient profile',res)
            if(res.status === "success"){
              this.setState({p_name:res.data.first_name,p_email:res.data.email, p_dial_code:res.data.dial_code,p_phone:res.data.phone_number})
            }else{
              console.log('pat prof',res.data)
            }
        })
    }

    componentDidMount() {
        this.getName()
        fetch(`${REACT_APP_API_URL}/carerrom_product/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            }
        }).then(response => response.json())
            .then(async (res) => {
                console.log("payment", res)
                if (res.status === "success") {
                    this.setState({ loading: false, productDetails: res.data[0], paymentType: res.data[0].payment_type, prodId: res.data[0].app_prod_n_key })
                    
                    let endTimeCal = parseInt(this.props.getCareData.data?.time.split(':')[0]) + 1 
                    let endTime = endTimeCal.toString() +  ":00"
                    let atime = this.props.getCareData.data?.time.split(":")
                    let appointDetails = {
                        date: this.props.getCareData.data?.date,
                        time: atime[0].toString() + ":" + atime[1].toString(),
                        language: this.props.getCareData.data?.language,
                        prodId:res.data[0].app_prod_n_key,
                        product: res.data[0].product_title,
                        endTime: endTime,
                        amount: res.data[0].single_pay.amount,
                        discount: res.data[0].single_pay.discount,
                        discount_amount: res.data[0].single_pay.discount_amount,
                        no_of_session: res.data[0].single_pay.no_of_session
                    }
                    this.purchaseInitiated(appointDetails)
                } else {
                    this.setState({ loading: false, error: 'Some error has occured please try again' })
                }
            })
            .catch(err => console.log(err))
    }

    purchaseInitiated = (appointDetails) =>{
        let { prodId } = this.state
        let data = {
          "payment_status": "purchase_initiated",
          "amount": '',
          "payment_details":"",
         "patient_n_key": localStorage.getItem('_patientKey'),
        "app_prod_n_key":prodId
      }
        fetch(`${REACT_APP_API_URL}/cashfree_payment/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
            console.log("purchaseInitiated",res)
              if(res.status === "success"){
               appointDetails.app_payment_n_key = res.data.app_payment_n_key;
               localStorage.setItem("appoint_details",JSON.stringify(appointDetails))
              }else{
                  this.setState({loading:false})
              }
          })
          .catch(err => console.log('error',err))
    }


    selectPayment = (amount) =>{
        this.setState({selectedPayment:amount.toString()})
        let { prodId } = this.state
        let data = {
          "payment_status": "payment_initiated",
          "amount": amount,
          "payment_details":"",
         "patient_n_key": localStorage.getItem('_patientKey'),
        "app_prod_n_key":prodId
      }
        fetch(`${REACT_APP_API_URL}/cashfree_payment/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
              if(res.status === "success"){
                localStorage.setItem('app_payment_n_key',res.data.app_payment_n_key)
                this.setState({loading:false, paymentId:res.data.app_payment_n_key})
                this.getPaymentLink(amount)
              }else{
                  this.setState({loading:false})
              }
          })
          .catch(err => console.log('error',err))
      }
    
      getPaymentLink = (amount) =>{
        let orderId = Math.random().toFixed(5).split('.')[1]
        this.setState({orderId})
        const{p_dial_code}=this.state;

        let data = {
        "orderAmount" : amount.toString(), 
        "customerName" : localStorage.getItem('_fullName'), 
        "customerPhone" : localStorage.getItem('_mobileNumber'), 
        "customerEmail" : localStorage.getItem('_emailId'),
        dial_code:p_dial_code,
 
        "returnUrl" : `app.careme.health/paymentstatus` //http://localhost:3000/paymentstatus
    }
      
        fetch(`${REACT_APP_API_URL}/create_paymentlink/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
              if(res.status === "success"){
                  console.log('payment link', res)
                  localStorage.setItem('orderId',res.orderId)
                localStorage.setItem('purchase_type',"APPOINTMENT")
                  window.location.replace(res.data)
              }else{
                  this.setState({loading:false})
              }
          })
          .catch(err => console.log('error',err))
      }
    
      
    render() {
        let { productDetails, paymentType, paymentToken, selectedPayment } = this.state;
        return (
            <>
                <div className="choose-language-outer bg-none">
                    {/* <p>Payment</p> */}
                    {productDetails?.single_pay && paymentType === "Single Pay" &&
                        <Col md={6} className="mx-auto text-center choose-language-inner px-0">
                            <p>Please confirm your appointment</p>
                            <div className="payment_sec">
                                <p>Payment </p>
                                <h4> {this.props.getCareData.data?.date && this.props.getCareData.data?.date}
                      &nbsp;-- {this.props.getCareData.data?.time && this.props.getCareData.data?.time}</h4>
                                <h5 className="consultation">{parseInt(productDetails?.single_pay.amount) - parseInt(productDetails?.single_pay.discount_amount) === 0 ? "FREE" : "₹" + (parseInt(productDetails?.single_pay.amount) - parseInt(productDetails?.single_pay.discount_amount)).toString()}</h5>
                                <CardSubtitle className="mb-2 text-muted pay_subhead">One Time Consultation fees</CardSubtitle>
                                <button className="pay_btn" onClick={()=>this.selectPayment(parseInt(productDetails?.single_pay.amount) - parseInt(productDetails?.single_pay.discount_amount))}>Pay and confirm</button>
                            </div>
                        </Col>
                    }
                    {productDetails?.subscription_plan && paymentType === "Subscription Pay" &&
                        <Col md={6} className="mx-auto text-center choose-language-inner px-0">
                             <div className="payment_sec">
                                  <h5 className="consultation">₹{parseInt(productDetails?.subscription_plan.amount) - parseInt(productDetails?.subscription_plan.discount_amount) === 0 ? "FREE" : "₹" + (parseInt(productDetails?.subscription_plan.amount) - parseInt(productDetails?.subscription_plan.discount_amount)).toString()}</h5>
                                <CardTitle className="pay_head">Pay for Single Session</CardTitle>
                                <CardSubtitle className="mb-2 text-muted pay_subhead">Don't worry we'll remind your for the next session</CardSubtitle>
                                <button className="pay_btn" onClick={()=>this.selectPayment(parseInt(productDetails?.subscription_plan.amount) - parseInt(productDetails?.subscription_plan.discount_amount))}>Pay and confirm</button>
                            </div>
                        </Col>
                    }

                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData,
        getCareData: state.GetCareData
    }
}

export default connect(mapStateToProps, null)(Payment);
