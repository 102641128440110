import React from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { Flag } from 'react-feather';
import Store from '../../../Store';
import { GetCareStateAction } from '../../../Actions/GetCareAction';
import Loader from '../Loader';
import { REACT_APP_API_URL } from '../../processENV';

class SelectTime extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            time: [],
            selectedTime:'',
        };
    }

    componentDidMount() {
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey'),
            "appointment_date":this.props.getCareData.data?.date
        }
        
        this.setState({loading:true})
        fetch(`${REACT_APP_API_URL}/appointment_availability/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
                console.log("appoint date",res.data)
                if(res.status === "success"){
                    this.setState({loading:false, time:res.data})
                }else{
                    this.setState({loading:false,error:'Some error has occured please try again'})
                }
            })
    }

    selectTime = (value) =>{
        this.setState({selectedTime:value})
        Store.dispatch(GetCareStateAction({
            getCareStep:4,
            getCareCurrentStep:4,
            date: this.props.getCareData.data?.date,
            time: value,
            language: this.props.getCareData.data?.language
        }));
    }

    render() {
        let { time, loading, selectedTime } = this.state;
        return (
            <>
                <div className="choose-language-outer bg-none">
                    {loading && <Loader />}
                    <div className="d-flex">
                        <Col md={6} className="mx-auto text-center choose-language-inner px-0  ">
                            <p>You can schedule your appointment time now based on your convenience and check your meeting schedule on your home screen. </p>
                            { this.props.getCareData.data?.date && <p>{ this.props.getCareData.data?.date}</p>}
                            { selectedTime && <p>{ selectedTime}</p>}
                            <div className="choose-date-container">
                                {time && time.map((item, i)=>{
                                    return <p className={'date-time-card'} key={i} onClick={()=>this.selectTime(item)}>{item}</p>
                                })}
                            </div></Col>

                    </div>
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData,
        getCareData: state.GetCareData
    }
}

export default connect(mapStateToProps, null)(SelectTime);
