import React from "react";
import ServiceCard from "./ServiceCard";
import {
  Container, Row, Col, Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Media, Collapse, Label
} from 'reactstrap';
import profile from '../../../../assets/images/profilepic.png'
import banner from '../../../../assets/images/woman.jpg'
import product from '../../../../assets/images/images.jpg'
import { REACT_APP_API_URL, REACT_APP_BASE_URL, getCurrencySymbol } from '../../../processENV';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Star, Headphones, Calendar, Activity,ChevronRight } from 'react-feather';
import Loader from "../../Loader";
import ServiceReview from "./ServiceReview";
import { withTranslation } from 'react-i18next';

import { sha256, sha224 } from 'js-sha256';

const queryString = require('query-string');

var base64 = require('base-64');

var payCard =null;
var payBank =null;
var payWallet =null;
var payUpi =null;


class ServicePlan extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      productDetails:null,
      loading:true,
      noData:false,
      prodId:'',
      paymentType:'',
      coupon:null,
      couponError:null,
      discountPer:'',
      doctorslist:[],
      dial_code:'',
      p_dial_code:'',
      productfaqs:[],
      topButton:'',
      middleButton:'',
      bottomButton:'',
      paymentId:'',
      secretKey:'',
      paymentToken:'',
      selectedPayment:'',
      couponApplied:false,
      orderId:'',
      collapsePlan2:false,
      collapsePlan3:false
    };
  }

  getName=async()=>{
    let data = {
        "patient_n_key": localStorage.getItem('_patientKey')
    }
    fetch(`${REACT_APP_API_URL}/patientedit_app/`, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data), 
    }).then(response => response.json())
    .then(async (res)=>{
      console.log('patient profile',res)
        if(res.status === "success"){
          this.setState({p_name:res.data.first_name, p_dial_code:res.data.dial_code,p_email:res.data.email,p_phone:res.data.phone_number})
        }else{
          console.log('pat prof',res.data)
        }
    })
}
  componentDidMount(){
    this.getName()
    let prodId = window.location.pathname.split('/')[2]
    let data = {
        "app_prod_n_key": prodId,
        "status": "Product_select",
        "patient_n_key": localStorage.getItem('_patientKey')
    }
    this.setState({loading:true,prodId})
    
    fetch(`${REACT_APP_API_URL}/appflow_status/`, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),
      }).then(response => response.json())
      .then(async (res)=>{
          if(res.status === "success"){
              this.getProductDetails(prodId)
              var config = {};
              config.layout = {};
              config.checkout = "transparent";
              config.mode = "TEST";
              var response = window.CashFree.init(config);
              }else{
              this.setState({loading:false,noData:true,error:'Some error has occured please try again'})

          }
      })
  }

  getProductDetails = (prodId) => {
    let data = {
      "app_prod_n_key": prodId 
    }
    
      fetch(`${REACT_APP_API_URL}/getapp_product/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
        },
        body: JSON.stringify(data),
        }).then(response => response.json())
        .then(async (res)=>{
          console.log("productDetails",res)
            if(res.status === "success"){
               
                this.setState({loading:false, productDetails:res.data,paymentType:res.data.payment_type,doctorslist: res.data.doctor_details, productfaqs:res.data.faqs, topButton:res.data.top_button,  middleButton:res.data.center_button, bottomButton:res.data.bottom_button})
            }else{
                this.setState({loading:false,noData:true,error:'Some error has occured please try again'})
            }
        })
  }

  collapsePlan = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      collapsePlan2isOpen: false,
      collapsePlan3isOpen: false

    },()=> this.purchaseInitiated())
  }

  collapsePlan2 = () => {
    this.setState({
      collapsePlan2isOpen: !this.state.collapsePlan2isOpen,
      isOpen: false,
       collapsePlan3isOpen: false
    },()=> this.purchaseInitiated())
  }

  collapsePlan3 = () => {
    this.setState({
      collapsePlan3isOpen: !this.state.collapsePlan3isOpen,
      isOpen: false,
      collapsePlan2isOpen: false
    },()=> this.purchaseInitiated())
  }

  couponHandler = (e,i)  => {
    let item = e.target.value; 
    let coup = this.state.coupon
    this.setState({coupon:{
        ...coup,
       [i]: item
    }})
}

  purchaseInitiated = () =>{
    let { prodId } = this.state
    let data = {
      "payment_status": "purchase_initiated",
      "amount": '',
      "payment_details":"",
     "patient_n_key": localStorage.getItem('_patientKey'),
    "app_prod_n_key":prodId
  }
    fetch(`${REACT_APP_API_URL}/cashfree_payment/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),
      }).then(response => response.json())
      .then(async (res)=>{
        console.log("purchaseInitiated",res)
          if(res.status === "success"){
           
          }else{
              this.setState({loading:false})
          }
      })
      .catch(err => console.log('error',err))
}

  submitCoupon = (amount,cIndex) => {
    
    let { coupon } = this.state;
        let data = {
          "patient_n_key": localStorage.getItem('_patientKey'),
          "promo_code":coupon[cIndex]
      }
      this.setState({loading:true, couponError:null})
      fetch(`${REACT_APP_API_URL}/promocode_check/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
              if(res.status === "success"){
                  this.setState({ discountPer:res.data, couponApplied:true},()=>this.submitPayment(amount))
              }else{
                  this.setState({loading:false,couponError:{[cIndex]:res.data}})
              }
          })
          .catch(err => console.log('error',err))
  }

  submitPayment = (amount) =>{
    this.setState({selectedPayment:amount.toString()})
    let prodId = window.location.pathname.split('/')[2]
    let data = {
      "payment_status": "payment_initiated",
      "amount": amount,
      "payment_details":"",
     "patient_n_key": localStorage.getItem('_patientKey'),
    "app_prod_n_key":prodId
  }
    fetch(`${REACT_APP_API_URL}/cashfree_payment/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),
      }).then(response => response.json())
      .then(async (res)=>{
          if(res.status === "success"){
              localStorage.setItem('app_payment_n_key',res.data.app_payment_n_key)
              this.setState({loading:false, paymentId:res.data.app_payment_n_key, coupon:'', discountPer:'', couponApplied:false})
              this.getPaymentLink(amount)
          }else{
              this.setState({loading:false})
          }
      })
      .catch(err => console.log('error',err))
  }

  selectPayment = (amount, cIndex) =>{
    let { coupon } = this.state;
    if(coupon && coupon[cIndex]){
      this.submitCoupon(amount,cIndex)
    }else{
     this.submitPayment(amount)
    }
  }

//   getPaymentToken = (amount) =>{
//     let orderId = Math.random().toFixed(5).split('.')[1]
//     this.setState({orderId})
//     let data = {
//     "orderId" : orderId,
//     "orderAmount" : amount.toString(), 
//     "orderCurrency" : "INR", 
//     "orderNote" : "", 
//     "customerName" : localStorage.getItem('_fullName'), 
//     "customerPhone" : localStorage.getItem('_mobileNumber'), 
//     "customerEmail" : localStorage.getItem('_emailId'), 
//     "paymentOption" :"" ,
//     "paymentCode" : ""
// }
  
//     fetch(`${REACT_APP_API_URL}/sign_cashfree/`, {
//       method: 'POST',
//       headers: {
//           'Content-Type': 'application/json',
//           'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
//       },
//       body: JSON.stringify(data),
//       }).then(response => response.json())
//       .then(async (res)=>{
//           if(res.status === "success"){
//               this.setState({loading:false, secretKey:res.data},()=>{
//                 console.log("selectedPayment",this.state.selectedPayment)
//                 console.log("secretKey",this.state.secretKey)
//                 // this.seamlessMethod()
//                 // document.getElementById("redirectForm").submit();
//                 this.getPaymentLink(amount)
//               })
//           }else{
//               this.setState({loading:false})
//           }
//       })
//       .catch(err => console.log('error',err))
//   }

  getPaymentLink = (amount) =>{
    let orderId = Math.random().toFixed(5).split('.')[1]
    this.setState({orderId})
    const{p_dial_code}=this.state;

    let data = {
    "orderAmount" : amount.toString(), 
    "customerName" : localStorage.getItem('_fullName'), 
    "customerPhone" : localStorage.getItem('_mobileNumber'), 
    "customerEmail" : localStorage.getItem('_emailId'), 
    dial_code:p_dial_code,
    "returnUrl" :  `${REACT_APP_BASE_URL}/paymentstatus` //http://localhost:3000/paymentstatus
}
    fetch(`${REACT_APP_API_URL}/create_paymentlink/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),
      }).then(response => response.json())
      .then(async (res)=>{
          if(res.status === "success"){
              console.log('payment link', res)
              localStorage.setItem('orderId',res.orderId)
              localStorage.setItem('purchase_type',"PRODUCT")
              window.location.replace(res.data)
          }else{
              this.setState({loading:false})
          }
      })
      .catch(err => console.log('error',err))
  }
  
  render() {
    let { isOpen, loading, productDetails, noData, prodId, paymentType, coupon, couponError, doctorslist, productfaqs, collapsePlan3isOpen, collapsePlan2isOpen,
      topButton,  middleButton, bottomButton, secretKey, paymentToken, selectedPayment, discountPer, couponApplied } = this.state;
      let { t } = this.props;

    const testimonials = {
      dots: true,
      // infinite: true,
      slidesToScroll: 1,
      // slidesToShow: 3,
      // autoplay: true,
      speed: 1000,
      // autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            // slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    const testimonials2 = {
      dots: false,
      // infinite: true,
      slidesToScroll: 1,
      // slidesToShow: 3,
      autoplay: true,
      speed: 1000,
      // autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            // slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (

      <Container>
        <Row className="plan_describe">
        {loading ? <Loader/> :
        noData ?
        <p>No data found</p>
        :
          <Col md={10} className="mx-auto">

            <Card >
              <Row>
                <Col md="3" className="text-center">
                  <img src={productDetails.product_image && productDetails.product_image} className="mw-100" />

                </Col>
                <Col md="8">
                  <CardTitle tag="h3" className="mt-2  prod_head">{productDetails.product_title && productDetails.product_title}</CardTitle>

                  <CardSubtitle tag="h6" className="m-0 text-muted prod_short_desc"> {productDetails.product_short_discription && productDetails.product_short_discription}</CardSubtitle>
                  <p className="prod_long_desc">
                  {productDetails.product_long_discription && productDetails.product_long_discription}
  </p>
                  <Link className="btn btn-primary mt-2" onClick={this.collapsePlan}> {topButton}</Link>
                  <Collapse isOpen={isOpen}>
                    <div className="start_program">
                    {productDetails.single_pay && paymentType === "Single Pay" && productDetails.single_pay.map((item,i) =>{
                      return <Row key={i}>
                        <Col md="2" className="px-2 d-flex align-items-center col-3">
                          {/* <input type="radio" id={i} className="mx-auto" name="plan"  /> */}
                        </Col>
                        <Label    htmlFor={i} className="d-flex">
                        <Col md="8" className="px-2 col-7"   >
                        
                          <CardTitle  className="pay_head">{t('Pay for Single Session')}</CardTitle>
                          <CardSubtitle  className="mb-2 text-muted pay_subhead">{t("Don't worry we'll remind you for the next session")}</CardSubtitle>
                          {/* <form > */}
                            <input type="text" name="coupon" value={coupon && coupon[i]  !== null ?coupon[i]:'' } placeholder={t("Enter Coupon Code")} onChange={(e)=>this.couponHandler(e,i)} className="form-control" />
                            {couponError && couponError[i] !== null ?  <p className='error'>{couponError[i]}</p> : <></>}
                            {couponApplied && <p>Coupon applied</p>}
                            {/* <button type="submit" style={{display:'none'}} >submit</button> */}
                          {/* </form> */}
                        </Col>
                        <Col md={4}>
                            <h4> {getCurrencySymbol(item?.currency)} {item.amount}</h4>
                            <button className="btn btn-primary selectbtn" onClick={()=>this.selectPayment((discountPer === '' ? item.amount: item.amount - discountPer),i)}>{t("SELECT")}</button>
                        </Col>
                        </Label>
                    </Row> 
                    })} 
                    {productDetails.subscription_plan && paymentType === "Subscription Pay" && productDetails.subscription_plan.map((item,i) =>{
                      return  <Row key={i}>
                        <Col md="2" className="px-2 d-flex align-items-center col-3">
                          {/* <input type="radio" id={i} name="plan" className="mx-auto"  /> */}
                        </Col>
                        <Label    htmlFor={i} className="d-flex">
                        <Col md="8" className="px-2 col-7"   >
                        
                        <CardTitle  className="pay_head">{t('Pay for Single Session')}</CardTitle>
                          <CardSubtitle  className="mb-2 text-muted pay_subhead">{t("Don't worry we'll remind you for the next session")}</CardSubtitle>
                          {/* <form > */}
                            <input type="text" name="coupon" value={coupon && coupon[i]  !== null ?coupon[i]:'' } placeholder={t("Enter Coupon Code")} onChange={(e)=>this.couponHandler(e,i)} className="form-control" />
                            {couponError && couponError[i] !== null ?  <p className='error'>{couponError[i]}</p> : <></>}
                            {couponApplied && <p>Coupon applied</p>}
                            {/* <button type="submit" style={{display:'none'}} >submit</button> */}
                          {/* </form> */}
                        </Col>
                        <Col md={4}>
                            <h4> {getCurrencySymbol(item?.currency)} {item.amount}</h4>
                            <button className="btn btn-primary selectbtn" onClick={()=>this.selectPayment((discountPer === '' ? item.amount: item.amount - discountPer),i)}>{t("SELECT")}</button>
                        </Col>
                        </Label>
                    </Row> 
                    })}  
                      </div>
                  </Collapse>
                  <div className="prod_Desc">
                    <Row>
                      <div className="text-center" style={{width:'100%'}}>
                      <h3>{t('What you get from')}{productDetails.product_title} ?</h3>
                      </div>
                    {productfaqs && productfaqs.map((item,i) =>{
                      return(
                    <Col md={6} className="my-2" key={i} >
                    <h5>
                      <img  src={item.icon} />
                    <span className="px-2"> {item.question}</span>
                    </h5>
                    <p className="m-0">&nbsp;{item.answer}</p>
                    </Col>)
                    })}
                    </Row>
                  </div>
                  <ServiceReview prodId={prodId}/>
                  <Link className="btn btn-primary my-2" onClick={this.collapsePlan2}> {middleButton}</Link>
                  <Collapse isOpen={collapsePlan2isOpen}>
                    <div className="start_program">
                    {productDetails.single_pay && paymentType === "Single Pay" && productDetails.single_pay.map((item,i) =>{
                      return <Row key={i}>
                        <Col md="2" className="px-2 d-flex align-items-center col-3">
                          {/* <input type="radio" id={i} className="mx-auto" name="plan"  /> */}
                        </Col>
                        <Label  htmlFor={i} className="d-flex">
                        <Col md="8" className="px-2 col-7"   >
                        
                          <CardTitle  className="pay_head">Pay for Single Session</CardTitle>
                          <CardSubtitle  className="mb-2 text-muted pay_subhead">Don't worry we'll remind your for the next session</CardSubtitle>
                          {/* <form > */}
                            <input type="text" name="coupon" value={coupon && coupon[i]  !== null ?coupon[i]:'' } placeholder={t("Enter Coupon Code")} onChange={(e)=>this.couponHandler(e,i)} className="form-control" />
                            {couponError && couponError[i] !== null ?  <p className='error'>{couponError[i]}</p> : <></>}
                            {couponApplied && <p>Coupon applied</p>}
                            {/* <button type="submit" style={{display:'none'}} >submit</button> */}
                          {/* </form> */}
                        </Col>
                        <Col md={4}>
                            <h4> {getCurrencySymbol(item?.currency)} {item.amount}</h4>
                            <button className="btn btn-primary selectbtn" onClick={()=>this.selectPayment((discountPer === '' ? item.amount: item.amount - discountPer),i)}>{t("SELECT")}</button>
                        </Col>
                        </Label>
                    </Row> 
                    })} 
                    {productDetails.subscription_plan && paymentType === "Subscription Pay" && productDetails.subscription_plan.map((item,i) =>{
                      return  <Row key={i}>
                        <Col md="2" className="px-2 d-flex align-items-center col-3">
                          <input type="radio" id={i} name="plan" className="mx-auto"  />
                        </Col>
                        <Label  htmlFor={i} className="d-flex">
                        <Col md="8" className="px-2 col-7"   >
                        
                        <CardTitle  className="pay_head">{t('Pay for Single Session')}</CardTitle>
                          <CardSubtitle  className="mb-2 text-muted pay_subhead">{t("Don't worry we'll remind you for the next session")}</CardSubtitle>
                         {/* <form > */}
                            <input type="text" name="coupon" value={coupon && coupon[i]  !== null ?coupon[i]:'' } placeholder={t("Enter Coupon Code")} onChange={(e)=>this.couponHandler(e,i)} className="form-control" />
                            {couponError && couponError[i] !== null ?  <p className='error'>{couponError[i]}</p> : <></>}
                            {couponApplied && <p>Coupon applied</p>}
                            {/* <button type="submit" style={{display:'none'}} >submit</button> */}
                          {/* </form> */}
                        </Col>
                        <Col md={4}>
                            <h4> {getCurrencySymbol(item?.currency)} {item.amount}</h4>
                            <button className="btn btn-primary selectbtn" onClick={()=>this.selectPayment((discountPer === '' ? item.amount: item.amount - discountPer),i)}>{t("SELECT")}</button>
                        </Col>
                        </Label>
                    </Row> 
                    })}   
              </div>
                  </Collapse>
               

                <div className="text-center">
                <h3>{t('Benefits of careme health')} </h3> 
                <Row>
                <Col md="5">
                <Slider {...testimonials2} className="text-center">
                    {doctorslist && doctorslist.map(item =>{
                       return <div className="col-md-12 slider_sec doctor_list">
                          {item.doctor_profile !== '' && item.doctor_profile !== null ?
                         <img src={item.doctor_profile} /> : <img src={profile} />
                        }
                          <CardTitle tag="h5" className="text-center ">  {item.doctor_name}
                          </CardTitle>
                          <CardSubtitle tag="h6" className="text-center  text-muted ">                            {item.doctor_degree}

                          </CardSubtitle>
                          
                      </div>
                    }) }
                  </Slider></Col>
                  <Col md="7" className="prod_Desc text-left pt-4">
                     <h5 className="pt-2"> 
                      <img  src={product} />
                    <span className="px-2 text-muted">   The therapy sessions were very good and time efficient as well. </span>
                    </h5> 
                    <h5  className="pt-2">
                      <img  src={product} />
                    <span className="px-2 text-muted">   The therapy sessions were very good and time efficient as well. </span>
                    </h5> 
                  </Col>
                  </Row> </div>
                  <Link className="btn btn-primary mt-2" onClick={this.collapsePlan3}> {bottomButton}</Link>
                  <Collapse isOpen={collapsePlan3isOpen}>
                    <div className="start_program">
                    {productDetails.single_pay && paymentType === "Single Pay" && productDetails.single_pay.map((item,i) =>{
                      return <Row key={i}>
                        <Col md="2" className="px-2 d-flex align-items-center col-3">
                          {/* <input type="radio" id={i} className="mx-auto" name="plan"  /> */}
                        </Col>
                        <Label  htmlFor={i} className="d-flex">
                        <Col md="8" className="px-2 col-7"   >
                        
                        <CardTitle  className="pay_head">{t('Pay for Single Session')}</CardTitle>
                          <CardSubtitle  className="mb-2 text-muted pay_subhead">{t("Don't worry we'll remind you for the next session")}</CardSubtitle>
                           {/* <form > */}
                            <input type="text" name="coupon" value={coupon && coupon[i]  !== null ?coupon[i]:'' } placeholder={t("Enter Coupon Code")} onChange={(e)=>this.couponHandler(e,i)} className="form-control" />
                            {couponError && couponError[i] !== null ?  <p className='error'>{couponError[i]}</p> : <></>}
                            {couponApplied && <p>Coupon applied</p>}
                            {/* <button type="submit" style={{display:'none'}} >submit</button> */}
                          {/* </form> */}
                        </Col>
                        <Col md={4}>
                            <h4> {getCurrencySymbol(item?.currency)} {item.amount}</h4>
                            <button className="btn btn-primary selectbtn" onClick={()=>this.selectPayment((discountPer === '' ? item.amount: item.amount - discountPer),i)}>{t("SELECT")}</button>
                        </Col>
                        </Label>
                    </Row> 
                    })} 
                    {productDetails.subscription_plan && paymentType === "Subscription Pay" && productDetails.subscription_plan.map((item,i) =>{
                      return  <Row key={i}>
                        <Col md="2" className="px-2 d-flex align-items-center col-3">
                          {/* <input type="radio" id={i} name="plan" className="mx-auto"  /> */}
                        </Col>
                        <Label    htmlFor={i} className="d-flex">
                        <Col md="8" className="px-2 col-7"   >
                        
                        <CardTitle  className="pay_head">{t('Pay for Single Session')}</CardTitle>
                          <CardSubtitle  className="mb-2 text-muted pay_subhead">{t("Don't worry we'll remind you for the next session")}</CardSubtitle>
                          {/* <form > */}
                            <input type="text" name="coupon" value={coupon && coupon[i]  !== null ?coupon[i]:'' } placeholder={t("Enter Coupon Code")} onChange={(e)=>this.couponHandler(e,i)} className="form-control" />
                            {couponError && couponError[i] !== null ?  <p className='error'>{couponError[i]}</p> : <></>}
                            {couponApplied && <p>Coupon applied</p>}
                            {/* <button type="submit" style={{display:'none'}} >submit</button> */}
                          {/* </form> */}
                        </Col>
                        <Col md={4}>
                            <h4> {getCurrencySymbol(item?.currency)} {item.amount}</h4>
                            <button className="btn btn-primary selectbtn" onClick={()=>this.selectPayment((discountPer === '' ? item.amount: item.amount - discountPer),i)}>{t("SELECT")}</button>
                        </Col>
                        </Label>
                    </Row> 
                    })}    
              </div>
                  </Collapse>
                  
                  <img src={banner} className="product_banner" />
                  {/* <Link className="btn btn-primary"> Start The Program</Link> */}

                </Col>

              </Row>
            </Card>
          </Col>}
          {/* <form id="redirectForm" method="post" action="https://test.cashfree.com/billpay/checkout/post/submit">

                <input type="hidden" name="appId" value="3815045b8b52447631760a9fb05183"/>

                <input type="hidden" name="orderId" value={this.state.orderId}/>

                <input type="hidden" name="orderAmount" value={selectedPayment}/>

                <input type="hidden" name="orderCurrency" value="INR"/>

                <input type="hidden" name="orderNote" value="test"/>

                <input type="hidden" name="customerName" value={localStorage.getItem('_fullName')}/>

                <input type="hidden" name="customerEmail" value={localStorage.getItem('_emailId')}/>

                <input type="hidden" name="customerPhone" value={localStorage.getItem('_mobileNumber')}/>

                <input type="hidden" name="returnUrl" value="http://localhost:3000/home"/>

                <input type="hidden" name="signature" value={secretKey}/>

            </form> */}
        </Row></Container>
    )
  }
}
;

export default (withTranslation()(ServicePlan));
