
import Slider, { createSliderWithTooltip } from 'rc-slider';
import { lean,fat } from '../../../assets/images';

import React from 'react';
import 'rc-slider/assets/index.css';


const SliderWithTooltip = createSliderWithTooltip(Slider);

class SliderCustom extends React.Component {

    // handle = props => {
    //     const { value, dragging, index, ...restProps } = props;
    //     return (
    //       <SliderTooltip
    //         prefixCls="rc-slider-tooltip"
    //         overlay={`${value} %`}
    //         visible={dragging}
    //         placement="top"
    //         key={index}
    //       >
    //         <Handle value={value} {...restProps} />
    //       </SliderTooltip>
    //     );
    //   };


    render() {
        let { showSlider, sliderValue, minSliderValue, maxSliderValue, sliderStep,heightOrWeight } = this.props;

        return (
            <div className="w-100 d-flex align-items-center">
                {showSlider &&
                <>
                <img src={lean} className={heightOrWeight === 'weight' ? '' :'height'} alt=""/>
                    <SliderWithTooltip handle={this.handle} style={{ width: '90%', margin: 25 }}  value={sliderValue} min={minSliderValue} max={maxSliderValue} step={sliderStep} onChange={value => this.props.onInputChange(value)} />
               <img src={heightOrWeight === 'weight' ? fat : lean} alt=""/>
                </>
                }
            </div>
        )
    }

}


export default SliderCustom
