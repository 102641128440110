
import { v4 as uuidv4 } from 'uuid';
import { SHOW_FEEDBACK_MODAL, HIDE_FEEDBACK_MODAL } from './Constants';

export const showFeedbackModal = (data) => dispatch => {
  dispatch({
    type: SHOW_FEEDBACK_MODAL,
    payload: {
        id: uuidv4(),
        data
    }
  })
}

export const hideFeedbackModal = (data) => dispatch => {
  dispatch({
    type: HIDE_FEEDBACK_MODAL,
    payload: {
      id: uuidv4(),
      data
  }
  })
}