import React from 'react';
import { connect } from 'react-redux';
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle
} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
    FormGroup,
    Label,
} from 'reactstrap';
import Loader from '../../Layouts/Loader';
import { REACT_APP_API_URL } from '../../processENV';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Document from '../../../assets/images/Document.png'


class AppointmentHistory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            cancelled : [],
            completed : [],
            missed : [],
            upcoming : []
        };
    }

    componentDidMount() {
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        this.setState({ loading: true })
        fetch(`${REACT_APP_API_URL}/listappointmentsapp/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
        }).then(response => response.json())
            .then(async (res) => {
                if (res.status === "success") {
                    this.setState({ loading: false ,
                        cancelled : res.cancelled,
                        completed : res.completed,
                        missed : res.missed,
                        upcoming : res.upcoming })
                    console.log("Appointment History", res)
                } else {
                    this.setState({ loading: false, error: 'Some error has occured please try again' })

                }
            })
    }

    render() {

        let { loading, cancelled, completed, missed, upcoming  } = this.state

        return (
            <>
                <div className={"mainsec"}>
                    {loading && <Loader />}

                    { cancelled.length === 0 && completed.length === 0 && missed.length === 0 && upcoming.length === 0 ?
        <Container className="mb-4">
         <h2 className="text-center pt-2"> Appointment History</h2>
                        <Col className="text-center py-4">
                                <img src={Document} alt="" />
                                <h5 className="mt-3"> You haven't booked any Appointment on CareMe yet </h5>
                        </Col>
                        </Container>
                    :
                        <Container>
                    <h2 className="text-center pt-2"> Appointment History</h2>
                        <Row className="profilecard appointmenthistory">
                            <Col xs="6" sm="6" md="5" xs={12} className="mb-2">
                                <Card>
                                    <Accordion preExpanded={['1']} className=" " allowZeroExpanded>

                                    {upcoming.length !== 0 &&  <AccordionItem  >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Upcoming Appointments
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                            {upcoming.map(item =>{ return <Card>
                                                    <CardTitle className="px-4"><button className="btn btn-primary m-0 ml-auto ">Upcoming</button></CardTitle>
                                                    <CardBody>
                                                    
                                                         <div>
                                                            <ul>
                                                        <li>
                                                            Service Name:
                                                     </li>
                                                        <li>
                                                            {item.service_name}
                                                     </li>

                                                    </ul><ul>
                                                        <li>
                                                            Doctor Name:
                                                     </li>
                                                        <li>
                                                            {item.doctor_name}
                                                     </li>

                                                    </ul><ul>
                                                        <li>
                                                            Appointment  Date:
                                                     </li>
                                                        <li>
                                                            {item.appointment_date}
                                                     </li>

                                                    </ul><ul>
                                                        <li>
                                                            Appointment Time:
                                                     </li>
                                                        <li>
                                                            {item.appointment_time}
                                                     </li>

                                                    </ul> 
                                                    </div>
                                                        
                                                    </CardBody>
                                                </Card>
                                                    })}

                                            </AccordionItemPanel>
                                        </AccordionItem>}
                                        
                                        {completed.length !== 0 && 
                                        <AccordionItem  >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Completed Appointments
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                            {completed.map(item =>{ return <Card>
                                                    <CardTitle className="px-4"><button className="btn btn-primary m-0 ml-auto complete ">Completed</button></CardTitle>
                                                    <CardBody>

                                                         <div>
                                                            <ul>
                                                        <li>
                                                            Service Name:
                                                     </li>
                                                        <li>
                                                            {item.service_name}
                                                     </li>

                                                    </ul><ul>
                                                        <li>
                                                            Doctor Name:
                                                     </li>
                                                        <li>
                                                            {item.doctor_name}
                                                     </li>

                                                    </ul><ul>
                                                        <li>
                                                            Appointment  Date:
                                                     </li>
                                                        <li>
                                                            {item.appointment_date}
                                                     </li>

                                                    </ul><ul>
                                                        <li>
                                                            Appointment Time:
                                                     </li>
                                                        <li>
                                                            {item.appointment_time}
                                                     </li>

                                                    </ul> 
                                                    </div>
                                                    
                                                    </CardBody>
                                                </Card>})}

                                            </AccordionItemPanel>
                                        </AccordionItem>}
                                        {cancelled.length !== 0 &&
                                        <AccordionItem  >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Cancelled Appointments
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                            {cancelled.map(item =>{ return  <Card>
                                                    <CardTitle className="px-4"><button className="btn btn-primary  cancelled m-0 ml-auto ">Cancelled</button></CardTitle>
                                                    <CardBody>
                                                   <div>
                                                            <ul>
                                                        <li>
                                                            Service Name:
                                                     </li>
                                                        <li>
                                                            {item.service_name}
                                                     </li>

                                                    </ul><ul>
                                                        <li>
                                                            Doctor Name:
                                                     </li>
                                                        <li>
                                                            {item.doctor_name}
                                                     </li>

                                                    </ul><ul>
                                                        <li>
                                                            Appointment  Date:
                                                     </li>
                                                        <li>
                                                            {item.appointment_date}
                                                     </li>

                                                    </ul><ul>
                                                        <li>
                                                            Appointment Time:
                                                     </li>
                                                        <li>
                                                            {item.appointment_time}
                                                     </li>

                                                    </ul> 
                                                    </div>
                                                   
                                                    </CardBody>
                                                </Card> 
                                            })}

                                            </AccordionItemPanel>
                                        </AccordionItem>}
                                        {missed.length !== 0 &&
                                         <AccordionItem  >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Missed Appointments
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                            {missed.map(item =>{
                                                        return  <Card>
                                                    <CardTitle className="px-4"><button className="btn btn-primary missed m-0 ml-auto ">Missed</button></CardTitle>
                                                    <CardBody>
                                                    <div>
                                                            <ul>
                                                        <li>
                                                            Service Name:
                                                     </li>
                                                        <li>
                                                            {item.service_name}
                                                     </li>

                                                    </ul><ul>
                                                        <li>
                                                            Doctor Name:
                                                     </li>
                                                        <li>
                                                            {item.doctor_name}
                                                     </li>

                                                    </ul><ul>
                                                        <li>
                                                            Appointment  Date:
                                                     </li>
                                                        <li>
                                                            {item.appointment_date}
                                                     </li>

                                                    </ul><ul>
                                                        <li>
                                                            Appointment Time:
                                                     </li>
                                                        <li>
                                                            {item.appointment_time}
                                                     </li>

                                                    </ul> 
                                                    </div>
                                                    
                                                    </CardBody>
                                                </Card>})}

                                            </AccordionItemPanel>
                                        </AccordionItem>}
                                        
                                    </Accordion>

                                </Card>
                            </Col>
                        </Row>
                    </Container>
                        }
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(AppointmentHistory);
