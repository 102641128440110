import React from 'react';
import { Col, Row, Container } from 'reactstrap';

class Footer extends React.Component {

    render() {
        return (
            <>
                {/* <Row >            */}

                    <div md={12} className="footer" >
                        <p>All Rights reserved {(new Date().getFullYear())} </p>
                    </div>
                {/* </Row> */}
            </>
        )
    }
}

export default Footer;