import axios from "axios";
import moment from "moment/moment";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { ChevronLeft, ChevronRight, Circle, PhoneCall } from "react-feather";
import {
  BsCheck2Circle,
  BsCircleFill,
  BsWhatsapp
} from "react-icons/bs";
import { Flash } from "react-ionicons";
import { withRouter } from "react-router-dom";
import { CDN_URL, REACT_APP_API_URL } from "../processENV";
import Caremeloader from "./Caremeloader";
import FullScreenConfetti from "./Confetti";

const RoundProgressBar = ({
  answeredCount,
  questionCount,
  radius,
  strokeWidth,
  color,
}) => {
  const progress = answeredCount / questionCount;
  const circumference = 2 * Math.PI * radius;
  const progressStroke = progress * circumference;
  const remainingStroke = circumference - progressStroke;
  const progressColor = progress >= 0.5 ? "green" : "orange"; // Update the color based on the progress

  return (
    <div>
      <svg width={radius * 2 + strokeWidth} height={radius * 2 + strokeWidth}>
        <circle
          cx={radius + strokeWidth / 2}
          cy={radius + strokeWidth / 2}
          r={radius}
          stroke={color}
          strokeWidth={strokeWidth / 4}
          fill="none"
        />
        <path
          stroke={progressColor}
          strokeWidth={strokeWidth / 4}
          strokeDasharray={[progressStroke, remainingStroke]}
          d={`M ${radius + strokeWidth / 2}, ${
            strokeWidth / 2
          } A ${radius}, ${radius} 0 ${progress > 0.5 ? 1 : 0}, 1 ${
            radius + strokeWidth / 2
          }, ${radius * 2 + strokeWidth / 2} A ${radius}, ${radius} 0 ${
            progress > 0.5 ? 1 : 0
          }, 1 ${radius + strokeWidth / 2}, ${strokeWidth / 2}`}
          fill="none"
        />
        <text
          x={radius + strokeWidth / 2}
          y={radius + strokeWidth / 2}
          fontSize={12}
          fill="#000"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {answeredCount} / {questionCount}
        </text>
      </svg>
    </div>
  );
};

class wellbeingtn extends Component {
  constructor(props) {
    super(props);
    this.myButtonRef = React.createRef();
    this.topRef = React.createRef();
    this.state = {
      profile_: "",
      step: 1,
      disable: false,
      quesObj: [],
      bulkData: {},
      indx: 0,
      userinfo: {},
      emotionalscore_: "",
      socialscore_: "",
      physicalscore_: "",
      personalscore_: "",
      academicscore_: "",
      aspirationscore_: "",
      emotionalscale_: "",
      socialscale_: "",
      physicalscale_: "",
      personalscale_: "",
      academicscale_: "",
      aspirationscale_: "",
      showresultmodal: false,
      showLoader: true,
      showcofetti: false,
      emotional_: [
        {
          value: "Managing stress",
          selected: false,
          valuetn: "மன அழுத்தத்தை நிர்வகித்தல்",
        },
        { value: "Procrastination", selected: false, valuetn: "காலம் கடத்தல்" },
        { value: "Anger", selected: false, valuetn: "கோபம்" },
        {
          value: "Breaking negative habits",
          selected: false,
          valuetn: "எதிர்மறை பழக்கங்களை உடைத்தல்",
        },
        {
          value: "Overcoming past traumas",
          selected: false,
          valuetn: "கடந்தகால மன உளைச்சல்களை சமாளித்தல்",
        },
        { value: "Anxiety", selected: false, valuetn: "கவலை" },
        {
          value: "Finding a sense of purpose",
          selected: false,
          valuetn: "நோக்கத்தின் உணர்வைக் கண்டறிதல்",
        },
        {
          value: "ADHD",
          selected: false,
          valuetn: "கவனக் குறைவால் ஏற்படும் மிகை செயல்பாட்டுக் கோளாறு",
        },
        {
          value: "Boosting your self-confidence",
          selected: false,
          valuetn: "உங்கள் தன்னம்பிக்கையை அதிகரிப்பது",
        },
        {
          value: "None of the above ",
          selected: false,
          valuetn: "மேலே உள்ள எதுவும் இல்லை",
        },
      ],
      social_: [
        {
          value: "Fostering new connections",
          selected: false,
          valuetn: "புதிய இணைப்புகளை வளர்ப்பது",
        },
        {
          value: "DEI/Belonging",
          selected: false,
          valuetn: "DEI/உரியது",
        },
        {
          value: "Sexual issues",
          selected: false,
          valuetn: "பாலியல் பிரச்சினைகள்",
        },
        {
          value: "Enhance relationships",
          selected: false,
          valuetn: "உறவுகளை மேம்படுத்துதல்",
        },
        { value: "Family balance", selected: false, valuetn: "குடும்ப சமநிலை" },
        { value: "Social anxiety ", selected: false, valuetn: "சமூக பதட்டம்" },
        {
          value: "Resolve conflicts",
          selected: false,
          valuetn: "மோதல்களைத் தீர்க்கவும்",
        },
        {
          value: "None of the above",
          selected: false,
          valuetn: "மேலே உள்ள எதுவும் இல்லை",
        },
      ],
      set1: [
        {
          question:
            "When I want to feel less negative emotion, I change the way I'm thinking about the situation.",
          answer: "",
          questiontn:
            "நான் குறைவான எதிர்மறை உணர்ச்சிகளை உணர விரும்பினால், நான் சூழ்நிலையைப் பற்றி சிந்திக்கும் விதத்தை மாற்றிக் கொள்கிறேன்.",
        },
        {
          question: "I keep my emotions to myself.",
          answer: "",
          questiontn: "நான் என் உணர்ச்சிகளை எனக்குள் வைத்திருக்கிறேன்.",
        },
        {
          question:
            "When I'm faced with a stressful situation, I make myself think about it in a way that helps me stay calm.",
          answer: "",
          questiontn:
            "நான் ஒரு மன அழுத்த சூழ்நிலையை எதிர்கொள்ளும் போது, நான் அமைதியாக இருக்க உதவும் வகையில் சிந்திக்கிறேன்.",
        },
        {
          question:
            "When I'm feeling negative emotions, I make sure not to express them.",
          answer: "",
          questiontn:
            "நான் எதிர்மறை உணர்ச்சிகளை உணரும்போது, அவற்றை வெளிப்படுத்தாமல் இருப்பதை உறுதி செய்கிறேன்.",
        },
        {
          question:
            "I control my emotions by changing the way I think about the situation I'm in.",
          answer: "",
          questiontn:
            "நான் இருக்கும் சூழ்நிலையைப் பற்றி நான் நினைக்கும் விதத்தை மாற்றுவதன் மூலம் என் உணர்ச்சிகளைக் கட்டுப்படுத்துகிறேன்.",
        },
        {
          question: "I keep a lid on my emotions.",
          answer: "",
          questiontn: "நான் என் உணர்ச்சிகளை அடைத்துவைத்திருக்கிறேன்.",
        },
        {
          question:
            "When I'm faced with a stressful situation, I try to think of it in a way that makes it seem less threatening.",
          answer: "",
          questiontn:
            "நான் ஒரு மன அழுத்த சூழ்நிலையை எதிர்கொள்ளும்போது, அதைக் குறைவான அச்சுறுத்தலாகத் தோன்றும் வகையில் நான் அதைப் பற்றி சிந்திக்க முயற்சிக்கிறேன்.",
        },
        {
          question: "I control my emotions by not expressing them.",
          answer: "",
          questiontn: "நான் என் உணர்ச்சிகளை வெளிப்படுத்தாமல் கட்டுப்படுத்துகிறேன்.",
        },
        {
          question:
            "When I'm upset, I change the way I think about the situation in order to feel more positive.",
          answer: "",
          questiontn:
            "நான் வருத்தமாக இருக்கும் போது, என் மண என்னோட்டங்களை மாற்றி சந்தோஷமாக இருக்க சிந்திக்கிறேன்.",
        },
        {
          question:
            "When I'm really feeling positive emotions, I let myself express them fully.",
          answer: "",
          questiontn:
            "நான் பாசிட்டிவ் உணர்ச்சிகளை உணரும்போது, அவற்றை முழுமையாக வெளிப்படுத்த அனுமதிக்கிறேன்.",
        },
      ],
      indx1: 0,
      ans1: [
        {
          value: "Strongly disagree",
          selected: false,
          valuetn: "முரண்படுகிறோம்",
          scale: 1,
        },
        {
          value: "Moderately disagree",
          selected: false,
          valuetn: "மிதமாக உடன்படவில்லை",
          scale: 2,
        },
        {
          value: "Slightly disagree",
          selected: false,
          valuetn: "சற்று உடன்படவில்லை",
          scale: 3,
        },
        {
          value: "Neither agree nor disagree",
          selected: false,
          valuetn: "முரண்படவும் இல்லை முரண்பாடும் இல்லை ",
          scale: 4,
        },
        {
          value: "Slightly agree",
          selected: false,
          valuetn: "சற்று ஒப்புக்கொள்கிறேன்",
          scale: 5,
        },
        {
          value: "Moderately agree",
          selected: false,
          valuetn: "மிதமாக ஒப்புக்கொள்கிறேன்",
          scale: 6,
        },
        {
          value: "Strongly agree",
          selected: false,
          valuetn: "உறுதியாக ஒப்புக்கொள்கிறேன்",
          scale: 7,
        },
      ],
      set2: [
        {
          question:
            "How often do you feel close to your family members and/or friends? (FES/SCS)",
          answer: "",
          questiontn:
            "உங்கள் குடும்ப உறுப்பினர்கள் மற்றும்/அல்லது நண்பர்களுடன் நீங்கள் எவ்வளவு அடிக்கடி நெருக்கமாக உணர்கிறீர்கள்? ",
          answerOption: [
            {
              value: "Never",
              selected: false,
              valuetn: "ஒருபோதும் இல்லை",
              scale: 1,
            },
            { value: "Rarely", selected: false, valuetn: "அரிதாக", scale: 2 },
            {
              value: "Sometimes",
              selected: false,
              valuetn: "சில நேரங்களில்",
              scale: 3,
            },
            {
              value: "Often",
              selected: false,
              valuetn: "பெரும்பாலும்",
              scale: 4,
            },
            {
              value: "Almost Always",
              selected: false,
              valuetn: "கிட்டத்தட்ட எப்பொழுதும்",
              scale: 5,
            },
            {
              value: "Always",
              selected: false,
              valuetn: "எப்பொழுதும்",
              scale: 6,
            },
          ],
        },
        {
          question:
            "How satisfied are you with the support you receive from your family and friends? (BMSLSS/SSQ)",
          answer: "",
          questiontn:
            "உங்கள் குடும்பத்தினர் மற்றும் நண்பர்களிடமிருந்து நீங்கள் பெறும் ஆதரவில் நீங்கள் எவ்வளவு திருப்தி அடைகிறீர்கள்?",
          answerOption: [
            {
              value: "Very Dissatisfied",
              selected: false,
              valuetn: "மிகவும் அதிருப்தி",
              scale: 1,
            },
            {
              value: "Moderately Dissatisfied",
              selected: false,
              valuetn: "மிதமான அதிருப்தி",
              scale: 2,
            },
            {
              value: "Slightly Dissatisfied",
              selected: false,
              valuetn: "சற்று அதிருப்தி",
              scale: 3,
            },
            {
              value: "Neither Satisfied nor Dissatisfied",
              selected: false,
              valuetn: "திருப்தியும் இல்லை அதிருப்தியும் இல்லை",
              scale: 4,
            },
            {
              value: "Slightly Satisfied",
              selected: false,
              valuetn: "சற்று திருப்தி",
              scale: 5,
            },
            {
              value: "Moderately Satisfied",
              selected: false,
              valuetn: "மிதமான திருப்தி",
              scale: 6,
            },
            {
              value: "Very Satisfied",
              selected: false,
              valuetn: "மிகவும் திருப்தி",
              scale: 7,
            },
          ],
        },
        {
          question:
            "How often do you feel like you have someone to rely on when you need help? (SPS/SSQ) ",
          answer: "",
          questiontn:
            "உங்களுக்கு உதவி தேவைப்படும்போது உங்களுக்காக உதவ நம்பிக்கைக்குறிய ஒருவர் நமக்காக இருக்கின்றார் என்று உணர்ந்திருக்கிறீர்களா?",
          answerOption: [
            {
              value: "Never",
              selected: false,
              valuetn: "ஒருபோதும் இல்லை",
              scale: 1,
            },
            { value: "Rarely", selected: false, valuetn: "அரிதாக", scale: 2 },
            {
              value: "Sometimes",
              selected: false,
              valuetn: "சில சமயம்",
              scale: 3,
            },
            {
              value: "Often",
              selected: false,
              valuetn: "பெரும்பாலும்",
              scale: 4,
            },
            {
              value: "Almost Always",
              selected: false,
              valuetn: "கிட்டத்தட்ட எப்பொழுதும்",
              scale: 5,
            },
            {
              value: "Always",
              selected: false,
              valuetn: "எப்பொழுதும்",
              scale: 6,
            },
          ],
        },
        {
          question: "How connected do you feel with people around you? (SCS) ",
          answer: "",
          questiontn:
            "உங்களைச் சுற்றியுள்ளவர்களுடன் எவ்வளவு இணக்கமாக உள்ளீர்கள்?",
          answerOption: [
            {
              value: "Not at all connected",
              selected: false,
              valuetn: "இணக்கம் இல்லை",
              scale: 1,
            },
            {
              value: "Slightly connected",
              selected: false,
              valuetn: "சற்று இணக்கமாக உணர்கிறேன்",
              scale: 2,
            },
            {
              value: "Moderately connected",
              selected: false,
              valuetn: "மிதமாக இணக்கமாக உணர்கிறேன்",
              scale: 3,
            },
            {
              value: "Very connected",
              selected: false,
              valuetn: "மிகவும் இணக்கமாக உணர்கிறேன்",
              scale: 4,
            },
            {
              value: "Extremely connected",
              selected: false,
              valuetn: "மிக  மிக இணக்கமாக உணர்கிறேன்",
              scale: 5,
            },
          ],
        },
        {
          question:
            "How often do you participate in social activities with your family members and/or friends?",
          answer: "",
          questiontn:
            "உங்கள் குடும்ப உறுப்பினர்கள் மற்றும்/அல்லது நண்பர்களுடன் நீங்கள் எத்தனை முறை சமூக நடவடிக்கைகளில் பங்கேற்கிறீர்கள்?",
          answerOption: [
            {
              value: "Never",
              selected: false,
              valuetn: "ஒருபோதும் இல்லை",
              scale: 1,
            },
            { value: "Rarely", selected: false, valuetn: "அரிதாக", scale: 2 },
            {
              value: "Sometimes",
              selected: false,
              valuetn: "சில சமயம்",
              scale: 3,
            },
            {
              value: "Often",
              selected: false,
              valuetn: "பெரும்பாலும்",
              scale: 4,
            },
            {
              value: "Almost Always",
              selected: false,
              valuetn: "கிட்டத்தட்ட எப்பொழுதும்",
              scale: 5,
            },
            {
              value: "Always",
              selected: false,
              valuetn: "எப்பொழுதும்",
              scale: 6,
            },
          ],
        },
        {
          question:
            "How satisfied are you with the quality of your relationships with family members and friends?",
          answer: "",
          questiontn:
            "குடும்ப உறுப்பினர்கள் மற்றும் நண்பர்களுடனான உங்கள் உறவுகளின் தரத்தில் நீங்கள் எவ்வளவு திருப்தி அடைகிறீர்கள்? ",
          answerOption: [
            {
              value: "Very Dissatisfied",
              selected: false,
              valuetn: "மிகவும் அதிருப்தி",
              scale: 1,
            },
            {
              value: "Moderately Dissatisfied",
              selected: false,
              valuetn: "மிதமான அதிருப்தி",
              scale: 2,
            },
            {
              value: "Slightly Dissatisfied",
              selected: false,
              valuetn: "சற்று அதிருப்தி",
              scale: 3,
            },
            {
              value: "Neither Satisfied nor Dissatisfied",
              selected: false,
              valuetn: "திருப்தியும் இல்லை அதிருப்தியும் இல்லை",
              scale: 4,
            },
            {
              value: "Slightly Satisfied",
              selected: false,
              valuetn: "சற்று திருப்தி",
              scale: 5,
            },
            {
              value: "Moderately Satisfied",
              selected: false,
              valuetn: "மிதமான திருப்தி",
              scale: 6,
            },
            {
              value: "Very Satisfied",
              selected: false,
              valuetn: "மிகவும் திருப்தி",
              scale: 7,
            },
          ],
        },
        {
          question:
            "How often do you feel that your family members and/or friends understand your feelings and emotions?",
          answer: "",
          questiontn:
            "உங்கள் குடும்ப உறுப்பினர்கள் மற்றும்/அல்லது நண்பர்கள் உங்கள் உணர்வுகளையும் உணர்ச்சிகளையும் எவ்வளவு அடிக்கடி புரிந்துகொள்கிறீர்கள் என்று நினைக்கிறீர்கள்? ",
          answerOption: [
            {
              value: "Never",
              selected: false,
              valuetn: "ஒருபோதும் இல்லை",
              scale: 1,
            },
            { value: "Rarely", selected: false, valuetn: "அரிதாக", scale: 2 },
            {
              value: "Sometimes",
              selected: false,
              valuetn: "சில சமயம்",
              scale: 3,
            },
            {
              value: "Often",
              selected: false,
              valuetn: "பெரும்பாலும்",
              scale: 4,
            },
            {
              value: "Almost Always",
              selected: false,
              valuetn: "கிட்டத்தட்ட எப்பொழுதும்",
              scale: 5,
            },
            {
              value: "Always",
              selected: false,
              valuetn: "எப்பொழுதும்",
              scale: 6,
            },
          ],
        },
        {
          question:
            "How often do you feel lonely or disconnected from those around you?",
          answer: "",
          questiontn:
            "உங்களைச் சுற்றியுள்ளவர்களிடமிருந்து நீங்கள் எவ்வளவு அடிக்கடி தனிமையாக அல்லது இணக்கம் இல்லாமல் உணர்கிறீர்கள்? ",
          answerOption: [
            {
              value: "Never",
              selected: false,
              valuetn: "ஒருபோதும் இல்லை",
              scale: 1,
            },
            { value: "Rarely", selected: false, valuetn: "அரிதாக", scale: 2 },
            {
              value: "Sometimes",
              selected: false,
              valuetn: "சில சமயம்",
              scale: 3,
            },
            {
              value: "Often",
              selected: false,
              valuetn: "பெரும்பாலும்",
              scale: 4,
            },
            {
              value: "Almost Always",
              selected: false,
              valuetn: "கிட்டத்தட்ட எப்பொழுதும்",
              scale: 5,
            },
            {
              value: "Always",
              selected: false,
              valuetn: "எப்பொழுதும்",
              scale: 6,
            },
          ],
        },
        {
          question:
            "How many people do you feel you can count on for emotional support in times of need? (SSQ)",
          answer: "",
          questiontn:
            "தேவைப்படும் சமயங்களில் எத்தனை பேரிடம்  இருந்து நீங்கள் உணர்ச்சிபூர்வமான ஆதரவைப் பெற முடியும் என்று நினைக்கிறீர்கள்?",
          answerOption: [
            {
              value: "None",
              selected: false,
              valuetn: "எதுவும் இல்லை",
              scale: 1,
            },
            { value: "1-2", selected: false, valuetn: "1-2", scale: 2 },
            { value: "3-4", selected: false, valuetn: "3-4", scale: 3 },
            { value: "5-6", selected: false, valuetn: "5-6", scale: 4 },
            { value: "7-8", selected: false, valuetn: "7-8", scale: 5 },
            {
              value: "9 or more",
              selected: false,
              valuetn: "9 அல்லது அதற்கு மேற்பட்டவை",
              scale: 6,
            },
          ],
        },
        {
          question:
            "How satisfied are you with the opportunities you have to meet new people and form new relationships?",
          answer: "",
          questiontn:
            "புதிய நபர்களைச் சந்திக்கவும், புதிய உறவுகளை உருவாக்கவும் உங்களுக்குக் கிடைக்கும் வாய்ப்புகளில் நீங்கள் எவ்வளவு திருப்தி அடைகிறீர்கள்?",
          answerOption: [
            {
              value: "Very Dissatisfied",
              selected: false,
              valuetn: "மிகவும் அதிருப்தி",
              scale: 1,
            },
            {
              value: "Moderately Dissatisfied",
              selected: false,
              valuetn: "மிதமான அதிருப்தி",
              scale: 2,
            },
            {
              value: "Slightly Dissatisfied",
              selected: false,
              valuetn: "சற்று அதிருப்தி",
              scale: 3,
            },
            {
              value: "Neither Satisfied nor Dissatisfied",
              selected: false,
              valuetn: "திருப்தியும் இல்லை அதிருப்தியும் இல்லை",
              scale: 4,
            },
            {
              value: "Slightly Satisfied",
              selected: false,
              valuetn: "சிறிது திருப்தி",
              scale: 5,
            },
            {
              value: "Moderately Satisfied",
              selected: false,
              valuetn: "மிதமான திருப்தி",
              scale: 6,
            },
            {
              value: "Very Satisfied",
              selected: false,
              valuetn: "மிகவும் திருப்தி",
              scale: 7,
            },
          ],
        },
      ],
      indx2: 0,
      personal_: [
        {
          value: "Stress & Anxiety",
          selected: false,
          valuetn: "மன அழுத்தம்",
        },
        { value: "Loneliness", selected: false, valuetn: "தனிமை" },
        { value: "New career", selected: false, valuetn: "புதிய தொழில்/ வேலை" },
        {
          value: "Social Media",
          selected: false,
          valuetn: "சமூக ஊடகம்",
        },
        {
          value: "Future Uncertainties",
          selected: false,
          valuetn: "எதிர்காலத்தைப் பற்றிய பயம்",
        },
        { value: "None of the above", selected: false, valuetn: "மேலே எதுவும் இல்லை" },
        
      ],
      set3: [
        {
          question:
            "I can always manage to solve difficult problems if I try hard enough.",
          answer: "",
          questiontn:
            "நான் போதுமான அளவு முயற்சி செய்தால் கடினமான பிரச்சனைகளை தீர்க்க என்னால் எப்போதும் முடியும்.",
        },
        {
          question:
            "If someone opposes me, I can find the means and ways to get what I want.",
          answer: "",
          questiontn:
            "யாராவது என்னை எதிர்த்தால், நான் விரும்புவதைப் பெறுவதற்கான வழிமுறைகள் மற்றும் வழிகளை  என்னால் கண்டுபிடிக்க முடியும்.",
        },
        {
          question:
            "It is easy for me to stick to my aims and accomplish my goals.",
          answer: "",
          questiontn:
            "எனது லட்சியங்களை இறுக  பற்றிக்கொண்டு மற்றும் எனது இலக்குகளை நிறைவேற்றுவது எனக்கு எளிதானது.",
        },
        {
          question:
            "I am confident that I could deal efficiently with unexpected events.",
          answer: "",
          questiontn:
            "எதிர்பாராத நிகழ்வுகளை என்னால் திறம்பட சமாளிக்க முடியும் என்பதில் எனக்கு நம்பிக்கை உள்ளது.",
        },
        {
          question:
            "Thanks to my resourcefulness, I know how to handle unforeseen situations.",
          answer: "",
          questiontn:
            "எனது சமயோசிதத்திற்கு நன்றி, எதிர்பாராத சூழ்நிலைகளை எவ்வாறு கையாள்வது என்பது எனக்குத் தெரியும்.",
        },
        {
          question:
            "I can solve most problems if I invest the necessary effort.",
          answer: "",
          questiontn:
            "தேவையான முயற்சியை முதலீடு செய்தால் பெரும்பாலான பிரச்சனைகளை என்னால் தீர்க்க முடியும்.",
        },
        {
          question:
            "I can remain calm when facing difficulties because I can rely on my coping abilities.",
          answer: "",
          questiontn:
            "சிரமங்களை எதிர்கொள்ளும் போது நான் அமைதியாக இருக்க முடியும் ஏனென்றால் அதில் இருந்து மீளும் திறன் என்னிடம் உள்ளது?",
        },
        {
          question:
            "When I am confronted with a problem, I can usually find several solutions.",
          answer: "",
          questiontn:
            "நான் ஒரு பிரச்சனையை எதிர்கொள்ளும் போது,  அதில் இருந்து மீள்வதற்கு என்னால் பல வழிகளை காண முடியும்?",
        },
        {
          question: "If I am in trouble, I can usually think of a solution.",
          answer: "",
          questiontn:
            "நான் சிக்கலில் இருந்தால், நான் பொதுவாக ஒரு தீர்வைப் பற்றி சிந்திக்க முடியும்.",
        },
        {
          question: "I can usually handle whatever comes my way.",
          answer: "",
          questiontn: "பொதுவாக என்ன வந்தாலும் என்னால் கையாள முடியும்.",
        },
      ],
      ans3: [
        {
          value: "Not at all true",
          selected: false,
          valuetn: "முற்றிலும் உண்மை இல்லை",
          scale: 1,
        },
        {
          value: "Hardly true",
          selected: false,
          valuetn: "அரிதாகவே உண்மை",
          scale: 2,
        },
        {
          value: "Moderately true",
          selected: false,
          valuetn: "நடுநிலையான உண்மை",
          scale: 3,
        },
        { value: "Exactly true", selected: false, valuetn: "உண்மை", scale: 4 },
      ],
      indx3: 0,
      physical_: [
        {
          value: "Fitness/exercise",
          selected: false,
          valuetn: "உடற்தகுதி/உடற்பயிற்சி",
        },
        {
          value: "Healthier diet",
          selected: false,
          valuetn: "ஆரோக்கியமான உணவு",
        },
        { value: "Healthy work", selected: false, valuetn: "ஆரோக்கியமான வேலை" },
        {
          value: "Stress management",
          selected: false,
          valuetn: "மன அழுத்த மேலாண்மை",
        },
        {
          value: "Sleep enhancement",
          selected: false,
          valuetn: "தூக்கத்தை மேம்படுத்துதல்",
        },
        {
          value: "Resilience/coping",
          selected: false,
          valuetn: "மீள்தன்மை/சமாளித்தல்",
        },
        {
          value: "Chronic conditions",
          selected: false,
          valuetn: "நாள்பட்ட நிலைமைகள்",
        },
        {
          value: "Self-care routine",
          selected: false,
          valuetn: "சுய பராமரிப்பு வழக்கம்",
        },
        {
          value: "Physical/mental balance",
          selected: false,
          valuetn: "உடல்/மன சமநிலை",
        },
        {
          value: "None of the above",
          selected: false,
          valuetn: "மேலே உள்ளவை எதுவும் இல்லை",
        },
      ],
      set4: [
        {
          question: "Do you feel tired a lot lately?",
          answer: "",
          questiontn: "நீங்கள் சமீபத்தில் மிகவும் சோர்வாக உணர்கிறீர்களா?",
        },
        {
          question: "Is your appetite good?",
          answer: "",
          questiontn: "உங்கள் பசி நன்றாக உள்ளதா?",
        },
        {
          question: "Do you have a lot of headaches?",
          answer: "",
          questiontn: "உங்களுக்கு தலைவலி அதிகம் உள்ளதா?",
        },
        {
          question: "Have you gained or lost a lot of weight lately?",
          answer: "",
          questiontn:
            "நீங்கள் சமீப காலமாக எடை கூடிவிட்டீர்களா அல்லது குறைந்துள்ளீர்களா?",
        },
        {
          question: "Are you not sleeping well?",
          answer: "",
          questiontn: "நீங்கள் நன்றாக தூங்கவில்லையா?",
        },
        {
          question: "Do you feel in good health?",
          answer: "",
          questiontn: "நீங்கள் நல்ல ஆரோக்கியத்துடன் இருக்கிறீர்களா?",
        },
      ],
      ans4: [
        {
          value: "Strongly Agree",
          selected: false,
          valuetn: "கடுமையாக ஒப்புக்கொள்கிறேன்",
          scale: 1,
        },
        {
          value: "Agree",
          selected: false,
          valuetn: "ஒப்புக்கொள்கிறேன்",
          scale: 3,
        },
        { value: "Neutral", selected: false, valuetn: "நடுநிலை", scale: 5 },
        {
          value: "Disagree",
          selected: false,
          valuetn: "உடன்படவில்லை",
          scale: 8,
        },
        {
          value: "Strongly Disagree",
          selected: false,
          valuetn: "கடுமையாக உடன்படவில்லை",
          scale: 10,
        },
      ],
      indx4: 0,
      academic_: [
        { value: "Study Skills", selected: false, valuetn: "படிக்கும் திறன்" },
        {
          value: "Academic Stress",
          selected: false,
          valuetn: "கல்வி மன அழுத்தம்",
        },
        { value: "New Path ", selected: false, valuetn: "புதிய பாதை" },
        { value: "Collaboration", selected: false, valuetn: "கூட்டுப்பணி" },
        {
          value: "Positive Environment",
          selected: false,
          valuetn: "நேர்மறை சூழல்",
        },
        {
          value: "Critical Thinking",
          selected: false,
          valuetn: "விமர்சன சிந்தனை",
        },
        {
          value: "Online Learning",
          selected: false,
          valuetn: "ஆன்லைன் கற்றல்",
        },
        { value: "Balance", selected: false, valuetn: "சமநிலை" },
        { value: "Mentorship", selected: false, valuetn: "வழிகாட்டுதல்" },
        {
          value: "None of the above",
          selected: false,
          valuetn: "மேலே உள்ளவை எதுவும் இல்லை",
        },
      ],
      set5: [
        {
          question: "I know why I’m in college and what I want out of it.",
          answer: "",
          questiontn:
            "எனது கல்லூரி படிப்பின் முக்கியத்துவம் மற்றும் அதன் மூலம் நான் அடையப்போகும் இலக்கு என்ன என்பதில் தெளிவாக உணர்கிறேன்.",
        },
        {
          question: "My academic goals and purposes are well defined.",
          answer: "",
          questiontn:
            "எனது கல்வி இலக்குகள் மற்றும் நோக்கங்கள் நன்கு வரையறுக்கப்பட்டுள்ளன.",
        },
        {
          question: "Getting a college degree is very important to me.",
          answer: "",
          questiontn: "கல்லூரி பட்டம் பெறுவது எனக்கு மிகவும் முக்கியமானது.",
        },
        {
          question: "I am enjoying my academic work at college.",
          answer: "",
          questiontn:
            "நான் கல்லூரியில் எனது கல்விப் பணியை அனுபவித்து வருகிறேன்.",
        },
        {
          question:
            "Lately, I have been having doubts regarding the value of a college education.",
          answer: "",
          questiontn:
            "சசமீப காலமாக, கல்லூரிக் கல்வியின் மதிப்பு குறித்து எனக்கு சந்தேகம் உள்ளது.",
        },
      ],
      ans5: [
        {
          value: "Strongly Agree",
          selected: false,
          valuetn: "வலுவாக ஒப்புக்கொள்கிறேன்",
          scale: 1,
        },
        {
          value: "Agree",
          selected: false,
          valuetn: "ஒப்புக்கொள்கிறேன்",
          scale: 3,
        },
        { value: "Neutral", selected: false, valuetn: "நடுநிலை", scale: 5 },
        {
          value: "Disagree",
          selected: false,
          valuetn: "கருத்து வேறுபாடு",
          scale: 8,
        },
        {
          value: "Strongly Disagree",
          selected: false,
          valuetn: "முரண்படுகிறோம்",
          scale: 10,
        },
      ],
      indx5: 0,
      aspirations_: [
        { value: "Goal Setting", selected: false, valuetn: "இலக்கு அமைத்தல்" },
        {
          value: "Vision Clarity ",
          selected: false,
          valuetn: "தெளிவான நோக்கம்",
        },
        {
          value: "Skill Development ",
          selected: false,
          valuetn: "திறன் மேம்பாடு",
        },
        { value: "Networking", selected: false, valuetn: "நெட்வொர்க்கிங்" },
        {
          value: "Career Advancement",
          selected: false,
          valuetn: "தொழில் முன்னேற்றம்",
        },
        {
          value: "Peronal Growth",
          selected: false,
          valuetn: "தனிப்பட்ட வளர்ச்சி",
        },
        {
          value: "Work Satisfaction",
          selected: false,
          valuetn: "பணி திருப்தி",
        },
        {
          value: "Life Purpose",
          selected: false,
          valuetn: "வாழ்க்கையின் நோக்கம்",
        },
        {
          value: "Achievement Balance",
          selected: false,
          valuetn: "சாதனை சமநிலை",
        },
        {
          value: "None of the above",
          selected: false,
          valuetn: "மேலே உள்ளவை எதுவும் இல்லை",
        },
      ],
      set6: [
        {
          question:
            "I know how to change specific things that I want to change in my life.",
          answer: "",
          questiontn:
            "என் வாழ்க்கையில் நான் மாற்ற விரும்பும் குறிப்பிட்ட விஷயங்களை எப்படி மாற்றுவது என்பது எனக்குத் தெரியும்.",
        },
        {
          question: "I have a good sense of where I am headed in my life.",
          answer: "",
          questiontn:
            "என் வாழ்க்கையில் நான் எங்கு செல்கிறேன் என்பதை நான் நன்கு உணர்ந்திருக்கிறேன்.",
        },
        {
          question:
            "I know what I need to do to get started toward reaching my goals.",
          answer: "",
          questiontn:
            "எனது இலக்குகளை அடைவதற்கு நான் என்ன செய்ய வேண்டும் என்பது எனக்குத் தெரியும்.",
        },
        {
          question: "I take charge of my life.",
          answer: "",
          questiontn: "என் வாழ்க்கையை நான் பொறுப்பேற்கிறேன்.",
        },
        {
          question: "I have a plan for making my life more balanced.",
          answer: "",
          questiontn:
            "என் வாழ்க்கையை இன்னும் சீரானதாக மாற்றுவதற்கான திட்டம் என்னிடம் உள்ளது.",
        },
      ],
      ans6: [
        {
          value: "Strongly Agree",
          selected: false,
          valuetn: "வலுவாக ஒப்புக்கொள்கிறேன்",
          scale: 10,
        },
        {
          value: "Agree",
          selected: false,
          valuetn: "ஒப்புக்கொள்கிறேன்",
          scale: 8,
        },
        { value: "Neutral", selected: false, valuetn: "நடுநிலை", scale: 5 },
        {
          value: "Disagree",
          selected: false,
          valuetn: "கருத்து வேறுபாடு",
          scale: 3,
        },
        {
          value: "Strongly Disagree",
          selected: false,
          valuetn: "முரண்படுகிறோம்",
          scale: 1,
        },
      ],
      indx6: 0,
      overall_: {},
    };
  }

  componentDidMount() {
    this.getUser();
    setTimeout(()=>{
      this.setState({showLoader:false})
    },2000)
    const headers = {
      "Content-Type": "application/json",
      Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
    };
    axios.post(REACT_APP_API_URL+'/nmuser_wellbeing/',{nm_id:localStorage._nm},{headers}).then((res)=>{
      console.log(res)
      if(res.data.status=='success'){
        this.setState({overall_:res.data.data,step:20})
      }
      else{
        this.setState({step:1})
      }
    })
  }

  getUser = async () => {
    let data = JSON.stringify({ patient_n_key: localStorage._patientKey });
    const headers = {
      "Content-Type": "application/json",
      Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
    };
    axios
      .post(REACT_APP_API_URL + "/patientedit_app/", data, { headers })
      .then((res) => {
        console.log(res);
        console.log("patient_e", res);
        this.setState({ userinfo: res.data.data });
      });
  };
  handleForward = () => {
    const { step } = this.state;
    {
      this.setState({ step: step + 1 }, () => {
        if (
          step + 1 == 2 ||
          step + 1 == 5 ||
          step + 1 == 8 ||
          step + 1 == 11 ||
          step + 1 == 14 ||
          step + 1 == 17
        ) {
          this.setState({ disable: false });
        } else if (step + 1 == 20) {
          this.submitData_();
          this.setState({ showcofetti: true }, () => {
            setTimeout(() => {
              this.setState({ showcofetti: false, showresultmodal: true });
            }, 5000);
          });
        } else this.setState({ disable: true });
      });
    }
    this.topRef.current.scrollIntoView({ behavior: "smooth" });
  };
  handleCheck_ = async (index) => {
    const arr = [...this.state.emotional_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ emotional_: arr, disable: false });
  };
  handleCheck1_ = async (index) => {
    const arr = [...this.state.social_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ social_: arr, disable: false });
  };
  handleCheck2_ = async (index) => {
    const arr = [...this.state.personal_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ personal_: arr, disable: false });
  };
  handleCheck3_ = async (index) => {
    const arr = [...this.state.physical_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ physical_: arr, disable: false });
  };
  handleCheck4_ = async (index) => {
    const arr = [...this.state.academic_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ academic_: arr, disable: false });
  };
  handleCheck5_ = async (index) => {
    const arr = [...this.state.aspirations_];
    if (index + 1 == arr.length) {
      arr.map((i, ix) => {
        if (ix == index) return (i.selected = true);
        else return (i.selected = false);
      });
    } else {
      arr[index].selected = !arr[index].selected;
      arr[arr.length - 1].selected = false;
    }
    this.setState({ aspirations_: arr, disable: false });
  };
  handleset1_ = async (index, inx) => {
    const arr = [...this.state.set1];
    arr[inx].answer = this.state.ans1[index].value;
    arr[inx].scale = this.state.ans1[index].scale;
    this.setState({ set1: arr }, () => {
      if (this.state.indx1 + 1 == this.state.set1.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx1: this.state.indx1 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    let cognitiveScore = 0;
    let expressiveScore = 0;
    // Loop through the response array
    for (let i = 0; i < filtervalue.length; i++) {
      const itemScore = filtervalue[i];

      // Check if the item is a cognitive reappraisal item
      if ([1, 3, 5, 7, 9].includes(i + 1)) {
        cognitiveScore += itemScore;
      }

      // Check if the item is an expressive suppression item
      if ([2, 4, 6, 8, 10].includes(i + 1)) {
        expressiveScore += itemScore;
      }
    }
    let score = cognitiveScore - expressiveScore;
    this.setState({ emotionalscale_: score });
    if (score && score < 0) {
      this.setState({ emotionalscore_: "Low" });
    } else if (score && score == 0) {
      this.setState({ emotionalscore_: "Moderate" });
    } else if (score && score > 0) {
      this.setState({ emotionalscore_: "Excellent" });
    }
  };
  handleset2_ = async (index, inx) => {
    const arr = [...this.state.set2];
    arr[inx].answer = arr[inx].answerOption[index].value;
    arr[inx].scale = arr[inx].answerOption[index].scale;
    this.setState({ set2: arr }, () => {
      if (this.state.indx2 + 1 == this.state.set2.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx2: this.state.indx2 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    const score = filtervalue.reduce((acc, val) => acc + val, 0);
    this.setState({ socialscale_: score });
    if (score && score <= 30) {
      this.setState({ socialscore_: "Low" });
    } else if (score && score <= 50 && score >= 31) {
      this.setState({ socialscore_: "Moderate" });
    } else if (score && score <= 62 && score >= 51) {
      this.setState({ socialscore_: "Excellent" });
    }
  };
  handleset3_ = async (index, inx) => {
    const arr = [...this.state.set3];
    arr[inx].answer = this.state.ans3[index].value;
    arr[inx].scale = this.state.ans3[index].scale;
    this.setState({ set3: arr }, () => {
      if (this.state.indx3 + 1 == this.state.set3.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx3: this.state.indx3 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    const score = filtervalue.reduce((acc, val) => acc + val, 0);
    this.setState({ personalscale_: score });
    console.log(score);
    if (score && score <= 20) {
      this.setState({ personalscore_: "Low" });
    } else if (score && score <= 34 && score >= 21) {
      this.setState({ personalscore_: "Moderate" });
    } else if (score && score <= 40 && score >= 35) {
      this.setState({ personalscore_: "Excellent" });
    }
  };
  handleset4_ = async (index, inx) => {
    const arr = [...this.state.set4];
    arr[inx].answer = this.state.ans4[index].value;
    arr[inx].scale = this.state.ans4[index].scale;
    this.setState({ set4: arr }, () => {
      if (this.state.indx4 + 1 == this.state.set4.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx4: this.state.indx4 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    const score = filtervalue.reduce((acc, val) => acc + val, 0);
    this.setState({ physicalscale_: score });
    console.log(score);
    if (score && score <= 14) {
      this.setState({ physicalscore_: "Low" });
    } else if (score && score <= 24 && score >= 15) {
      this.setState({ physicalscore_: "Moderate" });
    } else if (score && score <= 60 && score >= 25) {
      this.setState({ physicalscore_: "Excellent" });
    }
  };
  handleset5_ = async (index, inx) => {
    const arr = [...this.state.set5];
    arr[inx].answer = this.state.ans5[index].value;
    arr[inx].scale = this.state.ans5[index].scale;
    this.setState({ set5: arr }, () => {
      if (this.state.indx5 + 1 == this.state.set5.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx5: this.state.indx5 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    const score = filtervalue.reduce((acc, val) => acc + val, 0);
    console.log(score);
    this.setState({ academicscale_: score });
    if (score && score <= 19) {
      this.setState({ academicscore_: "Low" });
    } else if (score && score <= 39 && score >= 20) {
      this.setState({ academicscore_: "Moderate" });
    } else if (score && score <= 50 && score >= 40) {
      this.setState({ academicscore_: "Excellent" });
    }
  };
  handleset6_ = async (index, inx) => {
    const arr = [...this.state.set6];
    arr[inx].answer = this.state.ans6[index].value;
    arr[inx].scale = this.state.ans6[index].scale;
    this.setState({ set6: arr }, () => {
      if (this.state.indx6 + 1 == this.state.set6.length) {
        this.setState({ disable: false });
        this.myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else this.setState({ indx6: this.state.indx6 + 1, disable: true });
    });
    let filtervalue = arr.map((i) => i.scale);
    const score = filtervalue.reduce((acc, val) => acc + val, 0);
    console.log(score);
    this.setState({ aspirationscale_: score });
    if (score && score <= 19) {
      this.setState({ aspirationscore_: "Low" });
    } else if (score && score <= 39 && score >= 20) {
      this.setState({ aspirationscore_: "Moderate" });
    } else if (score && score <= 50 && score >= 40) {
      this.setState({ aspirationscore_: "Excellent" });
    }
  };
  handleuser = () => {
    if (localStorage._patientKey) {
      window.location.href = "/home";
    } else window.location.href = "https://app.careme.health/";
  };
  handleToggle = () => {
    this.setState({ showresultmodal: false });
    window.location.reload()
  };
  submitData_ = async () => {
    const {
      emotional_,
      physical_,
      personal_,
      social_,
      academic_,
      aspirations_,
      socialscore_,
      emotionalscore_,
      physicalscore_,
      personalscore_,
      academicscore_,
      aspirationscore_,
      socialscale_,
      physicalscale_,
      personalscale_,
      academicscale_,
      aspirationscale_,
      emotionalscale_,
    } = this.state;
    const emot = emotional_.filter((i) => i.selected === true);
    const soc = social_.filter((i) => i.selected === true);
    const pers = personal_.filter((i) => i.selected === true);
    const phys = physical_.filter((i) => i.selected === true);
    const acad = academic_.filter((i) => i.selected === true);
    const aspi = aspirations_.filter((i) => i.selected === true);
    let data = JSON.stringify({
      emotional: {
        focus_areas: emot.map((i) => i.value),
        assessment_score: emotionalscale_,
        assesment: this.state.set1,
        total_score: 70,
        progress_status:this.state.emotionalscore_,
      },
      social: {
        focus_areas: soc.map((i) => i.value),
        assessment_score: socialscale_,
        assesment: this.state.set2,
        total_score: 63,
        progress_status:this.state.socialscore_,
      },
      personal: {
        focus_areas: pers.map((i) => i.value),
        assessment_score: personalscale_,
        assesment: this.state.set3,
        total_score: 40,
        progress_status:this.state.personalscore_,
      },
      physical: {
        focus_areas: phys.map((i) => i.value),
        assessment_score: physicalscale_,
        assesment: this.state.set4,
        total_score: 60,
        progress_status:this.state.physicalscore_,
      },
      academic: {
        focus_areas: acad.map((i) => i.value),
        assessment_score: academicscale_,
        assesment: this.state.set5,
        total_score: 50,
        progress_status:this.state.academicscore_,
      },
      aspiration: {
        focus_areas: aspi.map((i) => i.value),
        assessment_score: aspirationscale_,
        assesment: this.state.set6,
        total_score: 50,
        progress_status:this.state.aspirationscore_,
      },
      positive_areas: [
        socialscore_ == "Excellent" && "Social",
        emotionalscore_ == "Excellent" && "Emotional",
        physicalscore_ == "Excellent" && "Physical",
        personalscore_ == "Excellent" && "Personal",
        academicscore_ == "Excellent" && "Academic",
        aspirationscore_ == "Excellent" && "Aspiration",
      ].filter((i) => i),
      focus_areas: [
        (socialscore_ == "Low" || socialscore_ == "Moderate") && "Social",
        (emotionalscore_ == "Low" || emotionalscore_ == "Moderate") &&
          "Emotional",
        (physicalscore_ == "Low" || physicalscore_ == "Moderate") && "Physical",
        (personalscore_ == "Low" || personalscore_ == "Moderate") && "Personal",
        (academicscore_ == "Low" || academicscore_ == "Moderate") && "Academic",
        (aspirationscore_ == "Low" || aspirationscore_ == "Moderate") &&
          "Aspiration",
      ].filter((i) => i),
      patient_n_key: localStorage._patientKey || localStorage._nm,
      lang: "ta",
    });
    console.log(JSON.parse(data));
    const headers = {
      "Content-Type": "application/json",
      Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
    };
    axios
      .post(REACT_APP_API_URL + "/welbeing_post/", data, { headers })
      .then((res) => {
        console.log(res);
        axios
          .post(
            REACT_APP_API_URL + "/wellbeing_tracker/",
            { patient_n_key: localStorage._patientKey || localStorage._nm },
            { headers }
          )
          .then((res) => {
            console.log(res);
            if (res.data.status == "success") {
              this.setState({ overall_: res.data.data });
            }
          });
      });
  };
  getTamilName = (str) => {
    if (str == "aspiration") {
      return "ஆசை";
    } else if (str == "academic") {
      return "கல்வி நிலை";
    } else if (str == "social") {
      return "சமூக உறவு";
    } else if (str == "personal") {
      return "தனிப்பட்ட நிலை";
    } else if (str == "physical") {
      return "உடல்நிலை";
    } else if (str == "emotional") {
      return "உணர்ச்சி";
    }
  };
  gotoDash = async () => {
    if (this.state.step == 20) {
      this.submitData_();
    }
    this.setState({ step: 1, disable: false });
    if(localStorage._patientKey){
      window.location.href = "/home";
    }
    else{
      window.location.assign('https://app.careme.health/')
    }
  };
  goback_ = async () => {
    const { step } = this.state;
    if (step == 1) {
      this.gotoDash();
    } else this.setState({ step: step - 1, disable: false });
  };

  firstelement = () => {
    if (this.state.overall_) {
      const keys = Object.keys(this.state.overall_);
      const firstKey = keys[0];
      const createdOnValue = this.state.overall_[firstKey];
      return createdOnValue;
    }
  };
  isCreatedWithin14Days = () => {
    const today = moment().startOf('day');
    const createdOnDate = moment(this.firstelement()?.created_on);
    const dateDifferenceInDays = today.diff(createdOnDate, 'days');
    const daysLeft = 14 - dateDifferenceInDays;
    const isWithin14Days = dateDifferenceInDays >= 0 && dateDifferenceInDays <= 14;
    const dateLeft = isWithin14Days ? today.clone().add(daysLeft, 'days') : null;
    return {
      isWithin14Days: isWithin14Days,
      daysLeft: isWithin14Days ? daysLeft : null,
      dateLeft: moment(dateLeft).format('DD-MM-YYYY'),
    };
}


  capitalize = (string) => {
    let capitalizedString = string.charAt(0).toUpperCase() + string.slice(1);
    return capitalizedString;
  };
  render() {
    const {
      step,
      indx,
      emotional_,
      set1,
      indx1,
      ans1,
      social_,
      set2,
      indx2,
      personal_,
      set3,
      ans3,
      indx3,
      physical_,
      ans4,
      set4,
      indx4,
      academic_,
      ans5,
      set5,
      indx5,
      ans6,
      set6,
      indx6,
      aspirations_,
      disable,
      emotionalscore_,
      socialscore_,
      personalscore_,
      physicalscore_,
      academicscore_,
      aspirationscore_,
      userinfo,
      showLoader,
      showcofetti,
      showresultmodal,
    } = this.state;
    return (
      showLoader ? (
        <Caremeloader />
      ) : showcofetti ? (
        <FullScreenConfetti />
      ) : showresultmodal ? (
        <>
          <Modal
            centered
            size="lg"
            show={showresultmodal}
            onHide={this.handleToggle}
          >
            <Modal.Body>
              <h3 className="font-weight-light py-3 text-center header_font_">
                🎉 உங்கள் மதிப்பீட்டை முடித்ததற்கு வாழ்த்துகள்! 🎉
              </h3>
  
              <p className="font-weight-light h5 header_font_">
              Careme Health இல், உங்கள் மனநலத்தைப் புரிந்துகொள்வதற்கும் மேம்படுத்துவதற்கும் நீங்கள் எடுக்கும் ஒவ்வொரு அடியையும் கொண்டாடுவோம் என்று நாங்கள் நம்புகிறோம். நினைவில் கொள்ளுங்கள், விளைவு எதுவாக இருந்தாலும், சிறிய, சீரான மாற்றங்கள்தான் வெற்றிக்கும் பின்னடைவுக்கும் வழி வகுக்கும். இந்த நடவடிக்கை எடுத்ததற்காக உங்களைப் பற்றி பெருமைப்படுங்கள். உங்கள் பயணத்தில் உங்களுக்கு ஆதரவாக நாங்கள் இருக்கிறோம்..
                ❤️
              </p>
              <p className="font-weight-light py-2 h5 text-center header_font_">
              வலுவாகவும் ஊக்கமாகவும் இருங்கள். நல்வாழ்வுக்கான உங்கள் முயற்சி ஒரு நல்ல தொடக்கமாகும்.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleToggle}>முடிவுகளைப் பார்க்கவும்</Button>
            </Modal.Footer>
          </Modal>
      <FullScreenConfetti />

        </>
      ) :  <>
        <div className={step!==20?"topcontainer_ col-md-12 mx-auto d-flex m-0 p-0":''}>
          {step !== 20 ? (
            <div
              style={{ background: "#215352", minHeight: "680px" }}
              className="col-md-4 rounded m-5 d-none d-lg-block mt-md-3"
            >
              <div className="mt-md-5 pt-md-5">
                <h2
                  style={{ color: "#fff" }}
                  className="header_font_ text-center"
                >
                 கேர்மீ ஹெல்த் </h2>
                <p
                  style={{ color: "#fff" }}
                  className="text_p_ mt-n3 font-weight-bold text-center"
                >
                  நன்றாக உணருங்கள். சிறப்பாக வாழுங்கள்.
                </p>
              </div>
              <h4
                style={{ color: "#fff" }}
                className="mt-md-5 ml-md-5 pl-md-5 header_font_  text-left"
              >
                உங்கள் நல்வாழ்வைப் புரிந்து கொள்ளுங்கள்
              </h4>
              <div className="mt-md-5 ml-md-5 pl-md-5 text-left">
                <p
                  style={{ opacity: step > 0 && step < 3 ? 1 : 0.5 }}
                  className="white_color_"
                >
                  <span className="mr-2 mb-0">
                    {step > 0 && step < 3 ? (
                      <Circle size={14} />
                    ) : step >= 3 ? (
                      <BsCheck2Circle />
                    ) : (
                      <BsCircleFill size={12} />
                    )}
                  </span>{" "}
                  அறிமுகம்
                </p>
                <div
                  className="mt-n2 mb-1"
                  style={{
                    width: "3px",
                    marginLeft: "5px",
                    opacity: step > 0 && step < 3 ? 1 : 0.5,
                    height: "12px",
                    background: "#fff",
                    borderRadius: "10px",
                  }}
                ></div>
                <p
                  style={{ opacity: step > 2 && step < 6 ? 1 : 0.5 }}
                  className="white_color_"
                >
                  <span className="mr-2">
                    {step > 2 && step < 6 ? (
                      <Circle size={14} />
                    ) : step >= 6 ? (
                      <BsCheck2Circle />
                    ) : (
                      <BsCircleFill size={12} />
                    )}{" "}
                  </span>{" "}
                  உணர்ச்சி
                </p>
                <div
                  className="mt-n2 mb-1"
                  style={{
                    width: "3px",
                    marginLeft: "5px",
                    height: "12px",
                    opacity: step > 2 && step < 6 ? 1 : 0.5,
                    background: "#fff",
                    borderRadius: "10px",
                  }}
                ></div>
                <p
                  style={{ opacity: step > 5 && step < 9 ? 1 : 0.5 }}
                  className="white_color_"
                >
                  <span className="mr-2">
                    {step > 5 && step < 9 ? (
                      <Circle size={14} />
                    ) : step >= 9 ? (
                      <BsCheck2Circle />
                    ) : (
                      <BsCircleFill size={12} />
                    )}{" "}
                  </span>
                  சமூக உறவு
                </p>
                <div
                  className="mt-n2 mb-1"
                  style={{
                    width: "3px",
                    marginLeft: "5px",
                    height: "12px",
                    opacity: step > 5 && step < 9 ? 1 : 0.5,
                    background: "#fff",
                    borderRadius: "10px",
                  }}
                ></div>
                <p
                  style={{ opacity: step > 8 && step < 12 ? 1 : 0.5 }}
                  className="white_color_"
                >
                  <span className="mr-2">
                    {step > 8 && step < 12 ? (
                      <Circle size={14} />
                    ) : step >= 12 ? (
                      <BsCheck2Circle />
                    ) : (
                      <BsCircleFill size={12} />
                    )}{" "}
                  </span>
                  தனிப்பட்ட நிலை 
                </p>
                <div
                  className="mt-n2 mb-1"
                  style={{
                    width: "3px",
                    marginLeft: "5px",
                    height: "12px",
                    opacity: step > 8 && step < 12 ? 1 : 0.5,
                    background: "#fff",
                    borderRadius: "10px",
                  }}
                ></div>
                <p
                  style={{ opacity: step > 11 && step < 15 ? 1 : 0.5 }}
                  className="white_color_"
                >
                  <span className="mr-2">
                    {step > 11 && step < 15 ? (
                      <Circle size={14} />
                    ) : step >= 15 ? (
                      <BsCheck2Circle />
                    ) : (
                      <BsCircleFill size={12} />
                    )}
                  </span>
                  உடல்நிலை
                </p>
                <div
                  className="mt-n2 mb-1"
                  style={{
                    width: "3px",
                    marginLeft: "5px",
                    height: "12px",
                    opacity: step > 11 && step < 15 ? 1 : 0.5,
                    background: "#fff",
                    borderRadius: "10px",
                  }}
                ></div>
                <p
                  style={{ opacity: step > 14 && step < 18 ? 1 : 0.5 }}
                  className="white_color_"
                >
                  <span className="mr-2">
                    {step > 14 && step < 18 ? (
                      <Circle size={14} />
                    ) : step >= 18 ? (
                      <BsCheck2Circle />
                    ) : (
                      <BsCircleFill size={12} />
                    )}{" "}
                  </span>{" "}
                  கல்வி நிலை
                </p>
                <div
                  className="mt-n2 mb-1"
                  style={{
                    width: "3px",
                    marginLeft: "5px",
                    height: "12px",
                    opacity: step > 14 && step < 18 ? 1 : 0.5,
                    background: "#fff",
                    borderRadius: "10px",
                  }}
                ></div>
                <p
                  style={{ opacity: step >= 18 ? 1 : 0.5 }}
                  className="white_color_"
                >
                  <span className="mr-2">
                    {step == 18 ? (
                      <Circle size={14} />
                    ) : step >= 18 ? (
                      <BsCheck2Circle />
                    ) : (
                      <BsCircleFill size={12} />
                    )}
                  </span>{" "}
                  இலட்சியங்கள்
                </p>
              </div>
            </div>
          ) : null}
          <div ref={this.topRef} className={step!==20?"col-md-6 p-md-5 p-4":''}>
            {step == 1 ? (
              <>
                <div className="inputcontainer_ mt-md-5 mt-3 ml-md-5">
                  <p className="toptext_ mt-md-5 pt-md-5 h3">
                  👋 வரவேற்கிறோம்,{" "}
                    {userinfo &&
                      userinfo.first_name &&
                      this.capitalize(userinfo && userinfo.first_name)}{" "}
                    ! 💖  உங்களின் தனிப்பயனாக்கப்பட்ட நல்வாழ்வு சுயவிவரத்தை உருவாக்குவோம்!
                  </p>
                  <p className="secondtxt_ mt-3">
                  கேர்மீ ஹெல்த் உடன் உங்களை இணைத்துக் கொள்வதில் நாங்கள் மகிழ்ச்சியடைகிறோம். உங்கள் பயணத்தைத் தனிப்பயனாக்க மற்றும் பொருத்தமான ஆதரவை வழங்க, உங்கள் நல்வாழ்வு சுயவிவரத்தை உருவாக்குவதன் மூலம் தொடங்குவோம். இந்த விரிவான சுயவிவரமானது, எங்கள் பராமரிப்பு மாதிரியின் ஆறு கவனம் செலுத்தும் பகுதிகளில் உங்கள் தற்போதைய நிலையை மதிப்பிடும்:
                  </p>
                  <div className="checkbox_">
                    <button className="checkboxtextbtn_ rounded m-2 p-2 text_color_">
                      😊 உணர்ச்சி
                    </button>
                    <button className="checkboxtextbtn_ rounded m-2 p-2 text_color_">
                      🧩 தனிப்பட்ட நிலை 
                    </button>
                    <button className="checkboxtextbtn_ rounded m-2 p-2 text_color_">
                      💬 சமூக உறவு
                    </button>
                    <button className="checkboxtextbtn_ rounded m-2 p-2 text_color_">
                      🎓  கல்வி நிலை 
                    </button>
                    <button className="checkboxtextbtn_ rounded m-2 p-2 text_color_">
                      💪 உடல்நிலை
                    </button>
                    <button className="checkboxtextbtn_ rounded m-2 p-2 text_color_">
                      🌟 இலட்சியங்கள்
                    </button>
                  </div>
                  <p className="btmtop_ mt-3">
                  வெறும் 10 நிமிடங்களில், உங்கள் நல்வாழ்வைப் பற்றிய நுண்ணறிவுகளைப் பெறுவீர்கள், மேலும் கேர்மீ ஹெல்த் மூலம் மாற்றத்தக்க அனுபவத்திற்கான அடித்தளத்தை அமைப்பீர்கள்..{" "}
                  </p>
                  <p className="btmsecondtop_">
                  ஆரோக்கியமான, மகிழ்ச்சியான உங்களுக்கான இந்தப் பயணத்தைத் தொடங்குவோம்!
                  </p>
                </div>
              </>
            ) : null}

            {step == 2 ? (
              <>
                <div className="inputcontainer_ mt-md-5 mt-3 ml-md-5">
                  <p className="toptext_ mt-md-5 pt-md-5 h3">
                  🎯 உங்கள் உணர்ச்சி நல்வாழ்வு இலக்குகளை அமைக்கவும்
                  </p>
                  <p className="secondtxt_ mt-md-4">
                  உங்கள் உணர்ச்சி மதிப்பீட்டிற்குள் நுழைவதற்கு முன், உங்கள் உணர்ச்சி நல்வாழ்வின் எந்த அம்சங்களில் நீங்கள் மேன்பாடு செய்ய விரும்புகிறீர்கள் என்பதைக் கண்டறிவோம்.
                  </p>
                  <p className="secondtxt_ mt-md-4">
                  உங்களின் தனிப்பட்ட வளர்ச்சிப் பயணத்தை சிறப்பாக ஆதரிப்பதற்கு எங்களின் பரிந்துரைகள் மற்றும் ஆதாரங்களைத் தக்கவைக்க இது உதவும்.
                  </p>
                  <p className="btmtop_ mt-md-4">
                  💬 நாங்கள் உங்களுக்கு ஆதரவாக இருக்கிறோம், எனவே உங்கள் மனதில் உள்ளதை தயங்காமல் பகிரவும். 🤗
                  </p>
                </div>
              </>
            ) : null}
            {step == 3 ? (
              <>
                <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
                  <p className="questiontxt_ h3 header_font_  mt-md-5 pt-md-5 h3 header_font_  mt-md-5 pt-md-5">
                  உங்கள் உணர்ச்சி நல்வாழ்வைப் பற்றி நீங்கள் நினைக்கும் போது, நீங்கள் எதைச் செய்ய விரும்புகிறீர்கள்?
                  </p>
                  <p className="questiontxt_ header_font_ pt-md-1 h5">
                  (பொருந்தும் அனைத்தையும் தேர்ந்தெடுக்கவும்)
                  </p>
                  <div className="checkbox_ mt-md-5">
                    {emotional_.map((i, index) => {
                      return (
                        <button
                          onClick={() => this.handleCheck_(index)}
                          className="checkboxtextbtn_ m-2 p-2 text_color_ rounded m-2 p-2"
                          style={{
                            backgroundColor: i.selected ? "#ddefe5" : "#fff",
                          }}
                        >
                          {i.valuetn}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : null}
            {step == 4 ? (
              <>
                <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
                  <p className="questiontxt_ h3 header_font_  mt-md-5 pt-md-5">
                    {indx1 + 1}. {set1[indx1].questiontn}
                  </p>
                  <div className="radiobox_ mt-md-5 col-md-7">
                    {ans1.map((i, index) => {
                      return (
                        <button
                          onClick={() => this.handleset1_(index, indx1)}
                          className="checkboxtextbtn_ text-left rounded m-2 p-2 text_color_"
                          style={{
                            backgroundColor:
                              set1[indx1].answer == i.value
                                ? "#ddefe5"
                                : "#fff",
                          }}
                        >
                          {i.valuetn}
                        </button>
                      );
                    })}
                  </div>
                  <div className="btnview_ d-flex flex-row my-4 justify-content-between ">
                    <button
                      onClick={
                        indx1 == 0
                          ? null
                          : () => {
                              this.setState({
                                indx1: indx1 - 1,
                                disable: true,
                              });
                            }
                      }
                      className="prev_  "
                      style={{ opacity: indx1 > 0 ? 1 : 0.5 }}
                    >
                      <ChevronLeft />
                    </button>
                    <RoundProgressBar
                      answeredCount={set1.filter(i=>i.answer).length}
                      questionCount={set1.length}
                      radius={22}
                      strokeWidth={20}
                      color="#f7f7f7"
                    />
                    <button
                      disabled={!set1[indx1].answer}
                      onClick={
                        indx1 + 1 == set1.length
                          ? null
                          : () => {
                              this.setState({ indx1: indx1 + 1 });
                            }
                      }
                      className="prev_"
                      style={{
                        opacity:
                          indx1 + 1 == set1.length && !set1[indx1].answer
                            ? 0.5
                            : 1,
                      }}
                    >
                      <ChevronRight />
                    </button>
                  </div>
                </div>
              </>
            ) : null}

            {step == 5 ? (
              <>
                <div className="inputcontainer_ mt-md-5 mt-3 ml-md-5">
                  <p className="topicon_ mt-md-5 text_p_">
                  ✅ உணர்ச்சி மதிப்பீடு முடிந்தது!
                  </p>
                  <p className="toptext_ h3 mt-md-4">
                  👏 உங்கள் உணர்வுபூர்வமான வாழ்க்கையைப் பற்றிய கேள்விகளை முடித்ததற்கு வாழ்த்துகள்! நீங்கள் ஒரு சிறந்த தொடக்கத்தில் இருக்கிறீர்கள். 🌟
                  </p>
                  <p className="questiontxt_ h5 header_font_ mt-md-4">
                  இப்போது, உங்களின் தனிப்பட்ட தேவைகளைப் பூர்த்திசெய்யும் வகையில் தகுந்த ஆதரவையும் ஆதாரங்களையும் வழங்க உங்கள் சமூக நல்வாழ்வைப் பற்றி மேலும் அறிய விரும்புகிறோம். 🧐
                  </p>
                </div>
              </>
            ) : null}

            {step == 6 ? (
              <>
                <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
                  <p className="questiontxt_ mt-md-5 h3">
                  உங்கள் சமூக நலனை கருத்தில் கொள்ளும்போது, எந்தெந்த பகுதிகளில் கவனம் செலுத்த விரும்புகிறீர்கள்?
                  </p>
                  <p className="questiontxt_ h5 header_font_ pt-1">
                  (பொருந்தும் அனைத்தையும் தேர்ந்தெடுக்கவும்)
                  </p>
                  <div className="checkbox_ mt-md-5">
                    {social_.map((i, index) => {
                      return (
                        <button
                          onClick={() => this.handleCheck1_(index)}
                          className="checkboxtextbtn_ rounded m-2 p-2 text_color_"
                          style={{
                            backgroundColor: i.selected ? "#ddefe5" : "#fff",
                          }}
                        >
                          {i.valuetn}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : null}

            {step == 7 ? (
              <>
                <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
                  <p className="questiontxt_ h3 header_font_ mt-md-5 pt-md-5">
                    {indx2 + 1}. {set2[indx2].questiontn}
                  </p>
                  <div className="radiobox_ mt-md-5 col-md-7">
                    {set2[indx2].answerOption.map((i, index) => {
                      return (
                        <button
                          onClick={() => this.handleset2_(index, indx2)}
                          className="checkboxtextbtn_ text-left rounded m-2 p-2 text_color_"
                          style={{
                            backgroundColor:
                              set2[indx2].answer == i.value
                                ? "#ddefe5"
                                : "#fff",
                          }}
                        >
                          {i.valuetn}
                        </button>
                      );
                    })}
                  </div>
                  <div className="btnview_ d-flex flex-row my-4 justify-content-between">
                    <button
                      onClick={
                        indx2 == 0
                          ? null
                          : () => {
                              this.setState({ indx2: indx2 - 1 });
                            }
                      }
                      className="prev_"
                      style={{ opacity: indx2 > 0 ? 1 : 0.5 }}
                    >
                      <ChevronLeft />
                    </button>
                    <RoundProgressBar
                      answeredCount={set2.filter(i=>i.answer).length}
                      questionCount={set2.length}
                      radius={22}
                      strokeWidth={20}
                      color="#f7f7f7"
                    />
                    <button
                      disabled={!set2[indx2].answer}
                      onClick={
                        indx2 + 1 == set2.length
                          ? null
                          : () => {
                              this.setState({ indx2: indx2 + 1 });
                            }
                      }
                      className="prev_"
                      style={{
                        opacity:
                          indx2 + 1 == set2.length && !set2[indx2].answer
                            ? 0.5
                            : 1,
                      }}
                    >
                      <ChevronRight />
                    </button>
                  </div>
                </div>
              </>
            ) : null}

            {step == 8 ? (
              <>
                <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
                  <p className="topicon_ mt-md-5">
                  ✅ சமூக மதிப்பீடு முடிந்தது! வாழ்த்துக்கள்! 🙌
                  </p>
                  <p className="toptext_ h3 mt-md-4">
                  உங்கள் சமூக சுயவிவரத்தை முடித்ததற்கு வாழ்த்துகள்! 🎉
                  </p>
                  <p className="questiontxt_ h5 header_font_  mt-md-4 ">
                  இப்போது, உங்கள் தனிப்பட்ட நல்வாழ்வில் கவனம் செலுத்தி, வளர்ச்சி மற்றும் முன்னேற்றத்திற்கான பகுதிகளைக் கண்டறியலாம்.
                  </p>
                </div>
              </>
            ) : null}
            {step == 9 ? (
              <>
                <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
                  <p className="questiontxt_ h5 header_font_  mt-md-5 ">
                    உங்களுக்கு உதவ, சில பொதுவான தனிப்பட்ட நலன் சார்ந்த
                    தலைப்புகளை கீழே பட்டியலிட்டுள்ளோம். உங்களுக்கு மிகவும்
                    பொருத்தமான தலைப்பைத் தேர்ந்தெடுக்கவும்.
                  </p>
                  <p className="questiontxt_ h3 header_font_  mt-md-2 ">
                  உங்கள் தனிப்பட்ட நல்வாழ்வு பயணம் இங்கே தொடங்குகிறது:
                  </p>
                  <p className="questiontxt_ h5 header_font_ pt-1 ">
                    (பொருந்தும் அனைத்தையும் தேர்ந்தெடுக்கவும்)
                  </p>
                  <div className="checkbox_ mt-md-5">
                    {personal_.map((i, index) => {
                      return (
                        <button
                          onClick={() => this.handleCheck2_(index)}
                          className="checkboxtextbtn_ rounded m-2 p-2 text_color_"
                          style={{
                            backgroundColor: i.selected ? "#ddefe5" : "#fff",
                          }}
                        >
                          {i.valuetn}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : null}
            {step == 10 ? (
              <>
                <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
                  <p className="questiontxt_ h3 header_font_  mt-md-5 pt-md-5">
                    {indx3 + 1}. {set3[indx3].questiontn}
                  </p>
                  <div className="radiobox_ mt-md-5 col-md-7">
                    {ans3.map((i, index) => {
                      return (
                        <button
                          onClick={() => this.handleset3_(index, indx3)}
                          className="checkboxtextbtn_ text-left rounded m-2 p-2 text_color_"
                          style={{
                            backgroundColor:
                              set3[indx3].answer == i.value
                                ? "#ddefe5"
                                : "#fff",
                          }}
                        >
                          {i.valuetn}
                        </button>
                      );
                    })}
                  </div>
                  <div className="btnview_ d-flex flex-row my-4 justify-content-between">
                    <button
                      onClick={
                        indx3 == 0
                          ? null
                          : () => {
                              this.setState({ indx3: indx3 - 1 });
                            }
                      }
                      className="prev_"
                      style={{ opacity: indx1 > 0 ? 1 : 0.5 }}
                    >
                      <ChevronLeft />
                    </button>
                    <RoundProgressBar
                      answeredCount={set3.filter(i=>i.answer).length}
                      questionCount={set3.length}
                      radius={22}
                      strokeWidth={20}
                      color="#f7f7f7"
                    />
                    <button
                      disabled={!set3[indx3].answer}
                      onClick={
                        indx3 + 1 == set3.length
                          ? null
                          : () => {
                              this.setState({ indx3: indx3 + 1 });
                            }
                      }
                      className="prev_"
                      style={{
                        opacity:
                          indx3 + 1 == set3.length && !set3[indx3].answer
                            ? 0.5
                            : 1,
                      }}
                    >
                      <ChevronRight />
                    </button>
                  </div>
                </div>
              </>
            ) : null}

            {step == 11 ? (
              <>
                <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
                  <p className="topicon_ mt-md-5">
                  ✅ மதிப்பீடு முடிந்தது! வாழ்த்துக்கள்! 🙌
                  </p>
                  <p className="toptext_ h3 mt-md-4" style={{ marginTop: 20 }}>
                  உங்கள் தனிப்பட்ட சுயவிவரத்தை முடிப்பதில் சிறந்த வேலை! 👏
                  </p>
                  <p className="questiontxt_ h5 header_font_  mt-md-4 ">
                  இப்போது, உங்கள் உடல் நலனில் கவனம் செலுத்தி, வளர்ச்சி மற்றும் முன்னேற்றத்திற்கான பகுதிகளைக் கண்டறியலாம்.
                  </p>
                </div>
              </>
            ) : null}
            {step == 12 ? (
              <>
                <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
                  <p className="questiontxt_ h5 header_font_  mt-md-5 ">
                  நீங்கள் தொடங்குவதற்கு உதவ, கீழே சில பொதுவான உடல் நலக் கவலைகளை பட்டியலிட்டுள்ளோம். உங்களுக்கு மிகவும் பொருத்தமான தலைப்பைத் தேர்ந்தெடுக்கவும்.
                  </p>
                  <p className="questiontxt_ h3 header_font_  mt-md-2 ">
                  உங்கள் உடல் நலப் பயணம் இங்கே தொடங்குகிறது:
                  </p>
                  <p className="questiontxt_ h5 header_font_  pt-1 ">
                    (பொருந்தும் அனைத்தையும் தேர்ந்தெடுக்கவும்)
                  </p>
                  <div className="checkbox_ mt-md-5">
                    {physical_.map((i, index) => {
                      return (
                        <button
                          onClick={() => this.handleCheck3_(index)}
                          className="checkboxtextbtn_ rounded m-2 p-2 text_color_"
                          style={{
                            backgroundColor: i.selected ? "#ddefe5" : "#fff",
                          }}
                        >
                          {i.valuetn}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : null}
            {step == 13 ? (
              <>
                <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
                  <p className="questiontxt_ h3 header_font_  mt-md-5 pt-md-5">
                    {indx4 + 1}. {set4[indx4].questiontn}
                  </p>
                  <div className="radiobox_ mt-md-5 col-md-7">
                    {ans4.map((i, index) => {
                      return (
                        <button
                          onClick={() => this.handleset4_(index, indx4)}
                          className="checkboxtextbtn_ text-left rounded m-2 p-2 text_color_"
                          style={{
                            backgroundColor:
                              set4[indx4].answer == i.value
                                ? "#ddefe5"
                                : "#fff",
                          }}
                        >
                          {i.valuetn}
                        </button>
                      );
                    })}
                  </div>
                  <div className="btnview_ d-flex flex-row my-4 justify-content-between">
                    <button
                      onClick={
                        indx4 == 0
                          ? null
                          : () => {
                              this.setState({ indx4: indx4 - 1 });
                            }
                      }
                      className="prev_"
                      style={{ opacity: indx4 > 0 ? 1 : 0.5 }}
                    >
                      <ChevronLeft />
                    </button>
                    <RoundProgressBar
                      answeredCount={set4.filter(i=>i.answer).length}
                      questionCount={set4.length}
                      radius={22}
                      strokeWidth={20}
                      color="#f7f7f7"
                    />
                    <button
                      disabled={!set4[indx4].answer}
                      onClick={
                        indx4 + 1 == set4.length
                          ? null
                          : () => {
                              this.setState({ indx4: indx4 + 1 });
                            }
                      }
                      className="prev_"
                      style={{
                        opacity:
                          indx4 + 1 == set4.length && !set4[indx4].answer
                            ? 0.5
                            : 1,
                      }}
                    >
                      <ChevronRight />
                    </button>
                  </div>
                </div>
              </>
            ) : null}

            {step == 14 ? (
              <>
                <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
                  <p className="topicon_ mt-md-5">
                  ✅ சிறந்த வேலை! உடல் மதிப்பீடு முடிந்தது! 🙌
                  </p>
                  <p className="toptext_ h3 mt-md-4" style={{ marginTop: 20 }}>
                  உங்கள் உடல் சுயவிவரத்தை முடித்ததற்கு வாழ்த்துகள்! 🎉
                  </p>
                  <p className="questiontxt_ h5 header_font_  mt-md-4 ">
                  இப்போது, உங்கள் கல்வி நலனை ஆராய்வோம்.
                  </p>
                </div>
              </>
            ) : null}
            {step == 15 ? (
              <>
                <div className="inputcontainer_ mt-md-5 ml-md-5 mt-3">
                  <p className="questiontxt_ h3 header_font_  mt-md-5 pt-md-5">
                  கல்வி நல்வாழ்வு கவனம்: கீழே உள்ள சுருக்கமான விருப்பங்களிலிருந்து நீங்கள் கவனம் செலுத்த விரும்பும் பகுதியைத் தேர்ந்தெடுக்கவும்:
                  </p>
                  <p className="questiontxt_ h5 header_font_  pt-1">
                    (பொருந்தும் அனைத்தையும் தேர்ந்தெடுக்கவும்)
                  </p>
                  <div className="checkbox_ mt-md-5">
                    {academic_.map((i, index) => {
                      return (
                        <button
                          onClick={() => this.handleCheck4_(index)}
                          className="checkboxtextbtn_ rounded m-2 p-2 text_color_"
                          style={{
                            backgroundColor: i.selected ? "#ddefe5" : "#fff",
                          }}
                        >
                          {i.valuetn}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : null}
            {step == 16 ? (
              <>
                <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
                  <p className="questiontxt_ h3 header_font_  mt-md-5 pt-md-5">
                    {indx5 + 1}. {set5[indx5].questiontn}
                  </p>
                  <div className="radiobox_ mt-md-5 col-md-7">
                    {ans5.map((i, index) => {
                      return (
                        <button
                          onClick={() => this.handleset5_(index, indx5)}
                          className="checkboxtextbtn_ text-left rounded m-2 p-2 text_color_"
                          style={{
                            backgroundColor:
                              set5[indx5].answer == i.value
                                ? "#ddefe5"
                                : "#fff",
                          }}
                        >
                          {i.valuetn}
                        </button>
                      );
                    })}
                  </div>
                  <div className="btnview_ d-flex flex-row my-4 justify-content-between">
                    <button
                      onClick={
                        indx5 == 0
                          ? null
                          : () => {
                              this.setState({ indx5: indx5 - 1 });
                            }
                      }
                      className="prev_"
                      style={{ opacity: indx5 > 0 ? 1 : 0.5 }}
                    >
                      <ChevronLeft />{" "}
                    </button>
                    <RoundProgressBar
                      answeredCount={set5.filter(i=>i.answer).length}
                      questionCount={set5.length}
                      radius={22}
                      strokeWidth={20}
                      color="#f7f7f7"
                    />
                    <button
                      disabled={!set5[indx5].answer}
                      onClick={
                        indx5 + 1 == set5.length
                          ? null
                          : () => {
                              this.setState({ indx5: indx5 + 1 });
                            }
                      }
                      className="prev_"
                      style={{
                        opacity:
                          indx5 + 1 == set5.length && !set5[indx5].answer
                            ? 0.5
                            : 1,
                      }}
                    >
                      <ChevronRight />{" "}
                    </button>
                  </div>
                </div>
              </>
            ) : null}

            {step == 17 ? (
              <>
                <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
                  <p className="topicon_ mt-md-5">
                  ✅ உங்கள் கல்விச் சுயவிவரத்தை முடித்ததில் நன்றாக முடிந்தது! 🌟
                  </p>
                  <p className="toptext_  h3 mt-md-4">
                  நாங்கள் இந்தப் பயணத்தைத் தொடரும்போது, உங்கள் இலட்சியங்கள் நல்வாழ்வுக்கு எங்கள் கவனத்தை மாற்ற வேண்டிய நேரம் இது.
                  </p>
                  <p className="questiontxt_ h5 header_font_  mt-md-4  ">
                  உங்களின் தனிப்பட்ட மற்றும் தொழில் வளர்ச்சிக்கு உந்து சக்தியாக உங்கள் அபிலாஷைகளே உள்ளன.
                  </p>
                </div>
              </>
            ) : null}
            {step == 18 ? (
              <>
                <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
                  <p className="questiontxt_ h3 header_font_  mt-md-5 ">
                    {" "}
                    வழங்கப்பட்ட விருப்பங்களிலிருந்து நீங்கள் கவனம் செலுத்த விரும்பும் பகுதியைத் தேர்ந்தெடுக்கவும்.
                  </p>
                  <p className="questiontxt_ h3 header_font_  mt-md-2 ">
                  உங்கள் இலட்சியங்கள் நல்வாழ்வைத் தொடரவும்:
                  </p>
                  <p className="questiontxt_ h5 header_font_  pt-1 ">
                    (பொருந்தும் அனைத்தையும் தேர்ந்தெடுக்கவும்)
                  </p>
                  <div className="checkbox_ mt-md-5">
                    {aspirations_.map((i, index) => {
                      return (
                        <button
                          onClick={() => this.handleCheck5_(index)}
                          className="checkboxtextbtn_ rounded m-2 p-2 text_color_"
                          style={{
                            backgroundColor: i.selected ? "#ddefe5" : "#fff",
                          }}
                        >
                          {i.valuetn}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : null}
            {step == 19 ? (
              <>
                <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
                  <p className="questiontxt_ h3 header_font_  mt-md-5 pt-md-5">
                    {indx6 + 1}. {set6[indx6].questiontn}
                  </p>
                  <div className="radiobox_ mt-md-5 col-md-7">
                    {ans6.map((i, index) => {
                      return (
                        <button
                          onClick={() => this.handleset6_(index, indx6)}
                          className="checkboxtextbtn_ text-left rounded m-2 p-2 text_color_"
                          style={{
                            backgroundColor:
                              set6[indx6].answer == i.value
                                ? "#ddefe5"
                                : "#fff",
                          }}
                        >
                          {i.valuetn}
                        </button>
                      );
                    })}
                  </div>
                  <div className="btnview_ d-flex flex-row my-4 justify-content-between">
                    <button
                      onClick={
                        indx6 == 0
                          ? null
                          : () => {
                              this.setState({ indx6: indx6 - 1 });
                            }
                      }
                      className="prev_"
                      style={{ opacity: indx6 > 0 ? 1 : 0.5 }}
                    >
                      <ChevronLeft />
                    </button>
                    <RoundProgressBar
                      answeredCount={set6.filter(i=>i.answer).length}
                      questionCount={set6.length}
                      radius={22}
                      strokeWidth={20}
                      color="#f7f7f7"
                    />
                    <button
                      disabled={!set6[indx6].answer}
                      onClick={
                        indx6 + 1 == set6.length
                          ? null
                          : () => {
                              this.setState({ indx5: indx6 + 1 });
                            }
                      }
                      className="prev_"
                      style={{
                        opacity:
                          indx6 + 1 == set6.length && !set6[indx6].answer
                            ? 0.5
                            : 1,
                      }}
                    >
                      <ChevronRight />
                    </button>
                  </div>
                </div>
              </>
            ) : null}

            {step !== 20 && (
              <button
                ref={this.myButtonRef}
                disabled={disable}
                onClick={() => this.handleForward()}
                className="btmbtn_ ml-md-5 mt-md-3 mt-5  mb-md-5 px-4 py-2 font-weight-bold text_p_ rounded "
                style={{
                  opacity: disable ? 0.5 : 1,
                  fontSize: "16px",
                  backgroundColor: disable ? "#333333" : "#215352",
                }}
              >
                {step == 1
                  ? `ஆரம்பித்துவிடுவோம்`
                  : step == 5
                  ? "சமூக கேள்விகளைத் தொடங்குங்கள்"
                  : step == 9
                  ? "தனிப்பட்ட கேள்விகளைத் தொடங்கவும்"
                  : "தொடரவும்"}
              </button>
            )}
            
          </div>
        </div>
        {step == 20 ? (
          <>
           <div  className="d-flex flex-column flex-md-row col-md-10 mx-md-5 mx-0 p-0 p-md-3">
              <div className="col-md-9 mx-auto  table-responsive">
                <div className="col-md-12 mx-auto mt-md-1 d-flex p-0">
                  <img
                    src={`${CDN_URL}/CaremeLogo/final%20logo%20with%20slogan%20copy.png`}
                    className="img-fluid"
                    style={{ maxHeight: "100px" }}
                  />
                  <h5
                    style={{ opacity: 0.9 }}
                    className="header_font_ text-center _text_color align-self-center"
                  >
                    உங்கள் தனிப்பயனாக்கப்பட்ட நல்வாழ்வுக்கான படிகள் 🗺️ - உங்கள்
                    பகுப்பாய்வு செய்யப்பட்ட மதிப்பீட்டு முடிவுகள்
                  </h5>
                  {(localStorage._nm&&!localStorage._patientKey)? <img src={'https://portal.naanmudhalvan.tn.gov.in/img/logo.d52ff4c5.png'} className='img-fluid' style={{maxHeight:'100px'}} />
        :null}
                </div>
                <div className="d-lg-none d-sm-block my-2 px-2">
                <div className="text-center">
                  <button
                    style={{
                      background: "#ed8824",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                    className="btn btn-block"
                  >
                    Download our mobile app now!
                  </button>
                </div>
                <div className="text-center mt-1">
                  <div className="d-flex flex-row justify-content-between">
                    <div
                      className="m-1 p-2 mt-2"
                      style={{
                        maxHeight: "120px",
                        maxWidth: "110px",
                        background: "#4a5ae8",
                      }}
                    >
                      <Flash size={22} color={"orange"} />
                      <p
                        className="text-white m-0"
                        style={{ fontSize: "10px" }}
                      >
                        Take a step towards better mental well-being.
                      </p>
                    </div>
                    <div>
                      <img
                        src={`${CDN_URL}/Others/qr-code.png`}
                        className="img-fluid text-center m-1"
                        style={{ minHeight: "120px" }}
                      />
                    </div>

                    <div
                      style={{
                        maxHeight: "120px",
                        maxWidth: "110px",
                        background: "#ffff08",
                      }}
                      className="m-1 mt-2 p-2"
                    >
                      <p className="m-0" style={{ fontSize: "8px" }}>
                        Always Here for You
                      </p>
                      <div className="d-flex justify-content-around">
                        <PhoneCall size={14} />
                        <BsWhatsapp size={14} />
                      </div>
                      <b style={{ fontSize: "10px" }}>+91 7829-002-002</b>
                      <p
                        className="m-0 text-center pt-1"
                        style={{ fontSize: "7px" }}
                      >
                        Available 24/7 for your support and care. Whenever
                        you're ready, we're here.
                      </p>
                    </div>
                  </div>
                </div>
                </div>
                <table className="table table-bordered pb-2  pb-md-4 table-fixed rounded table-responsive-md border">
                <colgroup>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "20%" }} />
                </colgroup>
                <thead>
                  <tr style={{ backgroundColor: "#ddefe5" }}>
                    <th className="py-4 _text_color text-center header_font_ h4">
                    
                    </th>
                    <th className="py-4 _text_color header_font_ h4">கவனம் செலுத்த வேண்டியவை</th>
                    <th className="py-4 _text_color text-center header_font_ h4">
                    மேம்படுத்த வேண்டியது
                    </th>
                    <th className="py-4 _text_color header_font_ h4">
                    சிறந்த நிலையில் உள்ளது
                    </th>
                    <th className="py-4 _text_color text-center header_font_ h4">
                    
                    </th>
                  </tr>
                </thead>
                  
                  <tbody>
                    {Object.keys(this.state.overall_).map((category, index) => (
                      <tr
                        onClick={() =>
                          this.props.history.push("/wellbeing-tn-asses-view", {
                            data: this.state.overall_[category],
                          })
                        }
                        className="py-5"
                        key={index}
                      >
                        <td className="text_p_ h5 _text_color py-4">
                          <p>{this.getTamilName(category)}</p>
                        </td>
                        <td>
                          {this.state.overall_[category].progress_status ===
                            "Low" && (
                            <div className="position-relative">
                              <div className="banner-text">
                                {this.state.overall_[category].assessment_score}
                              </div>
                              <div
                                className="bg-dark text-center mx-auto pt-4"
                                style={{ height: "34px", width: "3px" }}
                              ></div>
                              <div className="progress mt-0">
                                <div
                                  className="progress-bar "
                                  role="progressbar"
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#f5a97a",
                                  }}
                                  aria-valuenow="33.33"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </div>
                          )}
                        </td>
                        <td>
                          {this.state.overall_[category].progress_status ===
                            "Moderate" && (
                            <div className="position-relative">
                              <div className="banner-text">
                                {this.state.overall_[category].assessment_score}
                              </div>
                              <div
                                className="bg-dark text-center mx-auto pt-4"
                                style={{ height: "34px", width: "3px" }}
                              ></div>
                              <div className="progress mt-0">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#74e86f",
                                  }}
                                  aria-valuenow="66.66"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </div>
                          )}
                        </td>
                        <td className="position-relative pr-0">
                          {this.state.overall_[category].progress_status ===
                            "Excellent" && (
                            <div
                              style={{ width: "80%" }}
                              className="mt-0 d-flex flex-row"
                            >
                              <div className="position-relative flex-grow-1">
                                <div className="banner-text">
                                  {
                                    this.state.overall_[category]
                                      .assessment_score
                                  }
                                </div>
                                <div
                                  className="bg-dark text-center mx-auto pt-4"
                                  style={{ height: "34px", width: "3px" }}
                                ></div>
                                <div className="progress ">
                                  <div
                                    className="progress-bar "
                                    role="progressbar"
                                    style={{
                                      width: "100%",
                                      backgroundColor: "#698ec9",
                                    }}
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          )}
                          
                        </td>
                        <td className="text-center">
                        <button style={{background:'#ddefe5',color:'#215352'}} className="btn mt-2">பரிந்துரைகள்</button>
                      </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div
                  class="mx-auto mt-md-5 mt-2 rounded"
                  style={{ backgroundColor: "rgba(0, 0, 0, 9%)" }}
                >
                  <div class="rounded-box border p-3 d-flex rounded">
                    <div
                      style={{ opacity: 0.7, fontSize: "16px" }}
                      class="text_p_  font-weight-bold"
                    >
                    <b> 📅 அடுத்த மதிப்பீட்டு தேதி: {this.isCreatedWithin14Days().dateLeft}: </b>

{' '}{this.isCreatedWithin14Days().daysLeft}  நாட்களில்  அதிகாரப்பூர்வமான அறிமுகத்திற்கு காத்திருக்கவும்  முன்னேற்றம் மற்றும்  மேம்பட்ட  நல்வாழ்வுக்கான  பயணத்தை தொடங்க தயாராகுங்கள்.  இந்த அற்புதமான கருவியை உங்களுடன் பகிர்ந்து கொள்வதற்கும் அது உங்கள் வாழ்க்கையில் ஏற்படுத்தும் நேர்மறையான  தாக்கத்தை பார்ப்பதற்கும் நாங்கள்  ஆர்வமாக உள்ளோம்.  முன் எப்போதும் இல்லாத வகையில் நீங்கள்  உங்கள் ஆரோக்கியத்திற்கு  பொறுப்பேற்க வேண்டும். </div>
                  </div>
                </div>
                <h3 className="mt-3 mt-md-5 header_font_ _text_color ">
                போக்குகள் (நிலை)
                </h3>
                <div
                  class="mx-auto mt-md-3 mt-2 mb-md-5 rounded"
                  style={{ backgroundColor: "#dbd5ce" }}
                >
                  <div class="rounded-box border rounded p-3">
                    <p class="text-center mx-auto py-1 h1 ">📈</p>
                    <div
                      style={{ opacity: 0.8, fontSize: "16px" }}
                      class="text_p_  font-weight-bold"
                    >
                       உங்கள் பதிவில்  இரண்டு  பதிவுகளுக்கு  குறைவாக இருந்தால்  பல சந்தர்ப்பங்களில்  உங்கள்  நல்வாழ்வை  பதிவு செய்ய  நாங்கள் உங்களை ஊக்குவிக்கிறோம்  வெவ்வேறு காரணிகள்  உங்கள்  பயணத்தை  எவ்வாறு பாதிக்கலாம்  மற்றும்   மதிப்புமிக்க  வடிவங்கள் மற்றும்  போக்குகளை கண்டறியலாம்
                    </div>
                  </div>
                </div>
                <div className="text-center">
                <button onClick={this.handleuser} className="btn" style={{background:'#215352',color:'#fff',fontWeight:'bold'}}>டாஷ்போர்டுக்குச் செல்லவும்</button>
              </div>
              </div>
              <div className="col-md-4 border ml-md-5 ml-2 pt-3 rounded shadow-sm mt-md-5 mt-4" style={{backgroundColor:'#f7cb83',height:'750px',overflow:'hidden',top:'30px',position:'sticky'}}>
                <div style={{borderWidth:'7px'}} className="header_font_ _text_color h2 py-2 border-white text-center border-bottom">Download Our App!</div>
                <p className="text_p_ h5 text-center py-2 _text_color">
                  Get the best experience by downloading our app. Access
                  exclusive features and content.
                </p>
                <div>
                  <div className="py-1">
                    <span style={{fontSize:'15px'}} className="text_p_ _text_color">
                      <strong className="header_font_ _text_color">🌿 Elevate your self-care:</strong> Find peace with
                      mindfulness, meditation, and relaxation in our app
                    </span>
                  </div>
                  <div className="py-1">
                    <span style={{fontSize:'15px'}} className="text_p_ _text_color">
                      <strong className="header_font_ _text_color">🌟 Connect and thrive:</strong> Join a caring
                      community, share, and gain strength together.
                    </span>
                  </div>
                  <div className="py-1">
                    <span style={{fontSize:'15px'}} className="text_p_ _text_color">
                      <strong className="header_font_ _text_color">🧘‍♂️ Personalized guidance:</strong>Navigate life
                      confidently with expert online counseling.
                    </span>
                  </div>
                  <div className="py-1">
                    <span style={{fontSize:'15px'}} className="text_p_ _text_color">
                      <strong className="header_font_ _text_color">🌈 Expert insights, anytime:</strong> Tailored
                      strategies for your well-being from top psychiatrists.
                    </span>
                  </div>
                  <div className="py-1">
                    <span style={{fontSize:'15px'}} className="text_p_ _text_color">
                      <strong className="header_font_ _text_color">🌌 24/7 support:</strong> Get inspired and guided by
                      coaches, anytime.
                    </span>
                  </div>
                  <div className="py-1">
                    <span style={{fontSize:'15px'}} className="text_p_ _text_color">
                      <strong className="header_font_ _text_color">🌱 Shape your well-being journey:</strong> Your
                      feedback shapes a brighter future for all.
                    </span>
                  </div>
                </div>
                <div className="d-sm-none d-lg-block d-none ">
                <div className="text-center">
                  <button
                    style={{
                      background: "#ed8824",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                    className="btn btn-block"
                  >
                    Download now
                  </button>
                </div>
                <div className="text-center mt-1">
                  <div className="d-flex flex-row justify-content-between">
                    <div
                      className="m-1 p-2 mt-2"
                      style={{
                        maxHeight: "120px",
                        maxWidth: "110px",
                        background: "#4a5ae8",
                      }}
                    >
                      <Flash size={22} color={"orange"} />
                      <p
                        className="text-white m-0"
                        style={{ fontSize: "10px" }}
                      >
                        Take a step towards better mental well-being.
                      </p>
                    </div>
                    <div>
                      <img
                        src={`${CDN_URL}/Others/qr-code.png`}
                        className="img-fluid text-center m-1"
                        style={{ minHeight: "120px" }}
                      />
                    </div>

                    <div
                      style={{
                        maxHeight: "120px",
                        maxWidth: "110px",
                        background: "#ffff08",
                      }}
                      className="m-1 mt-2 p-2"
                    >
                      <p className="m-0" style={{ fontSize: "8px" }}>
                        Always Here for You
                      </p>
                      <div className="d-flex justify-content-around">
                        <PhoneCall size={14} />
                        <BsWhatsapp size={14} />
                      </div>
                      <b style={{ fontSize: "10px" }}>+91 7829-002-002</b>
                      <p
                        className="m-0 text-center pt-1"
                        style={{ fontSize: "7px" }}
                      >
                        Available 24/7 for your support and care. Whenever
                        you're ready, we're here.
                      </p>
                    </div>
                  </div>
                </div>
                </div>
                <div className="d-flex justify-content-between py-3">
                  <img className="mx-1" onClick={() => window.open("https://careme.onelink.me/01QR/bpmu5cly")} style={{width:'170px',height:'50px',cursor:'pointer'}}  src={`${CDN_URL}/Websiteicons/playstore.png`}/>
                  <img className="mx-1" onClick={() => window.open("https://careme.onelink.me/01QR/bpmu5cly")} style={{width:'170px',height:'50px',cursor:'pointer'}}  src={`${CDN_URL}/Websiteicons/appstore.png`} />
                </div>
                <div className="h6 py-2 text-center text_p_">
                  For more details please visit{" "}
                  <a href="https://careme.health" target="_blank">
                    www.careme.health
                  </a>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }
}
export default withRouter(wellbeingtn);
