import React from 'react';
import { ArrowLeftCircle } from 'react-feather';
import { connect } from 'react-redux'; 
import {Redirect, withRouter} from 'react-router-dom'
import { FormGroup, Label } from 'reactstrap';
import { REACT_APP_API_URL } from '../../processENV';
import Loader from '../Loader';
import { Link } from "react-router-dom";

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];


class Reschedule extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            ans1:'',
            ans2:'',
            ans3:'',
            step:1,
            interested:false,
            reason:'',
            showOthers:false,
            error:'',
            appointDetails:null
        };
    }

    componentDidMount(){
        let prodId = window.location.pathname.split('/')[2]
        let appointDetails = JSON.parse(localStorage.getItem('sucessfulappointment'))
        this.setState({appointDetails})
        console.log("componentDidMount", prodId)
    }

    dateConversion = (rdate) =>{
        let date = new Date(rdate);
        // console.log("dateee",date.getDate() + " " + monthNames[date.getMonth()]  + "," +  date.getFullYear()  + "at" +  date.getHours() + ":" + (date.getMinutes() == "0" ? date.getMinutes() + "0" :date.getMinutes()) )
        return  (date.getDate() + " " + monthNames[date.getMonth()]  + "," +  date.getFullYear()  + " at " +  date.getHours() + ":" + (date.getMinutes() == "0" ? date.getMinutes() + "0" :date.getMinutes()) + " " + (parseInt(date.getHours()) < 12 ? " am" : " pm") )
      }

    setValue = (name,value) =>{
        if(value === "Reschedule appointment" || value === "Reschedule for later"){
            this.props.navigateComponent(1)
        }else if(value === 'Nope cancel'){
            console.log("Nope cancel")
            this.setState({[name]:value, showOthers:true},()=> this.cancelAppointment())
        }else{
            console.log("else")
            this.setState({ [name]:value, step: this.state.step + 1})
        }
    }

    othersHandler = (e) =>{
        this.setState({reason:e.target.value})
    }

    cancelAppointment = () =>{

        let data = {
          "patient_n_key": localStorage.getItem('_patientKey'),
          "appointment_n_key": this.props.rescheduleData.data?.prodId,
          "status":"Cancelled",
         "cancelled_reason":"test"
      }
      
      fetch(`${REACT_APP_API_URL}/app_cancel_appoint/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
        },
        body: JSON.stringify(data),
        }).then(response => response.json())
        .then(async (res)=>{
          console.log('cancel appointment',res.data)
            if(res.status === "success"){
                window.location.replace('/home')
            }else{
              console.log('cancel appointment',res.data)
            }
        })
      }

    generateRadio = (name, value) =>{
        return value.length !== 0 ?
            value.map((item, i) => {
                return (
                        <p className="getCare-choice" onClick={()=>this.setValue(name, item)}>
                            {item}
                        </p>
                )
            }):''
    }

    sendReason = (e) =>{
        let { reason } = this.state;
        if(e){
            e.preventDefault();
        }


        if(!reason){
            this.setState({
                error : "please fill in some reason"
            })
        }else{
            let data = {
                "patient_n_key": localStorage.getItem('_patientKey'),
                "comments": reason
            }
            this.setState({loading:true,error:''})
            fetch(`${REACT_APP_API_URL}/feedback_post/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
                },
                body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
                if(res.status === "success"){
                    this.setState({loading:false,reason:''})
                    window.location.replace('/home')
                }else{
                    this.setState({loading:false,reason:'',error:'Some error occured.Please try again after sometime'})
                }
            })
        }
    }

    render() {
        let { loading, ans1, ans2, ans3, step, reason, interested, showOthers, appointDetails } = this.state;
        return (
            <>
            <div  style={{overflowX:'scroll',margin:'10px auto'}}>
                {loading && <Loader/>}
            <div className="register pt-4">
                <Link to="/home" className="p-4">
                <ArrowLeftCircle size={25} /></Link>
            <div className="head_question">
            <div className="question_layout col-md-5">
                    <p className="d-inline pl-2">Your appointment has been successfully booked with  {JSON.parse(localStorage.getItem('sucessfulappointment'))[0].doctor_name} 
                    &nbsp;on {this.dateConversion(JSON.parse(localStorage.getItem('sucessfulappointment'))[0].appointment_date)} </p>
            </div> 
            {ans1 && <div className="question_layout col-md-5 ml-auto"> <div className='ansdiv col-auto'>
                                    <p className="d-inline pl-2">{ans1}</p>
            </div></div>}
            {!ans1 && <div className={`bottomsec 'd-block'}`}>
                {this.generateRadio('ans1',['Reschedule appointment', 'Cancel'])}
            </div>}

            { step >= 2 && <div>
                 <div className="question_layout col-md-5">
                    <p className="d-inline pl-2">Your appointment 
                    on {this.dateConversion(JSON.parse(localStorage.getItem('sucessfulappointment'))[0].appointment_date)}
                    will be cancelled.Do you need help rescheduling it?
                    </p>
            </div> 
            {ans2 && <div className="question_layout col-md-5 ml-auto"><div className='ansdiv col-auto'>
                                    <p className="d-inline pl-2">{ans2}</p>
            </div></div>}
            {!ans2 && <div className={`bottomsec 'd-block'}`}>
                {this.generateRadio('ans2',['Reschedule for later', 'Nope cancel'])}
            </div>}  
            </div>}
            {/* { step >= 3 && <div>
                 <div className="question_layout col-md-5">
                    <p className="d-inline pl-2">Can you tell us what stops you from taking the call?</p>
            </div> 
            {ans3 && <div className="question_layout col-md-5 ml-auto"> <div className='ansdiv col-auto'>
                                    <p className="d-inline pl-2">{ans3}</p>
            </div></div>}
            {!ans3 && <div className={`bottomsec 'd-block'}`}>
                {this.generateRadio('ans3',['I am at work', 'I dont have time to talk now','I am in the middle of something', 'I look messy', 'I am just not interested', 'I am having second thoughts about therapy','Others'])}
            </div>}  
            </div>}
                { showOthers && <form onSubmit={this.sendReason}>
                    <input type="text" name="reason" className="form-control mt-2" value={reason} onChange={(e)=>this.othersHandler(e)}/>
                    <button style={{display:'none'}} type="submit">submit</button>
                </form> 
                } */}
            </div>
            </div>
            
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        rescheduleData: state.RescheduleData
    }
}

export default connect(mapStateToProps, null)(withRouter(Reschedule));
