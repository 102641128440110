import React from "react";
import * as Cookies from "js-cookie";
import queryString from 'query-string';

import "./meeting.css";
import AgoraVideoCall from "../../Components/AgoraVideoCall";
import { AGORA_APP_ID, APP_BASEURL } from "../../agora.config";
import { REACT_AGORA_APP_ID } from '../../Components/processENV'
import NetworkDetector from '../../NetworkDetector/NetworkDetector';
import ChatScreen from "../../Components/AgoraVideoCall/ChatScreen";
import {Maximize } from 'react-feather'
import SessionScreen from '../../Components/WaitingScreen/SessionScreen'

// const SessionScreen = (props) => {

//   // console.log('my props', this.props);
//   // var appoinmentData = props.location.state.appoinmentData;
//   // var appKey = props.location.state.appoinmentData.appointment_n_key;
//   // var user = props.location.state.user;

//   return(
//     <div class="session-screen-wrapper">
//       <div id="SessionScreen" className={"bgimg-sesssion"}></div>
//       <div id="sessiontxt"> Please wait the session is starting </div>
//     </div>
//   );

// }


class Meeting extends React.Component {
  constructor(props) {
    super(props);
    // this.videoProfile = Cookies.get("videoProfile").split(",")[0] || "480p_4";
    // this.channel = Cookies.get("channel") || "test";
    // this.transcode = Cookies.get("transcode") || "interop";
    // this.attendeeMode = Cookies.get("attendeeMode") || "video";
    // this.baseMode = Cookies.get("baseMode") || "avc";
    // this.appId = REACT_AGORA_APP_ID;

    this.videoProfile = "480p_4";
    this.channel = this.props.location.state.appointmentData.appointment_n_key;
    this.transcode = "interop";
    this.attendeeMode = "video";
    this.baseMode = "avc";
    this.appId = REACT_AGORA_APP_ID;
    this.user = this.props.location.state.user;
    var appoinmentData = this.props.location.state.appointmentData;
      this.appointData = appoinmentData
    this.state = {
       appKey:"",
       user:"",
       userJoined: false,
       minScreen:false,
       chatOpen:false
    }

    if (!this.appId) {
      return alert("Get App ID first!");
    }
    // this.uid = (this.props.match.params.user + this.props.match.params.id).replace(/[^a-zA-Z0-9]/g, '');
    var uidKey = this.props.location.state.appointmentData.appointment_id;
    var doctorUidKey = uidKey !== 96988 ? 96988 : 96999
  //  var rand =  Math.floor(95000 + Math.random() * 9000);
  //  var doctorUidKey = (rand).toFixed();
    this.uid = this.props.location.state.user == 'doctor' ? doctorUidKey : uidKey ;
    //this.uid = 0;
    console.log('user uid',this.uid);
    this.callbackUser=  this.callbackUser.bind(this);
  }

   callbackUser(value){
    console.log("call back value",value)
    if(value == true){
      this.setState({userJoined: true})
      console.log("call back true")
    }
    else{
      this.setState({userJoined : false})
    }
  }
  openChatScreen = () =>{
    this.setState({chatOpen: !this.state.chatOpen})
  }

  componentDidMount()
  {
    console.log('my props', this.props);
    if(this.props != undefined){
      if(this.props.location.state)
     var appoinmentData = this.props.location.state.appointmentData;
     var appKey = this.props.location.state.appointmentData.appointment_n_key;
     this.channel = this.props.location.state.appointmentData.appointment_n_key;
      this.user = this.props.location.state.user;
      this.appointData = appoinmentData
     this.setState({appKey: appKey, user:this.user, appData: this.appointData}, () =>{
      console.log('app state',this.state.appKey,this.state.appData,this.state.user,this.user)
      localStorage.setItem('_doctorkey',this.state.appData.org_n_key);
     } );
     
    }
    console.log('meeting params', this.props.location.pathname ,this.state.appKey , this.props.match.params);
    if(this.props.location.pathname === '/'){
      console.log('meeting' );
      this.props.history.push('/meeting');
    }
  }

  minmaxHandler = () =>{
    this.setState({minScreen: !this.state.minScreen})
  }

  render() {
    let { chatOpen } =  this.state;
    const { userJoined} = this.state;
    return (
       
       ( 
       
       <div className={!userJoined ? "wrapper meeting userwait" : "wrapper meeting"}>
         {!userJoined && <SessionScreen  props={this.props} />}
        <div className="ag-header">
          {/* <div className="ag-header-lead">
            <img
              className="header-logo"
              src={require("../../assets/images/ag-logo.png")}
              alt=""
            />
            <span>AgoraWeb v2.1</span>
          </div> */}
          <div className="ag-header-msg">
            {/* Room:&nbsp;<span id="room-name">{this.state.appKey}</span> */}
          </div>
          <div className="ag-header-switch">
       {userJoined &&   <span
          // className="ag-btn exitBtn"
      // onClick={this.handleExit}
        // className={this.state.readyState ? 'ag-btn  ' : 'ag-btn exitBtn disabled'}
            title="Switch" onClick={this.minmaxHandler}>
            <Maximize />
          </span>}
                </div>
          
        </div>
        <div className="ag-main">
          <div className="ag-container">
            
          {/* <div id="video">
          <div id="local"></div>
          <div id="remote"></div> */}
         {/* </div> */}
       
            <AgoraVideoCall
                minScreen={this.state.minScreen}
                minmaxHandler={this.minmaxHandler}
                callbackUser= { this.callbackUser}
                videoProfile={this.videoProfile}
                channel={this.channel}
                transcode={this.transcode}
                attendeeMode={this.attendeeMode}
                baseMode={this.baseMode}
                appId={this.appId}
                uid={this.uid}
                user={this.user}
                appData={this.appointData}
                openChatScreen={this.openChatScreen}
                chatOpen={chatOpen}
                userJoined ={userJoined}
            />

          </div>
        </div>
        {/* {chatOpen && <ChatScreen chatOpen={chatOpen} openChatScreen={this.openChatScreen} />} */}

      </div>
       )
    );
  }
}

export default Meeting;
