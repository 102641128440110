import React from 'react';
import { connect } from 'react-redux';
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,Collapse
} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
    FormGroup,
    Label,
} from 'reactstrap';
import Loader from '../../Layouts/Loader';
import { REACT_APP_API_URL } from '../../processENV';
import Flatpickr from "react-flatpickr";
import { MinusCircle, PlusCircle, XCircle } from 'react-feather';
import { withTranslation } from 'react-i18next';

class Smoking extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            youAre:'',
            perday:'',
            years:'',
            quitting:'',
            stoppedOn:'',
            comments:'',
            smokerType:'',
            loading:false,
            isOpen:false,
            smokingList : []
        };
    }

    componentDidMount(){
        this.getSmokingList();
      }
      
      getSmokingList = () =>{
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        fetch(`${REACT_APP_API_URL}/tobacco_details_list/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('smoking list',res)
             this.setState({smokingList: res})
                  
          })
          .catch(err => console.log('smoking list error',err))
      }

      deleteSmoking = (id) =>{
        fetch(`${REACT_APP_API_URL}/tobaccohistory/${id}/`, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          }
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('delete smoking',res)
             this.getSmokingList()
          })
          .catch(err => console.log('smoking list error',err))
      }

    collapsePlan = () => {
        this.setState({
          isOpen: !this.state.isOpen
        })
      }
    smokingInputHandler = (e) =>{
        let id = e.target.name;
        this.setState({
            [id] : e.target.value,
            error:''
        })
    }

    submitSmokingHistory = (e) => {
        let { youAre, perday, years, quitting, stoppedOn, comments, smokerType } = this.state;
        var today = new Date(); 
        let dd = (today.getDate.toString().length === 1) ? ('0' + today.getDate()) : today.getDate();
        let mm = (today.getMonth().toString().length === 1) ? ('0' + (today.getMonth() + 1)) : (today.getMonth() + 1);
        let yy = (today.getFullYear().toString().length === 1) ? ('0' + today.getFullYear()) : today.getFullYear();
        let date = yy + '-' + mm + '-' + dd;

        if(!youAre){
            this.setState({
                error : "please fill all the fields"
            })
        }
        else if(youAre === "Smoker" && (!perday || !years || !quitting || !comments || !smokerType)){ 
            this.setState({
                error : "please fill all the fields"
            })
        }else if(youAre === "Ex-Smoker" && (!perday || !years || !stoppedOn || !comments || !smokerType)){
            this.setState({
                error : "please fill all the fields"
            })
        }
        else{
            let data = {}
            if(youAre === "Non-smoker" || youAre === "Smoker") {
                 data = {
                    "patient_n_key": localStorage.getItem('_patientKey'),
                    "appointment_n_key": localStorage.getItem('_appointmentKey'),
                    "tobacco_use": youAre,
                    "type": smokerType,
                    "no_of_packs_perday": perday,
                    "interested_in_quitting": quitting,
                    "no_of_years": years,
                    "stopped_on": date,
                    "assessment_date": date,
                    "comments": comments
                }
            }else{
                 data = {
                    "patient_n_key": localStorage.getItem('_patientKey'),
                    "appointment_n_key": localStorage.getItem('_appointmentKey'),
                    "tobacco_use": youAre,
                    "type": smokerType,
                    "no_of_packs_perday": perday,
                    "interested_in_quitting": quitting,
                    "no_of_years": years,
                    "assessment_date": date,
                    "stopped_on": stoppedOn,
                    "comments": comments
                }
            }
            
            this.setState({loading:true,error:''})
            console.log('smoking data',data)
            fetch(`${REACT_APP_API_URL}/tobaccohistory/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
                },
                body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
                if(res.status === "success"){
                    this.getSmokingList()
                    this.setState({loading:false,
                                    youAre:'',
                                    perday:'',
                                    years:'',
                                    quitting:'',
                                    stoppedOn:'',
                                    comments:'',
                                    smokerType:'',
                                    loading:false})
                }else{
                    this.setState({loading:false,error:'Some error has occured please try again'})
                }
            })
        }
    }

    handleDateChange = ddd => {

        if (ddd === null || ddd === "") {
            this.setState({
                stoppedOn: null
            });
        } else {
            let date = new Date(ddd);

            let dd = (date.getDate.toString().length === 1) ? ('0' + date.getDate()) : date.getDate();
            let mm = (date.getMonth().toString().length === 1) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1);
            let yy = (date.getFullYear().toString().length === 1) ? ('0' + date.getFullYear()) : date.getFullYear();
            
           let fromDate = yy + "-" + mm + "-" + dd ;
            this.setState({
                stoppedOn:fromDate
            });
            
        }
    };

    render() {
        let { loading, stoppedOn, error, youAre, perday, years, quitting, isOpen, smokingList, smokerType, comments } = this.state;       
        let { t } = this.props;
        return (
            <>
                <Col md={8} xs={12} className="mb-2 px-0">
                {loading && <Loader/>}
                                            <Card >
                                                <CardSubtitle tag="h5" className="mt-2  text-center text-muted">{t('TOBACCO HISTORY')}<span className="cusicon" onClick={this.collapsePlan}> {isOpen ? <MinusCircle />: <PlusCircle />}</span></CardSubtitle>
                                                <Collapse isOpen={isOpen}>

                                                <CardBody className="col-md-9 mx-auto">
                                                    <Formik
                                                        initialValues={{
                                                            name: '',
                                                            email: '',
                                                        }}
                                                        // validationSchema={BasicProfile}
                                                        onSubmit={this.submitSmokingHistory}
                                                    >
                                                        {({ errors, touched }) => (
                                                            <Form>
                                                                    <FormGroup className="w-100 m-0">
                                                                        <label>{t('Do You Smoke')}?</label>

                                                                    </FormGroup>
                                                                    <div className="d-flex col-md-12 px-0 px-md-0">

                                                                    <FormGroup className="mr-2" >
                                                                        <Label check>
                                                                            <input type="radio" checked={youAre === "Smoker" ? true : false } value="Smoker" name="youAre" onChange={(e)=> this.smokingInputHandler(e)}/>{' '}
                                                                            {t('Non Smoker')}</Label>
                                                                    </FormGroup>
                                                                    <FormGroup className="mr-2" >
                                                                        <Label check>
                                                                            <input type="radio" checked={youAre === "Non-smoker" ? true : false } value="Non-smoker" name="youAre" onChange={(e)=> this.smokingInputHandler(e)}/>{' '}
                                                                            {t('Ex Smoker')}</Label>
                                                                    </FormGroup>
                                                                    <FormGroup className="mr-2" >
                                                                        <Label check>
                                                                            <input type="radio" checked={youAre === "Ex-Smoker" ? true : false }  value="Ex-Smoker" name="youAre" onChange={(e)=> this.smokingInputHandler(e)}/>{' '}
                                                                            {t('Smoker')}</Label>
                                                                    </FormGroup>  </div>
                                                                    {youAre !== "Non-smoker" ?
                                                                    <div>
                                                                        <FormGroup className="pl-md-0 w-100">
                                                                        <label>{t('Type')}</label>
                                                                        <Field as="select" name="smokerType" className="form-control" value={smokerType} onChange={(e)=> this.smokingInputHandler(e)}>
                                                                                <option value="">Select Type</option>
                                                                                <option value="social">{t('Social')}</option>
                                                                                <option value="light">{t('Light')}</option>
                                                                                <option value="heavy">{t('Heavy')}</option>
                                                                                <option value="moderate">{t('Moderate')}</option>
                                                                            </Field>
                                                                        {errors.para && touched.para ? (
                                                                            <ErrorMessage name="para" />
                                                                        ) : null}
                                                                    </FormGroup>
                                                                    
                                                                    <FormGroup className="pl-md-0 w-100">
                                                                        <label>{t('Number of Packs per Day')}</label>
                                                                        <Field name="perday" value={perday} className="form-control" placeholder={t('Number of Packs per Day')} onChange={(e)=> this.smokingInputHandler(e)}/>
                                                                        {errors.name && touched.name ? (
                                                                            <ErrorMessage name="fname" />

                                                                        ) : null}
                                                                    </FormGroup>
                                                                    <FormGroup className="pl-md-0 w-100">
                                                                        <label>{t('Number of years')}</label>
                                                                        <Field name="years" value={years} className="form-control" placeholder={t('Number of years')}  onChange={(e)=> this.smokingInputHandler(e)}/>
                                                                        {errors.name && touched.name ? (
                                                                            <ErrorMessage name="fname" />

                                                                        ) : null}
                                                                    </FormGroup>
                                                                    {youAre !== "Ex-Smoker" ?
                                                                    <div>
                                                                    <FormGroup className="w-100 m-0">
                                                                        <label>{t('Interested in Quitting')}?</label>

                                                                    </FormGroup>
                                                                    <div className="d-flex col-md-12 px-0" >

                                                                    <FormGroup className="mr-3" >
                                                                        <Label check>
                                                                            <input type="radio" checked={quitting === "Yes" ? true : false } value="Yes" name="quitting" onChange={(e)=> this.smokingInputHandler(e)}/>{' '}
                                                                            {t('Yes')}</Label>
                                                                    </FormGroup>
                                                                    <FormGroup className="mr-3" >
                                                                        <Label check>
                                                                            <input type="radio" checked={quitting === "No" ? true : false }  value="No" name="quitting" onChange={(e)=> this.smokingInputHandler(e)}/>{' '}
                                                                            {t('No')}</Label>
                                                                    </FormGroup>
                                                                    </div>
                                                                    </div>
                                                                    :
                                                                    <></>}
                                                                    {youAre !== "Smoker" ? 
                                                                    <FormGroup className="w-100 mx-0" >
                                                                        <Label check className="w-100">  {t('When did you Quit Smoking')}?
                                                                        <Flatpickr
                                                                            className="form-control w-100"
                                                                            selected={stoppedOn}
                                                                            value={stoppedOn}
                                                                            options={{ maxDate: new Date() }}
                                                                            placeholder={t('When did you Quit Smoking')}
                                                                             onChange={date => this.handleDateChange(date)}
                                                                        />
                                                                       </Label>
                                                                    </FormGroup>
                                                                    :
                                                                    <></>}
                                                                    <FormGroup className="w-100 mx-0" >
                                                                        <Label check className="w-100"> Comments
                                                                        <Field name="comments" value={comments} className="form-control" placeholder="Comments" onChange={(e)=> this.smokingInputHandler(e)}/>
                                                                        {errors.name && touched.name ? (
                                                                            <ErrorMessage name="fname" />

                                                                        ) : null}
                                                                       </Label>
                                                                    </FormGroup>
                                                                    </div>
                                                                    :
                                                                    <></>}
                                                                    {error && <p className="errorMesage mt-3">{error}</p>}
                                                                <div className="text-center">
                                                                    <button type="submit" className="btn btn-primary mx-auto">{t('UPDATE')}</button>

                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>                                </CardBody>
                                                    </Collapse>
                                                    {smokingList && smokingList.length !== 0
                                                    ? smokingList.map( item =>{
                                                        return <div className="allhistory"> <Col md={8} xs={12} className="mb-2 mx-auto indhistory">
                                                        <div className="tagsec">
                                                             <p><h6 className="m-0">{item.tobacco_use}</h6><a onClick={() => this.deleteSmoking(item.tobacco_history_id)}><XCircle /></a></p>
                                                        
                                                        <p> {item.tobacco_use !== "Non-smoker" && <span>{item.no_of_packs_perday}  Pack for {item.no_of_years} years </span>}
                                                            </p> 
                                                        </div>
                                                    </Col> </div>
                                                    })
                                                :<></>  }
                                            </Card>
                                        </Col> 
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(withTranslation()(Smoking));
