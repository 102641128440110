import React from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Label,
  CardTitle,
  CardSubtitle,
  Alert,
} from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Redirect, withRouter } from "react-router-dom";
import {
  CDN_URL,
  REACT_APP_API_URL,
  REACT_APP_BASE_URL,
  REACT_APP_BU_API_URL,
  RET_URL,
  getCurrencySymbol,
} from "../../processENV";
import Loader from "../Loader";
import { withTranslation } from "react-i18next";
import { FaCheck, FaRegCheckCircle } from "react-icons/fa";
import { Check, CheckCircle } from "react-feather";
import moment from "moment";
import ReactShowMoreText from "react-show-more-text";
import { BsCircleFill } from "react-icons/bs";
const publicIp = require("react-public-ip");
class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sessions: [],
      coupons: null,
      couponError: null,
      couponApplied: false,
      selectedPayment: "",
      productDetails: null,
      product: "",
      orderId: "",
      p_name: "",
      p_email: "",
      p_phone: "",
      follow_addon: false,
      confim_modal: false,
      cancel_modal: false,
      d_url: "",
      exc_goal: "",
      p_dial_code: "",
      edit_coupon: false,
      apply_copon: [0, 0, 0],
      coupon_error: false,
      coup_valid: false,
      prod: "",
      redirect: false,
      empDetails: "",
      cop_code: "",
      adon: false,
      addonamount: "",
    };
  }

  getName = async () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };
    fetch(`${REACT_APP_API_URL}/patientedit_app/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("patient profile", res);
        if (res.status === "success") {
          this.setState({
            p_name: res.data.first_name,
            p_dial_code: res.data.dial_code,
            p_email: res.data.email,
            p_phone: res.data.phone_number,
          });
        } else {
          console.log("pat prof", res.data);
        }
      });
  };
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  getNewProd = async () => {
    let data = {
      ip: (await publicIp.v4()) || "",
      patient_n_key: localStorage.getItem("_patientKey"),
    };
    fetch(`${REACT_APP_API_URL}/products_groups/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("new prod", res.data);
        if (res.status === "success") {
          this.setState({ addonamount: res.addon });
        } else {
          console.log("new prod", res.data);
        }
      });
  };
  componentDidMount() {
    this.getName();
    this.getNewProd();
    // this.getFollow();
    console.log(this.props);
    let prd = this.props?.location?.state?.data;
    console.log(prd.prod);
    this.setState({
      prod: prd.prod,
      empDetails: prd.journeyData,
      redirect: prd.redirect,
      cop_code: prd.prod.promocode,
      couponApplied: prd.prod?.promocode ? true : false,
    });
  }

  couponHandler = (e, ix) => {
    var data = this.state.apply_copon;
    if (e.target.value.trim().length == 6) {
      let filt = e.target.value.toUpperCase();
      data[ix] = filt;
      this.setState({ apply_copon: data });
    } else {
      console.log("err");
    }
  };

  payInit = async (amount, id) => {
    const data = {
      patient_n_key: localStorage.getItem("_patientKey"),
      payment_status: "Payment Initiated",
      payment_details: id,
      product_amount: this.state.prod.product_amount,
      discount: this.state.prod.discount,
      discount_amount: this.state.prod.discount_amount,
      url: "",
      app_prod_grp_key: this.state.prod.app_prod_grp_key,
      promo_code: this.state.cop_code?.toUpperCase() || null,
      paid_amount: amount,
      amount: amount,
      app_prod_n_key:
        this.state.prod.app_prod_grp_key == "APP-PROD-CAT-2"
          ? "APP_PROD-5"
          : this.state.prod.app_prod_grp_key == "APP-PROD-CAT-3"
          ? "APP_PROD-3"
          : "APP_PROD-9",
      product: this.state.prod.title,
      addon: this.state.adon,
      total_session: 1,
      app_payment_n_key: "",
    };
    console.log(data);
    fetch(`${REACT_APP_API_URL}/cashfree_payment/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log(res);
        if (res.status == "success") {
          localStorage.setItem("app_payment_n_key", res.data.app_payment_n_key);
        }
      })
      .catch((err) => console.log(err));
  };
  submitCoupon = () => {
    if (this.state.cop_code.length !== 6) {
      this.setState({ coup_valid: true });
    } else {
      let data = {
        patient_n_key: localStorage.getItem("_patientKey"),
        promo_code: this.state.cop_code.toUpperCase(),
        product_type: "Listed",
        position: 1,
        app_prod_grp_key: this.state.prod.app_prod_grp_key,
      };
      this.setState({ coup_valid: false });
      console.log(data);
      fetch(`${REACT_APP_BU_API_URL}/bu_promocheck/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log(res);
          if (res.status === "success") {
            var newProd = { ...this.state.prod };
            var val =
              newProd.product_amount -
              (res.data * newProd.product_amount) / 100;
            newProd.amount = Math.round(val);
            newProd.discount_amount = Math.round(
              this.state.prod.product_amount - val
            );
            newProd.discount = res.data;

            this.setState({
              coupon_error: false,
              prod: newProd,
              couponApplied: true,
            });
          } else {
            this.setState({ coupon_error: true });
          }
        })
        .catch((err) => console.log("error", err));
    }
  };

  submitPayment = (amount, sessions, item) => {
    console.log("call");
    this.setState({ selectedPayment: amount.toString() });
    //   localStorage.setItem('app_payment_n_key',productDetails.app_payment_n_key)
    let therapyDetails = {
      app_payment_n_key: this.state.productDetails.app_payment_n_key,
      journey_id: this.state.productDetails.journey_id,
      total_session: sessions,
      doc_app_id: this.state.productDetails.doc_app_id,
      app_prod_n_key: this.state.productDetails.app_prod_n_key,
      product_amount: item.amount,
      discount: item.discount,
      amount: amount.toString(),
      discount_amount: item.discount_amount,
      product: this.state.productDetails.title,
      app_prod_grp_key: this.state.productDetails.app_prod_grp_key,
      follow_adon: this.state.follow_addon,
    };
    localStorage.setItem("therapy_details", JSON.stringify(therapyDetails));
    this.getPaymentLink(amount, item, therapyDetails);
  };

  getPaymentLink = (amount, item) => {
    console.log(item);
    const { p_email, p_name, p_phone, p_dial_code } = this.state;
    let data = {
      orderAmount: amount.toString(),
      customerName: p_name,
      customerPhone: p_phone,
      customerEmail: p_email,
      returnUrl: RET_URL,
      dial_code: p_dial_code,
      currency: item.currency,
      order_note: this.state.prod?.title || "",
    };
    console.log(data);
    fetch(`${REACT_APP_API_URL}/create_paymentorder/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log(res);
        if (res.status === "success") {
          let datas = {
            prod: this.state.prod,
            redirect: this.state.redirect,
            empDetails: this.state.empDetails,
            addon: this.state.adon,
          };
          console.log("payment link", res);
          localStorage.setItem("orderId", res.orderId);
          localStorage.setItem("purchase_type", "INDIVIDUAL");
          localStorage.setItem("_c", JSON.stringify(datas));
          this.payInit(amount, res.orderId);
          const cf = new global.Cashfree(res.payment_session_id);
          cf.redirect();
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => console.log("error", err));
  };

  removeCopon = () => {
    var newProd = { ...this.state.prod };
    newProd.amount = newProd.product_amount;
    newProd.discount_amount = 0;
    newProd.discount = 0;
    this.setState({
      coupon_error: false,
      prod: newProd,
      couponApplied: false,
      cop_code: "",
    });
  };

  render() {
    let {
      loading,
      confim_modal,
      cancel_modal,
      prod,
    } = this.state;
    let { t } = this.props;
    return (
      <div className="col-md-5 mx-auto mt-5 pt-md-2 shadow px-0">
        <p className="h2 text_color header_font_ text-center pt-5 pt-md-4"> Great Work</p>
        <p style={{ fontSize: "17px" }} className="text-left px-2 px-md-5 text_p_ mt-4">
          You're in the right place. This is the first step to feeling well.
          Our providers can diagonse wht's going on and get you on the right
          treatment to get back to being you.
        </p>
        <Modal isOpen={confim_modal}>
          <ModalHeader style={{ textAlign: "center" }}>
            Take care of yourself wherever you go
          </ModalHeader>
          <ModalBody>
            <img
              src={`${CDN_URL}/newapp/CAREMEPROMISE%20copy.png`}
              style={{ width: "50px", height: "50px", float: "right" }}
            />
            <h5 style={{ textAlign: "center", textDecoration: "underline" }}>
              Chat with your therapist anytime
            </h5>
            <p>
              <b>Note: </b>This is not a live conversation; your therapist will
              reply in 24 hours or less, and we promise to send one response
              every day.
            </p>
            <p style={{ textAlign: "center" }}>
              Please download our{" "}
              <a target="_blank" href="http://go.careme.health/gplay">
                mobile app
              </a>{" "}
              to use the chat feature.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.setState({ confim_modal: false })}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => this.setState({ adon: true, confim_modal: false })}
            >
              Confirm
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal isOpen={cancel_modal}>
          <ModalHeader style={{ textAlign: "center" }}>
            Take care of yourself wherever you go
          </ModalHeader>
          <ModalBody>
            <p>
              We meet you where you're at and help get you where you want to be
              by adding chat support you will be able to connect with your
              therapist anytime.
            </p>
            <h5 style={{ textAlign: "center" }}>
              Are you sure you want to remove it?
            </h5>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() =>
                this.setState({ follow_addon: false, cancel_modal: false })
              }
            >
              Yes
            </Button>{" "}
            <Button
              color="primary"
              onClick={() => this.setState({ cancel_modal: false })}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
        <div className="col-md-10 mx-auto p-0 px-md-2 px-1 pb-md-3">
          {loading && <Loader />}
          <h5 className="py-2 _text_color font-weight-bold header_font_" style={{ textAlign: "center" }}>
            🖥️ Live Session: 🕐{" "}
            {prod.title === "Individual Therapy" ? "45 - 50 min" : null}
            {prod.title === "Psychiatric Consultation" ? "10 - 30 mins" : null}
            {prod.title === "Couple Therapy" ? "100 - 110 mins" : null}
          </h5>
          <hr />
          <Col className="text-center col-md-12 mx-auto py-2">
            <div className="d-flex flex-row">
              <p style={{fontSize:'15px'}} className="text_p_ mr-auto">Total Amount </p>
              <p style={{fontSize:'15px'}} className="ml-auto text_p_">
                {getCurrencySymbol(this.state.prod?.currency)}{" "}
                {this.state.prod && parseInt(this.state.prod.product_amount)}
              </p>
            </div>
            <div className="d-flex flex-row">
              <p style={{fontSize:'15px'}} className="text_p_ mr-auto">Total Discount </p>
              <p style={{fontSize:'15px'}} className="ml-auto text_p_">
                {getCurrencySymbol(this.state.prod?.currency)}{" "}
                {this.state.prod && parseInt(this.state.prod.discount_amount)}
              </p>
            </div>
            <div className="d-flex flex-row">
              <p style={{fontSize:'15px'}} className="text_p_ mr-auto">Amount Payable </p>
              <p style={{fontSize:'15px'}} className="ml-auto text_p_">
                {getCurrencySymbol(this.state.prod?.currency)}{" "}
                {this.state.prod && parseInt(this.state.prod.amount)}
              </p>
            </div>
          </Col>
          <hr />

          {this.state.prod && parseInt(this.state.prod.discount_amount) !== 0 ? (
            <div
              style={{ background: "#ddefe5", color: "green" }}
              className="d-flex flex-row align-items-center col-md-12 mx-auto border border-success rounded px-5 py-3 mb-4"
            >
              <h3
                style={{ color: "green" }}
                className="h6 my-0 text-center font-weight-bold"
              >
                Your Total Savings 🎉{" "}
              </h3>
              <span className="ml-auto h6 my-0 font-weight-bold">
                {getCurrencySymbol(this.state.prod?.currency)}{" "}
                {this.state.prod && parseInt(this.state.prod.discount_amount)}
              </span>
            </div>
          ) : null}
          {this.state.prod?.coupon ? (
            <div
              style={{background: "#e0af72"}}
              className="text-center col-md-12 mx-auto rounded px-md-5 px-1 py-3 mb-4"
            >
              <h3
                style={{color: "#215352",fontSize:'12px'}}
                className="my-0 text-center font-weight-bold"
              >
               Coupon "{this.state.prod?.coupon}" Applied!! Prioritize Your Well-being.
              </h3>
            </div>
          ) : null}
          </div>
          <div
            className="d-flex flex-row align-items-center justify-content-between mt-md-3 col-md-12 bg-white border mx-auto border-top shadow-lg py-3"
          >
            <div className="">
              <span className="h5 ml-2 font-weight-bold">
                {getCurrencySymbol(this.state.prod?.currency)}{" "}
                {this.state.prod && this.state.adon
                  ? parseInt(this.state.prod.amount) + this.state.addonamount
                  : this.state.prod.amount}
              </span>
            </div>
            <div className="">
              <button
              className="rounded-sm px-4 py-2"
                style={{
                  background: "#215352",
                  color: "#fff",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  this.getPaymentLink(
                    this.state.adon
                      ? parseInt(this.state.prod.amount) +
                          this.state.addonamount
                      : this.state.prod.amount,
                    this.state.prod
                  );
                }}
              >
                Proceed to pay
              </button>
            </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    getCareData: state.GetCareData,
    TherapyJourneyData: state.TherapyJourneyData,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(Payment)));
