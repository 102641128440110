import { combineReducers } from 'redux';
import { GetCareReducer } from './GetCareReducer';
import { RescheduleReducer } from './RescheduleReducer';
import { ScheduleReducer } from './ScheduleReducer';
import { LoginReducer } from './LoginReducer';
import { feedbackPopup } from './PopUp';
import { TherapyJourneyReducer } from './TherapyJourneyReducer';
import { ScheduleAppReducer } from './ScheduleAppReducer';

export default combineReducers({
    LoginData:LoginReducer,
    feedbackPopup:feedbackPopup,
    GetCareData: GetCareReducer,
    RescheduleData: RescheduleReducer,
    ScheduleData: ScheduleReducer,
    ScheduleAppData: ScheduleAppReducer,
    TherapyJourneyData: TherapyJourneyReducer
});