import React from 'react';
import { Modal,ModalBody, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { ArrowLeftCircle, ArrowRight, Flag } from 'react-feather';
import Store from '../../../../Store';
import { ScheduleAction } from '../../../../Actions/ScheduleAction';
import Loader from '../../Loader';
import { CURE_URL, REACT_APP_API_URL } from '../../../processENV';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
class AsesQues extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            quesObj: [],
            bulkData:{},
            step:0,
            indx:0,
            modal:false,
            disable:true,
        };
    }
   
getQues=(key)=>{
    const headers={
        "Content-Type": "application/json",
        "Authorization": "Token 70c48f814c3a6cc1b4e1dcb0ea4b2cc0713c90bf"
       }
      axios.get(`${CURE_URL}/orderform_details/${key}/`,{headers}).then((res)=>{
          console.log(res)
          this.setState({
              bulkData:res.data,
              quesObj:res.data.form_data
          })
      })      
}
   
    componentDidMount() {
        let key=this.props.location.state.key
        if(key){
            this.getQues(key) 
        }
        else{
            console.log('waiting for key')
        }
    }

    selectproblem = ( value) =>{
            Store.dispatch(ScheduleAction({
               problem:this.props.scheduleData.data.problem,
               scheduleStep:2,
               scheduleCurrentStep:2,
            }));
    }
    
    goBack = () =>{
       
    }
    sendFront=()=>{
        if(this.state.indx+1 !== this.state.quesObj.length){
            this.setState({indx:this.state.indx+1})
        }
        else if(this.state.indx+1 == this.state.quesObj.length){
          console.log('nil')
      }
        else{
            console.log('end')
        }
    }
    goFront=()=>{
         this.setState({modal:true})
         setTimeout(()=>{
             this.props.history.push('home')
         },3000)
    }
    handleQues = async(indx, vals) => {
            this.setState({
             quesObj: this.state.quesObj.map((item, index) => {
                if (indx !== index) return item;
                return { ...item, user_answer: vals };
              })
               });
               if(this.state.indx+1 !== this.state.quesObj.length){
               this.setState({disable:false},()=>setTimeout(()=>{
                this.setState({disable:true})
               },100))
              }
               if(this.state.indx+1 == this.state.quesObj.length){
                this.setState({disable:false})
            }
             setTimeout(()=>{
              this.sendFront()
             },200)
          
          };
          submitQues=async()=>{
            const data = {
                // link_id: 3,
                patient_n_key:localStorage.getItem('_patientKey') ,
                form: this.state.bulkData.title,
                order_form_id: this.state.bulkData.order_form_id,
                values: this.state.quesObj,
              };
            const headers = {
                "Content-Type": "application/json",
                Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
              };
              console.log(
                `${REACT_APP_API_URL}/therpayform_post/`,
                data
              );
              axios
                .post(
                    `${REACT_APP_API_URL}/therpayform_post/`,
                  data,
                  { headers }).then((res)=>{
                      console.log(res)
                        this.goFront()
                    
                  })
        }
    render() {
        const { step,indx, bulkData,loading,disable, quesObj,modal} = this.state;
        return (
            <>
            {modal?(
                 <Modal isOpen={modal}  >
                 <ModalBody>
                 Kudos! Thanks for taking the Assessment.<br/>
If you have any questions about our appointments, we’ll be glad to help. You can also give us a call at +91-7829-002-002                 </ModalBody>
                
               </Modal>
            ):null}
                <div className="choose-language-outer bg-none pt-4">
                <a onClick={this.goBack}className="p-4">
                <ArrowLeftCircle size={25} /></a>
                     {loading && <Loader />}
                    <div className="d-flex _text_Asses ">
                        <Col md={6} className="mx-auto text-center choose-language-inner px-0">
                        {step ==1 ? <div className='desc_text_Asses'>
                             <p>
                             Next, we'll ask you about the symptoms you're experiencing, then help you understand what they mean and what you can do about them. You must be honest and respond as accurately as possible.
                             </p>
                             <button onClick={()=>{this.setState({step:1})}}>Continue</button>
                         </div>:null}
                        {step ==0 ? <div>
                            {bulkData &&quesObj&& quesObj.length!==0 && <div className='answrr_ques' style={{marginTop:'-20px'}}>
                                <h4>{bulkData.description}</h4> 
                                <h5>{indx+1}<ArrowRight /> {quesObj[indx].question}</h5>
                                  {bulkData &&quesObj&&quesObj[indx].answer.map((i,inx)=>{
                                      return(
                                        <div className='radio_style'> 
                                        <button className={`${ i===quesObj[indx].user_answer ?'chhecked':null}`} onClick={()=>this.handleQues(indx,i)}>
                                          {i}
                                        </button>
                                        </div>
                                      )
                                  })} 
                           </div>}
                           {indx === quesObj.length -1 && <div className='cnt_btn'>
                            <button disabled={disable} onClick={()=>this.submitQues()}>Next <ArrowRight /></button>
                        </div>}
                           {indx >0 && <div onClick={()=>this.setState({indx:indx-1})} className='prev_div'>
                                 <ArrowLeftCircle />
                             </div>}
                        </div>:null} 
                       
                         </Col>

                    </div>
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData,
        scheduleData: state.ScheduleData
    }
}

export default withRouter(connect(mapStateToProps, null)(AsesQues));
