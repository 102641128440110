import moment from "moment";
import React from "react";
import ReadMore from "../../Helpers/Readmore";
import "../../pages/meeting/meeting.css";

class SendSms extends React.Component {
  constructor(props) {
    super(props);
    this.messagesEndRef = React.createRef();
    this.state = {};
  }

  render() {
    let newMsg = this.props.newMsg;
    const item = this.props?.item;
    return (
      <>
        {newMsg !== "" && newMsg !== null ? (
          <div
            ref={this.messagesEndRef}
            className="col-md-7 col-10 text-left mr-auto my-2"
          >
            <div className="mr-auto">
              {this.props.link ? (
                <a
                  href={newMsg}
                  target="_blank"
                  style={{ background: "#f7f7f7" }}
                  className="d-inline-block px-0 mx-0 p-2 mb-0 rounded"
                >
                  {newMsg}
                </a>
              ) : (
                <div
                  style={{ background: "#f7f7f7" }}
                  className="d-inline-block  p-2 mb-0 rounded"
                >
                  <ReadMore text={newMsg} background="#f7f7f7" maxWords={80} />
                </div>
              )}
            </div>
            <span
              style={{ fontSize: "10px" }}
              className="text-sm text-right mr-auto pl-1"
            >
              {moment(item?.time).format("h:mm A")}
            </span>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default SendSms;
