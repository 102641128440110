import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import { therapist } from '../../../assets/images';
import { register } from '../../../assets/images';
import slide2 from '../../../assets/images/slide2.png'
import slide3 from '../../../assets/images/slide3.png'
import slide4 from '../../../assets/images/slide4.png'
import slide5 from '../../../assets/images/slide5.png'

// import {logo,DownArrow,loaderSVG} from '../../assets/images';

class LeftContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url:slide2
        };
    }

    componentDidMount() {
        

    }

    render() {
        return (
            <>
                <Row className={` m-0 ${this.props.step === 3 && this.props.step === ""  ? "bg-background" : ""}`}>
                    <div className={`left-content-outer mx-auto`} >
                        <div className="left-content-inner">
                            <div className={ "d-md-block d-sm-block d-lg-block d-none"}>    <h1>CareMe Health</h1>
                                <h5>Your personal healthcare assistant</h5>
                                {this.props.step === 3 ? <h2 className="mt-4"> Knowing more about <br /> you means helping  <br /> you better. Talk to Cici </h2> : ''}
                                <img className='slide_img' src={this.state.url} alt="choose language image" />
                                {/* {this.props.step !== 3 ? <h4>Determine your problem</h4> : ''}
                                {this.props.step !== 3 ? <h6>Get diagnosed, <br /> anywhere & anytime</h6> : ''} */}
                            </div>
                            <div className="d-md-block d-sm-block d-lg-block d-none left-content-dot">
                                <span className={this.state.url == slide2? "active" : ""} onClick={() => this.setState({url:slide2})}></span>
                                <span className={this.state.url == slide3? "active" : ""} onClick={() => this.setState({url:slide3})}></span>
                                <span className={this.state.url == slide4 ? "active" : ""} onClick={() => this.setState({url:slide4})}></span>
                                <span className={this.state.url == slide5 ? "active" : ""} onClick={() => this.setState({url:slide5})}></span>
                                {/* <span className={this.props.step === 3 ? "active" : ""} onClick={this.props.currentStep < 3 ? () => { return true } : () => this.props.navigateComponent(3)}></span> */}
                            </div>
                        </div>

                    </div></Row>

            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {

    }
}

export default connect(mapStateToProps, null)(LeftContent);
