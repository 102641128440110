import React from 'react'
import { connect } from 'react-redux'

const AddCsvFile = (props) => {
  return (
    <div className='col-md-10 ml-auto mr-md-5 mt-md-5 pt-md-1'>
        <div className='border rounded '>

        </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddCsvFile)