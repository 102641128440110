import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col,  FormGroup } from 'reactstrap';

class Input extends React.Component {
    render() {
        let { label,name, type,placeholder, currentValue } = this.props;
      
        return (
            <>
             <FormGroup>
                <input className="form-control" value={currentValue} type={type} name={name}  placeholder={placeholder} onChange={(e)=>this.props.onInputChange(e)} />
            </FormGroup>
            </>
        )
    }

}


export default Input
