import {RESCHEDULE_STATUS} from './Constants';
import { v4 as uuidv4 } from 'uuid';

export const RescheduleAction = (data) => {
    return {
        type: RESCHEDULE_STATUS,
        payload: {
            id: uuidv4(),
            data
        }
    }
}