import React ,{createRef}from 'react';
import { connect } from 'react-redux'; 
import {withRouter} from 'react-router-dom'
import { Container, Row, Col,Tooltip, Progress } from 'reactstrap';
import { ArrowLeft, ArrowRight, Check, Circle, Info, StopCircle } from 'react-feather';
import { REACT_APP_API_URL } from '../../processENV';
import Store from '../../../Store';
import { LoginStateAction } from '../../../Actions/LoginAction';
import Loader from '../Loader';
import caremelogo from '../../../assets/images/caremelogo.png'
import { Edit3, Menu, Power, User, Send } from 'react-feather';
import GetFormInput from './GetFormInput';
import moment from 'moment';

class RegistrationForm extends React.Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.monthFocus = React.createRef();
        this.yearFocus = React.createRef();
        this.state = {
            quesObj:[],
            newData:{},
      indx: 0,
      gender: [
        {label: 'Male', value: 'Male', checked: false},
        {label: 'Female', value: 'Female', checked: false},
        {label: 'Non-Binary', value: 'Non-Binary', checked: false},
        {label: 'Transgender', value: 'Transgender', checked: false},
        {label: 'Something Else', value: 'Something Else', checked: false},
        {label: 'I choose Not to Disclose', value: 'I choose Not to Disclose', checked: false},
      ],
      maritalStaus: [
        {label: 'Never Married', value: 'Never Married', checked: false},
        {label: 'Married', value: 'Married', checked: false},
        {label: 'Widowed', value: 'Widowed', checked: false},
        {label: 'Divorced', value: 'Divorced', checked: false},
        {label: 'Separated', value: 'Separated', checked: false},
      ],
     occupation:[
        {label: 'Agriculture', value: 'Agriculture', checked: false},
        {label: 'Artist', value: 'Artist', checked: false},
        {label: 'Human Resource', value: 'Human Resource', checked: false},
        {label: 'Engineer', value: 'Engineer', checked: false},
        {label: 'Software Developer', value: 'Software Developer', checked: false},
        {label: 'Student', value: 'Student', checked: false},
        {label: 'Professor', value: 'Professor', checked: false},
        {label: 'Programmer', value: 'Programmer', checked: false},
        {label: 'Project Manager', value: 'Project Manager', checked: false},
        {label: 'Self-Employed', value: 'Self-Employed', checked: false},
        {label: 'Doctor', value: ' Doctor', checked: false},
        {label: 'Sales Executive', value: 'Sales Executive', checked: false},
        {label: 'Director', value: 'Director', checked: false},
        {label: 'Lawyer', value: 'Lawyer', checked: false},
        {label: 'Designer', value: 'Designer', checked: false},
        {label: 'Government Worker', value: 'Government Worker', checked: false},
        {label: 'Welfare Worker', value: 'Welfare Worker', checked: false},
        {label: 'Pilot', value: 'Pilot', checked: false},
        {label: 'Others', value: 'Others', checked: false},
      ],
      emailError: false,
      dateError:false,
      disable: true,
      date: '',
      month: '',
      year: '',
      tooltipOpen:false,

        };
    }

    componentDidMount() {
       var arr = this.props.location.state.pendingArray;
       this.setState({quesObj:arr})
    }
    submitQues=async()=>{
      const {newData}=this.state;
      console.log(newData)
      const data={
        patient_n_key:localStorage._patientKey,
        occupation:newData.occupation,
        date_of_birth:moment(newData.date_of_birth).format('YYYY-MM-DD'),
        first_name:newData.first_name,
        last_name:newData.last_name,
        gender:newData.gender,
        marital_status:newData.marital_status,
        email:newData.email,
        phone_number:newData.phone_number
      }
      console.log(data)
      fetch(`${REACT_APP_API_URL}/patientedit_app/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
        },
        body: JSON.stringify(data),
    }).then(response => response.json())
    .then((res)=>{
      console.log(res)
      if(res.status==='success'){
         window.location.replace('home')
      }
    })
    }
    handleQues = async (text, key, val) => {
        if (key == 'first_name') {
          if (text.trim().length >= 2) {
            this.setState({
              newData: {...this.state.newData, 'first_name': text},
              disable: false,
            });
          } else {
            this.setState({disable: true});
          }
          console.log(this.state.quesObj, this.state.disable);
        }
        if (key == 'last_name') {
          if (text.trim().length >= 2) {
            this.setState({
              newData: {...this.state.newData, 'last_name': text},
              disable: false,
            });
          } else {
            this.setState({disable: true});
          }
        }
        if (key == 'phone_number') {
          if (text.trim().length >= 2) {
            this.setState({
              newData: {...this.state.newData, 'phone_number': text},
              disable: false,
            });
          } else {
            this.setState({disable: true});
          }
        }
        if (key == 'email') {
          let pattern =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (pattern.test(text)) {
            this.setState({
              newData: {...this.state.newData, 'email': text},
              disable: false,
              emailError: false,
            });
          } else {
            this.setState({disable: true, emailError: true});
          }
        }
        if (key == 'date_of_birth') {
           var nD= new Date()
      var Yr = nD.getFullYear()
     
        if(text >= Yr){
          this.setState({dateError:true, disable:true})
        }
       else if(!this.state.month || !this.state.date){
          this.setState({dateError:true, disable:true})
        }
       else{ var DOB = this.state.month + '/' + this.state.date + '/' + text
       var currentDate = new Date();
       var currentYear = currentDate.getFullYear();
       var age = currentYear - text;
       if(age <=15){
        this.setState({disable:true})
       }
       else{
        this.setState({
          newData: {...this.state.newData, 'date_of_birth':moment(DOB).toDate()},
          disable: false,
          ageError:'',
        });
        console.log(this.state)
       }
      }
        }
        if(key =='pincode'){
          if (text.trim().length >= 2) {
            this.setState({
              newData: {...this.state.newData, pincode: text},
              disable: false,
            });
          } else {
            this.setState({disable: true});
          }
        }
      };
      handleRadio = (val, ind) => {
        var arr = this.state.gender.map((i, indx)=>{
          if(indx === ind){
            return{...i, checked:true}
          }
          return {...i, checked:false}
        })
        this.setState({gender:arr,
          newData:{...this.state.newData,
            'gender':val
          },
          disable:false
        },()=>{
          if(this.state.quesObj.length-1 === this.state.indx){
            console.log('yesds')
          }else{
            setTimeout(()=>this.setState({
              indx:this.state.indx+1,disable:true}),100)
          }
        })
      };
      handleRadios = (val, ind) => {
        console.log(this.state.quesObj)
        var arr = this.state.maritalStaus.map((i, indx)=>{
          if(indx === ind){
            return{...i, checked:true}
          }
          return {...i, checked:false}
        })
        this.setState({maritalStaus:arr,
          newData:{...this.state.newData,
            'marital_status':val
          },
          disable:false
        },()=>{
          if(this.state.quesObj.length-1 === this.state.indx){
            console.log('yesds')
          }else{
            setTimeout(()=>this.setState({
              indx:this.state.indx+1,disable:true}),100)
          }
        })
      };
      handleCheck=(val,ind)=>{
        console.log(val)
        var arr = this.state.occupation.map((i, indx)=>{
          if(indx === ind){
            return{...i, checked:true}
          }
          return {...i,checked:false}
        })
        this.setState({occupation:arr,
          newData:{...this.state.newData,
            'occupation':val
          },
          disable:false
        })
      }
      handleSubmit=async()=>{
        console.log(this.state.newData)
      }
      toggle() {
        this.setState({
          tooltipOpen: !this.state.tooltipOpen
        });
      }
    
      checkDOB=async()=>{
        console.log(this.state.newData.date_of_birth)
        if(this.state.newData.date_of_birth){
          var currentDate = new Date();
           var currentYear = currentDate.getFullYear();
           var age = currentYear - this.state.year;
           if(this.state.date && this.state.date >31){this.setState({disable:true})}this.setState({disable:false})
           if(this.state.month && this.state.month >12){this.setState({disable:true})}this.setState({disable:false})
           if(age <=15){
            this.setState({disable:true})
           }
            else{
              this.setState({disable:false})
            }
        }
        else{this.setState({disable:true})}
      }

    render() {
        const {quesObj,indx, gender, maritalStaus,disable,newData, occupation}=this.state;
        return (
            <>
                <div className="register_form">
                    {quesObj ? <div>
                  {indx !==0? <div style={{position:'relative', top:50, left:20,color:'#000'}}> <ArrowLeft color='#000' size={24} onClick={()=>this.setState({indx:indx-1})} />back </div>:null }
                  <Progress  value={`${((indx+1) / (quesObj.length)) * 100}`} color="dark" />
                      { quesObj[indx] === 'first_name' && <div className='input_reg_div'>
                        <h3> How can we call you?</h3>
                        <input type='text' autoFocus onFocus={newData.first_name?()=>{this.setState({disable:false})}:null} placeholder='First Name' defaultValue={newData.first_name}  className='first_name__' onChange={(e)=> this.handleQues(e.target.value, 'first_name') }/>
                        </div>}
                      { quesObj[indx] === 'last_name' && <div className='input_reg_div'  >
                        <h3> How can we call you?</h3>
                        <input type="text" autoFocus onFocus={newData.last_name?()=>{this.setState({disable:false})}:null} placeholder='Last Name' defaultValue={newData.last_name} className='last_name__' onChange={(e)=> this.handleQues(e.target.value, 'last_name') } />
                        </div>}
                      { quesObj[indx] === 'phone_number' && <div className='input_reg_div'>
                        <h3>What's Your Contact Number?</h3> 
                        <input type='number' autoFocus onFocus={newData.phone_number?()=>{this.setState({disable:false})}:null} placeholder='Ex: +91 999999999' className='phone_number__' defaultValue={newData.phone_number} onChange={(e)=> this.handleQues(e.target.value, 'phone_number') } />
                         </div>}
                      { quesObj[indx] === 'email' && <div className='input_reg_div'>
                       <h3>Give us your email! </h3>
                       <input type='text' autoFocus onFocus={newData.email?()=>{this.setState({disable:false})}:null} placeholder='Email Address' defaultValue={newData.email} className='email_input__' onChange={(e)=> this.handleQues(e.target.value, 'email') } />
                        </div>}
                       {this.state.emailError && <span style={{marginTop:80}}>Enter a valid email address</span>}

                      { quesObj[indx] === 'date_of_birth' && <div className='input_reg_div'>
                        <h3>What's your DOB?</h3> 
                        <div className='date_container__'>
                          <input type='number' maxLength='2' defaultValue={this.state.date} autoFocus onFocus={()=>this.checkDOB()} placeholder='DD' className='date_input__' 
                       onChange={(e) => {
                          if(e.target.value > 31 || e.target.value==0 ){
                            this.setState({dateError:true,disable:true})
                          }
                          else{
                            this.setState({date: e.target.value,dateError:false},()=>  e.target.value.trim().length==2 ?this.monthFocus.current.focus():null)
                          }
                        }
                   } />
                          <input type='number' maxLength='2' ref={this.monthFocus} defaultValue={this.state.month} onFocus={()=>this.checkDOB()} placeholder='MM' className='month_input__'  
                   onChange={(e) =>
                   {
                    if(e.target.value > 12 || e.target.value==0){
                      this.setState({dateError:true,disable:true})
                    }
                    else{
                      this.setState({month: e.target.value,dateError:false},()=>  e.target.value.trim().length==2 ?this.yearFocus.current.focus():null)
                    }
                  }
                   } />
                          <input type='number' ref={this.yearFocus} defaultValue={this.state.year} onFocus={()=>this.checkDOB()} maxLength={4} placeholder='YYYY' className='year_input__'  onChange={(e) => {
                    if((e.target.value < 1900)||(e.target.value.trim().length < 4)){
                      this.setState({dateError:true,disable:true})
                    }
                    else{
                      this.setState({year: e.target.value,dateError:false})
                      if(e.target.value.trim().length >=4)this.handleQues(e.target.value, 'date_of_birth');
                    } 
                  }
                }
                />
                        </div>
                        </div>}
                        {this.state.dateError && <span style={{marginTop:80}}>Enter a valid date</span>}
                      { quesObj[indx] === 'gender' && <div className='input_reg_div'>
                       <h3> What is your biological sex? </h3>
                       <span href='#' id="TooltipExample"  style={{fontSize:15,marginLeft:0, color:'#000'}}>Why we're asking <Info color="#000" size={20} /> </span>
                       <Tooltip placement="right" style={{color:'#fff'}} isOpen={this.state.tooltipOpen} target="TooltipExample" toggle={this.toggle}>
                       We need to know your biological sex for medical purposes only, to help us provide the best care possible. We understand your gender identity may be different.
                      </Tooltip>
                       <div style={{marginTop:70}}>
                       {gender.map((i,indx)=>{
                         return(
                          
                          <div onClick={()=>this.handleRadio(i.value, indx)}  className='reg_radio__'>
                            <p className={i.checked?'_reg_radio_':'_reg_radio'} style={{textAlign:'center'}}>{i.label} </p>
                          </div>
                         )
                       })}
                       </div>
                       </div>}
                      { quesObj[indx] === 'marital_status' && <div className='input_reg_div'>
                       <h3 style={{marginBottom:'40px'}}>What's Your Marital Status? </h3> 
                       {maritalStaus.map((i, indx)=>{
                         return(
                           <div onClick={()=>this.handleRadios(i.value, indx)} className='reg_radio__'>
                           <p style={{textAlign:'center'}} className={i.checked?'_reg_radio_':'_reg_radio'}> {i.label} </p>
                           </div>
                         )
                       })}
                        </div>}
                      { quesObj[indx] === 'occupation' && <div className='input_reg_div'>
                        <h3>What's Your occupation? </h3>
                       <div className='btn_radion_reg'> 
                      {occupation.map((i, indx)=>{
                         return(
                           <div onClick={()=>this.handleCheck(i.value, indx)} className={`inner_radio_ ${i.checked?'chng_radio':null}`}>
                            {i.label} 
                           </div>
                         )
                       })}
                        </div>
                        </div>
                        }
                      { quesObj[indx] === 'pincode' && <div className='input_reg_div'>
                     <h3>Enter Your Pincode!</h3> 
                      <input type='number' defaultValue={newData.pincode} autoFocus onFocus={newData.pincode?()=>{this.setState({disable:false})}:null} placeholder='pincode' className='pincode_input__' onChange={(e)=> this.handleQues(e.target.value, 'pincode') } />
                      </div>}

                      <div className='reg_footer'>
                        {quesObj.length-1 == indx ? 
           
                          <button disabled={disable} style={{opacity:disable?.3:1}} onClick={()=>this.submitQues()}>Continue <ArrowRight /> </button>:
                          <button disabled={disable} style={{opacity:disable?.3:1}} onClick={() =>{
                            if(this.state.quesObj.length-1 === this.state.indx){
                              console.log('Yes')
                            }else{
                              this.setState({
                                indx: indx + 1,
                                disable: true,
                              })            }
                          }}>Next</button>
                          }
                      </div>
                     </div> :<Loader />}
                    
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(withRouter(RegistrationForm));
