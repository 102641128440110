import React from "react";
import "../../pages/meeting/meeting.css";
import {XCircle, Send} from 'react-feather'
import SendSms from './SendSms'
import ReceiveMsg from './ReceiveMsg'
class ChatScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mess:[]
        }
    }

    componentDidMount() {

    }


    render() {
        return (
            <>
                <div class="chat-screen-main">
                    <div class="chat-window"><p className="title">Chat screen <span onClick={this.props.openChatScreen}><XCircle /></span></p> 
                    <SendSms />
                    <ReceiveMsg />
                    <div class="btn_sec" > <textarea  value={this.state.value} onChange={this.handleChange} className="form-control col-md-10" /> <a className="send-btn"><Send /></a>
                    </div> 
                    </div>
                </div>
            </>
        )
    }
}

export default ChatScreen;
