import React from 'react';
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Collapse
} from 'reactstrap';
import { connect } from 'react-redux';
import Store from '../../../Store';
import { LoginStateAction } from '../../../Actions/LoginAction';
import { Link } from "react-router-dom";

import { withTranslation } from 'react-i18next';
import { ChevronRight, MessageSquare } from 'react-feather';
import SelectSession from './SelectSession';
import Demo from './Demo';

class ChatTherapy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.loginData !== this.props.loginData) {
            this.setState({
                step: this.props.loginData.data?.step,
                mobileNumber: this.props.loginData.data?.mobileNumber,
                countryCode: this.props.loginData.data?.countryCode
            })
        }
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    };


    render() {
        let { isOpen } = this.state;
        let { t } = this.props;

        return (
            <>
                <div className={"login-main homecontainer pt-5"}>
                    <Container>
                        <Row>
            
                                <Col className="visit-care" md="3">
                                     <Card className="draw">
                                        <Row>
                                            <Col md="2" className="px-1 px-md-2 col-3">
                                                <MessageSquare size={34} color={'#ffa400'} />
                                            </Col>

                                            <Col md="10" className="px-1 col-6">
                                            <CardTitle tag="h5" > Chat with Care team  </CardTitle>
                                              </Col>


                                        </Row>
                                        <Row>
                                            <Col md="4" className="px-1 px-md-2 col-3">
                                            </Col>
                                          <Col md="6" className="pl-md-0 px-0 paynow col-6 mx-auto">
                                                <Link className="w-100"><button className="btn btn-primary">Chat for free <ChevronRight size={20} /> </button> </Link>
                                            </Col>
                                        </Row>

                                    </Card>
                                </Col>
                                <Col className="visit-care" md="3">
                                     <Card className="draw">
                                        <Row>
                                            <Col md="2" className="px-1 px-md-2 col-3">
                                                <MessageSquare size={34} color={'#ffa400'} />
                                            </Col>

                                            <Col md="10" className="px-1 col-6">
                                                <CardTitle tag="h5" > Pay for Chat Therapy  </CardTitle>
                                              </Col>


                                        </Row>
                                        <Row>
                                            <Col md="2" className="px-1 px-md-2 col-3">
                                            </Col>

                                            <Col md="6" className="px-1  col-9">
                                              </Col>

                                            <Col md="4" className="pl-md-0 px-0 paynow col-6 mx-auto">
                                                <Link className="w-100"><button className="btn btn-primary" onClick={this.toggle}>Buy <ChevronRight size={20} /> </button> </Link>
                                            </Col>
                                        </Row>

                                    </Card>
                                </Col>
                        </Row>
                        <Row>
                        <Collapse isOpen={isOpen} className="w-100">
                            <SelectSession />
                        </Collapse>
                        </Row>
                        <Demo />
                    </Container>

                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(withTranslation()(ChatTherapy));
