import React from 'react';
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Collapse
} from 'reactstrap';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
    FormGroup,
    Label,
} from 'reactstrap';
import { REACT_APP_API_URL } from '../../processENV';
import Loader from '../Loader';
import { MinusCircle, PlusCircle, XCircle } from 'react-feather';
import { withTranslation } from 'react-i18next';

import { ToastContainer, toast } from 'react-toastify';

class Obstetric extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            living:'',
            para:'',
            abortions:'',
            gravida:'',
            isOpen:false,
            obstetricList:[]
        };
    }

    obstetricInputHandler = (e) =>{
        let id = e.target.name;
        this.setState({
            [id] : e.target.value
        })
    }

    toastSuccess = (e) => toast.success(e, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        });
        toastError = (e) => toast.error(e, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            });
   

    collapsePlan = () => {
        this.setState({
          isOpen: !this.state.isOpen
        })
      }
    
      componentDidMount(){
        this.getMenstrualList();
      }
      
      deleteMenstrual = (id) =>{
        fetch(`${REACT_APP_API_URL}/menstrual_history//${id}/`, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          }
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('delete Menstrual',res)
             this.toastError(res.data)
             this.getMenstrualList()
          })
          .catch(err => console.log('alcohol list error',err))
      }

      getMenstrualList = () =>{
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        fetch(`${REACT_APP_API_URL}/menstrual_list/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('Menstrual list',res)
             this.setState({obstetricList: res})
                  
          })
          .catch(err => console.log('allergy list error',err))
      }
    submitObstetricHistory = (e) => {
        let {   living,para,
            abortions,
            gravida } = this.state;  
                  var today = new Date();
        let dd = (today.getDate.toString().length === 1) ? ('0' + today.getDate()) : today.getDate();
        let mm = (today.getMonth().toString().length === 1) ? ('0' + (today.getMonth() + 1)) : (today.getMonth() + 1);
        let yy = (today.getFullYear().toString().length === 1) ? ('0' + today.getFullYear()) : today.getFullYear();
        let date = yy + '-' + mm + '-' + dd;

        if(!gravida || !abortions || !living || !para ){ 
            this.setState({
                error : "please fill all the fields"
            })
        }else{
         
            let data = {
                "patient_n_key": localStorage.getItem('_patientKey'),
                "appointment_n_key": localStorage.getItem('_appointmentKey'),
                "obstetric_his_gravida": gravida,
                "obstetric_his_para": para,
                "obstetric_his_living": living,
                "obstetric_his_abortion": abortions,
            
                      "assessment_date": date,
                    
             }

            this.setState({loading:true,error:''})
            console.log('smoking data',data)
            fetch(`${REACT_APP_API_URL}/menstrual_history//`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
                },
                body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
                if(res.status === "success"){
                        this.toastSuccess('Menstrual Updated Successfully')
                    this.setState({loading:false,
                        living:'',
                        para:'',
                        abortions:'',
                        gravida:'',
                                    loading:false})
                                    this.getMenstrualList()
                }else{
                    this.setState({loading:false,error:'Some error has occured please try again'})
                }
            })
        }
    }


    render() {
        let {   living,para,
        abortions,
        gravida, loading, isOpen, obstetricList } = this.state;
        let { t } = this.props;

        return (
            <>
                <Col md={8} xs={12} className="mb-2 px-0">
                    {loading && <Loader/>}
                                            <Card >
                                                <CardSubtitle tag="h5" className="mt-2  text-center text-muted">{t('OBSTETRIC HISTORY')} <span className="cusicon" onClick={this.collapsePlan}> {isOpen ? <MinusCircle />: <PlusCircle />}</span></CardSubtitle>
                                                <CardBody className="col-md-10 col-12  mx-auto">
                                                <Collapse isOpen={isOpen}>

                                                    <Formik
                                                        initialValues={{
                                                            name: '',
                                                            email: '',
                                                        }}
                                                        onSubmit={this.submitObstetricHistory}
                                                    >
                                                        {({ errors, touched }) => (
                                                            <Form>
                                                                     
                                                                    <FormGroup className="pl-md-0 w-100">
                                                                        <label>{t('Gravida - How Many Pregnancies have you had in total')}</label>
                                                                        <Field name="gravida" className="form-control" placeholder={t('Gravida - How Many Pregnancies have you had in total')} value={gravida}  onChange={(e)=> this.obstetricInputHandler(e)}/>
                                                                        {errors.gravida && touched.gravida ? (
                                                                            <ErrorMessage name="gravida" />
                                                                        ) : null}
                                                                    </FormGroup>
                                                                    <FormGroup className="pl-md-0 w-100">
                                                                        <label>{t('Para - How Many Pregnancies have you had beyond 24 Weeks')}</label>
                                                                        <Field name="para" className="form-control" placeholder={t('Para - How Many Pregnancies have you had beyond 24 Weeks')} value={para}  onChange={(e)=> this.obstetricInputHandler(e)}/>
                                                                        {errors.para && touched.para ? (
                                                                            <ErrorMessage name="para" />
                                                                        ) : null}
                                                                    </FormGroup>
                                                                    <FormGroup className="pl-md-0 w-100">
                                                                        <label>{t('Living - How Many Living Children you Have')}</label>
                                                                        <Field name="living" className="form-control" placeholder={t('Living - How Many Living Children you Have')}value={living}  onChange={(e)=> this.obstetricInputHandler(e)}/>
                                                                        {errors.living && touched.living ? (
                                                                            <ErrorMessage name="living" />
                                                                        ) : null}
                                                                    </FormGroup>
                                                                    <FormGroup className="pl-md-0 w-100">
                                                                        <label>{t('Abortion - How Many Abortions You have had before')}</label>
                                                                        <Field name="abortions" className="form-control" placeholder={t('Abortion - How Many Abortions You have had before')} value={abortions}  onChange={(e)=> this.obstetricInputHandler(e)}/>
                                                                        {errors.abortions && touched.abortions ? (
                                                                            <ErrorMessage name="abortions" />
                                                                        ) : null}
                                                                    </FormGroup>

                                                       
                                                                <div className="text-center">
                                                                    <button type="submit" className="btn btn-primary mx-auto">{t('UPDATE')}</button>

                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>     
                                                    </Collapse>
                                                    {obstetricList.obstetric_his_gravida !== "" && obstetricList.obstetric_his_gravida !== null ?   <div className="allhistory">
                                                    {obstetricList && obstetricList.length !== 0
                                                    ? obstetricList.map( item =>{
                                                        return <Col md={10} xs={12} className="mb-2 mx-auto indhistory">
                                                     <div className="tagsec">
                                                             <p><h6 className="m-0 obs">{item.obstetric_his_para} Pregrancies beyond 24 weeks with {item.obstetric_his_abortion} abortions</h6>
                                                              <a onClick={() => this.deleteMenstrual(item.menstrual_history_id)}><XCircle /></a></p>
                                                             <h5>{item.obstetric_his_gravida} Pregnancies with {item.obstetric_his_living} living</h5>

                                                        </div>
                                                    </Col> 
                                                    })
                                                :<></>  }
                                                        
                                                         </div>:''}
                                                                 </CardBody>
                                                
                                            </Card>
                                        </Col> 
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(withTranslation()(Obstetric));
