import React from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { usePapaParse,jsonToCSV } from 'react-papaparse';
import axios from 'axios';
import { REACT_APP_BU_API_URL } from '../processENV';
import { useEffect } from 'react';
import MaterialTable from 'material-table'
import moment from 'moment/moment';
import { PeopleOutlineTwoTone } from '@material-ui/icons';
import { IoIosPeople } from 'react-icons/io';
import { ChevronRight, Download, UploadCloud, UserPlus, UserX } from 'react-feather';
import { Modal,Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Delete } from '@material-ui/icons';
import { Edit } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';
import { toast,ToastContainer } from 'react-toastify';
import {Card} from 'react-bootstrap';

const AddUser = (props) => {
    const history = useHistory();
    const [add,setAdd]=useState(false)
    const [fileName,setFilename]=useState()
    const [disablbtn,setDisablebtn]=useState(false)
    const [limits, setLimits]=useState({})
    const [name,setName]=useState(false)
    const [name1,setName1]=useState(false)
    const [address,setAddress]=useState(false)
    const [dial,setDial]=useState(false)
    const [dob,setDob]=useState(false)
    const [marital,setMarital]=useState(false)
    const [gender,setGender]=useState(false)
    const [email,setEmail]=useState(false)
    const [demail,setDEmail]=useState(false)
    const [phone,setPhone]=useState(false)
    const [dphone,setDPhone]=useState(false)
    const [aray,setaray]=useState()
    const [users,setUsers]=useState([])
    const [column,setColumn]=useState([])
    const [mod, setMod]=useState(false)
    const [disuser, setDisuser]=useState(false)
    const [disuserdetails, setDisuserdetails]=useState(false)
    const { readString } = usePapaParse();
    const hiddenFileInput = React.useRef(null);

   const checkemail=(email)=> { 
        return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email);
        } 
 
   const checknum=(nmb)=>{
          if( nmb&&nmb.length == 10){
            return true
          }
          else return false
        } 
     
    const cancel=()=>{
        setAddress(false)
        setEmail(false)
        setPhone(false)
        setDEmail(false)
        setDPhone(false)
        setMarital(false)
        setDob(false)
        setDial(false)
        setName(false)
        setName1(false)
        setAdd(false)
    }  
    const convert=()=>{
        const config={
            quotes: false, //or array of booleans
            quoteChar: '"',
            escapeChar: '"',
            delimiter: ",",
            header: true,
            newline: "\r\n",
            skipEmptyLines: false, //or 'greedy',
            columns: null //or array of strings
          }
        //   console.log(jsonToCSV(aray,config))
        return jsonToCSV(aray,config)
    }  
    const submit=()=>{
        let ar=convert()
       
        const headers={ 
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          }
        const data = new FormData()
        const blob = new Blob([ar], { type: 'text/csv' });
        const file = new File([blob], fileName, { type: blob.type })
        console.log(file)
        data.append('data',file)
        data.append('bu_org_id',localStorage._b)
        console.log(data)
        axios.post(REACT_APP_BU_API_URL+'/add_patient_business/',data,{headers}).then((res)=>{
            console.log(res)
           if(res.data.status=='success'){
            toast.success('CSV Uploaded', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            window.location.href='/admin-panel'
           }
        })
    }
   
    const editUser=async(val)=>{
        history.push({
            pathname: '/create-user',
            state: { val ,edit:true}
          });
    }
    const disableUser=async(val)=>{
            setDisuser(!disuser)
            setDisuserdetails(val)
            console.log(val)
    }
    const handleCSV=(e)=>{    
        let doc=e.target.files[0]
        setFilename(doc.name)
        readString(doc, {
            header: true,
            worker: true,
            skipEmptyLines:true,
            complete: (results) => {
              console.log(results);
              setaray(results)
              setAdd(true)
              setMod(false)
            let gender = results?.data?.filter(i=>!i.gender)
            let phone = results?.data?.filter(i=>!i.phone_number)
            let email = results?.data?.filter(i=>!i.email)
            let first_name = results?.data?.filter(i=>!i.first_name)
            let last_name = results?.data?.filter(i=>!i.last_name)
            let dial_code = results?.data?.filter(i=>!i.dial_code)
            let date_of_birth = results?.data?.filter(i=>!i.date_of_birth)
            let address_line_one = results?.data?.filter(i=>!i.address_line_one)
            let marital_status = results?.data?.filter(i=>!i.marital_status)
            
            if(gender.length >0){
                setDisablebtn(true)
                setGender(true)
            }
            if(phone.length >0){
                setDisablebtn(true)
                setPhone(true)
            }
            if(email.length >0){
                setDisablebtn(true)
                setEmail(true)
            }
            if(first_name.length >0){
                setDisablebtn(true)
                setName(true)

            }
            if(last_name.length >0){
                setDisablebtn(true)
                setName1(true)

            }
            if(dial_code.length >0){
                setDisablebtn(true)
                setDial(true)

            }
            if(date_of_birth.length >0){
                setDisablebtn(true)
                setDob(true)

            }
            if(address_line_one.length >0){
                setDisablebtn(true)
                setAddress(true)

            }
            if(marital_status.length >0){
                setDisablebtn(true)
                setMarital(true)

            }
      
            let listphone=results?.data?.map(i=>i.phone_number)
            if(listphone.filter(i=> !checknum(i)).length > 0){
                setPhone(true)
                setDisablebtn(true)
            }
            let listemail=results?.data?.map(i=>i.email)
            if(listemail.filter(i=>!checkemail(i)).length > 0){
                setEmail(true)
                setDisablebtn(true)
            }
            if(listemail.filter((item, index) => index !== listemail.indexOf(item)).length > 0){
                setDEmail(true)
                setDisablebtn(true)
            }
            if(listphone.filter((item, index) => index !== listphone.indexOf(item)).length > 0){
                setDPhone(true)
                setDisablebtn(true)
            }
            },
          });
    }

    useEffect(()=>{
        let isEditing = false; // Initialize the isEditing variable

        var results = aray
        let gender = results?.data?.filter(i=>!i.gender)
            let phone = results?.data?.filter(i=>!i.phone_number)
            let email = results?.data?.filter(i=>!i.email)
            let first_name = results?.data?.filter(i=>!i.first_name)
            let last_name = results?.data?.filter(i=>!i.last_name)
            let dial_code = results?.data?.filter(i=>!i.dial_code)
            let date_of_birth = results?.data?.filter(i=>!i.date_of_birth)
            let address_line_one = results?.data?.filter(i=>!i.address_line_one)
            let marital_status = results?.data?.filter(i=>!i.marital_status)
            if (gender?.length > 0 || phone?.length > 0 || email?.length > 0 ||
                first_name?.length > 0 || last_name?.length > 0 || dial_code?.length > 0 ||
                date_of_birth?.length > 0 || address_line_one?.length > 0 || marital_status?.length > 0) {
                isEditing = true; // Set isEditing to true if any field is being edited
              }
            
            if(gender?.length >0){
                setDisablebtn(true)
                setGender(true)
            }
            if(phone?.length >0){
                setDisablebtn(true)
                setPhone(true)
            }
            if(email?.length >0){
                setDisablebtn(true)
                setEmail(true)
            }
            if(first_name?.length >0){
                setDisablebtn(true)
                setName(true)

            }
            if(last_name?.length >0){
                setDisablebtn(true)
                setName1(true)

            }
            if(dial_code?.length >0){
                setDisablebtn(true)
                setDial(true)

            }
            if(date_of_birth?.length >0){
                setDisablebtn(true)
                setDob(true)

            }
            if(address_line_one?.length >0){
                setDisablebtn(true)
                setAddress(true)

            }
            if(marital_status?.length >0){
                setDisablebtn(true)
                setMarital(true)

            }
      
            let listphone=results?.data?.map(i=>i.phone_number)
            if(listphone?.filter(i=> !checknum(i)).length > 0){
                setPhone(true)
                setDisablebtn(true)
            }
            let listemail=results?.data?.map(i=>i.email)
            if(listemail?.filter(i=>!checkemail(i)).length > 0){
                setEmail(true)
                setDisablebtn(true)
            }
            if(listemail?.filter((item, index) => index !== listemail.indexOf(item)).length > 0){
                setDEmail(true)
                setDisablebtn(true)
            }
            if(listphone?.filter((item, index) => index !== listphone.indexOf(item)).length > 0){
                setDPhone(true)
                setDisablebtn(true)
            }
            setDisablebtn(isEditing);
    },[aray&&aray])

 const TextChange=(id)=>{
    var i = id.replace(/_/g,' ')
    var text = i[0].toUpperCase() + i.substr(1);
    return text
  }
   const getUsers=()=>{
    const headers={ 
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      }
      const data={bu_org_id:localStorage._b}
        axios.post(REACT_APP_BU_API_URL+'/list_business_details/',data,{headers}).then((res)=>{
           if(res.data.status=='success'){
            setUsers(res.data.patient_data)
            setLimits(res.data.limit)
             let arr = res.data.patient_data[0]
          const str = Object.entries(arr).map(([key, value]) => `${key}`);
         let dat= str.map((i)=>{
            if(i!=='tableData'){
             if(i=='date_of_birth'){
                return{
                    'title':TextChange(i),
                    'type':'date',
                    'field':`${i}`,
                    // filtering:true,
                        cellStyle: {
                        textAlign: "left",
                        fontFamily:'Hanken Grotesk',
                        fontWeight:'bold',
                        fontSize:15,
                       minWidth:'200px',
                        display:'table-cell'
                        }, 
                        render:rowData=>{return rowData.date_of_birth? moment(rowData.date_of_birth).format('DD-MM-YYYY'):''},
                  } 
             } 
             else if(i=='first_name'){
                return{
                    'title':'Name',
                    'type':'date',
                    'field':`${i}`,
                    // filtering:true,
                        cellStyle: {
                        textAlign: "left",
                        fontFamily:'Hanken Grotesk',
                        fontWeight:'bold',
                        fontSize:15,
                        display:'table-cell',
                       minWidth:'200px',
                        }, 
                        render:rowData=>rowData.first_name+' '+rowData.last_name,
                  } 
             } 
             else if(i=='phone_number'){
                return{
                    'title':'Phone Number',
                    'type':'date',
                    'field':`${i}`,
                    // filtering:true,
                        cellStyle: {
                        textAlign: "left",
                        fontFamily:'Hanken Grotesk',
                        fontWeight:'bold',
                        fontSize:15,
                       minWidth:'200px',
                        display:'table-cell'
                        }, 
                        render:rowData=>rowData.dial_code+' '+rowData.phone_number,
                  }
             }
             else if(i=='last_name'){
                return;
             } 
             else if(i=='department'){
              return;
           }  
             else if(i=='designation'){
              return;
           }  else if(i=='address_line_one'){
            return;
         } 
             else if(i=='patient_n_key'){
                return;
             } 
             else if(i=='gender'){
                return;
             } 
             else if(i=='marital_status'){
                return;
             } 
             else if(i=='dial_code'){
                return;
             } 
              else if(i=='user_status'){
                return{
                 'title':TextChange(i),
                  'field':`${i}`,
                  render: rowData => (
                  <div style={{width:'64px',padding:'2px 3px',textAlign:'center', borderRadius:6,background: rowData.user_status === 'Active' ? 'green' : '#c22408',color:'#fff',fontWeight:'bold' }}>
                    {rowData.user_status}
                  </div>
                )}
                  }
             else{
                return{
                    'title':TextChange(i),
                    'field':`${i}`,
                    // filtering:true,
                        cellStyle: {
                        textAlign: "left",
                        fontFamily:'Hanken Grotesk',
                        fontWeight:'bold',
                        fontSize:15,
                       minWidth:'200px',
                        }, 
                  } 
             }
            }
           }).filter(i=>i)
          console.log(dat)
          setColumn(dat)
           }    

        })
    }

    useEffect(()=>{
   
        getUsers()
    },[])
    useEffect(()=>{
        let data = {
          "patient_n_key": localStorage.getItem('_patientKey')
      }
        fetch(`${REACT_APP_BU_API_URL}/bu_org_details/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
      }).then(response => response.json())
          .then(async (res) => {
              if (res?.admin) {
               console.log('_')
              } else {
                window.location.href='/home'
              }
          })
          .catch(err => console.log(err))
      },[])
      const handleChange = (value, rowIndex, columnName) => {
        // Update the value in the aray state when the input value changes
        const updatedData = [...aray.data];
        updatedData[rowIndex][columnName] = value;
        console.log(updatedData)
        setaray({ ...aray, data: updatedData });
      };
   const disableuser=async()=>{
    const headers={ 
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      }
      const data={patient_n_key:disuserdetails.patient_n_key,created_by_id:localStorage._patientKey}
        axios.post(REACT_APP_BU_API_URL+'/disable_user/',data,{headers}).then((res)=>{
            console.log(res)
            if(res.data.status=='success'){
                setDisuser(false)
                setDisuserdetails('')
                getUsers()
            }
        })     
   }
   const isDisabled = limits?.active_users >= limits?.users_limit;
   const totalLicense = limits?.users_limit;
   const licenseObtained = limits?.active_users;
   const remainingLicense = totalLicense - licenseObtained;
  return (
    <div className=''>
        <ToastContainer />
    <Modal show={disuser} className='p-2'>
                <Modal.Header onClick={()=>setDisuser(!disuser)} closeButton></Modal.Header>
                <Modal.Body style={{padding:'10px'}} className='p-2'>
                    <h4 className='my-4 mx-4 header_font_'>Are you sure? </h4>
                    <h4 className='mx-4 header_font_'>You want to disable this user?</h4>
                    <div className='d-flex flex-row justify-content-center m-5' >
                    <button onClick={()=>{disableuser()}} className='m-3 px-4 py-2 btn btn-primary'>Yes</button>
                    <button onClick={()=>setDisuser(!disuser)}  className='m-3 px-4 py-2 btn btn-secondary'>No</button>
                    </div>
                </Modal.Body>
        </Modal>
        <Modal show={mod} centered className='p-3'>
             <Modal.Header onClick={()=>setMod(!mod)} closeButton></Modal.Header>
             <Modal.Body style={{padding:'10px'}} className='p-3'>
                <h4 className='my-4 mx-4 header_font_'>How would you like to add the new employees?</h4>
                <div onClick={()=>hiddenFileInput.current.click()} for="formFile" className=' custom_border shadow-sm p-1 mt-5 mx-3 d-flex flex-row justify-content-around'>
                   <div >
                    <p className='pt-2 pl-3' ><UploadCloud color={'#1e72c7'} /> Upload Excel </p>
                    <p className='pl-3 mt-n2' style={{fontWeight:'light',opacity:.5,fontSize:'12px'}}>Select this if you have all the data of the new employees in an Excel sheet.</p>
                    </div> 
                    <p className='mx-3 mt-3 align-self-center'><ChevronRight color='#a1bac3' /> </p>
                    <input ref={hiddenFileInput} onChange={handleCSV} accept=".csv" type="file" className='d-none' />
                </div>
                <div onClick={()=>window.location.href='/create-user'} className='custom_border shadow-sm p-1 mt-4 mb-5 mx-3 d-flex flex-row justify-content-around'>
                     <div>
                    <p className='pt-2 pl-3'><UserPlus color={'#309c69'} /> Add Single Employee </p>
                    <p className='pl-3 mt-n2' style={{fontWeight:'light',opacity:.5,fontSize:'12px'}}>You’ll have to enter the required details of the new employees yourself.</p>
                    </div>
                    <p className='mx-3 mt-3  align-self-center'><ChevronRight color='#a1bac3' /> </p>
                </div>
            </Modal.Body>
        </Modal>

    <div className='col-md-10 ml-auto  mt-5 pt-3 text-center'>
      {!add?  <div className='mt-1 pt-2 mx-auto p-2'>
      <h2 className='h2 header_font_ text-left mt-md-3 pt-md-5 _text_color'><IoIosPeople size={40} /> Team</h2>
     
      <p className='h5 col-md-8 text_p_ text-center mx-auto mt-md-5 pt-md-5 _text_color'>💪 Empower Your Team with Customized Mental Health Support: 🧑‍💼 Manage Employee Access to Our Platform with Ease. 🆕 Add, 📝 Edit, or 🗑️ Remove Employees as Needed, and Provide Them with the 🧠 Evidence-Based Care They Need to Thrive.</p>
       
      <div className="row mt-md-5">
      <div className="col-md-4">
        <Card style={{background:'#509dd4'}} className="mb-3  text-white col-md-8">
          <Card.Body>
            <Card.Title className='h5 header_font_'>Total License</Card.Title>
            <Card.Text className='h2 header_font_'>{totalLicense}</Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="col-md-4">
        <Card style={{background:'#76ad85'}} className="mb-3 text-white col-md-8">
          <Card.Body>
            <Card.Title className='h5 header_font_'>License Obtained</Card.Title>
            <Card.Text className='h2 header_font_'>{licenseObtained}</Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="col-md-4 ">
        <Card style={{background:remainingLicense<=5?'#e86056':'#4096a3'}} className={`mb-3 col-md-8 text-white `}>
          <Card.Body>
            <Card.Title className='h5 header_font_'>Remaining License</Card.Title>
            <Card.Text className='h2 header_font_'>{remainingLicense}</Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>

        <div  className='d-flex flex-row justify-content-end mt-2 pt-3 '>
            <label 
            onClick={()=>{
              if(isDisabled){
                console.log('')
              }
              else setMod(!mod)
            }} 
            style={{cursor:isDisabled ?'not-allowed':'pointer',background:'#0a74bf',color:'#fff',width:'145px',height:'35px',paddingTop:'6px'}}   class="form-label  shadow-none rounded mt-2 ml-3 text_p_ font-weight-bold"><UserPlus size={18} /> Add Members</label>
        </div>
        </div>:null}
        {users&&users.length>0&&!add && <>
            
            <MaterialTable
                title={`${users.length} Members`}
                columns={column}
                data={users} 
                actions={[
                            { icon: Edit,
                              tooltip: 'Edit User',
                              onClick: (event, rowData) => editUser(rowData)
                            },
                            { icon: Delete,
                              tooltip: 'Disable User',
                              onClick: (event, rowData) => disableUser(rowData)
                            }
                        ]}
                options={{
                    actionsColumnIndex: -1,
                    exportButton: {
                        csv: true,
                        pdf: false,
                        icon: () => <GetAppIcon />,
                     },
                     pageSize:10,
                     emptyRowsWhenPaging:false,
                     pageSizeOptions:[10,30,50,70,100],
                     headerStyle: {
                        backgroundColor: '#76ab90',
                        color: '#FFF',
                        fontFamily:'Hanken Grotesk',
                        fontSize:'15px',
                        textAlign: "left",
                        fontWeight:'bold',
                      },
                      searchIcon: () => <SearchIcon />
                  }}
                
                />

        </>}
        {add?
        <>
        <table className='table table-responsive table-bordered'>
    <thead>
      <tr>
        {aray?.meta?.fields?.map((column, index) => (
          <th className='border' key={index}>
            {column}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {aray?.data?.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {aray?.meta?.fields?.map((column, columnIndex) => (
            <td key={columnIndex}>
              <input
                onChange={(e) => handleChange(e.target.value, rowIndex, column)}
                value={aray.data[rowIndex][column]}
              />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>

     <div className='text-center d-flex flex-column'>
       {phone && <span className='text_p_' style={{color:'red',fontWeight:'bold'}}>* Please enter valid phone numbers in all fields</span>}
       {dphone && <span className='text_p_' style={{color:'red',fontWeight:'bold'}}>* Duplicate phone numbers found, please check</span>}
       {demail && <span className='text_p_' style={{color:'red',fontWeight:'bold'}}>* Duplicate email found, please check</span>}
       {gender && <span className='text_p_' style={{color:'red',fontWeight:'bold'}}>* Gender field is mandatory</span>}
       {email && <span className='text_p_' style={{color:'red',fontWeight:'bold'}}>* Enter a valid email format in all fields</span>}
       {name && <span className='text_p_' style={{color:'red',fontWeight:'bold'}}>* First Name field is mandatory</span>}
       {name1 && <span className='text_p_' style={{color:'red',fontWeight:'bold'}}>*Last Name field is mandatory</span>}
       {dob && <span className='text_p_' style={{color:'red',fontWeight:'bold'}}>* DOB field is mandatory</span>}
       {address && <span className='text_p_' style={{color:'red',fontWeight:'bold'}}>* Address field is mandatory</span>}
       {dial && <span className='text_p_' style={{color:'red',fontWeight:'bold'}}>* Dialcode field is mandatory</span>}
       {marital && <span className='text_p_' style={{color:'red',fontWeight:'bold'}}>* Marital status field is mandatory</span>}
    </div>
        <div>
            <button onClick={()=>submit()} disabled={disablbtn} className='btn btn-primary m-2'>Submit</button>
            <button onClick={()=>cancel()} className='btn btn-secondary m-2'>Cancel</button>
        </div>
</>:null}
    </div>
    </div>
  )
}



const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddUser)