import React, { useEffect, useRef, useState } from 'react'
import { ChevronRight, Map, MapPin, Phone, Users } from 'react-feather'
import { IoIosPeople } from 'react-icons/io'
import { FcDepartment } from 'react-icons/fc'
import {BsCalendarCheck,BsFilePerson,BsBuilding, BsGenderTrans, BsPhone} from 'react-icons/bs'
import { connect } from 'react-redux'
import { useForm ,Controller} from "react-hook-form";
import { dialCode } from '../../utils/dialcode';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { CalendarViewDayOutlined, MailOutlineSharp, Person, PersonAddOutlined } from '@material-ui/icons';
import { GenIcon } from 'react-icons';
import { REACT_APP_BU_API_URL } from '../processENV'
import { toast, ToastContainer } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { Backdrop, Button, CircularProgress } from '@mui/material';


const AddSingleUser = (props) => {
  const location = useLocation();
  const history = useHistory();
  const[dupemail,setDupemail]=useState(false);
  const[dupphone,setDupPhone]=useState(false);
  const [values,setValues]=useState({
    first_name:'','last_name':'','email':'','date_of_birth':null,'marital_status':'','address_line_one':'','dial_code':'+91','phone_number':'','gender':'','department':'','designation':''})
    const { handleSubmit, register, setValue, control, formState: { errors } } = useForm();
    const [load,setLoad]=useState(false)
  const capitalize=(str)=>{
    let capitalizedStr = str?.charAt(0).toUpperCase() + str?.slice(1);
    return capitalizedStr
  }

  useEffect(()=>{
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey')
  }
    fetch(`${REACT_APP_BU_API_URL}/bu_org_details/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),
  }).then(response => response.json())
      .then(async (res) => {
          if (res?.admin) {
           console.log('_')
          } else {
            window.location.href='/home'
          }
      })
      .catch(err => console.log(err))
  },[])

  useEffect(() => {
    const data = location.state?.val;
    console.log(data,location.state);
    if (data) {
      const updatedData = {
        ...data,
        date_of_birth: data.date_of_birth ? new Date(data.date_of_birth) : null,
      };
  
      // Update the form fields with props data
      Object.keys(updatedData).forEach((key) => {
        if (updatedData[key] !== undefined && updatedData[key] !== null) {
          setValue(key, updatedData[key]);
          setValues((prevValues) => ({
            ...prevValues,
            [key]: updatedData[key],
          }));
        }
      });
    }
  }, [props, setValue]);

  const checkDuplicateMail = () => {
    return new Promise((resolve, reject) => {
      const data = location?.state?.edit
        ? {
            email: values.email,
            type: 'edit',
            patient_n_key: location.state?.val?.patient_n_key,
          }
        : {
            email: values.email,
            type: 'add',
          };
  
      fetch(`${REACT_APP_BU_API_URL}/duplicate_patient/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'a7143772a09f993125901ea752bfd4fcf4b31d29',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(res => {
          if (res.status === 'success') {
            resolve(); 
            setDupemail(false)
          } else {
            setDupemail(true)
            reject('Duplicate mail check failed'); 
            toast.error('Email already exist', {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch(error => {
          reject(error); 
        });
    });
  };
  
  const checkDuplicateNumber = () => {
    return new Promise((resolve, reject) => {
      const data = location?.state?.edit
        ? {
            phone_number: values.phone_number,
            type: 'edit',
            patient_n_key: location.state?.val?.patient_n_key,
          }
        : {
            phone_number: values.phone_number,
            type: 'add',
          };
  
      fetch(`${REACT_APP_BU_API_URL}/duplicate_patient/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'a7143772a09f993125901ea752bfd4fcf4b31d29',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(res => {
          if (res.status === 'success') {
            resolve(); 
            setDupPhone(false)
          } else {
            reject('Duplicate number check failed'); 
            setDupPhone(true)
            toast.error('PhoneNumber already exist', {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch(error => {
          reject(error); 
        });
    });
  };
  

const sendData=()=>{
  setLoad(true)
let data={
 "first_name":capitalize(values.first_name),
 "last_name":values.last_name,
 "dial_code":values.dial_code,
 "phone_number":values.phone_number,
 "email":values.email,
 "marital_status":values.marital_status,
 "gender":values.gender,
 "address_line_one":values.address_line_one,
 "date_of_birth":moment(values.date_of_birth).format('YYYY-MM-DD'),
 "designation":capitalize(values.designation),
 "department":capitalize(values.department),
 "bu_org_id":localStorage._b,
 "patient_n_key":location?.state?.edit?location.state?.val?.patient_n_key:'',
}
console.log(data)
  fetch(`${REACT_APP_BU_API_URL}/bu_add_patient/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),
      }).then(response => response.json())
      .then(async (res)=>{
          console.log("Added",res)
          if(res.status === "success"){
            toast.success('Member Added', {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            let d_={'first_name':'','last_name':'','email':'','date_of_birth':null,'marital_status':'','address_line_one':'','dial_code':'+91','phone_number':'','gender':'','department':'','designation':''}
             setValues(d_)
             setLoad(false)
             history.push('/admin-panel')
          }else{
            setLoad(false)
            console.log('not added')
          }
      })
}
const onSubmit =async()=> {
  try {
    await Promise.all([checkDuplicateMail(), checkDuplicateNumber()]);
    sendData();
  } catch (error) {
    console.log('Error occurred:', error);
  }
};
  const validateDateOfBirth = (selectedDate) => {
    console.log('osd')
    const currentDate = new Date();
    const selectedDateOfBirth = new Date(selectedDate);
    const yearsDiff = currentDate.getFullYear() - selectedDateOfBirth.getFullYear();

    if (yearsDiff < 18) {
      return 'Date of birth should be above 18 years.';
    }

    return true;
  };

  const handleChange=(key,val)=>{
   setValues({...values,[key]:val})
  }
  return (
  load? <Backdrop open={load} >
  <CircularProgress color="inherit" />
</Backdrop>:  <div>
      <ToastContainer />
     
    <div className='col-md-10 ml-auto mr-md-5 mt-md-5 pt-md-1'>
              <h2 className='h2 header_font_ mt-5 pt-5  mt-md-2 ml-md-5  _text_color'><IoIosPeople size={40} /> Team</h2>
              <h5 className='h5 header_font_ mt-4 mt-md-2 mt-md-4 ml-md-5 _text_color d-flex align-self-center'><span  style={{opacity:.5}}><IoIosPeople size={40} /> Team <ChevronRight /></span><span className='align-self-center font-weight-bold'>  Add/Edit Member </span></h5>
              <form className='mx-auto col-md-10' onSubmit={handleSubmit(onSubmit)} >
             <div className='d-flex flex-md-row flex-column '>
              <div className='d-flex flex-column ml-md-5 mt-md-5 mt-2 col-md-5'>
                <label className='header_font_ _text_color h5'><PersonAddOutlined /> First Name <span className='req_'>*</span> </label>
                
                <input 
                defaultValue={values.first_name}  
                name="first_name" 
                id="firs_name"
                {...register("first_name", { required: true,minLength:2})}  
                onChange={(e)=>handleChange('first_name',e.target.value)} 
                style={{fontSize:'14px'}} 
                className='text_p_ form-control mt-1 py-4' 
                placeholder='First name' />

                { !values.first_name&&errors.first_name && <p  className='error_'>First Name is required</p>}
                {errors.first_name&&errors.first_name?.type=='minLength' && <p className='error_'>First Name should be atleast 2 characters</p>}
              </div>
              <div className='d-flex flex-column ml-md-5 mt-2 mt-md-5 col-md-5'>
                <label className='header_font_ _text_color h5'><PersonAddOutlined /> Last Name <span className='req_'>*</span></label>
                <input defaultValue={values.last_name} name="last_name" placeholder='Last name'  {...register("last_name", { required: true})} onChange={(e)=>handleChange('last_name',e.target.value)} className='form-control mt-1 py-4' />
                {!values.last_name&&errors.last_name && <p className='error_'>Last Name is required</p>}
              </div>
              </div>
              <div className='d-flex flex-md-row flex-column '>
              <div className='d-flex flex-column ml-md-5 mt-2 mt-md-4 col-md-5'>
                <label className='header_font_ _text_color h5'><BsBuilding /> Department <span className='req_'>*</span> </label>
                <input value={values.department} {...register("department", { required: true,minLength:2})}  onChange={(e)=>handleChange('department',e.target.value)} style={{fontSize:'14px'}} className='text_p_ form-control mt-1 py-4' placeholder='Department' />
                {!values.department&&errors.department && <p  className='error_'>Department is required</p>}
                {errors.department&&errors.department?.type=='minLength' && <p className='error_'>First Name should be atleast 2 characters</p>}
              </div>
              <div className='d-flex flex-column ml-md-5 mt-2 mt-md-4 col-md-5'>
                <label className='header_font_ _text_color h5'><BsFilePerson /> Designation<span className='req_'>*</span></label>
                <input placeholder='Designation' value={values.designation}  {...register("designation", { required: true})} onChange={(e)=>handleChange('designation',e.target.value)} className='form-control mt-1 py-4' />
                {!values.designation&&errors.designation && <p className='error_'>Designation is required</p>}
              </div>
              </div>
               <div className='d-flex flex-md-row flex-column'>
              <div className='d-flex flex-column ml-md-5 mt-2 mt-md-4 col-md-5'>
                <label className='header_font_ _text_color h5'><MailOutlineSharp /> Email  <span className='req_'>*</span></label>
                <input placeholder='Email' value={values.email}  {...register("email", {required: true,pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} onChange={(e)=>handleChange('email',e.target.value)} className='form-control mt-1 py-4' />
                {!values.email&&errors.email && <p className='error_'>Email is required</p>}
                {errors.Email &&errors.email?.type=='pattern' && <p className='error_'>Valid Email is required</p>}
                {dupemail && <p className='error_'> Email Already exists</p>}
              </div>
              <div className='d-flex flex-column ml-md-5 mt-2 mt-md-4 col-md-5'>
                <label className='header_font_ _text_color h5'><BsGenderTrans /> Gender  <span className='req_'>*</span></label>
                <select  style={{height:'45px'}} value={values.gender} {...register("gender", { required: true})}   onChange={(e)=>handleChange('gender',e.target.value)}  className='form-control mt-1' >
                  <option disabled value="">Choose gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
                {!values.gender&&errors.gender && <p className='error_'>Gender is required</p>}
              </div> </div>
               <div className='d-flex flex-md-row flex-column'>
              <div className='d-flex flex-column ml-md-5 mt-2 mt-md-4 col-md-5'>
                <label className='header_font_ _text_color h5'><BsCalendarCheck /> Date Of Birth <span className='req_'>*</span></label>
                <Controller
                  control={control}
                  name="date_of_birth"
                  rules={{
                    required: 'Date of birth is required.',
                    validate: validateDateOfBirth
                  }}
                  value={values.date_of_birth}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                    selected={value||values.date_of_birth}
                    onChange={(date)=>{
                      onChange(date);
                      handleChange('date_of_birth',date);
                    }}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select a date"
                    className="form-control mt-1 py-4"
                    />
                  )}
                />
             {!values.date_of_birth&&errors.date_of_birth && <p className='error_'>{errors?.date_of_birth?.message}</p>}
             {errors.date_of_birth&&errors.date_of_birth.type=='validate' && <p className='error_'>Date of birth should be above 18 years</p>}
              </div>  
              
              <div className='d-flex flex-column ml-md-5 mt-2 mt-md-4 col-md-5'>
                <label className='header_font_ _text_color h5'><BsPhone /> Phone Number  <span className='req_'>*</span></label>
                <div className='d-flex flex-row mt-n3 py-3'>
                  <select  value={values?.dial_code} {...register("dial_code", { required: true })} className='form-control col-md-3' style={{height:'45px'}} onChange={(e)=>handleChange('dial_code',e.target.value)}>
                    {dialCode.map((i,indx)=>{return(<option key={indx} value={i.dial_code}>{i.dial_code +' '+`(${i.name})`}</option>)})}
                  </select>
                {!values.dial_code&&errors.dial_code && <p className='error_'>Dialcode is required</p>}
                  <input  value={values.phone_number} {...register("phone_number", { required: true,maxLength: 15,
                    validate: (value) =>
                      values.dial_code&&values.dial_code === "+91" ? value.length === 10 : value.length > 2,
                  })} onChange={(e)=>handleChange('phone_number',e.target.value)}  className='form-control py-4 col-md-9' placeholder='Phone number' />
                </div>
                {errors.phone_number &&errors.phone_number.type=='validate'&& <p className='error_ mt-n2'>Enter a valid mobile number</p>}
                {errors.phone_number &&errors.phone_number.type=='maxLength'&& <p className='error_ mt-n2'> Mobile number should be less than 15 digits</p>}
                {!values.phone_number&&errors.phone_number && <p className='error_ mt-n2'>Phone number is required</p>}
                {dupphone && <p className='error_'> Phone Number Already exists</p>}

              </div>  </div>
               <div className='d-flex flex-md-row flex-column'>
              <div className='d-flex flex-column mt-2 ml-md-5 mt-md-4 col-md-5'>
                <label className='header_font_ _text_color h5'> <Users /> Marital Status  <span className='req_'>*</span></label>
                <select value={values?.marital_status} {...register("marital_status", { required: true})} className='form-control mt-1' style={{height:'45px'}} onChange={(e)=>handleChange('marital_status',e.target.value)}>
                  <option disabled  value="">Marital Status</option>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Widowed">Widowed</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Separated">Separated</option>
                  <option value="Never Married">Never Married</option>
                  <option value="In a Relationship">In a Relationship</option>
                </select>
                {!values.marital_status&&errors.marital_status && <p className='error_'>Marital status is required</p>}
              </div> 
              <div className='d-flex flex-column ml-md-5 mt-2 mt-md-4 col-md-5'>
                <label className='header_font_ _text_color h5'><MapPin /> Address  <span className='req_'>*</span></label>
                <input value={values.address_line_one} placeholder='Address' {...register("address_line_one", { required: true})} onChange={(e)=>handleChange('address_line_one',e.target.value)}  className='form-control mt-1 py-4' />
                {!values.address_line_one&&errors.address_line_one && <p className='error_'>Address is required</p>}
              </div>  
              </div>
              <div className='justify-content-center my-md-5 mt-4 d-flex flex-row'>
          <button onClick={()=>history.push('/admin-panel')} className='btn btn-light'>Back</button>
          <button type="submit" onClick={handleSubmit(onSubmit)} className='btn btn-success ml-2'>Submit</button>
        </div>
        </form>
      
    </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddSingleUser)