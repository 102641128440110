import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import LeftContent from './LeftContent';
import ChooseLanguage from './TherapyCare';
import Store from '../../../Store';
import SelectSession from './SelectSession';
import { TherapyJourneyAction } from '../../../Actions/TherapyJourneyAction';
import SelectDateTime from './SelectDateTime';
import { withRouter } from 'react-router-dom';

class TIndex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            therapyStep:0,
            therapyCurrentStep:1,
            date:'',
            time:'',
            language:'english',
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.therapyData !== this.props.therapyData) {
            this.setState({
                therapyStep: this.props.therapyData.data?.therapyStep,
                date: this.props.therapyData.data?.date,
                time: this.props.therapyData.data?.time,
                language: this.props.therapyData.data?.language
            })
        }
      }
   componentDidMount(){
       console.log(this.props)
       let item=this.props.location.state?this.props.location.state.item:null;
       let action = this.props.location?.state?.action
       if(item ==='Followup'||action ==='Date'){
           this.setState({therapyStep:2})
       }
   } 
    navigateComponent = (id) =>{
        this.setState({therapyStep:id})
        Store.dispatch(TherapyJourneyAction({
            therapyStep:id,
            getCareCurrentStep:id,
            // currentStep:localStorage.getItem('_currentStep') !==null || localStorage.getItem('_currentStep') !== undefined  ? localStorage.getItem('_currentStep') : 0,
            date: this.props.therapyData.data?.date,
            time: this.props.therapyData.data?.time,
            language: this.props.therapyData.data?.language
        }));
    }


    render() {
        let { therapyStep } = this.state;
        return (
            <>
            {
                <div className={"login-main"}>
                    <Row className='m-0'>
                        <Col>
                            
                            {therapyStep === 0 && <SelectSession navigateComponent={this.navigateComponent}/>}
                            {therapyStep === 1 && <ChooseLanguage  />}
                            {therapyStep === 2 && <SelectDateTime/>}

                        </Col>      
                    </Row>
                </div>
                }
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        therapyData: state.TherapyJourneyData,

    }
}

export default  withRouter(connect(mapStateToProps, null)(TIndex));
