import React from "react";
import ServiceCard from "./ServiceCard";
import {
  Container, Row, Col, Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Media, Collapse, Label
} from 'reactstrap';
import appStore from '../../../../assets/images/appStore.png'
import playStore from '../../../../assets/images/google-playstore.png'
import facebook from '../../../../assets/images/facebook.png'
import { REACT_APP_API_URL } from '../../../processENV';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Star, Headphones, Calendar, Activity,ChevronRight } from 'react-feather';
import Loader from "../../Loader";
import { withTranslation } from 'react-i18next';

const queryString = require('query-string');



class ServiceReview extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      reviews:[]
    };
  }

  componentDidMount(){
    let data = {
      "app_prod_n_key": this.props.prodId 
    }
    this.setState({loading:true})
    fetch(`${REACT_APP_API_URL}/review_products/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),
      }).then(response => response.json())
      .then(async (res)=>{
          if(res.status === "success"){
              this.setState({loading:false, reviews:res.data})
          }else{
              this.setState({loading:false,noData:true,error:'Some error has occured please try again'})
          }
      })
  }

  render() {
    let {  loading, reviews } = this.state;

    let { t } = this.props;
    const testimonials = {
      dots: false,
      // infinite: true,
      slidesToScroll: 1,
      // slidesToShow: 3,
      // autoplay: true,
      speed: 1000,
      // autoplaySpeed: 2000,
      cssEase: "linear",
      arrows:false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            // slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
                <>  
                {loading ? <Loader/> :
                <Row className="service_review">  <Col md={5} className="testi-content"  >
                <h2>{this.props.t('Patients love using CareMe')}</h2>
                <h5>{t('Don’t take it from us. Here’s what our clients say')}</h5>
            </Col>
            <Col md={7} className="px-0">
                  <Slider {...testimonials} className="text-center">
                    {reviews && reviews.map(item =>{

                        return <div className="col-md-12 slider_sec">
                        <div className="mt-2">
                          <Star color={item.stars >= 1 ? "#ff884d" : "#dfdfdf"} fill={item.stars >= 1 ? "#ff884d" :  "#dfdfdf"} />
                          <Star color={item.stars >= 2 ? "#ff884d" : "#dfdfdf"} fill={item.stars >=  2 ? "#ff884d" : "#dfdfdf"} />
                          <Star color={item.stars >= 3 ? "#ff884d" : "#dfdfdf"} fill={item.stars >=  3 ? "#ff884d" : "#dfdfdf"} />
                          <Star color={item.stars >= 4 ? "#ff884d" : "#dfdfdf"} fill={item.stars >=  4 ? "#ff884d" : "#dfdfdf"} />
                          <Star color={item.stars >= 5 ? "#ff884d" : "#dfdfdf"} fill={item.stars >=  5 ? "#ff884d" : "#dfdfdf"} />
                        </div>
  
                        <p>
                          {item.review}
                                      </p>
                        <h5>
                          --{item.user_name}
                                      </h5>
                          <CardTitle tag="h5" className="text-center ">{t('Reviewed on')} <img className="ml-2 d-inline-block" 
                            src={item.source === "Google Review" ||item.source ===  "Play Store Review" ? playStore : (item.source === "App Store Review" ? appStore : facebook) } /></CardTitle>
                      </div>
                    }) }
                  </Slider></Col>
           </Row>}
                  
</>
    )
  }
}
;

export default withTranslation()(ServiceReview);