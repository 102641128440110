import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import Alcohol from './Alcohol';
import Allergy from './Allergy';
import Documents from './Documents';
import History from './History';
import Profile from './Profile';
import Smoking from './Smoking';

class ProfileMain extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showComponent : "PROFILE"
        };
    }

    componentDidUpdate(prevProps, prevState) {
        
    }

    changeComponent = (comp) =>{
        this.setState({showComponent:comp})
    }

    render() {
        let { showComponent } = this.state;
        return (
        <>
            <div>
                <Container>
                    <Row>
                        <Col>
                        <div className="profile-list">
                        <ul> 
                            <li onClick={()=>this.changeComponent("PROFILE")}>Basic Profile</li>
                            <li onClick={()=>this.changeComponent("DOCUMENTS")}>Documents</li>
                            <li onClick={()=>this.changeComponent("ALLERGY")}>Allergies</li>
                            <li onClick={()=>this.changeComponent("SMOKING")}>Smoking Habit</li>
                            <li onClick={()=>this.changeComponent("ALCOHOL")}>Alcohol Habit</li>
                            <li onClick={()=>this.changeComponent("HISTORY")}>History</li>
                        </ul>
                        </div>
                        </Col>
                        <Col>
                        <div>
                            {showComponent === "PROFILE" && <Profile/>}
                            {showComponent === "DOCUMENTS" && <Documents/>}
                            {showComponent === "ALLERGY" && <Allergy/>}
                            {showComponent === "SMOKING" && <Smoking/>}
                            {showComponent === "ALCOHOL" && <Alcohol/>}
                            {showComponent === "HISTORY" && <History/>}
                        </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            
        </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(ProfileMain);