import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { Flag } from 'react-feather';
import Store from '../../../Store';
import { GetCareStateAction } from '../../../Actions/GetCareAction';
import { REACT_APP_API_URL } from '../../processENV';
import Loader from '../Loader';

class ChooseLanguage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            languages: [],
            active:localStorage.getItem("_language")
        };
    }
   
    componentDidMount() {
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey'),
            "app_prod_n_key":"APP_PROD-1"
        }
        this.setState({loading:true,active:localStorage.getItem("_language")})
        fetch(`${REACT_APP_API_URL}/doctor_language/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
                console.log("appoint date",res.data)
                if(res.status === "success"){
                    this.setState({loading:false, languages:res.data})
                }else{
                    this.setState({loading:false,error:'Some error has occured please try again'})
                }
            })

    }

    selectLanguage = (lang) =>{
        this.setState({active:lang}) 
        Store.dispatch(GetCareStateAction({
            getCareStep:2,
            getCareCurrentStep:2,
            date: this.props.getCareData.data?.date,
            time: this.props.getCareData.data?.time,
            language: lang
        }));
    }

    render() {
        let { languages, loading, active } = this.state;
        return (
            <>
                <div className="choose-language-outer getcare_sec  ">
                    {console.log("this.props.getCareData.data?.language",this.props.getCareData.data?.language)}
                    {loading && <Loader/>}
                    <div className="d-flex">
                        <Col md={6} className="mx-auto">
                            <div className="choose-language-inner">
                                <p>We speak your language.<br /> So feel free to choose one which is most comfortable for you.</p>
                                <div className="choose-language-card">
                                    <div className="languagesec">

                                        {
                                            languages.map((item, i) => {
                                                return (
                                                    <p className={`choose-language-item  ${active === item ? "active" : ""}`} key={i} onClick={() => this.selectLanguage(item)}> <Flag size={18} className={  active  === item ? 'visible' : 'invisible' } /> {item} </p>) 
                                            })
                                        }

                                    </div>   <hr></hr>

                                </div>
                            </div></Col>

                    </div>
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData,
        getCareData: state.GetCareData
    }
}

export default connect(mapStateToProps, null)(ChooseLanguage);
