import React from 'react';
// import { Container, Row, Col } from 'reactstrap';
  import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Progress
} from 'reactstrap';
import caremelogo from '../../../assets/images/logo.png'
import profile from '../../../assets/images/profilepic.png'
import { Settings,  LogOut, User, Send } from 'react-feather';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

 
class Topbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            };
    }
 
    render(props) {
        let {compeletionPercentage,t} = this.props
        let { isOpen } = this.state;
 
         return (
            <>
                <div className={"login-main customnav"}>
                    <Navbar expand="md">
                    <NavbarBrand><Link className="nav-link p-0"   to="/home"><img src={caremelogo} alt="" /></Link></NavbarBrand>
                    
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={isOpen} navbar>
                            {/* <Nav className="ml-auto" navbar>
                                 <NavbarText className="">{t('Complete your profile')} 
                               </NavbarText>
                                <NavItem className="nav-progress vertical-align-middle">
                                
                                <Link className="nav-link"  to="/profile">
                                 <div className="progress">
                                    
                                        <Progress bar color="success" value={compeletionPercentage} /></div></Link>
                             
                                </NavItem>                          
                                <NavbarText className="">{compeletionPercentage}%
                               </NavbarText>

                            </Nav>

                            <UncontrolledDropdown nav inNavbar>
 
                                <DropdownToggle nav  >
                                    <NavbarText>Welcome {localStorage.getItem('_firstName')}
                                <span className="userpic">
                                            <img src={profile} alt="" />

                                        </span>
                                    </NavbarText>
                                </DropdownToggle>
                             </UncontrolledDropdown> */}

                        </Collapse>
                    </Navbar>
                </div>

            </>
        )
    }

}

 
export default (withTranslation()(Topbar));
