import React, { useState } from 'react';

interface ReadMoreProps {
  text: string;
  maxWords: number;
  font?:string;
  background?:string;
}

const ReadMore: React.FC<ReadMoreProps> = ({ text, maxWords,font,background }) => {
  const words = text.split(' ');
  const [isExpanded, setIsExpanded] = useState(false);

  if (words.length <= maxWords) {
    return <p className='mb-0 pb-0'>{text}</p>;
  }

  const displayedText = isExpanded ? text : words.slice(0, maxWords).join(' ');

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className=''>
      <p className={font||'text-sm pb-0 mb-0'}>{displayedText}</p>
      <button style={{backgroundColor:background||'#ccc',border:'none',color:'#496cb3',textDecorationLine:'underline'}} className='m-0 p-0 border-none' onClick={toggleReadMore}>
        {isExpanded ? 'Read Less' : 'Read More'}
      </button>
    </div>
  );
};

export default ReadMore;
