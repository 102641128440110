
import React from 'react';
import {
    Container, Row, Col, Card,
    FormGroup,
    TabPane,
    Label,
    TabContent, CardBody,
    CardTitle, CardSubtitle, Modal, ModalBody, ModalHeader, Button, ModalFooter
} from 'reactstrap';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import classnames from 'classnames';
import Store from '../../../Store';
import { LoginStateAction } from '../../../Actions/LoginAction';
import Flatpickr from "react-flatpickr";
import Allergy from './Allergy';
import Smoking from './Smoking';
import Alcohol from './Alcohol';
import { REACT_APP_API_URL } from '../../processENV';
import Loader from '../Loader';
import Documents from './Documents';
import History from './History';
import Obstetric from './Obstetric';
import Menstrual from './Menstrual';
import OtpInput from 'react-otp-input';

import { withTranslation } from 'react-i18next';

const data =[
     
    {
      "code": "+7 840",
      "name": "Abkhazia"
    },
    {
      "code": "+93",
      "name": "Afghanistan"
    },
    {
      "code": "+355",
      "name": "Albania"
    },
    {
      "code": "+213",
      "name": "Algeria"
    },
    {
      "code": "+1 684",
      "name": "American Samoa"
    },
    {
      "code": "+376",
      "name": "Andorra"
    },
    {
      "code": "+244",
      "name": "Angola"
    },
    {
      "code": "+1 264",
      "name": "Anguilla"
    },
    {
      "code": "+1 268",
      "name": "Antigua and Barbuda"
    },
    {
      "code": "+54",
      "name": "Argentina"
    },
    {
      "code": "+374",
      "name": "Armenia"
    },
    {
      "code": "+297",
      "name": "Aruba"
    },
    {
      "code": "+247",
      "name": "Ascension"
    },
    {
      "code": "+61",
      "name": "Australia"
    },
    {
      "code": "+672",
      "name": "Australian External Territories"
    },
    {
      "code": "+43",
      "name": "Austria"
    },
    {
      "code": "+994",
      "name": "Azerbaijan"
    },
    {
      "code": "+1 242",
      "name": "Bahamas"
    },
    {
      "code": "+973",
      "name": "Bahrain"
    },
    {
      "code": "+880",
      "name": "Bangladesh"
    },
    {
      "code": "+1 246",
      "name": "Barbados"
    },
    {
      "code": "+1 268",
      "name": "Barbuda"
    },
    {
      "code": "+375",
      "name": "Belarus"
    },
    {
      "code": "+32",
      "name": "Belgium"
    },
    {
      "code": "+501",
      "name": "Belize"
    },
    {
      "code": "+229",
      "name": "Benin"
    },
    {
      "code": "+1 441",
      "name": "Bermuda"
    },
    {
      "code": "+975",
      "name": "Bhutan"
    },
    {
      "code": "+591",
      "name": "Bolivia"
    },
    {
      "code": "+387",
      "name": "Bosnia and Herzegovina"
    },
    {
      "code": "+267",
      "name": "Botswana"
    },
    {
      "code": "+55",
      "name": "Brazil"
    },
    {
      "code": "+246",
      "name": "British Indian Ocean Territory"
    },
    {
      "code": "+1 284",
      "name": "British Virgin Islands"
    },
    {
      "code": "+673",
      "name": "Brunei"
    },
    {
      "code": "+359",
      "name": "Bulgaria"
    },
    {
      "code": "+226",
      "name": "Burkina Faso"
    },
    {
      "code": "+257",
      "name": "Burundi"
    },
    {
      "code": "+855",
      "name": "Cambodia"
    },
    {
      "code": "+237",
      "name": "Cameroon"
    },
    {
      "code": "+1",
      "name": "Canada"
    },
    {
      "code": "+238",
      "name": "Cape Verde"
    },
    {
      "code": "+ 345",
      "name": "Cayman Islands"
    },
    {
      "code": "+236",
      "name": "Central African Republic"
    },
    {
      "code": "+235",
      "name": "Chad"
    },
    {
      "code": "+56",
      "name": "Chile"
    },
    {
      "code": "+86",
      "name": "China"
    },
    {
      "code": "+61",
      "name": "Christmas Island"
    },
    {
      "code": "+61",
      "name": "Cocos-Keeling Islands"
    },
    {
      "code": "+57",
      "name": "Colombia"
    },
    {
      "code": "+269",
      "name": "Comoros"
    },
    {
      "code": "+242",
      "name": "Congo"
    },
    {
      "code": "+243",
      "name": "Congo, Dem. Rep. of (Zaire)"
    },
    {
      "code": "+682",
      "name": "Cook Islands"
    },
    {
      "code": "+506",
      "name": "Costa Rica"
    },
    {
      "code": "+385",
      "name": "Croatia"
    },
    {
      "code": "+53",
      "name": "Cuba"
    },
    {
      "code": "+599",
      "name": "Curacao"
    },
    {
      "code": "+537",
      "name": "Cyprus"
    },
    {
      "code": "+420",
      "name": "Czech Republic"
    },
    {
      "code": "+45",
      "name": "Denmark"
    },
    {
      "code": "+246",
      "name": "Diego Garcia"
    },
    {
      "code": "+253",
      "name": "Djibouti"
    },
    {
      "code": "+1 767",
      "name": "Dominica"
    },
    {
      "code": "+1 809",
      "name": "Dominican Republic"
    },
    {
      "code": "+670",
      "name": "East Timor"
    },
    {
      "code": "+56",
      "name": "Easter Island"
    },
    {
      "code": "+593",
      "name": "Ecuador"
    },
    {
      "code": "+20",
      "name": "Egypt"
    },
    {
      "code": "+503",
      "name": "El Salvador"
    },
    {
      "code": "+240",
      "name": "Equatorial Guinea"
    },
    {
      "code": "+291",
      "name": "Eritrea"
    },
    {
      "code": "+372",
      "name": "Estonia"
    },
    {
      "code": "+251",
      "name": "Ethiopia"
    },
    {
      "code": "+500",
      "name": "Falkland Islands"
    },
    {
      "code": "+298",
      "name": "Faroe Islands"
    },
    {
      "code": "+679",
      "name": "Fiji"
    },
    {
      "code": "+358",
      "name": "Finland"
    },
    {
      "code": "+33",
      "name": "France"
    },
    {
      "code": "+596",
      "name": "French Antilles"
    },
    {
      "code": "+594",
      "name": "French Guiana"
    },
    {
      "code": "+689",
      "name": "French Polynesia"
    },
    {
      "code": "+241",
      "name": "Gabon"
    },
    {
      "code": "+220",
      "name": "Gambia"
    },
    {
      "code": "+995",
      "name": "Georgia"
    },
    {
      "code": "+49",
      "name": "Germany"
    },
    {
      "code": "+233",
      "name": "Ghana"
    },
    {
      "code": "+350",
      "name": "Gibraltar"
    },
    {
      "code": "+30",
      "name": "Greece"
    },
    {
      "code": "+299",
      "name": "Greenland"
    },
    {
      "code": "+1 473",
      "name": "Grenada"
    },
    {
      "code": "+590",
      "name": "Guadeloupe"
    },
    {
      "code": "+1 671",
      "name": "Guam"
    },
    {
      "code": "+502",
      "name": "Guatemala"
    },
    {
      "code": "+224",
      "name": "Guinea"
    },
    {
      "code": "+245",
      "name": "Guinea-Bissau"
    },
    {
      "code": "+595",
      "name": "Guyana"
    },
    {
      "code": "+509",
      "name": "Haiti"
    },
    {
      "code": "+504",
      "name": "Honduras"
    },
    {
      "code": "+852",
      "name": "Hong Kong SAR China"
    },
    {
      "code": "+36",
      "name": "Hungary"
    },
    {
      "code": "+354",
      "name": "Iceland"
    },
    {
      "code": "+91",
      "name": "India"
    },
    {
      "code": "+62",
      "name": "Indonesia"
    },
    {
      "code": "+98",
      "name": "Iran"
    },
    {
      "code": "+964",
      "name": "Iraq"
    },
    {
      "code": "+353",
      "name": "Ireland"
    },
    {
      "code": "+972",
      "name": "Israel"
    },
    {
      "code": "+39",
      "name": "Italy"
    },
    {
      "code": "+225",
      "name": "Ivory Coast"
    },
    {
      "code": "+1 876",
      "name": "Jamaica"
    },
    {
      "code": "+81",
      "name": "Japan"
    },
    {
      "code": "+962",
      "name": "Jordan"
    },
    {
      "code": "+7 7",
      "name": "Kazakhstan"
    },
    {
      "code": "+254",
      "name": "Kenya"
    },
    {
      "code": "+686",
      "name": "Kiribati"
    },
    {
      "code": "+965",
      "name": "Kuwait"
    },
    {
      "code": "+996",
      "name": "Kyrgyzstan"
    },
    {
      "code": "+856",
      "name": "Laos"
    },
    {
      "code": "+371",
      "name": "Latvia"
    },
    {
      "code": "+961",
      "name": "Lebanon"
    },
    {
      "code": "+266",
      "name": "Lesotho"
    },
    {
      "code": "+231",
      "name": "Liberia"
    },
    {
      "code": "+218",
      "name": "Libya"
    },
    {
      "code": "+423",
      "name": "Liechtenstein"
    },
    {
      "code": "+370",
      "name": "Lithuania"
    },
    {
      "code": "+352",
      "name": "Luxembourg"
    },
    {
      "code": "+853",
      "name": "Macau SAR China"
    },
    {
      "code": "+389",
      "name": "Macedonia"
    },
    {
      "code": "+261",
      "name": "Madagascar"
    },
    {
      "code": "+265",
      "name": "Malawi"
    },
    {
      "code": "+60",
      "name": "Malaysia"
    },
    {
      "code": "+960",
      "name": "Maldives"
    },
    {
      "code": "+223",
      "name": "Mali"
    },
    {
      "code": "+356",
      "name": "Malta"
    },
    {
      "code": "+692",
      "name": "Marshall Islands"
    },
    {
      "code": "+596",
      "name": "Martinique"
    },
    {
      "code": "+222",
      "name": "Mauritania"
    },
    {
      "code": "+230",
      "name": "Mauritius"
    },
    {
      "code": "+262",
      "name": "Mayotte"
    },
    {
      "code": "+52",
      "name": "Mexico"
    },
    {
      "code": "+691",
      "name": "Micronesia"
    },
    {
      "code": "+1 808",
      "name": "Midway Island"
    },
    {
      "code": "+373",
      "name": "Moldova"
    },
    {
      "code": "+377",
      "name": "Monaco"
    },
    {
      "code": "+976",
      "name": "Mongolia"
    },
    {
      "code": "+382",
      "name": "Montenegro"
    },
    {
      "code": "+1664",
      "name": "Montserrat"
    },
    {
      "code": "+212",
      "name": "Morocco"
    },
    {
      "code": "+95",
      "name": "Myanmar"
    },
    {
      "code": "+264",
      "name": "Namibia"
    },
    {
      "code": "+674",
      "name": "Nauru"
    },
    {
      "code": "+977",
      "name": "Nepal"
    },
    {
      "code": "+31",
      "name": "Netherlands"
    },
    {
      "code": "+599",
      "name": "Netherlands Antilles"
    },
    {
      "code": "+1 869",
      "name": "Nevis"
    },
    {
      "code": "+687",
      "name": "New Caledonia"
    },
    {
      "code": "+64",
      "name": "New Zealand"
    },
    {
      "code": "+505",
      "name": "Nicaragua"
    },
    {
      "code": "+227",
      "name": "Niger"
    },
    {
      "code": "+234",
      "name": "Nigeria"
    },
    {
      "code": "+683",
      "name": "Niue"
    },
    {
      "code": "+672",
      "name": "Norfolk Island"
    },
    {
      "code": "+850",
      "name": "North Korea"
    },
    {
      "code": "+1 670",
      "name": "Northern Mariana Islands"
    },
    {
      "code": "+47",
      "name": "Norway"
    },
    {
      "code": "+968",
      "name": "Oman"
    },
    {
      "code": "+92",
      "name": "Pakistan"
    },
    {
      "code": "+680",
      "name": "Palau"
    },
    {
      "code": "+970",
      "name": "Palestinian Territory"
    },
    {
      "code": "+507",
      "name": "Panama"
    },
    {
      "code": "+675",
      "name": "Papua New Guinea"
    },
    {
      "code": "+595",
      "name": "Paraguay"
    },
    {
      "code": "+51",
      "name": "Peru"
    },
    {
      "code": "+63",
      "name": "Philippines"
    },
    {
      "code": "+48",
      "name": "Poland"
    },
    {
      "code": "+351",
      "name": "Portugal"
    },
    {
      "code": "+1 787",
      "name": "Puerto Rico"
    },
    {
      "code": "+974",
      "name": "Qatar"
    },
    {
      "code": "+262",
      "name": "Reunion"
    },
    {
      "code": "+40",
      "name": "Romania"
    },
    {
      "code": "+7",
      "name": "Russia"
    },
    {
      "code": "+250",
      "name": "Rwanda"
    },
    {
      "code": "+685",
      "name": "Samoa"
    },
    {
      "code": "+378",
      "name": "San Marino"
    },
    {
      "code": "+966",
      "name": "Saudi Arabia"
    },
    {
      "code": "+221",
      "name": "Senegal"
    },
    {
      "code": "+381",
      "name": "Serbia"
    },
    {
      "code": "+248",
      "name": "Seychelles"
    },
    {
      "code": "+232",
      "name": "Sierra Leone"
    },
    {
      "code": "+65",
      "name": "Singapore"
    },
    {
      "code": "+421",
      "name": "Slovakia"
    },
    {
      "code": "+386",
      "name": "Slovenia"
    },
    {
      "code": "+677",
      "name": "Solomon Islands"
    },
    {
      "code": "+27",
      "name": "South Africa"
    },
    {
      "code": "+500",
      "name": "South Georgia and the South Sandwich Islands"
    },
    {
      "code": "+82",
      "name": "South Korea"
    },
    {
      "code": "+34",
      "name": "Spain"
    },
    {
      "code": "+94",
      "name": "Sri Lanka"
    },
    {
      "code": "+249",
      "name": "Sudan"
    },
    {
      "code": "+597",
      "name": "Suriname"
    },
    {
      "code": "+268",
      "name": "Swaziland"
    },
    {
      "code": "+46",
      "name": "Sweden"
    },
    {
      "code": "+41",
      "name": "Switzerland"
    },
    {
      "code": "+963",
      "name": "Syria"
    },
    {
      "code": "+886",
      "name": "Taiwan"
    },
    {
      "code": "+992",
      "name": "Tajikistan"
    },
    {
      "code": "+255",
      "name": "Tanzania"
    },
    {
      "code": "+66",
      "name": "Thailand"
    },
    {
      "code": "+670",
      "name": "Timor Leste"
    },
    {
      "code": "+228",
      "name": "Togo"
    },
    {
      "code": "+690",
      "name": "Tokelau"
    },
    {
      "code": "+676",
      "name": "Tonga"
    },
    {
      "code": "+1 868",
      "name": "Trinidad and Tobago"
    },
    {
      "code": "+216",
      "name": "Tunisia"
    },
    {
      "code": "+90",
      "name": "Turkey"
    },
    {
      "code": "+993",
      "name": "Turkmenistan"
    },
    {
      "code": "+1 649",
      "name": "Turks and Caicos Islands"
    },
    {
      "code": "+688",
      "name": "Tuvalu"
    },
    {
      "code": "+1 340",
      "name": "U.S. Virgin Islands"
    },
    {
      "code": "+256",
      "name": "Uganda"
    },
    {
      "code": "+380",
      "name": "Ukraine"
    },
    {
      "code": "+971",
      "name": "United Arab Emirates"
    },
    {
      "code": "+44",
      "name": "United Kingdom"
    },
    {
      "code": "+1",
      "name": "United States"
    },
    {
      "code": "+598",
      "name": "Uruguay"
    },
    {
      "code": "+998",
      "name": "Uzbekistan"
    },
    {
      "code": "+678",
      "name": "Vanuatu"
    },
    {
      "code": "+58",
      "name": "Venezuela"
    },
    {
      "code": "+84",
      "name": "Vietnam"
    },
    {
      "code": "+1 808",
      "name": "Wake Island"
    },
    {
      "code": "+681",
      "name": "Wallis and Futuna"
    },
    {
      "code": "+967",
      "name": "Yemen"
    },
    {
      "code": "+260",
      "name": "Zambia"
    },
    {
      "code": "+255",
      "name": "Zanzibar"
    },
    {
      "code": "+263",
      "name": "Zimbabwe"
    }
  
]
class AddProfile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            activeTab: '1',
            firstName: '',
            lastName: '',
            dob: '',
            age: '',
            gender: '',
            relationship: '',
            email: '',
            countryCode: '+91',
            mobileNumber: '',
            mobileError: '',
            error: '',
            loading: false,
            mainUser: [],
            familyUser: [],
            isOpen: false,
            otp: '',
            otpDetails: '',
            otpError: false,
            errorMessage: '',
            address:'',
            city:'',
            state:'',
            pincode:''
        };
    }

    verticaltoggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    handleChange = otp => this.setState({ otp });


    componentDidMount() {
    }

    onInputChange = (e) => {
         this.setState({  countryCode: e.target.value }
        )
        
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.loginData !== this.props.loginData) {
            this.setState({
                step: this.props.loginData.data?.step,
                mobileNumber: this.props.loginData.data?.mobileNumber,
                countryCode: this.props.loginData.data?.countryCode
            })
        }
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    };

    profileInputHandler = (e) => {

        let id = e.target.name;
        if (e.target.name === "mobileNumber" && e.target.value.length === 10 && e.target.value === localStorage.getItem('_mobileNumber')) {
            this.setState({ mobileError: "Please use different mobile number", mobileNumber: '' })
        } else {
            this.setState({
                [id]: e.target.value,
                mobileError: ''
            })
        }

    }

    profileSubmitHandler = () => {
        let { firstName, lastName, dob, gender, relationship, mobileNumber, otp, age } = this.state;

        if (age >= 18) {
            if (!firstName || !lastName || !dob || !gender || !relationship || !mobileNumber) {
                console.log("if 1", this.state)
                this.setState({ error: 'please fill all the fields', error: "" })
            } else {
                this.setState({ isOpen: !this.state.isOpen }, () => {
                    this.sendOtp()
                })
            }
        } else {
            if (!firstName || !lastName || !dob || !gender || !relationship) {
                console.log("if 2", this.state)
                this.setState({ error: 'please fill all the fields', error: "" })
            } else {
                this.submitFamilyProfile2()

            }
        }
    }


    submitFamilyProfile = () => {
        let { firstName, lastName, gender, relationship, mobileNumber, otp, otpDetails, age, address, city, state, pincode } = this.state;

        var today = new Date();
        let dd = (today.getDate.toString().length === 1) ? ('0' + today.getDate()) : today.getDate();
        let mm = (today.getMonth().toString().length === 1) ? ('0' + (today.getMonth() + 1)) : (today.getMonth() + 1);
        let yy = (today.getFullYear().toString().length === 1) ? ('0' + today.getFullYear()) : today.getFullYear();
        let date = yy + '-' + mm + '-' + dd;

        let data = {
            "patient_n_key": localStorage.getItem('_patientKey'),
            "appointment_n_key": localStorage.getItem('_appointmentKey'),
            "member_first_name": firstName,
            "member_last_name": lastName,
            "member_relationship": relationship,
            "member_age": age,
            "gender": gender,
            "assessment_date": date,
            "phone_number": mobileNumber,
            "sendotp": otpDetails,
            "typedotp": otp,
            'address_line_one':address,
            'city':city,
            'state':state,
            'pincode':pincode

        }

        this.setState({ loading: true, error: '', mobileError: '' })
        console.log('family profile data', data)
        fetch(`${REACT_APP_API_URL}/familyhistory/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
        }).then(response => response.json())
            .then(async (res) => {
                if (res.status === "success") {
                  window.location.replace('/profile')
                    this.setState({
                        loading: false,
                        isOpen: '',
                        firstName: '',
                        lastName: '',
                        dob: '',
                        age: '',
                        otp: '',
                        gender: '',
                        relationship: '',
                        address:'',
                      city:'',
                      state:'',
                      pincode:'',
                        mobileNumber: '',
                        email: '',
                        error: ''
                    })
                } else if (res.status === "failed") {
                    this.setState({ otp: '', loading: false, isOpen: false, error: res.data+ ' '+'Please use different mobile number' })
                } else {
                    this.setState({ loading: false, error: 'Some error has occured please try again' })
                }
            })
    }


    submitFamilyProfile2 = () => {
      let { firstName, lastName, gender, relationship, age } = this.state;

      var today = new Date();
      let dd = (today.getDate.toString().length === 1) ? ('0' + today.getDate()) : today.getDate();
      let mm = (today.getMonth().toString().length === 1) ? ('0' + (today.getMonth() + 1)) : (today.getMonth() + 1);
      let yy = (today.getFullYear().toString().length === 1) ? ('0' + today.getFullYear()) : today.getFullYear();
      let date = yy + '-' + mm + '-' + dd;

      let data = {
          "patient_n_key": localStorage.getItem('_patientKey'),
          "appointment_n_key": localStorage.getItem('_appointmentKey'),
          "member_first_name": firstName,
          "member_last_name": lastName,
          "member_relationship": relationship,
          "member_age": age,
          "member_gender": gender,
          "assessment_date": date,
          "phone_number": null,
      }

      this.setState({ loading: true, error: '', mobileError: '' })
      console.log('family profile data', data)
      fetch(`${REACT_APP_API_URL}/familyhistory/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
      }).then(response => response.json())
          .then(async (res) => {
              if (res.status === "success") {
                window.location.replace('/profile')
                  this.setState({
                      loading: false,
                      isOpen: '',
                      firstName: '',
                      lastName: '',
                      dob: '',
                      age: '',
                      otp: '',
                      gender: '',
                      relationship: '',
                      mobileNumber: '',
                      address:'',
                      city:'',
                      state:'',
                      pincode:'',
                      email: '',
                      error: ''
                  })
              } else if (res.status === "failed") {
                  this.setState({ otp: '', loading: false, isOpen: false, error: res.data+ ' '+'Please use different mobile number' })
              } else {
                  this.setState({ loading: false, error: 'Some error has occured please try again' })
              }
          })
  }


    getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        console.log("ageee", age)
        return age;
    }

    handleDateChange = ddd => {

        if (ddd === null || ddd === "") {
            this.setState({
                dob: null
            });
        } else {
            let date = new Date(ddd);

            let dd = (date.getDate.toString().length === 1) ? ('0' + date.getDate()) : date.getDate();
            let mm = (date.getMonth().toString().length === 1) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1);
            let yy = (date.getFullYear().toString().length === 1) ? ('0' + date.getFullYear()) : date.getFullYear();

            let fromDate = yy + "-" + mm + "-" + dd;
            this.setState({
                dob: fromDate,
                age: this.getAge(ddd)
            });

        }
    };

    sendOtp = () => {
        let { countryCode, mobileNumber } = this.state;
        let data = {
            "phone_number": mobileNumber,
            "dial_code": countryCode
        }

        this.setState({ mobileError: false, ccError: false, loading: true })
        fetch(`${REACT_APP_API_URL}/sendotp/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
        }).then(response => response.json())
            .then(async (res) => {
                if (res.status === "success") {
                    console.log("family mobile number reponse", res)
                    this.setState({ loading: false, otpDetails: res.data.Details })
                } else {
                    this.setState({ loading: false })
                    console.log("mobile number error", res)
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.log("mobile number reponse error", err)
            })
    }

    verifyOtp = (e) => {

        if (e) {
            e.preventDefault();
        }

        let { otp, otpDetails, mobileNumber, countryCode, } = this.state;
        let data = {
            "phone_number": mobileNumber,
            "sendotp": otpDetails,
            "typedotp": otp,
            "dial_code": countryCode
        }

        console.log("otppp", !otp || otp.toString().length < 6)

        if (!otp || otp.toString().length < 6) {
            this.setState({ otpError: true, errorMessage: 'Please enter valid otp' })
        } else {
            this.setState({ otpError: false, loading: true, errorMessage: '' })
            fetch(`${REACT_APP_API_URL}/register_patient/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
                },
                body: JSON.stringify(data),
            }).then(response => response.json())
                .then(async (res) => {
                    this.setState({ loading: false })
                    console.log("OTP reponse", res)
                    if (res.status === "old_patient") {
                        this.submitFamilyProfile()
                    }else{
                      this.setState({ otpError: true, errorMessage: 'Please enter valid otp' })
                    }
                })
                .catch(err => {
                    this.setState({ loading: false })
                    console.log("mobile number reponse error", err)
                })
        }
    }
    render() {
        let { firstName, lastName, dob, gender, relationship, error, loading, email, countryCode, mobileNumber, mobileError, isOpen, errorMessage, otpError, age, address, city, state, pincode  } = this.state;
        let { t } = this.props;
        return (
            <>
                <div className={"mainsec"}>
                    <Container>
                        <Row className="profilecard">
                            {loading && <Loader />}

                            <Col xs="6" sm="9" md="9" xs={12} className="mb-2">
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <Row>
                                            {/* <Col md={4} xs={12} className="mb-2">
                                                <imageCroppie/>
                                            </Col> */}
                                            <Col md={8} xs={12} className="mb-2">
                                                <Card >
                                                    <CardSubtitle tag="h5" className="mt-2  text-center text-muted">{t('Add Profile')}</CardSubtitle>
                                                    <CardBody className="col-md-8 mx-auto">
                                                        <Formik
                                                            initialValues={{
                                                                name: '',
                                                                email: '',
                                                            }}
                                                        // validationSchema={BasicProfile}
                                                        >
                                                            {({ errors, touched }) => (
                                                                <Form>
                                                                    <Row >
                                                                        <FormGroup className="col-md-6 pl-md-0">
                                                                            <label>{t('First Name')}</label>
                                                                            <Field name="firstName" className="form-control" placeholder={t('First Name')} value={firstName} onChange={(e) => this.profileInputHandler(e)} />
                                                                            {errors.name && touched.name ? (
                                                                                <ErrorMessage name="firstName" />

                                                                            ) : null}
                                                                        </FormGroup>

                                                                        <FormGroup className="col-md-6 pr-md-0">
                                                                            <label>{t('Last Name')}</label>

                                                                            <Field name="lastName" className="form-control" placeholder={t('Last Name')} value={lastName} onChange={(e) => this.profileInputHandler(e)} />
                                                                            {errors.email && touched.email ? (
                                                                                <ErrorMessage name="lastName" />
                                                                            ) : null}

                                                                        </FormGroup>
                                                                        <FormGroup className="w-100 col-md-12 px-md-0">
                                                                            <label>{t('Date of Birth')}</label>
                                                                            <Flatpickr
                                                                                className="form-control w-100"
                                                                                placeholder={t('Date of Birth')}
                                                                                selected={dob}
                                                                                value={dob}
                                                                                options={{ maxDate: new Date() }}
                                                                                onChange={date => this.handleDateChange(date)}
                                                                            />
                                                                        </FormGroup>
                                                                        <FormGroup className="pl-md-0 w-100">
                                                                            <label>{t('Relation Type')}</label>
                                                                            <Field as="select" name="relationship" id="relationship" value={relationship} className="form-control" onChange={(e) => this.profileInputHandler(e)}>
                                                                                <option value="">{t('Choose')}</option>
                                                                                <option value="Father">{t('Father')}</option>
                                                                                <option value="Mother">{t('Mother')}</option>
                                                                                <option value="Husband">{t('Husband')}</option>
                                                                                <option value="Wife">{t('Wife')}</option>
                                                                                <option value="Daughter">{t('Daughter')}</option>
                                                                                <option value="Brother">{t('Brother')}</option>
                                                                                <option value="Sister">{t('Sister')}</option>
                                                                                <option value="Grand Father">{t('Grandfather')}</option>
                                                                                <option value="Grand Mother">{t('Grandmother')}</option>
                                                                                <option value="Friend">{t('Friend')}</option>
                                                                                <option value="Cousin">{t('Cousin')}</option>
                                                                                <option value="Father-in-law">{t('Father-in-law')}</option>
                                                                                <option value="Mother-in-law">{t('Mother-in-law')}</option>
                                                                                <option value="Daughter-in-law">{t('Daughter-in-law')}</option>
                                                                                <option value="Brother-in-law">{t('Brother-in-law')}</option>
                                                                                <option value="Sister-in-law">{t('Sister-in-law')}</option>
                                                                                <option value="Nephew">{t('Nephew')}</option>
                                                                                <option value="Niece">{t('Niece')}</option>

                                                                                <option value="Paternal Uncle">{t('Paternal Uncle')}</option>
                                                                                <option value="Paternal Aunt">{t('Paternal Aunt')}</option>
                                                                                <option value="Maternal Aunt">{t('Maternal Aunt')}</option>
                                                                                <option value="Twin Brother">{t('Twin Brother')}</option>
                                                                                <option value="Twin Sister">{t('Twin Sister')}</option>
                                                                                <option value="StepFather">{t('StepFather')}</option>
                                                                                <option value="StepMother">{t('StepMother')}</option>
                                                                                <option value="StepBrother">{t('StepBrother')}</option>
                                                                                <option value="Grand daughter">{t('Grand daughter')}</option>
                                                                                <option value="partner">{t('partner')}</option>

                                                                                <option value="Other">{t('Other')}</option>
                                                                            </Field>
                                                                            {errors.para && touched.para ? (
                                                                                <ErrorMessage name="para" />
                                                                            ) : null}
                                                                        </FormGroup>
                                                                        <FormGroup className="w-100 col-md-12 px-md-0 m-0">
                                                                            <label>{t('Sex')}</label>

                                                                        </FormGroup>
                                                                        <div className="d-flex col-md-12 px-md-0">

                                                                            <FormGroup className="mr-3" >
                                                                                <Label check>
                                                                                    <input type="radio" name="gender" checked={gender === "Male" ? true : false} value={"Male"} onChange={(e) => this.profileInputHandler(e)} />{' '}
                                                                       {t('MALE')}</Label>
                                                                            </FormGroup>
                                                                            <FormGroup className="mr-3" >
                                                                                <Label check>
                                                                                    <input type="radio" name="gender" checked={gender === "Female" ? true : false} value={"Female"} onChange={(e) => this.profileInputHandler(e)} />{' '}
                                                                       {t('FEMALE')}</Label>
                                                                            </FormGroup>
                                                                            <FormGroup className="mr-3" >
                                                                                <Label check>
                                                                                    <input type="radio" name="gender" checked={gender === "Transgender" ? true : false} value={"Transgender"} onChange={(e) => this.profileInputHandler(e)} />{' '}
                                                                       {t('TRANSGENDER')}</Label>
                                                                            </FormGroup>
                                                                            {/* <FormGroup className="mr-3" >
                                                                                <Label check>
                                                                                    <input type="radio" name="gender" checked={gender === "Transgender" ? true : false } value={"Transgender"} onChange={(e)=>this.profileInputHandler(e)}/>{' '}
                                                                       Transgender</Label>
                                                                            </FormGroup>  */}
                                                                        </div>

                                                                        {age >= 18 && <div>
                                                                            <FormGroup className="w-100 col-md-12 px-md-0">
                                                                                <label>{t('Email Address')}</label>

                                                                                <Field name="email" className="form-control" placeholder={t('Email Address')} value={email} onChange={(e) => this.profileInputHandler(e)} />
                                                                                {errors.email && touched.email ? (
                                                                                    <ErrorMessage name="email" />
                                                                                ) : null}

                                                                            </FormGroup>

                                                                            <FormGroup className="w-100 col-md-12 px-md-0">
                                                                                <label className="W-100">{t('Phone Number')}</label>
                                                                                <select name="countryCode"  className={`form-control col-sm-3 col-3 px-0 d-inline mr-2 `} value={countryCode} onChange={this.onInputChange} placeholder="" >
                                                                                    {data && data.map((item, i) => {
                                                                                        return <option value={item.code}> {item.code}</option>

                                                                                    })}
                                                                                </select>
                                                                                {/* <input name="countryCode" className={`form-control col-sm-2 col-3 pl-1 d-inline mr-2 `} value={countryCode} placeholder="" /> */}
                                                                                <div className="d-inline input_Icon">
                                                                                    <input name="mobileNumber" className={`form-control col-sm-8 col-8 d-inline `} value={mobileNumber} placeholder={t('Phone Number')} onChange={(e) => this.profileInputHandler(e)} />
                                                                                    {mobileError && <p className="errorMesage mt-3">{mobileError}</p>}
                                                                                </div>

                                                                            </FormGroup>
                                                                        </div>}

                                                                        <FormGroup className="col-md-6 pl-md-0">
                                                                                <label>{t('Address')}</label>
                                                                                <Field name="address" className="form-control" placeholder="Address" value={address} onChange={(e) => this.profileInputHandler(e)} />
                                                                                {errors.name && touched.name ? (
                                                                                    <ErrorMessage name="Address" />

                                                                                ) : null}
                                                                            </FormGroup>
                                                                            <FormGroup className="col-md-6 pl-md-0">
                                                                                <label>{t('City')}</label>
                                                                                <Field name="city" className="form-control" placeholder="City" value={city} onChange={(e) => this.profileInputHandler(e)} />
                                                                                {errors.name && touched.name ? (
                                                                                    <ErrorMessage name="City" />

                                                                                ) : null}
                                                                            </FormGroup>
                                                                            <FormGroup className="col-md-6 pl-md-0">
                                                                                <label>{t('State')}</label>
                                                                                <Field name="state" className="form-control" placeholder="State" value={state} onChange={(e) => this.profileInputHandler(e)} />
                                                                                {errors.name && touched.name ? (
                                                                                    <ErrorMessage name="State" />

                                                                                ) : null}
                                                                            </FormGroup>
                                                                            <FormGroup className="col-md-6 pl-md-0">
                                                                                <label>{t('PinCode')}</label>
                                                                                <Field name="pincode" className="form-control" placeholder="PinCode" value={pincode} onChange={(e) => this.profileInputHandler(e)} />
                                                                                {errors.name && touched.name ? (
                                                                                    <ErrorMessage name="PinCode" />

                                                                                ) : null}
                                                                            </FormGroup>

                                                                    </Row>
                                                                    <div className="text-center">
                                                                        <button type="submit" className="btn btn-primary mx-auto" onClick={this.profileSubmitHandler}>{t('UPDATE')}</button>
                                                                        {error && <p className="errorMesage mt-3">{error}</p>}
                                                                    </div>
                                                                </Form>
                                                            )}
                                                        </Formik>                                </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Allergy />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <Documents />
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <Smoking />
                                    </TabPane>
                                    <TabPane tabId="5">
                                        <Alcohol />
                                    </TabPane>
                                    <TabPane tabId="6">
                                        <History />
                                    </TabPane>
                                    <TabPane tabId="7">
                                        <Menstrual />
                                    </TabPane>
                                    <TabPane tabId="8">
                                        <Obstetric />
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </Container>
                </div >
                <Modal centered isOpen={isOpen} toggle={this.toggle} size="lg" className="login-main" >
                    <ModalHeader toggle={this.toggle}> Verification</ModalHeader>
                    <ModalBody>
                        <div className="choose-language-outer">
                            {loading && <Loader />}
                            <div className="d-flex">
                                <Col md={6} className="mx-auto">
                                    <div className="choose-language-inner text-center">
                                        <div className="otp_sec">
                                            <p>{t('Check your SMS!')}</p>
                                            <h5 className="asterisk">
                                                <span>*</span>
                                                <span>*</span>
                                                <span>*</span>
                                                <span>*</span>
                                                <span>*</span>
                                                <span>*</span>
                                            </h5>
                                            <h6>{t('We have sent a 6 digit code to your number for verification')}
                                                
                                </h6>
                                            <form onSubmit={this.verifyOtp}>
                                                <div className="mx-auto col-md-10 reactinput mt-4 pl-5" >
                                                    <OtpInput
                                                        value={this.state.otp}
                                                        onChange={this.handleChange}
                                                        numInputs={6}
                                                        separator={<span> </span>}
                                                    /></div>
                                                {otpError && <p className="errorMesage mt-3">{errorMessage}</p>}
                                                <button type="submit" className="btn btn-primary w-100  mt-5"> {t('Verify')}  </button>
                                            </form>
                                            {/* <h6 className="mt-md-4 mt-3">
                                        Haven't received the code yet?
                                     </h6>
                                    <div className='resend-link mt-md-4  mt-3'>
                                        <a href="#">Receive code in a call</a>
                                        <span> | </span>
                                        <button onClick={this.resendOtp}>Resend the code</button>

                                    </div>  */}
                                        </div>
                                    </div></Col>

                            </div>
                        </div>
                    </ModalBody>
                    {/* <ModalFooter>
                    <Button type="submit" color="primary">Submit</Button>{' '}
                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter> */}
                </Modal>

            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(withTranslation()(AddProfile));
