import moment from 'moment';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactShowMoreText from 'react-show-more-text';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CDN_URL, REACT_APP_API_URL, RET_URL, getCurrencySymbol } from '../../processENV';
import Loader from '../Loader';
const publicIp = require("react-public-ip");

class SelectSession extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            sessions:[],
            coupons:null,
            couponError:null,
            couponApplied:false,
            selectedPayment:'',
            productDetails:null,
            product:'',
            orderId:'',
            p_name:'',
            p_email:'',
            p_phone:'',
            p_dial_code:'',
            follow_addon:false,
            confim_modal:false,
            cancel_modal:false,
            d_url:'',
            exc_goal:'',
            edit_coupon:false,
            apply_copon:[0,0,0],
            coupon_error:[false,false,false],
            coup_valid:[false,false,false],
            app_prod_grp_key:'',
            addonamount:'',
        };
    }

  componentDidMount(){
        this.getName();
        this.getFollow()
        this.getNewProd()
  }
  getName= async()=>{
      let data = {"patient_n_key":localStorage._patientKey}
      fetch(REACT_APP_API_URL+'/patientedit_app/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
        },
        body: JSON.stringify(data),
        }).then(response => response.json())
        .then(async (res)=>{
        console.log('chat_det',res)
            if(res.status === "success"){
              this.setState({p_name:res.data.first_name,p_dial_code:res.data.dial_code, p_phone:res.data.phone_number,p_email:res.data.email})
            }else{
              console.log('meeting id',res.data)
            }
        })
  }
  getFollow=()=>{
       let id=localStorage.getItem('_i')
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        
        fetch(`${REACT_APP_API_URL}/upcoming_recommended/`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
            console.log('selected service',res)
              if(res.status === "success"){
                console.log(res)
                this.setState({
                    productDetails:res?.data[0], 
                    sessions:res?.data[0]?.subscription_plan,
                     product:res?.data[0],
                     d_url:res?.data[0]?.doctor_photo,
                     exc_goal:res.exercise_goal,
                     app_prod_grp_key:res?.data[0]?.app_prod_grp_key
                    })
               localStorage.setItem('_amt',JSON.stringify(res.data[0]))
              }else{
                console.log('selected service',res.data)
              }
          })
  } 
  getNewProd=async()=>{
      let data={
          "ip":await publicIp.v4() || "",
           "patient_n_key": localStorage.getItem('_patientKey')
      }
      fetch(`${REACT_APP_API_URL}/products_groups/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
        },
        body: JSON.stringify(data),
        }).then(response => response.json())
        .then(async (res)=>{
          console.log('new prod',res.data)
            if(res.status === "success"){
              this.setState({addonamount:res.addon})
            }else{
              console.log('new prod',res.data)
            }
        })
  }
  payInit=async(amount,item,id)=>{
      const data= {
  "patient_n_key":localStorage.getItem('_patientKey'),
  "app_payment_n_key": this.state.productDetails.app_payment_n_key,
  "total_session":item.no_of_session,
  "payment_status":"Payment Initiated",
  "followup_amount":amount,
  'addon':this.state.follow_addon,
  "followup_payment_details":id,
  "followup_product_amount":item.amount,
  "followup_discount":item.discount,
  "followup_discount_amount":item.discount_amount,
  "url":"",
  'followup_doctor':this.state.productDetails.doc_app_id ,
  }
  console.log(data)
  fetch(`${REACT_APP_API_URL}/followup_payment/`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
    },
    body: JSON.stringify(data),
    }).then(response => response.json())
    .then(async (res)=>{
      console.log(res)
    })
    .catch((err)=>console.log(err))
  }
  submitPayment = (amount,sessions,item) =>{
        console.log('call')
        this.setState({selectedPayment:amount.toString()})
                //   localStorage.setItem('app_payment_n_key',productDetails.app_payment_n_key)
                  let therapyDetails = {
                    "app_payment_n_key": this.state.productDetails.app_payment_n_key,
                    "journey_id": this.state.productDetails.journey_id,
                    "total_session":sessions,
                    'doc_app_id':this.state.productDetails.doc_app_id,
                    "app_prod_n_key": this.state.productDetails.app_prod_n_key,
                    "product_amount": item.amount,
                    "discount": item.discount,
                    "amount":amount.toString(),
                    "discount_amount": item.discount_amount,
                    "product": this.state.productDetails.title,
                    'app_prod_grp_key':this.state.productDetails.app_prod_grp_key,
                    "follow_adon":this.state.follow_addon,
                    "promo_code":item.coupon,
                }
                localStorage.setItem("therapy_details",JSON.stringify(therapyDetails))
                  this.getPaymentLink(amount,item)
  }
  getPaymentLink = (amount,item) =>{
        let orderId = Math.random().toFixed(5).split('.')[1]
        const{p_email,p_name,p_phone,p_dial_code}=this.state;
        this.setState({orderId:orderId})
        console.log(orderId)
        let data = {
        "orderAmount" : amount.toString(), 
        "customerName" : p_name, 
        "customerPhone" : p_phone, 
        "customerEmail" : p_email, 
        "returnUrl" : RET_URL ,
        "currency":item.currency,
        dial_code:p_dial_code,
        order_note:'FollowUp'
    }
    console.log(data)
        fetch(`${REACT_APP_API_URL}/create_paymentorder/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
            console.log(res)
              if(res.status === "success"){
                  console.log('payment link', res)
                  localStorage.setItem('orderId',res.orderId)
                  localStorage.setItem('purchase_type',"APPOINTMENT")
                  this.payInit(amount,item,res.orderId)
                  const cf = new global.Cashfree(res.payment_session_id)
                  cf.redirect()
              }else{
                  this.setState({loading:false})
              }
          })
          .catch(err => console.log('error',err))
  }

  render() {
        const { loading, sessions,addonamount, couponApplied,p_name,d_url,exc_goal,coupon_error,coup_valid, couponError,product, coupons,follow_addon,confim_modal,cancel_modal} = this.state;
        let { t } = this.props;
        return (
            <>
            <Modal isOpen={confim_modal} >
                <ModalHeader style={{textAlign:'center'}} >Take care of yourself wherever you go</ModalHeader>
                <ModalBody>
               <img src={`${CDN_URL}/newapp/CAREMEPROMISE%20copy.png`} style={{width:'50px',height:'50px',float:'right'}}/>
               <h5 style={{textAlign:'center',textDecoration:'underline'}}>Chat with your therapist anytime</h5> 
               <p><b>Note: </b>This is not a live conversation; your therapist will reply in 24 hours or less, and we promise to send one response every day.</p>
                </ModalBody>
                <ModalFooter>
                <Button color="secondary" onClick={()=>this.setState({confim_modal:false})}>Cancel</Button>
                <Button color="primary" onClick={()=>this.setState({follow_addon:true,confim_modal:false})}>Confirm</Button>{' '}
                </ModalFooter>
            </Modal>
            <Modal isOpen={cancel_modal} >
                <ModalHeader style={{textAlign:'center'}} >Take care of yourself wherever you go</ModalHeader>
                <ModalBody>
               <p>We meet you where you're at and help get you where you want to be by adding chat support you will be able to connect with your therapist anytime.</p> 
               <h5 style={{textAlign:'center'}}>Are you sure you want to remove it?</h5>
                </ModalBody>
                <ModalFooter>
                <Button color="secondary" onClick={()=>this.setState({follow_addon:false,cancel_modal:false})}>Yes</Button>{' '}
                <Button color="primary" onClick={()=>this.setState({cancel_modal:false})}>No</Button>
                </ModalFooter>
            </Modal>
            <div className="therapyhead p-0 col-md-8 mx-auto" >
                {loading && <Loader/>}
                    {/* <h2>{t("Choose how you want to pay :")}</h2> */}
                    
                <p className="text-center h4 mt-5 pt-5 header_font_ _text_color">    Your care plan is ready! 😊 </p>
                <p className='therapy_see header_font_ _text_color'>Looking forward to see you on your next session at {moment(product&&product.next_session).format('DD-MM-YYYY')}</p>
                <div className='therapy_card_treatmentplan shadow-sm rounded text_p_ mx-auto col-md-8'>
                    <h4 className='header_font_'>Treatment Plan</h4>
                    <h5 className='header_font_ _text_color font-weight-bold text-center mx-auto'>Number of personalized therapy sessions recommended: {product&&product.total_session}</h5>
                   {exc_goal&& exc_goal.exercise.length!==0?
                    <div className='therapy_exc'>
                    <p className='header_font_ _text_color h5' style={{fontWeight:'bold',marginLeft:'20px'}}>Exercise:</p>
                    <button className='btn p-1' onClick={()=>window.location.href='/exercise'} >View all</button>
                    <div className='sh_more' style={{marginLeft:'30px'}}>
                    <ReactShowMoreText
                                lines={2}
                                more="Show more"
                                less="Show less"
                                expanded={false}
                                truncatedEndingComponent={"... "}
                                >
                    {exc_goal.exercise.map((i,ind)=>{
                        return(
                            <div  className='inside_therapy_exc'>
                            <p>{ind+1}{') '}{i}</p> <br/>
                            </div>
                        )
                    })}
                     </ReactShowMoreText>
                     </div>
                    </div>:null}
                    {exc_goal&&exc_goal.goals.length!==0?
                    <div className='therapy_goal'>
                        <p className='header_font_ _text_color h5' style={{fontWeight:'bold',marginLeft:'20px'}}>Goals:</p>
                        <button  className='btn p-1' onClick={()=>window.location.href='/goals'} >View all</button>
                        <div className='sh_more' style={{marginLeft:'30px'}}>
                    <ReactShowMoreText
                                lines={2}
                                more="Show more"
                                less="Show less"
                                expanded={false}
                                truncatedEndingComponent={"... "}
                                >
                    {exc_goal.goals.map((i,ind)=>{
                        return(
                            <div className='inside_therapy_exc'>
                              {ind+1}{') '}<p>{i}</p> <br/>
                            </div>
                        )
                    })}
                     </ReactShowMoreText>
                     </div>
                    </div>:null}
                </div>
                <h4 className=' h3 py-md-5 py-3 font-weight-bold header_font_ _text_color'>Helping you to get better and stay better</h4>
               {/* <Row>
               <div className='suport_chat'>
                    <button onClick={()=>{this.state.follow_addon?this.setState({cancel_modal:true}):this.setState({confim_modal:true})}} >{this.state.follow_addon?'Remove chat support':'Add chat support'}</button>
                </div>
                </Row> */}
            {sessions && sessions.map((item,i) =>{
                      return (
                      <div key={i} style={{backgroundColor:'#fff'}} className="col-md-7 shadow my-3 px-3 py-2 rounded mx-auto">
                       <div className='d-flex justify-content-between align-items-center px-2 py-4'>
                        <div style={{flex:.7}} className='text-left mx-auto mt-2 pl-3'>
                        <p className='text-left h3 header_font_'>Pay for {item.no_of_session} Session</p>
                        <p className='text-left h5 text_p_'>{
                          i===0?`Get started with an in-depth exploration of your goals and concerns. We'll discuss your needs and develop a personalized plan to help you achieve your objectives`:i===2?`Assessment and Goal Setting - We'll begin by assessing your current situation and identifying your strengths and areas for growth. Together, we'll establish clear and achievable goals to work towards.`:`Exploration and Goal Setting - Let's dive deep into your aspirations and challenges. We'll explore your values, beliefs, and aspirations, and set meaningful goals that align with your vision for the future.`
                        }</p>                            
                        </div>
                        <div style={{flex:.3}} className='mx-auto text-center pt-4'>
                            <p style={{textDecorationLine:'line-through'}} className="header_font_ h5 my-0">{getCurrencySymbol(item?.currency)} {parseInt(item.product_amount)}</p>
                            <p className="text_p_ header_font_ h5 my-2">Save  {getCurrencySymbol(item?.currency)} {item.discount_amount}</p>
                            <p className="header_font_ h3 my-0"> {getCurrencySymbol(item?.currency)} {parseInt(item.amount)}</p>
                        </div>
                        </div>
                        <button style={{backgroundColor:'#ddefe5',color:'#215352',borderRadius:'0px',}} className="btn btn-block font-weight-bold" onClick={()=>this.submitPayment(parseInt(item.amount),item.no_of_session,item)}>SELECT</button>
                    </div> 
                    )})} 
       
            
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        getCareData: state.GetCareData,
        TherapyJourneyData:state.TherapyJourneyData
    }
}

export default connect(mapStateToProps, null)(withTranslation()(SelectSession));
