import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CDN_URL, REACT_APP_BU_API_URL } from '../processENV'
import Caremeloader from './Caremeloader'
import {
    ComposedChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    Line,
    Area,
    Label,
    LabelList,
  } from 'recharts';
  
  
  const CustomHorizontalBars = ({ data }) => {
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          const dataItem = payload[0].payload;
          return (
            <div className="custom-tooltip">
              <p>{label}</p>
              <p>Needs Attention: {dataItem.low_percentage}% ({dataItem.low_users} users)</p>
              <p>Improving: {dataItem.moderate_percentage}% ({dataItem.moderate_users} users)</p>
              <p>Well-Balanced: {dataItem.high_percentage}% ({dataItem.high_users} users)</p>
            </div>
          );
        }
        return null;
      };
      const customLegendPayload = [
        { value: 'Needs Attention', type: 'square', color: '#FFA726' },
        { value: 'Improving', type: 'square', color: '#66BB6A' },
        { value: 'Well-Balanced', type: 'square', color: '#1976D2' },
      ];
    
    return (
        <ComposedChart
          layout="vertical"
          width={420}
          height={350}
          data={data}
          margin={{
            top: 20,
            right: 40,
            bottom: 40,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis type="number" domain={[0, 100]}  />
          <YAxis dataKey="assessment" type="category" scale="band" />
          <Tooltip content={<CustomTooltip />} /> 
          <Legend align="center" verticalAlign="bottom" payload={customLegendPayload} /> {/* Use customLegendPayload */}
          <Bar dataKey="low_percentage" barSize={20} fill="#FFA726"  >
          <LabelList dataKey="low_percentage" position="right" formatter={value => `${value}%`} />
          </Bar>
          <Bar dataKey="moderate_percentage" barSize={20} fill="#66BB6A" >
          <LabelList dataKey="moderate_percentage" position="right" formatter={value => `${value}%`} />
          </Bar>
          <Bar dataKey="high_percentage" barSize={20} fill="#1976D2" >
          <LabelList dataKey="high_percentage" position="right" formatter={value => `${value}%`} />
          </Bar>
        </ComposedChart>
    );
  };
  


export const NmDashboard = (props) => {

    const [chartData,setChartData]=useState([]) 
    const [userData,setUserData]=useState({}) 
    const [loading,setLoading]=useState(false)
    useEffect(()=>{
        getData()
    },[])

    const getData=async()=>{
        setLoading(true)
        let data = {"bu_org_id":9}
          fetch(`${REACT_APP_BU_API_URL}/wellbeing_dashboard/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
        }).then(response => response.json())
            .then(async (res) => {
                console.log(res)
                if (res.status=='success') {
                    setUserData(res.user_data)
                    setChartData(res.chart_data)
                    setTimeout(()=>{setLoading(false)},1500)
                } else {
                  console.log('-')
                }
            })
            .catch(err => console.log(err)) 
    }

  return (
   loading?<Caremeloader />: <div className='col-md-10 ml-md-auto ml-0 mr-md-5 mt-md-5 pt-md-1 p-0'>
        <h1 className='text-center header_font_ _text_color'>Wellbeing Track Records</h1>
               
                <div className="col-md-8 mx-auto mt-md-4 d-flex p-0">
                  <img
                    src={`${CDN_URL}/CaremeLogo/final%20logo%20with%20slogan%20copy.png`}
                    className="img-fluid"
                    style={{ maxHeight: "100px" }}
                  />
                  <h4 style={{ opacity: 0.9 }}
                    className="header_font_ text-center _text_color align-self-center">
                    CareMeHealth Wellbeing Assessment{'\n'}Comprehensive Analysis and Personal Insights Platform
                  </h4>
                   <img 
                   src={'https://portal.naanmudhalvan.tn.gov.in/img/logo.d52ff4c5.png'} 
                   className='img-fluid' 
                   style={{maxHeight:'100px'}} 
                   />
                </div>

                <h2 style={{textDecorationLine:'underline'}} className='pl-md-5 pt-5  pb-3 mt-md-4 header_font_ _text_color'>User Analytics:</h2>

                <div className='d-flex col-md-8 mx-auto justify-content-between flex-md-row flex-column'>
                    <div className='m-md-3 col-md-5'>
                        <h3 className='text-center header_font_ _text_color'>Number of users taken wellbeing assssment</h3>
                        <div style={{backgroundColor:"#ddefe5"}} className='border shadow-sm p-5 mt-2 rounded'>
                            <h1 style={{fontSize:'56px'}} className='header_font_ _text_color p-4'>{userData?.assessment_taken||null}</h1>
                        </div>
                    </div>
                    <div className='m-md-3 col-md-5'>
                        <h3 className='text-center header_font_ _text_color'>Number of users downloaded the app!</h3>
                        <div  style={{backgroundColor:"#215352"}} className='border shadow-sm p-5 mt-2 rounded'>
                            <h1 style={{fontSize:'56px',color:'#ddefe5'}} className='header_font_ p-4'>{userData?.careme_users||null}</h1>
                        </div>
                    </div>
                </div>

                <h2 style={{textDecorationLine:'underline'}} className='pl-md-5 pt-5 px-0 pb-3 mt-md-4 header_font_ _text_color'>Result/Score Analytics:</h2>
                <div className='d-flex  col-md-10 mt-md-3 flex-wrap pl-md-4 mx-auto'>
                {chartData.map((data, index) => (
                    <div key={index} className='ml-md-5'>
                    <h2 className='header_font_ _text_color text-center mx-auto'> {data.assessment} Wellbeing Assessment</h2>
                    <CustomHorizontalBars data={[data]} />
                    </div>
                ))}
                </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(NmDashboard);