import { SHOW_FEEDBACK_MODAL, HIDE_FEEDBACK_MODAL } from '../Actions/Constants';

export function feedbackPopup(state = null, action) {
    switch (action.type) {
      case SHOW_FEEDBACK_MODAL:
        return  action.payload
      case HIDE_FEEDBACK_MODAL:
        return  action.payload
      default:
        return state
    }
  }
  