import moment from "moment";
import React from "react";
import ReadMore from "../../Helpers/Readmore";
import "../../pages/meeting/meeting.css";

class ReceiveMsg extends React.Component {
  constructor(props) {
    super(props);
    this.messagesEndRef = React.createRef();
    this.state = {};
  }

  render() {
    let newMsg = this.props.newMsg;
    let item = this.props.item;
    return (
      <>
        {newMsg !== "" && newMsg !== null ? (
          <div
            ref={this.messagesEndRef}
            className="col-md-7 col-10 text-right ml-auto my-2"
          >
            <div className="ml-auto">
              {this.props.link ? (
                <a
                  href={newMsg}
                  target="_blank"
                  style={{ background: "#ddefe5" }}
                  className="d-inline-block px-0 mx-0 p-2 mb-0 rounded"
                >
                  {newMsg}
                </a>
              ) : (
                <div
                  style={{ background: "#ddefe5" }}
                  className="d-inline-block text-left p-2 mb-0 rounded"
                >
                  <ReadMore text={newMsg} background="#ddefe5" maxWords={40} />
                </div>
              )}
            </div>
            <span style={{ fontSize: "10px" }} className="text-sm pr-1">
              {moment(item?.time).format("h:mm A")}
            </span>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default ReceiveMsg;
