import React from 'react';
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Collapse
} from 'reactstrap';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
    FormGroup,
    Label,
} from 'reactstrap';
import { REACT_APP_API_URL } from '../../processENV';
import Loader from '../Loader';
import { MinusCircle, PlusCircle, XCircle } from 'react-feather';
import { withTranslation } from 'react-i18next';


import { ToastContainer, toast } from 'react-toastify';
class Menstrual extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            periodicity:'',
            menarche:'',
            menstrualList:[],
            isOpen:false
        };
    }
      toastSuccess = (e) => toast.success(e, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        });
        toastError = (e) => toast.error(e, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            });
    menstrualInputHandler = (e) =>{
        let id = e.target.name;
        this.setState({
            [id] : e.target.value
        })
    }

    collapsePlan = () => {
        this.setState({
          isOpen: !this.state.isOpen
        })
      }
    
      componentDidMount(){
        this.getMenstrualList();
      }
      
      deleteMenstrual = (id) =>{
        fetch(`${REACT_APP_API_URL}/menstrual_history//${id}/`, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          }
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('delete Menstrual',res)
             this.toastError(res.data)
             this.getMenstrualList()
          })
          .catch(err => console.log('alcohol list error',err))
      }

      getMenstrualList = () =>{
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        fetch(`${REACT_APP_API_URL}/menstrual_list/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
             console.log('Menstrual list',res)
             this.setState({menstrualList: res})
                  
          })
          .catch(err => console.log('allergy list error',err))
      }
    submitMenstrualHistory = (e) => {
        let { periodicity, menarche } = this.state;
        var today = new Date();
        let dd = (today.getDate.toString().length === 1) ? ('0' + today.getDate()) : today.getDate();
        let mm = (today.getMonth().toString().length === 1) ? ('0' + (today.getMonth() + 1)) : (today.getMonth() + 1);
        let yy = (today.getFullYear().toString().length === 1) ? ('0' + today.getFullYear()) : today.getFullYear();
        let date = yy + '-' + mm + '-' + dd;

        if(!periodicity || !menarche ){ 
            this.setState({
                error : "please fill all the fields"
            })
        }else{
            let data = {
                "patient_n_key": localStorage.getItem('_patientKey'),
                "appointment_n_key": localStorage.getItem('_appointmentKey'),
                      "menarche": menarche,
                     "periodicity": periodicity,
                      "assessment_date": date,
                    
             }

            this.setState({loading:true,error:''})
            console.log('smoking data',data)
            fetch(`${REACT_APP_API_URL}/menstrual_history//`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
                },
                body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
                if(res.status === "success"){
                        this.toastSuccess('Menstrual Updated Successfully')
                    this.setState({loading:false,
                                    periodicity:'',
                                    menarche:'',
                                    loading:false})
                                    this.getMenstrualList()
                }else{
                    this.setState({loading:false,error:'Some error has occured please try again'})
                }
            })
        }
    }


    render() {
        let { periodicity, menarche, loading, isOpen, menstrualList } = this.state;
        let { t } = this.props;

        return (
            <>
                <Col md={8} xs={12} className="mb-2 px-0">
                    {loading && <Loader/>}
                    <ToastContainer />
                                            <Card >
                                                <CardSubtitle tag="h5" className="mt-2  text-center text-muted">{t('MENSTRUAL HISTORY')}<span className="cusicon" onClick={this.collapsePlan}> {isOpen ? <MinusCircle />: <PlusCircle />}</span></CardSubtitle>
                                                <CardBody className="col-md-10 col-12  mx-auto">
                                                <Collapse isOpen={isOpen}>

                                                    <Formik
                                                        initialValues={{
                                                            name: '',
                                                            email: '',
                                                        }}
                                                        onSubmit={this.submitMenstrualHistory}
                                                    >
                                                        {({ errors, touched }) => (
                                                            <Form>
                                                                     
                                                                    <FormGroup className="pl-md-0 w-100">
                                                                        <label>{t('Menarche - Age of Occurrence of First Menstrual Period')}</label>
                                                                        <Field name="menarche" className="form-control" placeholder={t('Menarche - Age of Occurrence of First Menstrual Period')} value={menarche}  onChange={(e)=> this.menstrualInputHandler(e)}/>
                                                                        {errors.menarche && touched.menarche ? (
                                                                            <ErrorMessage name="menarche" />
                                                                        ) : null}
                                                                    </FormGroup>
                                                                    <FormGroup className="pl-md-0 w-100">
                                                                        <label>{t('Periodicity')}</label>
                                                                         <Field as="select" name="periodicity" className="form-control" onChange={(e)=> this.menstrualInputHandler(e)} >
                                                                        <option value="">{t('Periodicity')}</option>
                                                                        <option value="Less than 28 Days">{t('< 28 Days')}</option>
                                                                                <option value="28-35 Days">{t('28-35 Days')}</option>
                                                                                <option value="35 Days">{t('35 Days')}</option>
                                                                            </Field>
                                                                        {errors.para && touched.para ? (
                                                                            <ErrorMessage name="para" />
                                                                        ) : null}
                                                                    </FormGroup>
                              
                                                       
                                                                <div className="text-center">
                                                                    <button type="submit" className="btn btn-primary mx-auto">{t('UPDATE')}</button>

                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>     
                                                    </Collapse>
                                                    <div className="allhistory">
                                                    {menstrualList && menstrualList.length !== 0
                                                    ? menstrualList.map( item =>{
                                                        return <Col md={10} xs={12} className="mb-2 mx-auto indhistory">
                                                       {item.periodicity !=="" || item.periodicity !== null? <div className="tagsec">
                                                             <p><h6 className="m-0">{item.periodicity}</h6><a onClick={() => this.deleteMenstrual(item.menstrual_history_id)}><XCircle /></a></p>
                                                        
                                                        <p> <span>{item.type}</span>
                                                            </p> 
                                                        </div>:<></>}
                                                    </Col> 
                                                    })
                                                :<></>  }
                                                        
                                                         </div>
                                                                 </CardBody>
                                                
                                            </Card>
                                        </Col> 
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(withTranslation()(Menstrual));
