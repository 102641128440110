import React from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { REACT_APP_API_URL } from '../../processENV';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Loader from '../Loader';
import { Link } from "react-router-dom";

class FaqMarquee extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            faq:[],
            loading:true,
            buttonText:''
        };
    }
   
    componentDidMount(){

        fetch(`${REACT_APP_API_URL}/general_faqs/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            }
            }).then(response => response.json())
            .then(async (res)=>{
                if(res.status === "success"){
                    this.setState({faq:res.data})
                    fetch(`${REACT_APP_API_URL}/schedule_calltext/`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
                        }
                        }).then(response => response.json())
                        .then(async (res) =>{
                            this.setState({loading:false, buttonText:res.data})
                        })
                }else{
                    this.setState({loading:false,error:'Some error has occured please try again'})
                }
            })
            .catch(err =>console.log(err))
    }


    render() {
        let { faq, loading, buttonText} = this.state
        return (
            <>
                <Col md={12} className="newssec px-0" >
                    
                    {buttonText && <Link to="/getcare" className="btn btn-primary api_btn" title={buttonText}>{buttonText} </Link>}
                    {loading ? <Loader/> : 
                    <Accordion preExpanded={['1']} className="pt-3" allowZeroExpanded>
                        {faq.map((item,i)=>{
                            return(
                                <AccordionItem uuid={i} key={i}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        {item.question}
                                 </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                {item.answer}
                        </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            )
                        } )}
                       
                    </Accordion>
                }

                </Col>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(FaqMarquee);
