import React from "react";
import ServiceCard from "./ServiceCard";
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle
  } from 'reactstrap';
import { REACT_APP_API_URL } from '../../../processENV';
import { withTranslation } from 'react-i18next';

class ServiceMain extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            products:[]
        };
    }

    componentDidMount(){
        
        fetch(`${REACT_APP_API_URL}/applist_product/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            }
        }).then(response => response.json())
        .then(async (res)=>{
            if(res.status === "success"){
                this.setState({loading:false,products:res.data})
            }else{
                this.setState({loading:false,error:'Some error has occured please try again'})
            }
        })
    }    


    render() {
        
        let { products } = this.state;
        return (
            <Row>
                <Col md={12}>
                    <h2 className="my-3">{this.props.t('How can we connect')}</h2>
                </Col>
                {products.map((prod,i) =>{
                    return <ServiceCard key={i}  prodId={prod.app_prod_n_key} name={prod.product_title} subhead={prod.product_short_discription} productImage={prod.product_image}/>
                })}
        </Row>
        )
    }

}

export default withTranslation()(ServiceMain);