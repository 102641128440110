import React, {Component} from 'react'
import {Launcher} from 'react-chat-window'
import axios from 'axios';
import { REACT_APP_API_URL } from '../../processENV';

export default class Demo extends Component {
 
  constructor() {
    super();
    this.state = {
      messageList: []
    };
  }
  componentDidMount(){
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey')
  }
  
  fetch(`${REACT_APP_API_URL}/chat_history/`, {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
    },
     }).then(response => response.json())
    .then(async (res)=>{
      console.log('chat',res.data)
        if(res.status === "success"){
         }else{
          console.log('c service',res.data)
        }
    })
  }
  _onMessageWasSent(message) {
      console.log(message);
       var self = this
       let data = {
        "id":localStorage.getItem('_patientKey'),
        "channelid":localStorage.getItem('_patientKey'),
        "message":"Hi",
        "isSeen":"False",
        "patient_n_key":localStorage.getItem('_patientKey'),
        "type_of_chat":"CareRoom",
        "msg_type":"Text"
       }
       fetch(`${REACT_APP_API_URL}/chat_post/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
        },
        body: JSON.stringify(data),
        }).then(response => response.json())
        .then(async (res)=>{
            if(res.status === "success"){
              console.log(res.data);
             
            }else{
                this.setState({loading:false})
            }
        })
        .catch(err => console.log('error',err))
        this.setState({
          messageList: [...this.state.messageList, message]
        })
    }
   
  
 
  _sendMessage(text) {
    if (text.length > 0) {
      this.setState({
        messageList: [...this.state.messageList, {
          author: 'them',
          type: 'text',
          data: { text }
        }]
      })
    }
  }
 
  render() {
    return (<div>
      <Launcher
        agentProfile={{
          teamName: 'Chat-Therapy',
          imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
        }}
        onMessageWasSent={this._onMessageWasSent.bind(this)}
        messageList={this.state.messageList}
        showEmoji
        newMessagesCount={2}
      />
    </div>)
  }
}
