import { SCHEDULE_STATUS } from '../Actions/Constants';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  id:uuidv4(),
  data:{
    date:'',
    time:'',
    problem:'',
    mode:'',
    gender:'',
    language:'',
    scheduleStep:1,
    scheduleCurrentStep:1,
    pt_key:'',
    data1:'',
    therapist:null,
  }
  }

  const mergeData = data =>{
    let newData = {
      id:data.id,
      data: {
        ...initialState.data,
        ...data.data
      }
    }
    console.log(newData)
    return newData
  }
export function ScheduleReducer(state = initialState, action) {
  console.log(action.payload)
    switch (action.type) {
      case SCHEDULE_STATUS:
        return  mergeData(action.payload);
      default:
        return state
    }
  }
  