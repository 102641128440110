import React from 'react';
import { connect } from 'react-redux';
import { REACT_APP_API_URL } from '../../processENV';
import Loader from '../Loader';
import { Col } from 'reactstrap';

class SelectDateTime extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            date: [],
            selectedDate:'',
            spots : [],
            time: [],
            selectedTime:'',
            therapyDetails:null,
            paymentStatus:null,
            empId:'',
            transactionStatus:''
        };
    }

    componentDidMount() {
        this.getAppointmentDate()
    }

    selectDate = ( value) =>{
        this.setState({selectedDate:value},()=>this.getAppointmentTime())
    }

    selectTime = (value) =>{
        this.setState({selectedTime:value,loading:true},()=>this.appointDoctor())
        
    }   

    getAppointmentDate = () =>{
        let therapyDetails = JSON.parse(localStorage.getItem('therapy_details'))
        let data = {
            "employee_n_key":therapyDetails.doc_app_id,
            'app_prod_grp_key':therapyDetails.app_prod_grp_key,
        }
        this.setState({loading:true})
        fetch(`${REACT_APP_API_URL}/app_rescheduled_date/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
                console.log("therapy date",res)
                if(res.status === "success"){
                    this.setState({loading:false, date:res.data, spots:res.count})
                }else{
                    this.setState({loading:false,error:'Some error has occured please try again'})
                }
            })
    }

    getAppointmentTime = () =>{
        let therapyDetails = JSON.parse(localStorage.getItem('therapy_details'))
        let { selectedDate} = this.state
        let data = {
            "employee_n_key":therapyDetails.doc_app_id,
            "rescheduled_date":selectedDate,
            'app_prod_grp_key':therapyDetails.app_prod_grp_key,
        }
        
        this.setState({loading:true})
        fetch(`${REACT_APP_API_URL}/app_rescheduled_time/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
                console.log("therapy time",res.data)
                if(res.status === "success"){
                    this.setState({loading:false, time:res.data})
                }else{
                    this.setState({loading:false,error:'Some error has occured please try again'})
                }
            })
    }
 
    appointDoctor = () =>{
        let therapyDetails = JSON.parse(localStorage.getItem('therapy_details'))
        let { selectedDate, selectedTime}  =  this.state;
        console.log(therapyDetails)
        let data = {
                "patient_n_key":localStorage.getItem('_patientKey'),
                "appointment_date":selectedDate,
                "appointment_time":selectedTime,
                "product":therapyDetails.product,
                "app_payment_n_key":therapyDetails.app_payment_n_key,
                "doc_app_id":therapyDetails.doc_app_id,
                "app_prod_n_key":therapyDetails.app_prod_n_key,
                "mode_of_telehealth":null,
                'app_prod_grp_key':therapyDetails.app_prod_grp_key,
             }
        
        fetch(`${REACT_APP_API_URL}/careteam_book_appointment/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(async (res) => {
                console.log("session_appointment", res)
                this.setState({
                    appointment:res.data
                })
                if (res.status === "success") {
                    this.setState({transactionStatus:res.data.overall_status, loading:false},()=>{setTimeout(()=>{window.location.replace('/home')},1000)})
                        //    window.location.replace('/home')
                } else {
                    this.setState({ loading: false, error: 'Some error has occured please try again' })
                }
            })
            .catch(err => console.log(err))
    }

    render() {
        const { date, time, loading, selectedDate, selectedTime, spots} = this.state;
        return (
            <>
                {loading && <Loader />}

                {selectedDate === '' &&  <div className="col-md-8 mx-auto bg-none mt-5 pt-5">
                    <div className="d-flex mx-auto mt-5">
                        <Col  className="mx-auto text-center">
                        <p
                        style={{ fontSize: "20px" }}
                        className="h4 text-left _text_color text-md-center header_font_ font-weight-bold"
                        >You can schedule your appointment date now based on your convenience and check your meeting schedule on your home screen. </p>
                            {selectedDate && <p style={{fontSize:'16px'}} className='h4 header_font_ font-weight-bold'>{selectedDate}</p>}
                            <div className="col-md-6 px-0 mt-md-5 mt-2 mx-auto d-flex flex-column">                             
                                {date && date.map((item, i)=>{
                                    return (
                                        <button
                                        style={{border:'1px solid #ddefe4', fontFamily: "Hanken Grotesk", fontSize: "20px" }}
                                        className={"p-3 rounded bg-white _text_color text_p_ position-relative shadow-sm text_medium_ my-2"}
                                        key={item}
                                        onClick={() => this.selectDate(item)}
                                        >
                                        {item}
                                        <br />
                                        <small
                                            style={{ fontSize: "12px", right: 5, bottom: 3 }}
                                            className="text-right position-absolute font-weight-bold"
                                        >
                                            {spots[i]} slots{" "}
                                        </small>
                                        </button>
                                    )
                                })}
                            </div>
                        </Col>
                    </div>
                </div>}
                {selectedDate !== ''  &&  <div className="col-md-8 mx-auto bg-none mt-5 pt-5">
                <div className="d-flex mx-auto mt-5">
                        <Col  className="mx-auto text-center">
                        <p
                        style={{ fontSize: "20px" }}
                        className="h4 text-left _text_color text-md-center header_font_ font-weight-bold"
                        >You can schedule your appointment time now based on your convenience and check your meeting schedule on your home screen. </p>
                            { this.props.therapyData.data?.date && <p>{ this.props.therapyData.data?.date}</p>}
                            { selectedTime && <p style={{fontSize:'16px'}} className='h4 header_font_ font-weight-bold'>{ selectedTime}</p>}
                            <div className="col-md-6 px-0 mt-md-5 mt-2 mx-auto d-flex flex-column">                             
                                {time && time.map((item, i)=>{
                                    return (
                                        <button
                                        style={{border:'1px solid #ddefe4', fontFamily: "Hanken Grotesk", fontSize: "20px" }}
                                        className={"p-3 rounded bg-white _text_color text_p_ shadow-sm text_medium_ my-2"}
                                        key={item}
                                        onClick={() => this.selectTime(item)}
                                        >
                                        {item}
                                        </button>
                                    )
                                })}
                            </div>
                        </Col>
                    </div>
                </div>}
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        
        therapyData: state.TherapyJourneyData,

    }
}

export default connect(mapStateToProps, null)(SelectDateTime);
