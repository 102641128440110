import { LOGIN_STATUS } from '../Actions/Constants';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  id:uuidv4(),
  data:{
    step:0,
    currentStep:0,
    mobileNumber:'',
    countryCode:'',
    otp:'',
    language:'english',
    detail:'',
    isauth:false,
  }
  }

  const mergeData = data =>{
    let newData = {
      id:data.id,
      data: {
        ...initialState.data,
        ...data.data
      }
    }
    return newData
  }
export function LoginReducer(state = initialState, action) {
    switch (action.type) {
      case LOGIN_STATUS:
        return  mergeData(action.payload);
      default:
        return state
    }
  }
  