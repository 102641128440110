import React, { Component } from 'react'
import { Dropdown, DropdownItem, DropdownMenu,DropdownToggle, Modal, ModalFooter, Progress } from 'reactstrap';
import moment from 'moment';
import axios from 'axios';
import { CDN_URL, REACT_APP_API_URL, REACT_APP_BASEURL, REACT_APP_BASE_URL } from '../processENV';
import Caremeloader from './Caremeloader';
import forarr from '../../assets/images/forarr.png';
import { Icon } from '@material-ui/core';
import { ArrowLeftCircle, ArrowRight, CheckCircle, Circle, Plus, Star, XSquare } from 'react-feather';
import { CloseButton } from 'react-bootstrap';
import ReactShowMoreText from 'react-show-more-text';

class TherapyAsses extends Component {
    constructor(props){
        super(props);
        this.monthref=React.createRef();
        this.yearref=React.createRef();
        this.state={
            quesObj:[],
            dropdownMenu:false,
            bulkData:'',
            indx:0,
            step:1,
            score_details:{},
            modal:false,
            disable:true,
            emailError:false,
            open:false,
            dateError:false,
            date: null,
            month: null,
            year: null,
            nwes:true,
            sugesstion:'',
            image_bar:'',
            range:[1,2,3,4,5,6,7,8,9,10],
            url_1:`${CDN_URL}/Emoji/step11.png`,
            url_2:`${CDN_URL}/Emoji/step12.png`,
            url_3:`${CDN_URL}/Emoji/step13.png`,
            url_4:`${CDN_URL}/Emoji/step14.png`,
            url_5:`${CDN_URL}/Emoji/step15.png`,
            url_6:`${CDN_URL}/Emoji/step16.png`,
            sugesstion:'',
            rating:0,
            week:[],
            old_mood:`${CDN_URL}/Emoji/step13.png`,    
            loader:false,
            chart_img_1:`${CDN_URL}/progressbar/pb1.png`,
            chart_img_2:`${CDN_URL}/progressbar/pb2.png`,
            chart_img_3:`${CDN_URL}/progressbar/pb3.png`,
            chart_img_4:`${CDN_URL}/progressbar/pb4.png`,
            chart_img_5:`${CDN_URL}/progressbar/pb5.png`,
            pg_img_1:`${CDN_URL}/progressbar/progress%20bar1a.png`,
            pg_img_2:`${CDN_URL}/progressbar/progress%20bar1b.png`,
            pg_img_3:`${CDN_URL}/progressbar/progress%20bar1c.png`,
            pg_img_4:`${CDN_URL}/progressbar/progress%20bar1d.png`,
            pg_img_5:`${CDN_URL}/progressbar/progress%20bar1e.png`,
            headers:{},
            selfc_:false,
        }
    }
    getMoods=async(key)=>{
      var dt=moment().valueOf()
      var to_d=moment(dt).format('dddd');
      const {url_1,url_2,url_3,url_4,url_5,url_6}=this.state;
      const headers={
        Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'a7143772a09f993125901ea752bfd4fcf4b31d29',
       }
      var data=JSON.stringify({
          "order_form_id":key,
          "patient_n_key":localStorage.getItem('_patientKey')})
          axios.post( REACT_APP_API_URL+'/week_forms_fill/',data,{headers}).then((res)=>{
              console.log(res)
              if(res.data.status === 'success'){
                  // this.setState({week:res.data})
                  if(res.data.fill_count == 0){
                      this.setState({old_mood:url_1})
                  }
                  if(res.data.fill_count == 1){
                      this.setState({old_mood:url_2})
                  }
                  if(res.data.fill_count == 2){
                      this.setState({old_mood:url_3})
                  }
                  if(res.data.fill_count == 3){
                      this.setState({old_mood:url_4})
                  }
                  if(res.data.fill_count == 4){
                      this.setState({old_mood:url_5})
                  }
                  if(res.data.fill_count == 5){
                      this.setState({old_mood:url_6})
                  }
                  if(res.data.fill_count == 6){
                      this.setState({old_mood:url_6})
                  }
                  const dat = res?.data?.alldata?.map((i,indx)=>{if(i.day==to_d ){return indx}}).filter(i=>i)[0]
                  var resp = res.data.alldata
                  resp[dat?dat:0].completed=true
                  this.setState({week:resp})
                  }
             })
  }


   toggle = () => {this.setState({dropdownMenu:!this.state.dropdownMenu})}
componentDidMount(){
    console.log('callw')
    let item=this.props?.location.state
    console.log(':props==>:::::',this.props)
    let key=item.order_form_id
    // let key=118
      if(key){
        this.getQues(key)
         this.getMoods(key)
      }
}
    getQues=async(id)=>{
        const headers={
            "Content-Type": "application/json",
            "Authorization": "Token 70c48f814c3a6cc1b4e1dcb0ea4b2cc0713c90bf"
           }
          axios.get(REACT_APP_BASEURL+`api/orderform_details/${id}/`,{headers}).then((res)=>{
              console.log('oreder_foem_res',res)
              this.setState({
                  bulkData:res.data,
                  quesObj:res.data.form_data
              })
          })      
    }
    submitQues=async()=>{
      this.setState({loader:true})
      let questn = [...this.state.quesObj]
        const data = {
            // link_id: 3,
            patient_n_key: localStorage.getItem('_patientKey'),
            form: this.state.bulkData.title,
            order_form_id: this.state.bulkData.order_form_id,
            values:questn,
            thoughts:this.state.sugesstion,
            rating:this.state.rating,
          };
          const resultData={
            "patient_n_key":localStorage._patientKey,
            "request_date":moment().format('YYYY-MM-DD'),
            "order_form_id":this.state.bulkData.order_form_id,
         }       
         const headers = {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          };

          axios
            .post(
            REACT_APP_BASEURL+`api/caremeapp/therpayform_post/`,
              data,
              { headers }).then((res)=>{
                  console.log('therapy_form_post_res', res)
                  if(res.data.status === 'success'){
                    axios.post(REACT_APP_API_URL+'/view_therapy_result/',resultData,{headers}).then((resp)=>{
                      console.log(resp)
                      if(resp.data.status=='success'){
                        this.setState({loader:false})
                          this.props.history.push("/assesment-result-view", {
                            data: resp.data,
                          }) 
                         }
                       else{
                        console.log('no res')
                        this.setState({loader:false})
                        this.props.history.push('home')
                       }
                  })
                  }
                  else{
                    this.props.history.push('home')
                    }
                
              })
    }
    sendFront=()=>{
        if(this.state.indx+1 !== this.state.quesObj.length){
            this.setState({indx:this.state.indx+1})
        }
        else if(this.state.indx+1 == this.state.quesObj.length){
          this.setState({step:2})
      }
        else{
            console.log('end')
        }
    }
     handleCheck = (inddx, id, val, els) => {
        this.setState({
         quesObj:this.state.quesObj.map((item, index) => {
            var arr = [this.state.quesObj[id].user_answer];
            if (val) {
              if (arr[0] == null) {
                let newArr = [];
                newArr.push(els);
                arr = newArr;
                this.state.quesObj[id].answer[inddx].check = true;
              } else arr.push(els);
              this.state.quesObj[id].answer[inddx].check = true;
            }
            if (!val) {
              var newA = [].concat.apply([], arr).filter((i) => i !== els);
              arr = newA;
              this.state.quesObj[id].answer[inddx].check = false;
            }
            if( arr.length !==0){
                this.setState({disable:false})
             }
             else{
                this.setState({disable:true})
             }
            if (id !== index) return item;
            return { ...item, user_answer: [].concat.apply([], arr) };
          })        
          });
          
      };
      handleDate=async(indx,text)=>{
        var nD= new Date()
        var Yr = nD.getFullYear()
       
          if(text >= Yr){
            this.setState({dateError:true, disable:true})
          }
         else if(!this.state.month || !this.state.date){
            this.setState({dateError:true, disable:true})
          }
         else{ var DOB = this.state.month + '/' + this.state.date + '/' + text
            this.setState({
              quesObj: this.state.quesObj.map((item, index) => {
                 if (indx !== index) return item;
                 return { ...item, user_answer: moment(DOB).toDate() };
               }),
               disable:false
                });
        }
      }
    handleAns=(text,indx) => {
      console.log(text,indx) 
        this.setState({
         quesObj: this.state.quesObj.map((item, index) => {
            if (indx !== index) return item;
            return { ...item, user_answer: text };
          }),
          disable:false,
           });
           console.log(text,indx,this.state.quesObj) 
      };
      handleQues = async(indx, vals) => {
        this.setState({
         quesObj: this.state.quesObj.map((item, index) => {
            if (indx !== index) return item;
            return { ...item, user_answer: vals};
          })
           },()=>{
              this.sendFront()
           });
           this.setState({disable:false},()=>setTimeout(()=>{
            this.setState({disable:true})
           },300))
     
      };
          stepForward=(inx)=>{
           this.setState({indx:inx+1,disable:true,nwes:false})
            setTimeout(()=>this.setState({nwes:true}),100)
          }
  render() {
      const{quesObj,bulkData,selfc_, indx,step,disable,week,nwes,old_mood,modal,score_details,image_bar,loader,dropdownMenu}=this.state;
      console.log(week)
      return (
      
     nwes && <div className="col-md-12 position-relative px-2">
                {loader ? <Caremeloader  /> : null}
       {step==1? 
       <div>
          <p 
            onClick={() => indx ==0? (window.location.href='/home') : this.setState({indx:this.state.indx-1})}
          >
         </p>  
       </div>
       :null}
       {step==2 ? 
        <div>
        <p 
          onClick={() => this.setState({step:1})}
        >
       </p>  
     </div>
      :null}
       {step==1?<>
       {indx > 0? <>
       <div style={{left:20,top:25}} className='position-absolute'>
        <button
              style={{
                paddingHorizontal:14,
                paddingVertical:6,
                borderRadius:6,
                backgroundColor:'#fff',
                border:'none',
              }}
              onClick={()=>this.setState({indx:indx-1,disable:true})}
              >
                <ArrowLeftCircle />
              </button>
              </div>
       </>:null}
        {quesObj[indx]?.can_skip && quesObj[indx].can_skip ?
        <div style={{right:20,top:25}} className='position-absolute'>
              <button
              style={{
                paddingHorizontal:14,
                paddingVertical:6,
                borderRadius:6,
                backgroundColor:'#fff',
               
              }}
              onClick={()=>this.setState({indx:indx+1,disable:true})}
              >
                Skip
              </button></div>
              :<div style={{right:20,top:25}} className='position-absolute'><button  style={{
                paddingHorizontal:2,
                paddingVertical:2,
                borderRadius:4,
                shadowColor: "#000",
                backgroundColor:'#fff',
                border:'none'
              }}
                onClick={()=>this.props.history.push('home')}
                >
                <XSquare />
              </button> </div>}
       </> : null}
      {step==1 && quesObj? <Progress value={`${((indx+1) / (quesObj.length)) * 100}`} color="dark" />:null }    
      <div className='col-md-12 px-0 mt-md-3 mt-2 pt-md-3 pt-3'>  
         {step ===1 ? <>
        {bulkData ? <>       
        <div  className='px-0 showQ_'>
        {quesObj[indx].key==='Showcase'? null:<>
         <div className='d-lg-none d-sm-block '>
         <ReactShowMoreText
         lines={3}
         more="Show more"
         less="Show less"
         className="content-css descr_asses_therapy"
         anchorClass="show-more-less-clickable"
         onClick={this.executeOnClick}
         expanded={false}
         width={280}
         truncatedEndingComponent={"... "}
         >
         <p className="descr_asses_therapy" >
          {bulkData.description}</p>
          </ReactShowMoreText>
          </div>
          <div className='d-sm-none d-lg-block d-none '>
          <p className="descr_asses_therapy" >
          {bulkData.description}</p>
          </div>
          </>
          }

         {quesObj[indx].key==='Showcase'?null: <p className="mt-md-5 mt-2 ml-md-5 pl-md-4 text_p_ h5" >{indx+1}<ArrowRight />{' '} {quesObj[indx].question}</p>}
         {quesObj[indx].key==='Checkboxes'?<p className='sel_p_' style={{fontSize:'12px',margin:'10px',marginLeft:'200px',  textAlign:'left'}}>(select all that applies)</p>:null}

         {/* <Text>{indx+1}{quesObj.length-1}</Text> */}
        <div style={{width:'95%', marginTop:10}}>
      
           {quesObj[indx].key==='RadioButtons'?   <div className='ml-5 d-flex flex-column col-md-4'>
            { quesObj[indx].answer.map((i,inx)=>{
               return(
                <button className="radio ml-md-5 my-1 ml-0" style={{backgroundColor:i===quesObj[indx].user_answer?'#5ed1b2':'#dcdcdc'}} onClick={()=>this.handleQues(indx,i)} >
                <p style={{color:i===quesObj[indx].user_answer?'#fff':'#696969', margin:4, fontWeight:'bold',}}>{i}</p>
             </button>
               )
           })}</div>:null}
           {quesObj[indx].key==='TextInput'?
           <div className='ml-5'>
            <input
          className="col-md-4 form-control ml-md-5 py-4"
            placeholder={quesObj[indx].placeholder}
            autoFocus
            onFocus={()=>quesObj[indx].user_answer?this.setState({disable:false}):null}
            defaultValue={quesObj[indx].user_answer}
            onChange={(e) => {
                if(e.target.value.trim().length >1){
                    this.handleAns(e.target.value,indx)
                    this.setState({disable:false})
                }
                else{
                    this.setState({disable:true})
                }
                }
            }
           /></div>
           :null}
              {quesObj[indx].key==='NumberInput'?
               <div className='ml-5'>
            <input 
          className="col-md-4 form-control ml-md-5 py-4"
            placeholder={quesObj[indx].placeholder}
            autoFocus
            keyboardType='phone-pad'
            onFocus={()=>quesObj[indx].user_answer?this.setState({disable:false}):null}
            defaultValue={quesObj[indx].user_answer}
            onChange={(e) => {
                if(e.target.value.trim().length > 1){
                    this.handleAns(e.target.value,indx)
                    this.setState({disable:false})
                }
                else{
                    this.setState({disable:true})
                }
                }
            }
           />
           </div>
           :null}
             {quesObj[indx].key==='EmailID'?
             <div className='ml-5'>
            <input 
          className="col-md-4 form-control ml-md-5 py-4"
            placeholder={quesObj[indx].placeholder}
            autoFocus
            keyboardType='email-address'
            onFocus={()=>quesObj[indx].user_answer?this.setState({disable:false}):null}
            defaultValue={quesObj[indx].user_answer}
            onChange={(e) => {
                let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
             if (pattern.test(e.target.value)) {
                    this.handleAns(e.target.value,indx)
                    this.setState({disable:false,emailError:false})
                }
                else{
                    this.setState({disable:true,emailError:true})
                }
                }
            }
            />
            {this.state.emailError?
            <p className='text_p_ text-left ml-5' style={{margin:5,fontSize:12, color:'red', fontWeight:'bold', textAlign:'center'}}>
            Enter a valid email address
            </p>:null}
            </div>:null}
            {quesObj[indx].key==='Showcase'?

            <div className="text-center col-md-5 mt-5 pt-5 mx-auto">
            <p className="border shadow-sm p-5 text_p_ h3">
                {quesObj[indx].question}
            </p>
            </div>
           :null}
            {quesObj[indx].key==='Dropdown'?
             <div className='ml-5'>
             <select
             className='col-md-4 form-control ml-md-5 '
             defaultValue={quesObj[indx].user_answer}
             onChange={(e)=>{this.handleAns(e.target.value,indx)}}
             placeholder="Select your answer"
             value={quesObj[indx].user_answer}
           >
              {quesObj[indx].answer.map((i,indx)=>{
                return(
                  <option value={i.value} key={indx}>
                  {i.label}
                  </option>
                )
              })}
            </select>
            </div>
           :null}
           {quesObj[indx].key==='Checkboxes'?
           <div className='ml-5 d-flex flex-column col-md-4'>
           {quesObj[indx].answer.map((i,inx)=>{
               return(
                   <button className='radio ml-md-5 ml-0' style={{backgroundColor:i.check?'#5ed1b2':'#dcdcdc'}} onClick={()=>this.handleCheck(inx,indx,!i.check,i.item)} >
                      <p style={{color:'#000', margin:4, fontWeight:i.check?'bold':'400',}}>{i.item}</p>
                   </button>
               )
           })}
           </div>
           :null}
            {quesObj[indx].key==='TextArea'?
              <div className='ml-5'>
            <textarea 
           className="col-md-5 form-control ml-md-5 py-5"
            placeholder={quesObj[indx].user_answer?quesObj[indx].user_answer:quesObj[indx].placeholder}
            autoFocus
            multiline
            onFocus={()=>quesObj[indx].user_answer ? this.setState({disable:false}):null}
            defaultValue={quesObj[indx].user_answer}
            onChange={(e) => {
                if(e.target.value.trim().length > 1){
                    this.handleAns(e.target.value,indx)
                    this.setState({disable:false})
                }
                else{
                    this.setState({disable:true})
                }
                }
            }
            >
            </textarea>
            </div>
           :null}
           
           {quesObj[indx].key==='Range'?
          <>
          <div className="scale col-md-4  ml-md-5 text-left">
            {this.state.range.map((i,ind)=>{
              return(
                <button onClick={()=>{this.handleAns(i,indx);this.setState({disable:false})}} className={`border-none p-3 ${quesObj[indx].user_answer === i ?'shadow':""}`} style={{backgroundColor:quesObj[indx].user_answer === i ?'#fff':ind == 0?'#DFE257':ind==1?'#ECEA0E':ind==2?'#FAF701':ind==3?'#88E16A':ind==4?'#5FC713':ind==5?'#32A404':ind==6?'#147325':ind==7?'#E98838':ind==8?'#D13434':ind==9?'#FF0200':'#000',fontSize:'14px',color:quesObj[indx].user_answer === i ?'#000':'#fff',}}>
                  {i}
                </button>
              )
            })}
          </div>
          </>
           :null}
            {quesObj[indx].key==='DatePicker'? <>
            <div className="d-flex flex-row ml-md-5 ml-2 pl-md-5 pl-2 mt-4">
                <input
                 className= "form-control col-md-1 py-4 m-1"
                  placeholder="DD"
                  autoFocus
                  onFocus={()=>this.state.date?this.setState({disable:false}):null}
                  defaultValue={this.state.date}
                  keyboardType='number-pad'
                  maxLength={2}
                  onChange={(e) => {
                    if(e.target.value > 31 || e.target.value ==0){
                      this.setState({dateError:true,disable:true})
                    }
                    else{
                      console.log(typeof(text))
                      this.setState({date: e.target.value,dateError:false},()=>e.target.value.trim().length==2 && this.monthref.current.focus())
                    }
                  }
                    
                  }/>
                {/* <Text>/</Text> */}
                <input
                 className="form-control col-md-1 py-4 m-1"
                  maxLength={2}
                  ref={this.monthref}
                  onFocus={()=>this.state.month?this.setState({disable:false}):null}
                  defaultValue={this.state.month}
                  keyboardType='number-pad'
                  placeholder="MM"
                  onChange={(e) =>
                   {
                    if(e.target.value > 12 || e.target.value ==0){
                      this.setState({dateError:true, disable:true})
                    }
                    else{
                      this.setState({month: e.target.value,dateError:false},()=>e.target.value.trim().length==2 && this.yearref.current.focus())
                    }
                   }
                  }/>
                {/* <Text>/</Text> */}
                <input
                 className="form-control col-md-2 py-4 m-1"
                  defaultValue={this.state.year}
                  ref={this.yearref}
                  onFocus={()=>this.state.year?this.setState({disable:false}):null}
                  keyboardType='number-pad'
                  maxLength={4}
                  placeholder="YYYY"
                  onChange={(e) => {
                    if((e.target.value < 1800)||(e.target.value.trim().length < 4)){
                      this.setState({dateError:true,disable:true})
                    }
                    else{
                      this.setState({year: e.target.value,dateError:false})
                      if(e.target.value.trim().length >=4)this.handleDate(indx,e.target.value);
                    } 
                  }}/>
            </div> 
            {this.state.dateError ? (
                <p
                  style={{
                    color: 'red',
                    fontSize: 12,
                    margin: 5,
                    marginLeft:'200px',
                    textAlign: 'left',
                  }}>
                  Enter a Valid Date
                </p>
              ) : null}
            </>:null}
        </div>
      </div>
        </>:null} 
        </>: null}
        {step ==2 ? <>
        <div className='col-md-8 mx-auto text-center '>
        <img
          className='img-fluid'
          style={{maxHeight:'150px'}}
           src={this.state.old_mood}
           resizeMode="contain"
          />
          <p className="header_font_ _text_color py-3 h4">Regular logging will show </p>
          <p className='header_font_ _text_color pb-2 h4' style={{ fontSize:16,marginTop:-2,fontWeight:'600'}}>you better trends over time</p>
          <p style={{marginTop:25,fontSize:16}}>THIS WEEK</p>
                             <div className="weekcontainer">
                                {week.map((i,indx)=>{
                                    return(
                                        <div key={indx} className="weekRow">
                                           <p style={{fontSize:20}}>
                                                {i.day_start}
                                            </p>
                                           <p>
                                           {i.completed? <CheckCircle size={25} color='#8ecc82' />:<Circle size={25} color='#ebebeb' />}
                                            </p> 
                                        </div>
                                    )
                                })}
                             </div>
          <textarea 
         className="col-md-5 py-md-4 border text_p_ form-control mx-auto"
          placeholder='Add some thoughts'
          autoFocus
          multiline
          defaultValue={this.state.sugesstion}
          onChange={(e) => {this.setState({sugesstion:e.target.value}) }}
          >
          </textarea>
          <p className='header_font_ _text_color' style={{fontSize:18,marginTop:15,fontWeight:'600'}} >Rate this experience</p>
          <div className="ratings">
          <button onClick={()=>this.setState({rating:1})} style={{margin:4}}>
            <Star name='star' color={this.state.rating >=1?'#215352':'#cbc8c6'} size={45} />
          </button >
          <button onClick={()=>this.setState({rating:2})} style={{margin:4}}>
            <Star name='star' color={this.state.rating >=2 ?'#215352':'#cbc8c6'} size={45} />
          </button>
          <button onClick={()=>this.setState({rating:3})} style={{margin:4}}>
            <Star name='star' color={this.state.rating>=3?'#215352':'#cbc8c6'} size={45} />
          </button>
          <button onClick={()=>this.setState({rating:4})} style={{margin:4}}>
            <Star name='star' color={this.state.rating>=4?'#215352':'#cbc8c6'} size={45} />
          </button>
          <button onClick={()=>this.setState({rating:5})} style={{margin:4}}>
            <Star name='star' color={this.state.rating>=5?'#215352':'#cbc8c6'} size={45} />
          </button>
          </div>
          <div className='mx-auto text-center'>
          <button 
          className= "btn px-4 py-2 my-md-5"
          style={{background:'#215352',color:'#fff',fontWeight:'bold',borderRadius:'2px',}}
          onClick={()=>this.submitQues()}
          >
           Save & Submit
          </button>
          </div>
          
        </div>

        </>:null}
        {step == 3? <>
        <Modal isOpen={selfc_} className='position-relative' centered size='md'>
          <CloseButton onClick={()=>this.setState({selfc_:!selfc_})} style={{right:15,top:15}} className='position-absolute' />
            <div className='p-5 '>
              <h5 className='text_p_ mt-md-4 font-weight-bold'>Unfortunately, it seems that the self-care courses are not currently available on our web app. We apologize for the inconvenience.</h5>
              <h5 className='text_p_ mt-3 font-weight-bold'>To access these courses, please download our mobile app from the App Store or Google Play.</h5>
              <h5 className='text_p_ mt-3 font-weight-bold'>Thank you for your patience!</h5>
            </div>
            <ModalFooter>
              <div className='d-flex flex-row my-4 mx-auto justify-content-center'>
                <img onClick={()=>window.open('https://play.google.com/store/apps/details?id=com.careme')} style={{height:'50px',width:'140px'}} src={`${CDN_URL}/Websiteicons/playstore.png`} className='img-fluid m-2'/>
                <img onClick={()=>window.open('https://apps.apple.com/GH/app/id1553777469?l=en')} style={{height:'50px',width:'140px'}} src={`${CDN_URL}/Websiteicons/appstore.png`} className='img-fluid m-2' />
              </div>
            </ModalFooter>
        </Modal>
        <div className='col-md-12 mx-auto'>
             <p className='mt-5 h3 header_font_ text-center' >Here are your results</p>
          {score_details && score_details.length !==0 ?
          <>
           <p className='h4' style={{fontFamily:'Poppins-SemiBold', fontSize:16,width:'97%', textAlign:'center',marginTop:20}}>
            Your Assesment score is {this.state.score_details.user_score} out of {this.state.score_details.total_score}
            </p>
            <p className='h4' style={{fontFamily:'Poppins-SemiBold', fontSize:16,width:'97%', textAlign:'center',marginTop:20}}>
            Experiencing {this.state.score_details.severity} 
            </p>
          <div className='text-center col-md-12 mx-auto mt-md-3'>
            <div style={{background:'#ddefe5',color:'#215352'}} className='col-md-5 mx-auto border shadow-sm mb-4 p-3' > 
            <p className='text-left pt-2 pl-3'>{score_details.score_description}</p>
            </div>

            <div style={{background:'#fcefd2'}} className='col-md-5 mx-auto shadow-sm border-rounded m-1'>
              <h4 className='header_font_ py-3'>Simple Steps to Boost Your Well-being</h4>
                <div className='d-flex flex-column pb-4'>
                  {score_details.focus_areas.map((i,indx)=>{return(
                    <span key={indx} className='text-left font-weight-bold p-1'><Plus size={18} />{' '}{i} </span>
                  )})}
              </div>
            </div>
            
            <div className='col-md-6 mx-auto'>
              <h4 className='font-weight-bold mt-5 text-left pt-4'>Recommended courses</h4>
              </div>
              <div className='d-sm-none d-lg-block d-none '>
            <div className='d-flex flex-row col-md-6 mx-auto mt-3'>
              {score_details.selfcare.slice(0,4).map((i,indx)=>{return(
                <div style={{minWidth:'150px'}} className='border shadow-sm border-rounded m-2 pb-n1'>
                <img  style={{width:'100%'}}  src={i.course_image} />
                <button onClick={()=>this.setState({selfc_:!selfc_})} style={{background:'#215352',color:'#fff'}} className='text-center border-top btn-block p-2 font-weight-bold'>Take Now</button>
                </div>
              )})}
            </div>
            </div>
            <div className='d-lg-none flex-row  d-sm-block  flex-wrap col-md-6 mx-auto mt-3'>
              {score_details.selfcare.slice(0,4).map((i,indx)=>{return(
                <div style={{minWidth:'150px'}} className='border shadow-sm border-rounded m-2 pb-n1'>
                <img  style={{width:'100%'}}  src={i.course_image} />
                <button onClick={()=>this.setState({selfc_:!selfc_})} style={{background:'#215352',color:'#fff'}} className='text-center border-top btn-block p-2 font-weight-bold'>Take Now</button>
                </div>
              )})}
            </div>

            
            <div className='col-md-6 mx-auto'>
              <h4 className='font-weight-bold mt-5 text-left pt-4'>Recommended session</h4>
              <div className='col-md-8 mx-auto text-center mt-4 border rounded shadow-sm p-3'>
               <p style={{fontSize:'16px'}} className='text_p_ text-left'>A therapy session can be a great way to improve your emotional resilience. We encourage you to consider booking one</p> 
               <div className='text-center'>
              <button onClick={()=>window.location.href='/offerings'} style={{background:'#215352',color:'#fff'}} className='text-center btn-block p-2 font-weight-bold'>Book Now</button>
              </div>
              </div>
              
              </div>
              {score_details.community.length>0? <div className='col-md-6 mx-auto'>
              <h4 className='font-weight-bold mt-5 text-left pt-4'>Social Community Space</h4>
              <p style={{fontSize:'16px'}} className='header_font_ text-left mt-md-3'>Join Our Active Online Communities to Engage in Discussions and Get Help with Your Queries</p> 
              <div className='text-left mt-2'>
                {score_details.community.map((i,indx)=>{return(
                  <button style={{backgroundColor:'#215352',color:'#fff'}} className='m-2 rounded px-4 py-2 text_p_ font-weight-bold' onClick={()=>window.location.href=`/community/${i.title}`}>{i.title}</button>
                )})}
              </div>
              </div>:null}
          </div></>:null}
          <div className='text-center mt-5 pt-md-5 col-md-3 mx-auto'>
          <button 
          className='btn btn-block'
          style={{background:'#215352',color:'#fff',fontWeight:'bold',border:'none'}}
          onClick={()=>this.props.history.push('home')}>
           Go home
          </button>
          </div>
          </div>
        </>:null}
        {step ==1 && quesObj.length!==0 ? <div style={{bottom:20,right:30}} className="position-fixed">
            <button
            onClick={()=>{indx+1 == quesObj.length ? this.setState({step:2}):this.stepForward(indx)}}
            disabled={ quesObj && quesObj[indx].key==='Showcase'?false:disable}
            className='shadow'
            style={{backgroundColor:quesObj && quesObj[indx].key==='Showcase'? '#79d979':disable?'#809080':'#79d979',borderRadius:50,padding:7,border:'none'}}
            >
          <img
           style={{width: 25,height: 25,tintColor:quesObj[indx].key==='Showcase'?'#fff':disable?'#808080 ':'#fff'}}
           src={forarr}
          />
        </button>
        </div> :null}
      </div>
      </div>

    )
  }
}

export default TherapyAsses;
