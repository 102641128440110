import React from 'react';
import { connect } from 'react-redux'; 
import { Container, Row, Col, Label, CardTitle, CardSubtitle } from 'reactstrap';

import {Redirect, withRouter} from 'react-router-dom'
import { REACT_APP_API_URL, REACT_APP_BASE_URL, getCurrencySymbol } from '../../processENV';
import Loader from '../Loader';
import { withTranslation } from 'react-i18next';

class SelectSession extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            sessions:[],
            coupons:null,
            couponError:null,
            couponApplied:false,
            selectedPayment:'',
            productDetails:null,
            p_dial_code:'',

        };
    }
    getName=async()=>{
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        fetch(`${REACT_APP_API_URL}/patientedit_app/`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data), 
        }).then(response => response.json())
        .then(async (res)=>{
          console.log('patient profile',res)
            if(res.status === "success"){
              this.setState({p_name:res.data.first_name,p_email:res.data.email, p_dial_code:res.data.dial_code,p_phone:res.data.phone_number})
            }else{
              console.log('pat prof',res.data)
            }
        })
    }
    componentDidMount(){
        this.getName()
            let data = {
              "patient_n_key": localStorage.getItem('_patientKey')
          }
          
          fetch(`${REACT_APP_API_URL}/chat_plan/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
             }).then(response => response.json())
            .then(async (res)=>{
              console.log('selected service',res.data)
                if(res.status === "success"){
                  this.setState({productDetails:res.data,sessions:res.data?.subscription_plan})
                }else{
                  console.log('selected service',res.data)
                }
            })
    }

    couponHandler = (e,i)  => {
        let item = e.target.value; 
        console.log("couponHandlerrrr",item, i)
        let coup = this.state.coupons
        this.setState({coupons:{
            ...coup,
           [i]: item
        }},() => console.log("couponHandler",this.state.coupons))
    }

    selectPayment = (amount, cIndex, sessions,discount) =>{
        let { coupons } = this.state;
        if(coupons && coupons[cIndex]){
          this.submitCoupon(amount,cIndex,sessions,discount)
        }else{
         this.submitPayment(amount,cIndex,sessions,discount)
        }
      }

      submitCoupon = (amount,cIndex,sessions) => {
            // if(e){
            //     e.preventDefault()
            // }
        let { coupons } = this.state;
            let data = {
              "patient_n_key": localStorage.getItem('_patientKey'),
              "promo_code":coupons[cIndex]
          }
          this.setState({loading:true, couponError:null})
          fetch(`${REACT_APP_API_URL}/promocode_check/`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
              },
              body: JSON.stringify(data),
              }).then(response => response.json())
              .then(async (res)=>{
                  if(res.status === "success"){
                   
                      this.setState({ discountPer:res.data, couponApplied:true},()=>this.submitPayment(amount,sessions))
                  }else{
                      this.setState({loading:false,couponError:{[cIndex]:res.data}})
                  }
              })
              .catch(err => console.log('error',err))
      }

      submitPayment = (amount,cIndex,sessions,discount) =>{
        this.setState({selectedPayment:amount.toString()})
        let {productDetails} = this.state
        let data = {
            
                "app_prod_n_key": productDetails.app_prod_n_key,
                "discount": productDetails.subscription_plan[cIndex].discount,
                "discount_amount": productDetails.subscription_plan[cIndex].discount_amount,
                "paid_amount": productDetails.subscription_plan[cIndex].amount - productDetails.subscription_plan[cIndex].discount_amount,
                "patient_n_key": localStorage.getItem('_patientKey'),
                "product": productDetails.subscription_plan[cIndex].product_title,
                "product_amount": productDetails.subscription_plan[cIndex].amount - productDetails.subscription_plan[cIndex].discount_amount,
                "sale_price": productDetails.subscription_plan[cIndex].amount - productDetails.subscription_plan[cIndex].discount_amount,
                "status": "Paid",
                "tax": "0",
                "tax_amount": "0",
                "total_session": productDetails.subscription_plan[cIndex].no_of_session,
                "app_payment_n_key":this.state.productDetails.app_payment_n_key,
          
      }
        fetch(`${REACT_APP_API_URL}/book_chattherapy/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
              if(res.status === "success"){
                console.log(amount,discount);
                localStorage.setItem('app_payment_n_key',res.data.app_payment_n_key)
                  this.setState({loading:false, paymentId:res.data.app_payment_n_key, coupons:null, couponApplied:false})
                  let chatDetails = {
                    "app_payment_n_key": this.state.productDetails.app_payment_n_key,
                    "journey_id": this.state.productDetails.journey_id,
                    "total_session":sessions,
                    "app_prod_n_key": this.state.productDetails.app_prod_n_key,
                    "product_amount": this.state.productDetails.amount,
                    "discount": "",
                    "discount_amount": "",
                    "product": this.state.productDetails.product_title
                }
                localStorage.setItem("chat_details",JSON.stringify(res.data))
                  this.getPaymentLink(amount-discount)
              }else{
                  this.setState({loading:false})
              }
          })
          .catch(err => console.log('error',err))
      }

      getPaymentLink = (amount) =>{
        let orderId = Math.random().toFixed(5).split('.')[1]
        this.setState({orderId})
        const{p_dial_code}=this.state;

        let data = {
        "orderAmount" : amount.toString(), 
        "customerName" : localStorage.getItem('_fullName'), 
        "customerPhone" : localStorage.getItem('_mobileNumber'), 
        "dial_code" : localStorage.getItem('_mobileNumber'), 
        "customerEmail" : localStorage.getItem('_emailId'), 
        dial_code:p_dial_code,
        "returnUrl" :  `${REACT_APP_BASE_URL}/` //http://localhost:3000/paymentstatus
    }
        fetch(`${REACT_APP_API_URL}/create_paymentlink/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
              if(res.status === "success"){
                  console.log('payment link', res)

                  localStorage.setItem('orderId',res.orderId)
                  localStorage.setItem('purchase_type',"APPOINTMENT")
                  window.location.replace(res.data)
              }else{
                  this.setState({loading:false})
              }
          })
          .catch(err => console.log('error',err))
      }


    render() {
        let { loading, sessions, couponApplied, couponError, coupons } = this.state;
        let { t } = this.props;
        return (
            <>

            <div className="therapyhead bg-background  chattherapy col-md-5 mx-auto" >
                {loading && <Loader/>}
                    <h2>{t("Choose how you want to pay :")}</h2>
                    <p className="title">{t("Choose how you want to pay :")}</p>
                <p className="subtitle">{t("You are closer to becoming a better version of yourself. Choose one of the payment options to start your sessions")}</p>
                <ul>
                <li>Unlimited 24/7 text or voice Messaging</li>
                <li>Guaranteed Daily response atleast once a day & 6 days / week</li>
                </ul>
            {sessions.map((item,i) =>{
                      return <Row key={i} className="therapycard">
                        <Col md="2" className="px-2 d-flex align-items-center col-3">
                          {/* <input type="radio" id={i} className="mx-auto" name="plan"  /> */}
                        </Col>
                        <Label  htmlFor={i} className="d-flex">
                        <Col md="8" className="px-2 col-7"   >
                        
                          <CardTitle  className="pay_head">Pay for {item.no_of_session} Session</CardTitle>
                          <CardSubtitle  className="mb-2 text-muted pay_subhead">Guaranteed Daily response atleast once a day & 6 days / week</CardSubtitle>
                            <p>{t('Have a coupon code?')}</p>
                             {/* <form onSubmit={this.submitCoupon}> */}
                            <input type="text" name="coupon" value={coupons && coupons[i]  !== null ?coupons[i]:'' } onChange={(e) => this.couponHandler(e,i)}     className="form-control" />
                            {couponError && couponError[i] !== null ?  <p className='error'>{couponError[i]}</p> : <></>}
                            {couponApplied && <p>Coupon applied</p>}
                            <button type="submit"  style={{display:'none'}} >submit</button>
                          {/* </form> */}
                        </Col>
                        <Col md={4} className="text-center">
                             <h6> <strike>{getCurrencySymbol(item?.currency)} {item.amount}</strike></h6>
                             <h5><b>Save {getCurrencySymbol(item?.currency)} {item.discount_amount}</b></h5>
                            <h4> {getCurrencySymbol(item?.currency)} {item.amount - item.discount_amount}</h4>
                            <button className="btn btn-primary selectbtn" onClick={()=>this.selectPayment(item.amount, i,item.no_of_session,item.discount_amount)}>{t("SELECT")}</button>
                        </Col>
                        </Label>
                    </Row> 
                    })} 
       
            
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        getCareData: state.GetCareData
    }
}

export default connect(mapStateToProps, null)(withRouter(withTranslation()(SelectSession)));
