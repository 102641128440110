import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEng from "./assets/translations/en.json";
import translationHin from "./assets/translations/hin.json";
import translationTam from "./assets/translations/tam.json";
import translationKan from "./assets/translations/kan.json";
import translationMal from "./assets/translations/mal.json";
import translationMalay from "./assets/translations/Malay.json";
import translationMarathi from "./assets/translations/Marathi.json";
import translationTel from "./assets/translations/Telugu.json";
import translationUr from "./assets/translations/ur.json";
import translationBen from "./assets/translations/Bengali.json";

const fallbackLng = ['en'];
const availableLanguages = ['en', 'tam','hin','ben','kan','malay','mal','ur','tel','marathi'];

const resources = {
  en: {
    translation: translationEng
  },
  tam: {
    translation: translationTam
  },
  hin: {
    translation: translationHin
  },
  kan: {
    translation: translationKan
  },
  mal: {
    translation: translationMal
  },
  malay: {
    translation: translationMalay
  },
  ben: {
    translation: translationBen
  },
  ur: {
    translation: translationUr
  },
  tel: {
    translation: translationTel
  },
  marathi: {
    translation: translationMarathi
  }

};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;