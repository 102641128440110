import {SCHEDULE_APP} from './Constants';
import { v4 as uuidv4 } from 'uuid';

export const ScheduleApp = (data) => {
    return {
        type: SCHEDULE_APP,
        payload: {
            id: uuidv4(),
            data
        }
    }
}