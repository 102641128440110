import moment from "moment";
import React, { Component } from "react";
import { BsCheck2Circle, BsCircle } from "react-icons/bs";
import { withRouter } from "react-router";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { RescheduleAction } from "../../../Actions/RescheduleAction";
import { ScheduleAction } from "../../../Actions/ScheduleAction";
import { ScheduleApp } from "../../../Actions/ScheduleApp";
import { TherapyJourneyAction } from "../../../Actions/TherapyJourneyAction";
import { postapi } from "../../../Helpers/Api";
import Store from "../../../Store";
import forarr from "../../../assets/images/forarr.png";
import {
  AUDIO_URL,
  CDN_URL,
  REACT_APP_API_URL,
  REACT_APP_BASE_URL,
  REACT_APP_BU_API_URL,
} from "../../processENV";
import { ToastContainer, toast } from "react-toastify";
const publicIp = require("react-public-ip");

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
class Therapy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      meetingId: "",
      profiledocname: "",
      serverTime: "",
      cancel: "",
      audioLock: false,
      confirmAppointment: [],
      pending: [],
      followUp: [],
      products: [],
      successfulPayment: [],
      therapyForms: [],
      selectP: "",
      credits_available: "",
      prods: [],
      empDetails: [],
      old:false,
      new:false,
      overall_remaining:'',
      _fol_therapist:false,
      _paid_therapist:false,
    };
  }

  componentDidMount(){
    this.getSelectedAppointment()
    this.getNewProd()
    this.getFollowUp()
    this.getPending()
    this.getPaid()
    this.getPatname()
    this.getCredit()
    this.getEvent()
    this.getTherapyAsses()
    this.interval = setInterval(() =>  this.getOrgTime(), 2000);
  }
  componentWillUnmount(){
    clearInterval(this.interval)
  }
  getEvent=async()=>{
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey')
  }
  
    fetch(`${REACT_APP_BU_API_URL}/carme_events/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),

    }).then(response => response.json())
    .then((response)=>{
       if(response.status ==="success"){
         console.log('events_',response)
         this.setState({events_: response.alldata})
       }
     })
     fetch(`${REACT_APP_API_URL}/list_webinar/`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      }
    }).then(response => response.json())
    .then((response)=>{
       if(response){
         console.log('eventsprev_',response)
         this.setState({eventsprev_: response.results})
       }
     })
     
  }
  getTherapyAsses=()=>{
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey')
  }
  
    fetch(`${REACT_APP_API_URL}/home_therapyforms/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),

    }).then(response => response.json())
    .then((response)=>{
       if(response.status ==="success"){
         console.log(response)
         this.setState({
            therapyForms: response.data,   
            _wellbeing:response.welbeing,
            _welluser:response.org_type})
       }
     })
  }
  getLogo=async()=>{
    
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey'),
  }
    fetch(`${REACT_APP_BU_API_URL}/bu_org_details/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
     body: JSON.stringify(data),
    }).then(response => response.json())
    .then((response)=>{
      console.log('bu_details',response)
       if(response){
         this.setState({overall_remaining: response.overall_remaining,bu_logo:response.logo})
       }
     })
  }
  getCredit = async() =>{
    this.getLogo()
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey'),
  }
    fetch(`${REACT_APP_BU_API_URL}/bu_credit_check/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
     body: JSON.stringify(data),
    }).then(response => response.json())
    .then((response)=>{
      console.log('credit_Available',response)
       if(response){
         this.setState({credits_available: response.org_credit})
       }
     })
   }
  getOrgTime = async() =>{
    fetch(`${REACT_APP_API_URL}/org_current_time/`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
    }).then(response => response.json())
    .then((response)=>{
       if(response.status ==="success"){
         this.setState({serverTime: response.data})
       }
     })
   }
  dateConversion = (rdate) =>{
    let date = new Date(rdate);
    // console.log("dateee",date.getDate() + " " + monthNames[date.getMonth()]  + "," +  date.getFullYear()  + "at" +  date.getHours() + ":" + (date.getMinutes() == "0" ? date.getMinutes() + "0" :date.getMinutes()) )
    return  (date.getDate() + " " + monthNames[date.getMonth()]  + "," +  date.getFullYear()  + " at " +  date.getHours() + ":" + (date.getMinutes() == "0" ? date.getMinutes() + "0" :date.getMinutes()) + " " + (parseInt(date.getHours()) < 12 ? "AM" : " PM") )
  }

  dateConversionTherapy = (rdate) =>{
    let date = new Date(rdate);
    // console.log("dateee",date.getDate() + " " + monthNames[date.getMonth()]  + "," +  date.getFullYear()  + "at" +  date.getHours() + ":" + (date.getMinutes() == "0" ? date.getMinutes() + "0" :date.getMinutes()) )
    return  (date.getDate() + " " + monthNames[date.getMonth()]  + "," +  date.getFullYear() )
  }
  
  cancelAppointment = (aKey, reason) =>{
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey'),
      "appointment_n_key": aKey,
      "status":"Cancelled",
     "cancelled_reason":reason
  }
  console.log(data)
  fetch(`${REACT_APP_API_URL}/app_cancel_appoint/`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
    },
    body: JSON.stringify(data),
    }).then(response => response.json())
    .then(async (res)=>{
      console.log('cancel appointment',res.data)
        if(res.status === "success"){
          this.getSelectedAppointment()
        }else{
          console.log('cancel appointment',res.data)
        }
    })
  }
  SchedulePendng=async(key)=>{
      let item=key
      console.log()
      Store.dispatch(ScheduleApp({
        data1:item
      }))
      let jsonData = {
        patient_n_key: localStorage._patientKey,
        app_prod_grp_key:item.app_prod_grp_key,
      };
      fetch(`${REACT_APP_API_URL}/previous_check/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(jsonData),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("cancel appointment", res.data);
          if (res.status == "success") {
                if(res.data[0].is_active==1){
                  this.setState({_fol_therapist:false})
                  let appointmentDetail=JSON.stringify({
                    doc_app_id:res.data[0].employee_n_key,
                    app_prod_grp_key:item.app_prod_grp_key,
                    app_payment_n_key:item.app_payment_n_key,
                    addon:item.addon||false,
                })
                localStorage.setItem('therapy_details',appointmentDetail)
                this.props.history.push('therapyjourney/selectSession/',{action:"Date"})
                }
                else{
                  this.setState({
                    selectP: item,
                    creditModal: true,
                    empDetails: res.data,
                    _fol_therapist:true
                  });
                }
          }
          else{
            this.props.history.push(`/scheduleapp/followup/id`,{data1:item})    
            }
        });
  }

  scheduleApp=async(key)=>{
    console.log(key)
    let item=key
    const ptkey=localStorage._patientKey
    if(this.state.credits_available >0){
    if(item){
      let jsonData = {
        patient_n_key: ptkey,
        app_prod_grp_key: item.app_prod_grp_key,
        app_payment_n_key:item.app_payment_n_key,
        doc_app_id:item.doc_app_id,
        journey_id:item.journey_id
      };
      fetch(`${REACT_APP_API_URL}/previous_check/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(jsonData),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("cancel appointment", res.data);
          if (res.status == "success") {
            if(res.data[0].is_active==1){
              this.setState({_fol_therapist:false})
              fetch(`${REACT_APP_BU_API_URL}/bu_followup_payment/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                },
                body: JSON.stringify(jsonData),
              })
                .then((response) => response.json())
                .then((response)=>{
                  let appointmentDetail=JSON.stringify({
                    doc_app_id:res.data[0].employee_n_key,
                    app_prod_grp_key:item.app_prod_grp_key,
                    app_payment_n_key:response.app_payment_n_key,
                    addon:item.addon||false, 
                })
                localStorage.setItem('therapy_details',appointmentDetail)
                this.props.history.push('therapyjourney/selectSession/',{action:"Date"})
                })
              }
            else{
              this.props?.history?.push('/creditsleft',{data:item,empDetails:res.data})
            }
          }
        });
    } 
    }
  else{
    let jsonData = {
      patient_n_key: ptkey,
      app_prod_grp_key: item.app_prod_grp_key,
      app_payment_n_key:item.app_payment_n_key,
      doc_app_id:item.doc_app_id,
      journey_id:item.journey_id
    };
    const res = await postapi('/previous_check/',jsonData)
    if (res.status == "success") {
      if(res.data[0].is_active==1){
        Store.dispatch(TherapyJourneyAction({
          item:item,
          action:'Followup'
        }))
        this.props.history.push(`/therapyjourney/selectSession/`)
      }
      else {
        this.props?.history?.push('/therapistleft',{data:item,empDetails:res.data})
      }
    }
  } 
  }
        
        
  rescheduleAppointment = (item) =>{
    if(item.overall_status === "Rescheduled"){
      toast.error(`Appointment already rescheduled once, please contact support team!`, {
        position: "top-center",
        autoClose: 5000,
      })
    }
    else{
      Store.dispatch(RescheduleAction({
        prodId:item
    }));
    this.props.history.push(`/reschedule/appointment/date`,{data:item})
    }
  }
  scheduleAppointment =(item) =>{
    if(item){
      let jsonData = {
        patient_n_key: localStorage._patientKey,
        app_prod_grp_key: item.app_prod_grp_key,
        app_payment_n_key:item.app_payment_n_key,
      };
      fetch(`${REACT_APP_API_URL}/previous_check/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(jsonData),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("cancel appointment", res.data);
          if (res.status == "success") {
            if(res.data[0].is_active==1){
              this.setState({_paid_therapist:false})
                  let appointmentDetail=JSON.stringify({
                    doc_app_id:res.data[0].employee_n_key,
                    app_prod_grp_key:item.app_prod_grp_key,
                    app_payment_n_key:item.app_payment_n_key,
                    addon:item.addon||false,
                })
                localStorage.setItem('therapy_details',appointmentDetail)
                this.props.history.push('therapyjourney/selectSession/',{action:"Date",previous:true})
              }
            else{
              this.props.history.push("/creditsleft",{
                selectP: item,
                creditModal: true,
                empDetails: res.data,
                _paid_therapist:true
              });
            }
          }
          else{           
            if(item.title==='Psychiatric Consultation'){
              let selectedProblem={app_prod_n_key:'APP_PROD-3'};
              Store.dispatch(ScheduleAction({
                scheduleStep:0,
                scheduleCurrentStep:0,
                problem:selectedProblem,
                mode:'Video',
                data1:item
            }));
            }
            else{
              Store.dispatch(ScheduleAction({
                scheduleStep:0,
                scheduleCurrentStep:0,
                data1:item
              }))
            }
            this.props.history.push('/schedule/appointment',{data1:item})
          }
        });
    }    
  
  }
  sessionPay=(item)=>{
    Store.dispatch(TherapyJourneyAction({
      item:item,
      action:'Followup'
    }))
    this.props.history.push(`/therapyjourney/selectSession/`)
  }
  getNewProd=async()=>{
    let data={
        "ip": await publicIp.v4() || "",
        "patient_n_key": localStorage.getItem('_patientKey'),
    }
    fetch(`${REACT_APP_API_URL}/products_groups/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),
      }).then(response => response.json())
      .then(async (res)=>{
        console.log('new prod',res.data)
          if(res.status === "success"){
            this.setState({products:res.data})
          }else{
            console.log('new prod',res.data)
          }
      })
  }

  async joinFreeChat(){
    const pt_key = localStorage._patientKey;
    const JsonData={
        patient_n_key :localStorage._patientKey
    }
    fetch(REACT_APP_API_URL+'/counselling_room/', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(JsonData),
      }).then(response => response.json())
      .then(async (res)=>{
        if(res.status==='success'){
          let JsonData1 ={
            channelName: res.channelid,
            uid: pt_key,
          };
          fetch(REACT_APP_API_URL+'/agora_rtctoken/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(JsonData1),
            }).then(response => response.json())
            .then(async (resp)=>{
              if(resp.status=='success'){
                localStorage.uid=pt_key
                localStorage.channelName=res.channelid
                this.props.history.push('/chatscreen',{
                  channelId: res.channelid,
                token: resp.rtm_data,
                uid: pt_key,
                typeofchat: 'CounsellingRoom',
                freeChat:res,
                firstChat:res.new_user,
                doctor_photo:this.state.chat_item&& this.state.chat_item?.support_chat && this.state.chat_item?.support_chat.length !==0?this.state.chat_item?.support_chat[0]?.doctor_photo:null,
                doctor_name:this.state.chat_item&& this.state.chat_item?.support_chat && this.state.chat_item?.support_chat.length !==0?this.state.chat_item.support_chat[0]?.doctor_name:null,
                disable:this.state.chat_item&& this.state.chat_item?.support_chat && this.state.chat_item?.support_chat.length !==0?this.state.chat_item.support_chat[0]?.expired:false,
                })
              }
            })
        }
      })
  }

  getFollowUp=()=>{
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey'),
  }
  
  fetch(`${REACT_APP_API_URL}/upcoming_recommended/`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
    },
    body: JSON.stringify(data),
    }).then(response => response.json())
    .then(async (res)=>{
      console.log('followup service',res)
        if(res.status === "success"){
          this.setState({followUp:res.data})
        }else{
          console.log('followup service',res.data)
        }
    })
  }
  getPaid=()=>{
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey')
  }
  
  fetch(`${REACT_APP_API_URL}/upcoming_payment/`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
    },
    body: JSON.stringify(data),
    }).then(response => response.json())
    .then(async (res)=>{
      console.log('paid service',res.data)
        if(res.status === "success"){
          this.setState({successfulPayment:res.data})
        }else{
          console.log('pending service',res.data)
        }
    })
  }
  getPending=()=>{
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey')
  }
  
  fetch(`${REACT_APP_API_URL}/upcoming_followups/`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
    },
    body: JSON.stringify(data),
    }).then(response => response.json())
    .then(async (res)=>{
      console.log('pendinsg service',res.data)
        if(res.status === "success"){
          this.setState({pending:res.data})
        }else{
          console.log('pendissng service',res.data)
        }
    })
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  
    getPatname=()=>{
      let data = {
        "patient_n_key": localStorage.getItem('_patientKey')
    }
    fetch(`${REACT_APP_API_URL}/patientedit_app/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),
  
    }).then(response => response.json())
    .then((res)=>{
      this.setState({pat_name:res.data.first_name})
    })
    }

  getSelectedAppointment = () =>{
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey')
  }
  
  fetch(`${REACT_APP_API_URL}/upcoming_appoints/`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
    },
    body: JSON.stringify(data),
    }).then(response => response.json())
    .then(async (res)=>{
      console.log('selected service',res.data)
        if(res.status === "success"){
          this.setState({confirmAppointment:res.data})
          // if(res.data.sucessfulappointment){
          //   this.props.setAppointmentStatus(res.data?.sucessfulappointment ? res.data?.sucessfulappointment : null)
          //   this.props.setTherapyStatus(res.data?.therapy_journey ? res.data?.therapy_journey : null)
          // localStorage.setItem('sucessfulappointment',JSON.stringify(res.data.sucessfulappointment))
          // }
        }else{
          console.log('selected service',res.data)
        }
    })
  }
  changeAudio(){
    const timer = setTimeout(() => {
      this.setState({audioLock:false})
    }, 240000);
    return () => clearTimeout(timer);
  }
  getMeetingid = (item,id) =>{
    if(item.mode_of_telehealth ==='Video'){
      let data = {
        "patient_n_key": localStorage.getItem('_patientKey'),
          "appointment_n_key":id
    }
    
    fetch(`${REACT_APP_API_URL}/join_now_app/`, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),
      }).then(response => response.json())
      .then(async (res)=>{
        console.log('meeting id',res.data)
          if(res.status === "success"){
            this.setState({meetingId:res.data})
            let id = res.data.split('/')[4]
            console.log('meeting Id',id)
            window.location.replace(`${REACT_APP_BASE_URL}/patient/`+ id)
          }else{
            console.log('meeting id',res.data)
          }
      })
    }
   if(item.mode_of_telehealth ==='Audio'){
     this.setState({audioLock:true},()=>{this.changeAudio()})
    let data = {
        "org_n_key":"ORGID-1",
        "appointment_n_key":id
  }
  fetch(`${AUDIO_URL}/connect_audio/`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
    },
    body: JSON.stringify(data),
    }).then(response => response.json())
    .then(async (res)=>{
        if(res.status === "success"){
          console.log('audio id',res)
        }else{
          console.log('meeting id',res.data)
        }
    })
   }
   if(item.mode_of_telehealth ==='Chat'){
    var now_time=''
    fetch(`${REACT_APP_API_URL}/org_current_time/`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
        }).then(response => response.json())
        .then((response)=>{
           if(response.status ==="success"){
             now_time= response.data
             var meetingTime = '';
            meetingTime = item.appointment_date;
            const currentTime = moment(response.data).toDate();
            console.log('This_is_current_time', currentTime);
            console.log(moment(this.state.serverTime).toDate());
            var meetingDate = moment(meetingTime).toDate();
            let differenceTIme = (meetingDate.getTime() - currentTime.getTime()) / 1000; //- (19800) //minus offset time 330 mins *60
            var differenceSeconds = parseInt(differenceTIme) / 60;
    
            if(differenceSeconds <= 10){
              let JsonData1 ={
                channelName:item.appointment_n_key,
                uid: localStorage._patientKey,
              };
              fetch(REACT_APP_API_URL+'/agora_rtctoken/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
                },
                body: JSON.stringify(JsonData1),
                }).then(response => response.json())
                .then(async (resp)=>{
                  if(resp.status=='success'){
              localStorage.uid=localStorage._patientKey
              localStorage.channelName=item.appointment_n_key
              this.props.history.push('/chatscreen',{
                  channelId: item.appointment_n_key,
                  empKey: item?.doc_app_id,
                  token: resp.rtm_data,
                  uid: item.patient_n_key,
                  mode: item.mode_of_telehealth,
                  typeofchat: 'TeleHealth',
                  planitem: item,
                  doctor_photo:item.doctor_profile,
                  doctor_name:item.doctor_name,
              })
            }})
          }
            else{
              this.props.history.push('/waitingscreen',{
                item:item,
                typeofchat: 'TeleHealth',
              })
            }
    
    
           }
         })
    
  }
  }
  cancelReason=async(item)=>{
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Could you tell us the reason for cancellation',
      inputPlaceholder: 'Type your message here...',
      inputAttributes: {
        'aria-label': 'Type your message here',
      },
      customClass: {
        validationMessage: 'my-validation-message'
      },
      showCancelButton: true,
      preConfirm: (value) => {
        if (value.length <= 15) {
          Swal.showValidationMessage(
            ' Reason should be atleast 15 characters!'
          )
        }
        else{
          this.cancelAppointment(item&&item.appointment_n_key, value)
        }
      }
    
    })
  }

showbutton=(item)=>{
let nowDate = moment(this.state.serverTime).toDate()
let appDate = moment(item.appointment_date).toDate()
var diff = (appDate.getTime() - nowDate.getTime())/1000
var differenceSeconds = parseInt(diff) / 60 ;
if(differenceSeconds >= 60) {
  return true
}
else return false
}
 showAppointment=(item)=>{
  let nowDate = moment(this.state.serverTime).toDate()
  let appDate = moment(item.appointment_date).toDate()
  var diff = (appDate.getTime() - nowDate.getTime())/1000
  var differenceSeconds = parseInt(diff) / 60 ;
  if(differenceSeconds >= -180) {
  return true
  }
  else return false
 } 

 goToPayment = async (i, title) => {
  const { confirmAppointment, successfulPayment, followUp, pending } =
    this.state;
  const ptkey = localStorage.getItem("_patientKey");
  const initial = "therapy";
  console.log(i, title);
  if (title == "APP-PROD-CAT-1") {
    if (confirmAppointment.length !== 0&&confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
    .length !== 0) {
      confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
        .length !== 0
        ? this.getMeetingid(
            confirmAppointment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-1"
            )[0],
            confirmAppointment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-1"
            )[0].appointment_n_key
          )
        : console.log("no_confirmdata");
    }
   else if (successfulPayment.length !== 0&&successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
    .length !== 0) {
      successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
        .length !== 0
        ? this.scheduleAppointment(
            successfulPayment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-1"
            )[0]
          )
        : console.log("no_paydata");
    }
   else if (followUp.length !== 0&&followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
    .length !== 0) {
      followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
        .length !== 0
        ? this.scheduleApp(
            followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")[0],
            0
          )
        : console.log("no_therapydata");
    }
   else if (pending.length !== 0&&pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1").length !==
    0) {
      pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1").length !==
      0
        ? this.SchedulePendng(
            pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")[0]
              )
        : console.log("no_sessiondata");
    }
   else if (
      (pending.length !== 0 &&
        pending?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-1")
        )) ||
      (confirmAppointment.length !== 0 &&
        confirmAppointment?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-1")
        )) ||
      (successfulPayment.length !== 0 &&
        successfulPayment?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-1")
        )) ||
      (followUp.length !== 0 &&
        followUp?.some((i) => i.app_prod_grp_key?.includes("APP-PROD-CAT-1")))
    ) {
      console.log("yes");
    } else {
      console.log('wer')
      if (this.state.credits_available >= 1) {
        let jsonData = {
          patient_n_key: ptkey,
          app_prod_grp_key: i.app_prod_grp_key,
        };
        fetch(`${REACT_APP_API_URL}/previous_check/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
          body: JSON.stringify(jsonData),
        })
          .then((response) => response.json())
          .then(async (res) => {
            console.log("cancel appointment", res.data);
            if (res.status == "success") {
              fetch(`${REACT_APP_BU_API_URL}/bu_initiate_payment/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                },
                body: JSON.stringify(jsonData),
              })
                .then((response) => response.json())
                .then(async (resp) => {
              if(res.data[0].is_active==1){
                          let appointmentDetail=JSON.stringify({
                          doc_app_id:res.data[0].employee_n_key,
                          app_prod_grp_key:i.app_prod_grp_key,
                          app_payment_n_key:resp.app_payment_n_key 
                          })
                          localStorage.setItem('therapy_details',appointmentDetail)
                          this.props.history.push('therapyjourney/selectSession/',{action:"Date",previous:true})
                 }
              else{
                let py_=i
                py_.app_payment_n_key=resp.app_payment_n_key
                this.setState({
                  selectP: py_,
                  creditModal: true,
                  empDetails: res.data,
                });
              }
            })
        }
        else{
          let jsonData = {
            patient_n_key: ptkey,
            app_prod_grp_key: i.app_prod_grp_key,
          };
          fetch(`${REACT_APP_BU_API_URL}/bu_initiate_payment/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
            },
            body: JSON.stringify(jsonData),
          })
            .then((response) => response.json())
            .then((response)=>{
              Store.dispatch(ScheduleAction({
                scheduleStep:0,
                scheduleCurrentStep:0,
                data1:i,
                app_payment_n_key:response.app_payment_n_key
              }))
            this.props.history.push('/schedule/appointment',{data1:i,app_payment_n_key:response.app_payment_n_key})
            })
        }
      });
  } else {
        this.setState({
          selectP: i,
          nocreditModal: true,
        });
      }
    }
  }
  if (title == "APP-PROD-CAT-2") {
    if (confirmAppointment.length !== 0&& confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
    .length !== 0) {
      confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
        .length !== 0
        ? this.getMeetingid(
            confirmAppointment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-2"
            )[0],
            confirmAppointment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-2"
            )[0].appointment_n_key
          )
        : console.log("no_confirmdata");
    }
   else if (successfulPayment.length !== 0&&successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
    .length !== 0) {
      successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
        .length !== 0
        ? this.scheduleAppointment(
            successfulPayment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-2"
            )[0]
          )
        : console.log("no_paydata");
    }
    else if (followUp.length !== 0&& followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
    .length !== 0) {
      followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
        .length !== 0
        ? this.scheduleApp(
            followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")[0],
            0
          )
        : console.log("no_therapydata");
    }
    else if (pending.length !== 0&&  pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2").length !==
    0) {
      pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2").length !==
      0
        ? this.SchedulePendng(
            pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")[0]
            )
        : console.log("no_sessiondata");
    }
    else if (
      (pending.length !== 0 &&
        pending?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-2")
        )) ||
      (confirmAppointment.length !== 0 &&
        confirmAppointment?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-2")
        )) ||
      (successfulPayment.length !== 0 &&
        successfulPayment?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-2")
        )) ||
      (followUp.length !== 0 &&
        followUp?.some((i) => i.app_prod_grp_key?.includes("APP-PROD-CAT-2")))
    ) {
      console.log("yes");
    } else {
      if (this.state.credits_available >= 1) {
        let jsonData = {
          patient_n_key: ptkey,
          app_prod_grp_key: i.app_prod_grp_key,
        };
        fetch(`${REACT_APP_API_URL}/previous_check/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
          body: JSON.stringify(jsonData),
        })
          .then((response) => response.json())
          .then(async (res) => {
            console.log("cancel appointment", res.data);
            if (res.status == "success") {
              fetch(`${REACT_APP_BU_API_URL}/bu_initiate_payment/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                },
                body: JSON.stringify(jsonData),
              })
                .then((response) => response.json())
                .then(async (resp) => {
              if(res.data[0].is_active==1){
                          let appointmentDetail=JSON.stringify({
                          doc_app_id:res.data[0].employee_n_key,
                          app_prod_grp_key:i.app_prod_grp_key,
                          app_payment_n_key:resp.app_payment_n_key  
                          })
                          localStorage.setItem('therapy_details',appointmentDetail)
                          this.props.history.push('therapyjourney/selectSession/',{action:"Date",previous:true})
                 }
              else{
                let py_=i
                py_.app_payment_n_key=resp.app_payment_n_key
                this.setState({
                  selectP: py_,
                  creditModal: true,
                  empDetails: res.data,
                });
              }
            })
        }
        else{
          let jsonData = {
            patient_n_key: ptkey,
            app_prod_grp_key: i.app_prod_grp_key,
          };
          fetch(`${REACT_APP_BU_API_URL}/bu_initiate_payment/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
            },
            body: JSON.stringify(jsonData),
          })
            .then((response) => response.json())
            .then((response)=>{
              Store.dispatch(ScheduleAction({
                scheduleStep:0,
                scheduleCurrentStep:0,
                data1:i,
                app_payment_n_key:response.app_payment_n_key
              }))
            this.props.history.push('/schedule/appointment',{data1:i,app_payment_n_key:response.app_payment_n_key})
            })
        }
      });
  }  else {
        this.setState({
          selectP: i,
          nocreditModal: true,
        });
      }
    }
  }
  if (title == "APP-PROD-CAT-3") {
    if (confirmAppointment && confirmAppointment.length !== 0&&confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
    .length !== 0) {
      confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
        .length !== 0
        ? this.getMeetingid(
            confirmAppointment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-3"
            )[0],
            confirmAppointment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-3"
            )[0].appointment_n_key
          )
        : console.log("no_confirmdata");
    }
    else if (successfulPayment && successfulPayment.length !== 0&&successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
    .length !== 0) {
      successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
        .length !== 0
        ? this.scheduleAppointment(
            successfulPayment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-3"
            )[0]
          )
        : console.log("no_paydata");
    }
    else if (followUp && followUp.length !== 0&& followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
    .length !== 0) {
      followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
        .length !== 0
        ? this.scheduleApp(
            followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")[0],
            0
          )
        : console.log("no_therapydata");
    }
    else if (pending && pending.length !== 0&&  pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3").length !==
    0) {
      pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3").length !==
      0
        ? this.SchedulePendng(
            pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")[0]
            )
        : console.log("no_sessiondata");
    }
    else if (
      (pending.length !== 0 &&
        pending?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-3")
        )) ||
      (confirmAppointment.length !== 0 &&
        confirmAppointment?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-3")
        )) ||
      (successfulPayment.length !== 0 &&
        successfulPayment?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-3")
        )) ||
      (followUp.length !== 0 &&
        followUp?.some((i) => i.app_prod_grp_key?.includes("APP-PROD-CAT-3")))
    ) {
      console.log("yes");
    } else {
      if (this.state.credits_available >= 1) {
        let jsonData = {
          patient_n_key: ptkey,
          app_prod_grp_key: i.app_prod_grp_key,
        };
        fetch(`${REACT_APP_API_URL}/previous_check/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
          body: JSON.stringify(jsonData),
        })
          .then((response) => response.json())
          .then(async (res) => {
            console.log("cancel appointment", res.data);
            if (res.status == "success") {
              fetch(`${REACT_APP_BU_API_URL}/bu_initiate_payment/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                },
                body: JSON.stringify(jsonData),
              })
                .then((response) => response.json())
                .then(async (resp) => {
                if(res.data[0].is_active==1){
                      let appointmentDetail=JSON.stringify({
                        doc_app_id:res.data[0].employee_n_key,
                        app_prod_grp_key:i.app_prod_grp_key,
                        app_payment_n_key:resp.app_payment_n_key  
                      })
                      localStorage.setItem('therapy_details',appointmentDetail)
                      this.props.history.push('therapyjourney/selectSession/',{action:"Date",previous:true})
                 }
              else{
                let py_=i
                py_.app_payment_n_key=resp.app_payment_n_key
                this.setState({
                  selectP: py_,
                  creditModal: true,
                  empDetails: res.data,
                });
              }
            })
            }
            else{

              let jsonData = {
                patient_n_key: ptkey,
                app_prod_grp_key: i.app_prod_grp_key,
              };
              fetch(`${REACT_APP_BU_API_URL}/bu_initiate_payment/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                },
                body: JSON.stringify(jsonData),
              })
                .then((response) => response.json())
                .then((response)=>{
                  let selectedProblem = { app_prod_n_key: "APP_PROD-3" };
                  Store.dispatch(ScheduleAction({
                    scheduleStep:0,
                    scheduleCurrentStep:0,
                    problem:selectedProblem,
                    mode:'Video',
                    data1:i,
                    app_payment_n_key:response.app_payment_n_key
                  }))
                this.props.history.push('/schedule/appointment',{data1:i,app_payment_n_key:response.app_payment_n_key})
                })
            }
          });
      } else {
        this.setState({
          selectP: i,
          nocreditModal: true,
        });
      }
    }
  }
};

render(){
  if(this.state.doctor_profile === "" || this.state.doctor_profile === null ){
    var docname = this.state.doctor_name
    var res = docname.charAt(0);
    this.setState({
      profiledocname: res
    })

  }
  const {confirmAppointment,successfulPayment, products,followUp,pending, therapyForms,creditModal,nocreditModal,events_,eventsprev_,_wellbeing,_welluser} = this.state;
  const ptkey= localStorage.getItem('_patientKey')


    return (
      <div className='col-md-7 mx-auto pl-md-5 pr-md-0 mt-5' style={{overflowY:'scroll', marginTop:20,marginBottom:40}}>
           <ToastContainer />
            <Modal centered lg isOpen={creditModal} >
                {/* <ModalHeader style={{textAlign:'center'}} >Choose an option to continue with your appointment</ModalHeader> */}
                <ModalBody style={{textAlign:'center'}}>
                {this.state.empDetails[0]&&this.state.empDetails[0].is_active==0? 
                <div className="prec_check_ text_p_ font-weight-bold pt-5">
                Hey, we recently had to let our therapist {`${(this.state.empDetails[0]&&this.state.empDetails[0].first_name)+' '+(this.state.empDetails[0]&&this.state.empDetails[0].last_name)}`} go.
                 If you would like to continue with her/him, send an email <span style={{textDecorationLine:"underline"}}>{this.state.empDetails[0]&&this.state.empDetails[0].email_id}</span> or call <span style={{textDecorationLine:"underline"}}>{this.state.empDetails[0]&&this.state.empDetails[0].phone_number} </span> 
                  <br/>
                  <div>
                  <br/>
                    <p className='text_p_'>
                    You're not alone and we're here for you. We have therapists who can help you rebuild your life, learn and grow from the experience, and get back to where you were before this happened. Please reach out if you are ready to begin.
                    </p>
                  </div><br/>
                  <span style={{fontSize:12}}>Our support number: +91 7829-002-002</span>
                  <div style={{width:'70%'}} onClick={()=>this.setState({old:false,new:true})} className="prec_check mt-4 border rounded text-center mx-auto py-3">
                {this.state.new ? <BsCheck2Circle size={25} style={{marginRight:'4px'}} className="icn_span"/>:<BsCircle style={{marginRight:'4px'}} size={15} className="icn_span" />}{' '}{' '} I would like to switch therapist
                </div>
                </div>:
                <div onClick={()=>this.setState({old:true,new:false})} className="prec_check mt-4 border rounded">
                  {this.state.old ? <BsCheck2Circle size={30} className="icn_span" />:<BsCircle size={15} className="icn_span" />}  I would like to continue with my previous therapist
                  </div>}
                  {/* <span className="in_span">[OR]</span> */}
                
                </ModalBody>
                <ModalFooter>
                <Button color="secondary" onClick={()=>this.setState({creditModal:false})}>Cancel</Button>
                <Button color="primary"
                  disabled={(this.state.old || this.state.new)?false:true}
                onClick={()=>{
                                        this.setState({creditModal:false})
                                        if(this.state.old){
                                          let appointmentDetail=JSON.stringify({
                                            doc_app_id:this.state.empDetails[0].employee_n_key,
                                            app_prod_grp_key:this.state.selectP.app_prod_grp_key
                                          })  
                                          localStorage.setItem('therapy_details',appointmentDetail)
                                          if(this.state.selectP.app_prod_grp_key == 'APP-PROD-CAT-1' && this.state.credits_available >= 1){
                                              this.props.history.push('therapyjourney/selectSession/',{action:"Date"})
                                            }
                                            else if(this.state.selectP.app_prod_grp_key == 'APP-PROD-CAT-2' && this.state.credits_available >= 1){
                                              this.props.history.push('therapyjourney/selectSession/',{action:"Date"})
                                            }
                                            else if(this.state.selectP.app_prod_grp_key == 'APP-PROD-CAT-3' && this.state.credits_available >= 1){
                                              this.props.history.push('therapyjourney/selectSession/',{action:"Date"})
                                            }
                                            else{
                                              let data ={
                                                journeyData:this.state.empDetails[0],
                                                prod:this.state.selectP,
                                                redirect:true,
                                              }
                                              this.props.history.push('carepay',{data:data})
                                            }
                                        }
                                    if(this.state.new){
                                      if(this.state._fol_therapist){
                                        
                                        Store.dispatch(ScheduleAction({
                                          scheduleStep:0,
                                          scheduleCurrentStep:0,
                                          data1:this.state.selectP
                                        }))
                                        this.props.history.push('/schedule/appointment',{data1:this.state.selectP,_fol_therapist:true})
                                      }
                                      else if(this.state._paid_therapist){
                                        if(this.state.selectP.app_prod_grp_key == 'APP-PROD-CAT-3'){
                                          let selectedProblem={app_prod_n_key:'APP_PROD-3'};
                                          Store.dispatch(ScheduleAction({
                                            scheduleStep:0,
                                            scheduleCurrentStep:0,
                                            problem:selectedProblem,
                                            mode:'Video',
                                            data1:this.state.selectP
                                        }))
                                          this.props.history.push('/schedule/appointment',{data1:this.state.selectP,})}
                                       
                                       else{
                                          Store.dispatch(ScheduleAction({
                                            scheduleStep:0,
                                            scheduleCurrentStep:0,
                                            data1:this.state.selectP
                                          }))
                                          this.props.history.push('/schedule/appointment',{data1:this.state.selectP,})}
                                       
                                      }
                                       else{
                                        if(this.state.selectP.app_prod_grp_key == 'APP-PROD-CAT-3' && this.state.credits_available >= 1){
                                          let selectedProblem={app_prod_n_key:'APP_PROD-3'};
                                          Store.dispatch(ScheduleAction({
                                            scheduleStep:0,
                                            scheduleCurrentStep:0,
                                            problem:selectedProblem,
                                            mode:'Video',
                                            data1:this.state.selectP
                                        }))
                                          this.props.history.push('/schedule/appointment',{data1:this.state.selectP,})}
                                       
                                       else if(this.state.credits_available >= 1){
                                          Store.dispatch(ScheduleAction({
                                            scheduleStep:0,
                                            scheduleCurrentStep:0,
                                            data1:this.state.selectP
                                          }))
                                          this.props.history.push('/schedule/appointment',{data1:this.state.selectP,})}
                                       else{
                                          let data =
                                          {
                                            back:'bproductView',
                                            previous:'No',
                                            type:'offerings',
                                            prod:this.state.selectP
                                          }
                                          this.props.history.push('carepay',{data:data})
                                        }
                                    }
                                  }
                }}>Continue</Button>{' '}
                </ModalFooter>
            </Modal>

            <Modal centered isOpen={nocreditModal} >
                <ModalHeader style={{textAlign:'center'}} ></ModalHeader>
                <ModalBody>
                <h3> We’re here to help.
                  </h3>
               <p className="mod_p">
              Sorry! You do not have sufficient credit on your account to book this appointment. However, you can pay the amount and continue booking your appointment with our providers
                </p> 
                </ModalBody>
                <ModalFooter>
                <Button color="secondary" onClick={()=>this.setState({nocreditModal:false})}>Cancel</Button>
                <Button color="primary" onClick={()=>{
                let jsonData={
                        patient_n_key:localStorage.getItem("_patientKey"),
                        app_prod_grp_key:this.state.selectP&&this.state.selectP.app_prod_grp_key
                      }
                      fetch(`${REACT_APP_API_URL}/previous_check/`, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                        },
                        body: JSON.stringify(jsonData),
                      })
                        .then((response) => response.json()).then((res)=>{
                        console.log(res)
                        if(res.status=='success'){
                          if(res.data[0].is_active==1){
                            let data={
                                journeyData:res.data[0],
                                prod:this.state.selectP,
                                redirect:true,
                              }
                            this.props.history.push('carepay',{back:'bproductView', type:'offerings',data:data})
                          }
                          else{
                            this.setState({
                              creditModal:true,
                              empDetails:res.data,
                              nocreditModal:false
                            })
                          }
                        }
                        else{
                         let data={ 
                          type:'offerings',
                          prod:this.state.selectP}
                          this.props.history.push('carepay',{back:'bproductView', type:'offerings',data:data})
                          this.setState({nocreditModal:false})}
                      })
                    
                    
                }}>Start Consult</Button>{' '}
                </ModalFooter>
            </Modal>

        {/* {confirmAppointment.length !== 0 && (
          <h2 className="upcome_appoint">Upcoming Appointments</h2>
        )} */}
       {/* {confirmAppointment.length !==0 &&   <h2 className='upcome_appoint'>Upcoming Appointments</h2>} */}
{confirmAppointment.length !== 0 && confirmAppointment.map((item, index)=>{
  return(
    this.showAppointment(item) && 
    <div className='col-md-7 mx-auto draw shadow rounded p-4 bg-white mt-5 mb-3' >
    <div className="row px-3 text-center">
      <img style={{width:'65px',height:'65px'}} src={`${CDN_URL}/APP/appointment.png`} />
      <h3 className='pl-1 pt-4' style={{fontFamily:'Source Serif Pro',fontSize:'18px'}}>Appointment Confirmed!</h3>
    </div>
    <div className="row px-3 mt-2">
      <p className='text_p_ text_medium_'>
      We're looking forward to speaking with you, {item.patient_name}! Your upcoming session has been scheduled at {this.dateConversion(item && item.appointment_date)} with {item.doctor_name}</p>
    </div>
    <div className="row px-3 text-center mt-2 d-flex flex-row justify-content-center">
    {item.mode_of_telehealth ==="Video" && <button className={`btn  m-1 ${!this.showbutton(item)?'btn-block':''} ${!this.showbutton(item)?'btn_Schdule':'btn_Schdules'}`} onClick={()=>this.getMeetingid(item,item && item.appointment_n_key)}>Join Call</button>}
    {item.mode_of_telehealth ==="Audio" && <button className={`btn m-1 ${!this.showbutton(item)?'btn-block':''} ${!this.showbutton(item)?'btn_Schdule':'btn_Schdules'}`} onClick={()=>this.getMeetingid(item, item && item.appointment_n_key)}>Call Now</button>}
    {item.mode_of_telehealth ==="Chat" && <button className={`btn  m-1 ${!this.showbutton(item)?'btn-block':''} ${!this.showbutton(item)?'btn_Schdule':'btn_Schdules'}`} onClick={()=>this.getMeetingid(item,item && item.appointment_n_key)}>Join Chat</button>}
    {this.showbutton(item) && <button className="btn btn_Schdules m-1" style={{fontSize:'10px'}} onClick={()=>this.rescheduleAppointment(item && item)}>Reschedule</button>}
    {this.showbutton(item) &&  <button className="btn btn_Schdules m-1" style={{fontSize:'10px'}} onClick={()=>this.cancelReason(item)}>Cancel</button>}
    </div>
    </div>

    // <Col className="selected_service pl-md-0">
    //   <Card className="draw">
    //     <Link>
    //       <Row>
    //         <Col lg="12" md="10" className="px-2 col-12">
    //         <div className="selec_img">
    //           <img src={`${CDN_URL}/APP/appointment.png`} />
    //           </div>
    //           </Col>
    //           <div className="row">
    //           <div className="col-lg-10">
    //           <CardSubtitle tag="h6" className="mb-2 text-muted">We're looking forward to speaking with you, {item.patient_name}! Your upcoming session has been scheduled at {this.dateConversion(item && item.appointment_date)} with {item.doctor_name}</CardSubtitle>
    //           </div>
    //         <div className="col-lg-2 col_view my-auto px-2">
    //         <Col className="pl-0 col_view paynow  ">
    //         <div style={{display:'flex',flexDirection:'column'}}>
    //          {item.mode_of_telehealth ==="Video" && <button className="btn btn-primary" onClick={()=>this.getMeetingid(item,item && item.appointment_n_key)}>Join Call</button>}
    //          {item.mode_of_telehealth ==="Audio" && <button disabled={this.state.audioLock} className="btn btn-primary" onClick={()=>this.getMeetingid(item, item && item.appointment_n_key)}> Call Now</button>}
    //          {item.mode_of_telehealth ==="Chat" && <button className="btn btn-primary" onClick={()=>this.getMeetingid(item,item && item.appointment_n_key)}>Join Chat</button>}
    //         {this.showbutton(item) && <button className="btn btn-primary" onClick={()=>this.rescheduleAppointment(item && item)}>Reschedule</button>}
    //           {/* <button className="btn btn-primary" onClick={()=>this.cancelAppointment(item?.sucessfulappointment && item.sucessfulappointment[0]?.appointment_n_key)}>cancel</button> */}
    //           {this.showbutton(item) &&  <button className="btn btn-primary" onClick={()=>this.cancelReason(item)}>cancel</button>}
    //           </div>
    //         </Col>
    //         </div>
    //         </div>
    //       </Row>    
    //      </Link>
    //   </Card>
    // </Col>
    )
  })
  }
  
  
 {/* { confirmAppointment.length==0 && successfulPayment.length==0 && pending.length !== 0 &&  <h2  className='upcome_appoint'> Book your Follow-up Session</h2>} */}
 { confirmAppointment.length==0 && successfulPayment.length==0 &&pending.length==0 && followUp.length !== 0 && followUp.map((item, index)=>{
  return(
    <div className='col-md-7 mx-auto draw shadow rounded p-4 bg-white mt-5 mb-3' >
    <div className="row px-3 text-center">
      <img style={{width:'55px',height:'55px'}} src={`${CDN_URL}/APP/app/measring.png`} />
      <h3 className='pt-3 pl-2' style={{fontFamily:'Source Serif Pro'}}>Book Your Folloup</h3>
    </div>
    <div className="row px-3 mt-2">
    <p className='text_p_ text_medium_'>
    We’re ready when you are. Let's start this Journey! Your therapist <a style={{textDecoration:'underline',color:'blue',cursor:"pointer",zIndex:99}} href={`https://careme.health/provider/${item.doc_app_id}`} target="_blank">{item.doctor_name}</a>{' '}
    {this.state.credits_available >0? `has recommended ${item.total_sessions_recommended} sessions for you.`: `has crafted a personalised care plan for you`} </p>
   </div>
    <div className="row px-3 text-center mt-2">
      <button className='btn btn-block btn_Schdule' onClick={()=>this.scheduleApp(item &&  item)}> {this.state.credits_available>0? 'Book Now':'View care plan'}</button>
    </div>
    </div>

    )
  })
  }
   {/* {confirmAppointment.length==0 && successfulPayment.length==0 && pending.length==0&& followUp.length !== 0 &&  <h2  className='upcome_appoint'>Book Your Follow-up Appointments</h2>} */}
   { confirmAppointment.length==0 && successfulPayment.length==0 && pending.length !== 0 && pending.map((item, index)=>{
  return(
    <div className='col-md-7 mx-auto draw shadow rounded p-4 bg-white mt-5 mb-3' >
    <div className="row px-3 text-center">
      <img style={{width:'35px',height:'35px'}} src={`${CDN_URL}/APP/calendar1.png`} />
      <h3 className='pt-2 pl-2' style={{fontFamily:'Source Serif Pro'}}><b>{item.remaining_session}</b> Upcoming Sessions</h3>
    </div>
    <div className="row px-3 mt-2">
      <p className='text_p_ text_medium_'>
    That's Awesome! you have completed {item.total_session - item.remaining_session} sessions out of {" "}
                {item.total_session}, {" "}
                 let's progress towards your goals, re-evaluating along the
                 way-by your therapist!</p>
    </div>
    <div className="row px-3 text-center mt-2">
      <button className='btn btn-block btn_Schdule' onClick={()=>this.SchedulePendng(item && item)}>Schedule Now</button>
    </div>
    </div>
    )
  })
  }
     {confirmAppointment.length ==0 && successfulPayment.length !== 0 && successfulPayment.map((item, index)=>{
  return(
    
    <div className='col-md-7 mx-auto draw shadow rounded p-4 bg-white mt-5 mb-3' >
    <div className="row px-3 text-center">
      <img style={{width:'65px',height:'65px'}} src={`${CDN_URL}/APP/appointment.png`} />
      <h3 className='pt-3 pl-2' style={{fontFamily:'Source Serif Pro'}}><b>{item.remaining_session}</b> Book your session</h3>
    </div>
    <div className="row px-3 mt-2">
      <p className='text_p_ text_medium_'>
      We've received your payment and our therapist is waiting for you, click book now to book your appointment</p>
    </div>
    <div className="row px-3 text-center mt-2">
      <button className=' btn btn-block btn_Schdule' onClick={()=>this.scheduleAppointment(item && item)}>Book Now</button>
    </div>
    </div>
    )
  })
  }
        <div className='col-md-8 d-flex flex-column mx-auto mt-0 px-2 px-4'>
        <h3 className='text-center mt-5 pt-5'>What type of service are you looking for?</h3>
        <p className='text-center'>CareMe offers Confidential care that's accessible, affordable, and evidence-based.</p>
          {/* <div onClick={()=>window.open('https://caremehealth.synapstics.com/individual-therapy')} className='offerings_card'> */}

          {this.state.products.length > 0 &&
          <>
          <div onClick={()=>this.goToPayment(products[0],'APP-PROD-CAT-1')} className='shadow-sm border d-flex col-md-9 p-2 mx-auto mb-2 mt-3 bg-white rounded position-relative'>
          <img className='img-fluid ml-md-3' style={{maxHeight:'55px'}} src={`${CDN_URL}/APP/individual.png`} />
          <h3 className='pt-4 pb-4 pl-3 h5 ' >Book a therapist</h3>
          <img className='img-fluid ml-5 mr-n4 position-absolute' style={{maxHeight:'30px',right:34}} src={forarr}  />
          </div>
          <div onClick={()=>this.goToPayment(products[1],'APP-PROD-CAT-2')} className='shadow-sm border d-flex col-md-9 p-2 mx-auto mb-2 mt-3 bg-white rounded position-relative'>
          <img src={`${CDN_URL}/APP/couple.png`} className='img-fluid ml-md-3' style={{maxHeight:'55px'}}/>
          <h3 className='pt-4 pb-4 pl-3 h5 '>Book a couple therapy</h3>
          <img  src={forarr} className='img-fluid ml-5 mr-n4 position-absolute' style={{maxHeight:'30px',right:34}} />
          </div>
          <div onClick={()=>this.goToPayment(products[2],'APP-PROD-CAT-3')} className='shadow-sm border d-flex col-md-9 p-2 mx-auto mb-4 mt-3 bg-white rounded position-relative'>
          <img src={`${CDN_URL}/APP/stestscope12%20copy.png`} className='img-fluid ml-md-3' style={{maxHeight:'55px'}}/>
          <h3 className='pt-4 pb-4 pl-md-3 pl-1 h5 '>Book a psychiatrist</h3>
          <img  src={forarr} className='img-fluid ml-5 mr-n4 position-absolute' style={{maxHeight:'30px',right:34}} />
          </div>
          <div onClick={()=>this.joinFreeChat()} className='shadow-sm border d-flex col-md-9 p-2 mx-auto mb-4 mt-3 bg-white rounded position-relative'>
          <img src={`${CDN_URL}/APP/care%20coach%20copy.png`} className='img-fluid ml-md-3' style={{maxHeight:'55px'}}/>
          <h3 className='pt-4 pb-4 pl-md-3 pl-1 h5 '>Chat with care coach</h3>
          <img  src={forarr} className='img-fluid ml-5 mr-n4 position-absolute' style={{maxHeight:'30px',right:34}} />
          </div>
          </>}
          {/* <div onClick={()=>this.goToPayment('Individual Therapy','APP-PROD-CAT-1')} className='offerings_card'>
        <img src={individual} className='offerings_p_img'/>
        <h3>Individual Therapy</h3>
        <span className='offerings_span'>Care Redefined</span>
        <img src={forarr} className='offerings_arr1_img' />
        </div>
        <div onClick={()=>this.goToPayment('Couples Therapy','APP-PROD-CAT-2')} style={{background:'#F9DCDC'}} className='offerings_card'>
        <img src={couple} className='offerings_p_img'/>
        <h3>Couples Therapy</h3>
        <span className='offerings_span'>Talk it Out</span>
        <img  src={forarr} className='offerings_arr2_img' />
        </div>
        <div onClick={()=>this.goToPayment('Psychiatrist','APP-PROD-CAT-3')} style={{background:'#F1F1E6'}} className='offerings_card'>
        <img src={psychiatric} className='offerings_p_img'/>
        <h3>Psychiatrist</h3>
        <span className='offerings_span'>when you need it</span>
        <img  src={forarr} className='offerings_arr3_img' />
        </div>
        <div style={{background:'#EBE3F0'}} className='offerings_card'>
        <img src={group} className='offerings_p_img'/>
        <h3>Group Therapy</h3>
        <span className='offerings_span'>Come as you are</span>
        <img  src={forarr} className='offerings_arr4_img' />
        <span className='off_soon'>Coming soon</span>

        </div> */}
        </div>
      </div>
    );
  }
}

export default withRouter(Therapy);
