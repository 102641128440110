import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ProviderComponent from './Provider/ProviderComponent';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-image-crop/dist/ReactCrop.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-number-input/style.css'
import 'react-toastify/dist/ReactToastify.css';
import { Component, Suspense } from "react";

import './i18n.js';

import "flatpickr/dist/themes/material_green.css";
import Loader from './Components/Layouts/Loader';


if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}
ReactDOM.render(
  <Suspense fallback={<Loader />}>
  <React.StrictMode>
    <ProviderComponent />
  </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
