import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaCameraRetro } from "react-icons/fa";
import ShowMoreText from "react-show-more-text";
import { FaHeart } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { FaRegCommentAlt } from "react-icons/fa";
import { BsChat, BsHeart, BsHeartFill, BsShareFill } from "react-icons/bs";
import { Link, withRouter } from "react-router-dom";
import { CURE_URL, REACT_APP_API_URL } from "../processENV";
import { AiFillCloseSquare, AiOutlineHeart } from "react-icons/ai";
import ReactPlayer from "react-player";
import axios from "axios";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Modal } from "react-bootstrap";
import Threedots from "../../assets/images/Threedots.gif";
import { animateScroll as scroll } from "react-scroll";
import cam from "../../assets/images/cam.png";
import uer from "../../assets/images/uer.png";
import communitybanner from "../../assets/images/communitybanner.png";
import cmmty from "../../assets/images/cmmty.png";
import { CheckCircle, Circle, MoreVertical } from "react-feather";
import { useRef } from "react";
import {PiHeartThin} from 'react-icons/'

const ShowTag = (props) => {
  const capitalize = (string) => {
    return string[0].toUpperCase() + string.slice(1);
  };
  return (
    <p
      className="position-absolute"
      style={{
        color: "#fff",
        borderRadius: "2px",
        backgroundColor: "#6db0af",
        padding: "2px",
        fontWeight: "500",
        right: "0px",
        top: "0px",
      }}
    >
      {props.id ? capitalize(props.id) : ""}
    </p>
  );
};

const RoundLetter = ({ text }) => {
  if (!text) return null; // Handle empty input gracefully

  const firstLetter = text.charAt(0).toUpperCase();

  return (
    <div
      className="round-letter"
      style={{
        backgroundColor: 'white',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ed9015',
        fontWeight: 'bold',
        fontSize: '18px',
        marginRight:'4px'
      }}
    >
      {firstLetter}
    </div>
  );
};
const Posts = (props) => {
  const [post, setPost] = useState();
  const [disable, setDisable] = useState(true);
  const [file, setFile] = useState();
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [topics, setTopics] = useState();
  const [showPopup, setShowPopup] = useState();
  const [transferred, setTransferred] = useState(0);
  const [uploading, setUploading] = useState();
  const [share, setShare] = useState(false);
  const [sharecon, setShareCon] = useState();
  const [authenticated, setAuthenticated] = useState(false);
  const [shareItem, setShareItem] = useState();
  const [Mod, setMod] = useState(false);
  const [Modshow, setModshow] = useState(false);
  const [activityid, setActivityId] = useState(0);
  const [inx, setInx] = useState("");
  const [navbar, setNavbar] = useState(false);
  const divref = useRef();

  const [repr, setRepr] = useState([
    { name: "Advertising", select: false },
    { name: "Hate", select: false },
    { name: "Abusive", select: false },
    { name: "Other", select: false },
  ]);

  useEffect(() => {
    console.log(props.user)
    let auth = localStorage.status;
    if (auth === "completed") {
      setAuthenticated(true);
    }
    getTopic();
  }, []);
  useEffect(() => {
    if (file && file.size >= 20971520) {
      alert(`file size shouldn't exceed 20MB`);
    } else {
      var type = file && file.type.split("/")[0];
      if (file && file.type && type === "video") {
        let Uri = URL.createObjectURL(file);
        setVideo(Uri);
      }
      if (file && file.type && type === "image") {
        let Uri = URL.createObjectURL(file);
        setImage(Uri);
      }
    }
  }, [file && file]);
  useEffect(() => {
    if (post && post.length >= 5) {
      getTopic();
    } 
  }, [post && post]);

  const validateValue = (val) => {
    if (authenticated) {
      let value = val;
      if (value.length >= 10) {
        setPost(value);
        setDisable(false);
      } else {
        setDisable(true);
        setTopics(null);
      }
    } else {
      setShowPopup(true);
    }
  };
  const submitPost = (id) => {
    const config = {
      onUploadProgress: function (progressEvent) {
        const { loaded, total } = progressEvent;
        var percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}%`);
        if (percent < 100) {
          setTransferred(percent);
        }
      },
    };
    setUploading(true);
    let useData = JSON.stringify({
      user_key: localStorage._patientKey,
      text: post,
      topic_id: id ? id : "1",
    });
    let mediatype = file && file.type.split("/")[0];
    var form = new FormData();
    form.append("data", useData);
    form.append("media", mediatype);
    form.append(mediatype, file);
    console.log(form);
    axios
      .post(`${CURE_URL}/community/activity_post/`, form, config, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
      })
      .then((resp) => {
        console.log(resp);
        setTransferred(100);
        setTimeout(() => {
          setTransferred(0);
        }, 1000);
        if (resp.status === 200) {
          setPost('')
          setTimeout(() => {
            window.location.reload();
            setUploading(true);
          }, 2000);
        }
      })
      .catch((error) => {
        console.log("error is", error);
      });
  };
  const likeString = (id, i, item) => {
    if (authenticated) {
      var count = i;
      let updateitem = props.item.forEach((i, index) => {
        if (id === index) {
          return (i.likes = count + 1), (i.liked = true);
        }
      });
      setPost({ ...post, updateitem });
      setTopics(null)
      const data = {
        user_key: localStorage._patientKey,
        activity_id: item.id,
        reaction: true,
      };
      fetch(`${CURE_URL}/community/activity_reaction/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("community post", res);
          if (res.status === "success") {
          } else {
            console.log("error");
          }
        })
        .catch((err) => console.log(err));
    } else {
      setShowPopup(true);
    }
  };
  const onDislike = (id, i, item) => {
    if (authenticated) {
      if (i !== 0) var count = i;
      let updateitem = props.item.forEach((i, index) => {
        if (id === index) {
          return (i.likes = count - 1), (i.liked = false);
        }
      });
      setPost({ ...post, updateitem });
      setTopics(null)
      const data = {
        user_key: localStorage._patientKey,
        activity_id: item.id,
        reaction: false,
      };
      fetch(`${CURE_URL}/community/activity_reaction/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("community post", res);
          if (res.status === "success") {
          } else {
            console.log("error");
          }
        })
        .catch((err) => console.log(err));
    } else {
      setShowPopup(true);
    }
  };
  const getTopic = () => {
    const data = {
      user_key: localStorage._patientKey || "",
    };
    fetch(`${CURE_URL}/community/community_topics/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log('reio',res.data)
        if (res.status === "success") {
          setTopics(res.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => console.log(err));
  };
  const goLogin = () => {
    window.location.replace("/");
  };
  const checkUser = () => {
    if (authenticated) {
      console.log("null");
    } else {
      setShowPopup(true);
    }
  };
  const sendReport = (id) => {
    let fil = [...repr];
  fil = fil.map((item, index) => {
    if (index === id) {
      return { ...item, select: true };
    }
    return { ...item, select: false };
  });
  setRepr(fil);
  };
  const handlePres = (id) => {
    setModshow(false);
    setMod(true);
    setInx("");
    setActivityId(id)
  };
  const disSubmit = () => {
      const dat=[
    {name:'Advertising',select:false},
    {name:'Hate',select:false},
    {name:'Abusive',select:false},
    {name:'Other',select:false},
  ]
  const data = {
    user_key: localStorage._patientKey,
    activity_id:activityid,
    comments:repr.filter(i=>i.select)[0].name,
  }
  fetch(`${CURE_URL}/community/report_activity/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json()).then(async(res)=>{
    if(res.status ==="success"){
      setMod(false);
      setRepr(dat);
    }
  }).catch((err)=>{console.log(err)})
  
  };
  const goComment = (i) => {
    if (authenticated) {
      props.history.push(`./comment/${i.id}`, { id: i });
    } else {
      setShowPopup(true);
    }
  };
  const Capitalize = (str) => {
    const capt = str[0].toUpperCase() + str.substring(1);
    return capt;
  };
  const setTxt = (id) => {
    setModshow(true);
    setInx(id);
  };

  return (
    <div className="col-md-10 mx-auto p-md-2 p-1">

      <Modal centered show={uploading && transferred}>
       { uploading && transferred && (
        <div className="p-5">
      <ProgressBar color="#215350" className="mt-3" now={transferred} label={`${transferred}%`} />
      <p className="text-center">Posting <img src={Threedots} alt="" width={20} height={10} /></p>
      </div>
      )}</Modal>

      {showPopup && (
        <div className="position-relative">
          <Modal scrollable centered show={showPopup}>
            <AiFillCloseSquare
              style={{ right: "0px", top: "0px" }}
              onClick={() => setShowPopup(false)}
              className="position-absolute"
              size={25}
              color="red"
            />
            <div className="pt-5 mt-3 text-center">
              <h3 style={{ fontFamily: "Sours serif", fontWeight: "bold" }}>
                {" "}
                Get Started with CareMe Community
              </h3>
            </div>
            <div>
              <div className="">
                <img className="img-fluid" src={cmmty} />{" "}
              </div>
            </div>
            <div className="mx-auto text-center col-md-12 my-4">
              <buttton
                style={{
                  background: "#ba68c8",
                  color: "#fff",
                  fontSize: "16px",
                }}
                className="btn btn-block border rounded font-weight-bold"
                onClick={() => goLogin()}
              >
                Sign Up
              </buttton>
            </div>
          </Modal>
        </div>
      )}
      
      <Modal centered show={Mod}>
      <div className="text-center py-2" style={{backgroundColor:'#215352'}}>
           <p style={{fontSize:'22px'}} className="text-white">Report post</p>
          </div>
          <p className="pl-2 font-weight-light pt-4"  style={{fontSize:'16px'}}>Please provide the reason for reporting:</p>
        
        {repr.map((i, indx) => {
          return (
            <button
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 4,
                marginLeft: 20,
                border: "none",
                background: "none",
              }}
              onClick={() => sendReport(indx)}
            >
              {!i.select ? (
                <Circle size={17} style={{ margin: "8px" }} />
              ) : (
                <CheckCircle size={17} color={"green"} style={{ margin: "8px" }} />
              )}
              <span
                style={{
                  margin: "6px",
                  fontSize: 17,
                  fontFamily: "Poppins-Regular",
                }}
              >
                {i.name}
              </span>
            </button>
          );
        })}
        <div
          style={{
            float: "right",
            margin: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <button
            onClick={() => setMod(false)}
            style={{
              margin: "4px",
              background: "#215352",
              color: "#fff",
              fontWeight: "bold",
              opacity: 0.8,
            }}
            className="border rounded px-3 py-2"
          >
            Cancel
          </button>
          <button
            onClick={() => disSubmit()}
            style={{
              margin: "4px",
              background: "#215352",
              color: "#fff",
              fontWeight: "bold",
            }}
            className="border rounded px-3 py-2"

          >
            Send
          </button>
        </div>
      </Modal>

      <div
        className="col-md-10 mx-auto my-4 rounded  position-relative pb-3"
        style={{ backgroundColor: "#e6f0ea" }}
      >
        <div className="d-flex mt-1 ">
          <img
            className="img-fluid rounded-circle mt-n5"
            width="50"
            height="50"
            src={props.user ? props.user.avatar : uer}
          />
          <textarea
            onClick={() => checkUser()}
            onKeyPress={() => checkUser()}
            onChange={(e) => validateValue(e.target.value)}
            placeholder="Ask questions or share what you know"
            className="form-control mt-5 ml-2 pt-3 pl-3 shadow-sm"
            style={{ height: "140px" }}
          />
        </div>
        {image && (
          <div
            style={{ maxWidth: "70px", marginLeft: "60px" }}
            className="border rounded p-2 my-2 position-relative"
          >
            <img
              className="img-fluid p-1 pl-2"
              src={image}
              width="50"
              height="50"
            />
            <AiFillCloseSquare
              onClick={() => setImage(null)}
              className="position-absolute"
              color="red"
              size={15}
              style={{ top: "0px", right: "0px" }}
            />
          </div>
        )}
        {video && (
          <div
            style={{ maxWidth: "70px", marginLeft: "60px" }}
            className="border rounded p-2 my-2 position-relative"
          >
            <ReactPlayer
              controls={true}
              className="img-fluid p-1 pl-2"
              style={{ marginLeft: 10 }}
              width={50}
              height={50}
              playIcon
              url={video}
            />
            <AiFillCloseSquare
              onClick={() => setVideo(null)}
              className="position-absolute"
              color="red"
              size={15}
              style={{ top: "0px", right: "0px" }}
            />
          </div>
        )}
        {!image && !video && (
          <>
            <label className="ml-3" for="file-input">
              {" "}
              <img
                className="ml-5 my-3 img-fluid"
                src={cam}
                height="26px"
                width="26px"
              />
              {/* <span className="ml-2 font-weight-bold">Photo</span> */}
            </label>

            <input
              type="file"
              disabled={!authenticated}
              id="file-input"
              className="d-none"
              accept="image/*"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
          </>
        )}
        <button
          className={`btn  position-absolute font-weight-bold`}
          disabled={disable}
          style={{
            right: "15px",
            bottom: "25px",
            background: "#215352",
            color: "#fff",
          }}
          onClick={() => {
            submitPost();
          }}
        >
          Share
        </button>
      </div>
      <div className={` col-md-10  rounded shadow mx-auto position-relative`}>
        {post && topics && (
          <>
            {" "}
            <AiFillCloseSquare
              onClick={() => {
                setTopics(null);
              }}
              size={24}
              color="red"
              className="position-absolute"
              style={{ right: "0px", top: "0px" }}
            />
            <p className="mt-5 pt-4">
              Would you like to share it on specific group?
            </p>
          </>
        )}
        <div className="tag_section">
          {post && topics && (
            <>
              {topics.map((i, index) => {
                return (
                  <div
                    onClick={() => submitPost(i.topic_id)}
                    className="inner_topic rounded p-1"
                  >
                    {Capitalize(i.topic)}
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>

      {props.item.map((i, index) => {
        return (
          <>
            {/* <div  className={`Post_container ${topics ? 'pull_down':null}`}>            */}
            <div
              style={{ background: "#f0f0f0",border:'.6px solid #f0f0f0',}}
              className={`col-md-11 mx-auto my-md-5 my-4 py-3  rounded position-relative`}
            >
              <div className="d-flex flex-row mt-3 mt-md-4">
                <div>
                  {i.userImg ? (
                    <img
                      className="rounded-circle"
                      src={i.userImg}
                      alt=""
                      width="50"
                      height="50"
                    />
                  ) : (
                    <img src={uer} alt="" width="50" height="50" />
                  )}
                </div>
                <div className="d-flex flex-column ml-2">
                  <h4
                    style={{ fontSize: "20px" }}
                    className="text-left font-weight-bold"
                  >
                    {i.userName}{" "}
                    {i.role ? (
                      i.role !== "User" ? (
                        <span
                          style={{
                            background: "#ed9d34",
                            color: "#fff",
                            fontWeight: "bold",
                            padding: "5px",
                            fontSize: "11px",
                          }}
                        >
                          {i.role}
                        </span>
                      ) : null
                    ) : (
                      ""
                    )}
                  </h4>
                  <h5 style={{ fontSize: "12px" }} className="text-left">
                    {moment(i.postTime).fromNow()}
                  </h5>
                </div>
              </div>
              {i.title ? <ShowTag id={i.title} /> : null}


              <div className="post_text mx-auto pt-md-2 pt-1 pl-md-5 text-left">
                  <ShowMoreText
                    /* Default options */
                    lines={3}
                    className="mt-2 mx-auto text-left text_p_"
                    more="Show more"
                    less="Show less"
                    expanded={false}
                    truncatedEndingComponent={"... "}
                  >
                    {i.post}
                  </ShowMoreText>
              </div>
              <div className="pb-md-3 pb-2" style={{ textAlign: "center" }}>
                {" "}
                {i.mediaType === "image" && i.postImg && (
                  <img src={i.postImg} width={250} height={20} alt="" />
                )}
              </div>
              <div style={{ textAlign: "center" }}>
                {" "}
                {i.mediaType === "video" && i.postImg && (
                  <ReactPlayer
                    className="video_Player"
                    controls={true}
                    style={{ margin: "0 auto" }}
                    width={450}
                    height={250}
                    playIcon
                    url={i.postImg}
                  />
                )}
              </div>
              <div className="d-flex flex-row justify-content-between px-3 px-md-5 my-1 pb-3" style={{opacity:.7,fontWeight:'lighter'}}>
                <div className="s_icons d-flex border flex-row rounded-lg p-2 shadow-sm" style={{background:"#fcfcfc",cursor:'pointer'}}>
                  <p className="p-0 m-0">
                  {i.liked ? (
                    <BsHeartFill
                      onClick={() => onDislike(index, i.likes, i)}
                      color="red"
                      size={20}
                    />
                  ) : (
                    <BsHeart
                      onClick={() => likeString(index, i.likes, i)}
                      size={20}
                    />
                  )}</p>
                  <p className="p-0 pl-1 m-0">{i.likes > 0 ? i.likes == 1?i.likes+' '+'like':i.likes>1?i.likes+' '+'likes':null:'Like'}</p>
                </div>
                <div className="s_icons d-flex rounded-lg border p-2 shadow-sm" style={{background:"#fcfcfc",cursor:'pointer'}}>
                  <p className="p-0 m-0">
                  <BsChat
                    onClick={() => {
                      goComment(i);
                    }}
                    className="post_like"
                    size={20}
                  /></p>
                  <p className="p-0 pl-1 m-0" onClick={() => {
                      goComment(i);
                    }}
                  >
                    {i.comments !== 0?i.comments==1?i.comments+' '+'comment':i.comments>1?i.comments+' '+'comments':null:'No comments'}
                  </p>
                </div>
                <div className="d-flex rounded-lg p-2 shadow-sm" style={{background:"#fcfcfc",cursor:'pointer',border:'1px solid #fff'}}>
                <MoreVertical size={20} onClick={() => setTxt(index)} />
                </div>
              </div>
              {Modshow && index == inx ? (
                <div
                  onClick={() => handlePres(i.id)}
                  className="position-absolute"
                  style={{
                    background: "#fff",
                    paddingTop: "12px",
                    paddingBottom: "4px",
                    border: "1px solid #d9d9d9",
                    marginRight: "30px",
                    cursor: "pointer",
                    marginTop: "4px",
                    width: "100px",
                    float: "right",
                    boxShadow: "0px 0px 2px 2px #dcdcdc",
                    zIndex: 999,
                    right: "0px",
                  }}
                >
                  <p style={{ textAlign: "center" }}>Report</p>
                </div>
              ) : null}

              <div 
              onClick={() =>goComment(i)} 
                className=" px-3 mx-md-5 d-flex shadow-sm rounded-lg mt-3 py-2"
                style={{background:'#fcfcfc'}}
                >
                <img 
                 className="rounded-circle"
                 src={props.user ? props.user.avatar : uer}
                 alt=""
                 width="30"
                 height="30"
                />
                <p style={{opacity:.7,fontWeight:'lighter'}} className="align-self-center m-0 pl-3">Lend a hand...</p>
              </div>

            </div>
            <div className="px-5">
            <hr />
            </div>
            {index == 5 && (
              <>
                <p className="inline_p text-left ml-md-5 ml-2">
                  Check out these Groups!
                </p>
                <div className="inline_topics col-md-10 mx-auto">
                  {topics &&
                    topics.map((item, indx) => {
                      return (
                        <div
                          onClick={() =>
                            props.getGroupPost(item.topic_id, item.topic)
                          }
                          className="inner_title text-center d-flex"
                        >
                         <RoundLetter text={item.topic} /> 
                         <span>{Capitalize(item.topic)}</span>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </>
        );
      })}
    </div>
  );
};

export default withRouter(Posts);
