import React from 'react';
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle
} from 'reactstrap';
import { connect } from 'react-redux';
import { CDN_URL, REACT_APP_API_URL,REACT_APP_BASEURL } from '../../processENV';
import appStore from '../../../assets/images/appStore.png'
import playStore from '../../../assets/images/google-playstore.png'
import facebook from '../../../assets/images/facebook.png'
import android from '../../../assets/images/android.png'
import ios from '../../../assets/images/ios.png'
import { Star } from 'react-feather';
import Slider from "react-slick";
import ShowMoreText from "react-show-more-text";
import { withTranslation } from 'react-i18next';
import { BsStar, BsStarFill } from 'react-icons/bs';
import moment from 'moment';

class Reviews extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            reviews:[]
        };
    }

    componentDidMount(){
        
        let data = {
            "app_prod_n_key": ''
          }
        fetch(`${REACT_APP_BASEURL}/api/list_review/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            // body: JSON.stringify(data),
            }).then(response => response.json())
            .then(async (res)=>{
                console.log("reviews",res.data)
                if(res.status === "success"){
                    this.setState({loading:false, reviews:res.data})
                }else{
                    this.setState({loading:false,error:'Some error has occured please try again'})
                }
            })
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    };
    


    render() {
        let { isOpen, reviews } = this.state;
        let { t } = this.props;
        
        const testimonials = {
            // dots: true,
            // infinite: true,
            slidesToScroll: 3,
            slidesToShow: 3,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 3000,
            cssEase: "linear",
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <>
                <div className='col-md-10 ml-auto mr-md-2 mt-3 d-flex flex-column'>
                    <div className="mx-auto"  >
                        <h2 className='pt-5 text-center header_font_ _text_color'>Don’t just take our word for it</h2>
                        <h5 className='text-center text_p_ font-weight-bold _text_color'>See why people Love CareMe Health</h5>
                        <div className='mx-auto my-3 text-center'>
                            <img onClick={()=>window.open('https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.careme')} src={`${CDN_URL}/Websiteicons/playstore.png`} width='120' height='30' />
                            <img className='ml-2' onClick={()=>{window.open('https://apps.apple.com/GH/app/id1553777469?l=en')}} src={`${CDN_URL}/Websiteicons/appstore.png`}  width='120' height='30' />
                        </div>
                    </div>
        <div className="p-5 p-md-0" >
                    {reviews && reviews.length>0&&<div className="">
        <Slider {...testimonials}>
           {reviews.map((i, indx)=>{
             return(
               <div style={{border:'none',borderRadius:'5px'}} key={indx} className="m-3">
             <div style={{background:i.bg_color,minHeight:'350px',}} className={`m-3 p-3 shadow-sm rounded`}>
             <div className=''>
             {i.star==1? <div>
                   <BsStarFill />{' '}
                   <BsStar />{' '}
                   <BsStar />{' '}
                   <BsStar />{' '}
                   <BsStar />
               </div>:i.star==2? <div>
                   <BsStarFill />{' '}
                   <BsStarFill />{' '}
                   <BsStar />{' '}
                   <BsStar />{' '}
                   <BsStar />
               </div>:i.star==3? <div>
                   <BsStarFill />{' '}
                   <BsStarFill />{' '}
                   <BsStarFill />{' '}
                   <BsStar />{' '}
                   <BsStar />
               </div>:i.star==4? <div>
                   <BsStarFill />{' '}
                   <BsStarFill />{' '}
                   <BsStarFill />{' '}
                   <BsStarFill />{' '}
                   <BsStar />
               </div>:i.star==5? <div>
                   <BsStarFill />{' '}
                   <BsStarFill />{' '}
                   <BsStarFill />{' '}
                   <BsStarFill />{' '}
                   <BsStarFill />
               </div>:null}
               </div>
               <h3 className='my-2 header_font_'>{i.highlight}</h3>
               <br />
               <div className="mt-n4">
                 <p  className="text_p_">
                 <ShowMoreText
                  /* Default options */
                  lines={5}
                  className='mt-2 text_p_'
                  more="Show more"  
                  less="Show less"
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  {i.desc}
                </ShowMoreText>
                 </p>
               </div>
               <div className="text-right">
                 <h4 style={{fontSize:'12px'}} className="text_p_ text-right font-weight-bold mt-3">{i.user}</h4>
                 <p style={{fontSize:'13px',opacity:.6}} className='mt-n1 text_p_'>{moment(i.date).fromNow()}</p>
               </div>
               <p style={{opacity:.4}} className="text-right mt-n2">-{' '}{i.review_at}</p>
             </div>
           </div>
             )
           })}
           </Slider>
       </div>}

                    </div>

                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        loginData: state.LoginData
    }
}

export default connect(mapStateToProps, null)(withTranslation()(Reviews));
