import React from 'react';
import { ArrowLeftCircle } from 'react-feather';
import { connect } from 'react-redux'; 
import {Redirect, withRouter} from 'react-router-dom'
import { FormGroup, Label } from 'reactstrap';
import { REACT_APP_API_URL } from '../../processENV';
import Loader from '../Loader';
import { Link } from "react-router-dom";

class TherapyCare extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            productDetails:null,
            empId: '',
            sessions:[]
        };
    }

    componentDidMount(){
        this.setState({loading:true})
        let data = {
            "patient_n_key": localStorage.getItem('_patientKey')
        }
        
        fetch(`${REACT_APP_API_URL}/upcoming_Check/`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
          body: JSON.stringify(data),
          }).then(response => response.json())
          .then(async (res)=>{
            console.log('selected service',res.data)
              if(res.status === "success"){
                this.setState({loading:false,productDetails:res.data?.therapy_journey[0],empId: res.data?.therapy_journey[0]?.created_by_id , sessions:res.data?.therapy_journey[0]?.subscription_plan})
              }else{
                console.log('selected service',res.data)
              }
          })
    }


    render() {
        let { loading, ans1, ans2, empId, sessions } = this.state;
        return (
            <>
            <div className="container">
                {loading && <Loader/>}
            <div className="register pt-4 border-0">
                <Link to="/home" className="p-4">
                <ArrowLeftCircle size={25} /></Link>
            <div className="head_question">
            <div className="question_layout col-md-5">
                <p>Hi {localStorage.getItem('_firstName')}</p>
                    <p className="d-inline pl-2">Welcome to Careme HealthCare.Total sessions   
                    &nbsp;recommended for you by doctor {empId && empId} is  {sessions && sessions[sessions.length - 1]?.no_of_session} </p>
            </div> 
            {/* {ans1 && <div className="question_layout col-md-5 ml-auto"> <div className='ansdiv col-auto'>
                                    <p className="d-inline pl-2">{ans1}</p>
            </div></div>} */}
             <div className={`bottomsec signup-main 'd-block'}`}>
                        
                           <Link to="/therapyjourney" className="btn btn-primary mr-3">   Pay Now </Link>
                       
                        <Link to="/home" className="btn btn-danger"> No </Link>
                        
            </div>

            </div>
            </div>
            
                </div>
            </>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        rescheduleData: state.RescheduleData
    }
}

export default connect(mapStateToProps, null)(withRouter(TherapyCare));
