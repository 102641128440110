import React from 'react';
import { connect } from 'react-redux';
import Input from '../Inputcomponents/Input';
import Select from '../Inputcomponents/Select';
import DateCustom from '../Inputcomponents/DateCustom';
import SliderCustom from '../Inputcomponents/SliderCustom';

class GetFormInput extends React.Component {
    render() {
        let { control, ...rest } = this.props;
        switch (control) {
            case 'input':
                return <Input {...rest} />
            case 'select':
                return <Select {...rest} />
            case 'date':
                return <DateCustom {...rest} />
            case 'slider':
                return <SliderCustom {...rest} />
        }
        return (
            <>
            </>
        )
    }

}


export default GetFormInput
