import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Modal, Form } from 'react-bootstrap';
import { CDN_URL, REACT_APP_API_URL } from '../processENV';
import axios from 'axios';
import Caremeloader from './Caremeloader';

const GetuserLanguage = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [selectedlang,setSelectedlang] = useState(false);
  const [loader,setLoader]=useState(true)

  useEffect(()=>{
    getUserResult()
  },[])
  const getUserResult=()=>{
    const headers = {
      "Content-Type": "application/json",
      Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
    };
    axios
      .post(
        REACT_APP_API_URL + "/nmuser_wellbeing/",
        { nm_id:window.location.href.split('/')[5], },
        { headers }
      )
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          localStorage.setItem('_nm',window.location.href.split('/')[5])
          if(res.data.lang=='ta'){
            window.location.href='/wellbeing-tn-asses'
            setTimeout(()=>{
              setLoader(false)
            },1000) 
          }
          else {
            window.location.href='/wellbeing-profile'
            setTimeout(()=>{
              setLoader(false)
            },1000) 
          }      
        } else {
          console.log('-')
          setLoader(false)
        }
      });
  }
  const handleLanguageChange = (language) => {
    setModalShow(true)
    setSelectedlang(language)
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleEmail=(val)=>{
    setEmail(val)
    if(validateEmail(val)){
      setIsValid(true)
    }
    else setIsValid(false)
  }
  const handleSubmit = () => {
    if (validateEmail(email)) {
      setModalShow(false)
      const data={
        "nm_id":window.location.href.split('/')[5],
         "email":email
      }
      fetch(`${REACT_APP_API_URL}/add_nm_user/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
        },
        body: JSON.stringify(data),
        }).then(response => response.json())
        .then(async (res)=>{
          console.log(res)
          if(res.status=='success'){
            localStorage.setItem('_nm',window.location.href.split('/')[5])
          if(selectedlang=='ta'){
            window.location.href='/wellbeing-tn-asses'
            }
            else{
                window.location.href='/wellbeing-profile'
            }  
          }   
        })
        .catch(err => console.log('allergy list error',err))
     
    } else {
      setIsValid(false);
    }
  };
  
  return (
   loader?<Caremeloader />:<Container className="d-flex col-md-12 align-items-center justify-content-center" style={{ height: '100vh' }}>
      <Modal show={modalShow} onHide={() => setModalShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='header_font_ font-weight-normal'>💌 By sharing your email with us, you'll receive timely reminders for your next assessment and resources tailored to your wellbeing journey. Your privacy and trust are paramount to us, and your email will be used solely for the purpose of providing you with the best care and support.</p>
        <Form.Group controlId="email">
          <Form.Label className='header_font_'>Email Address / மின்னஞ்சல் முகவரி</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => handleEmail(e.target.value)}
            isInvalid={!isValid}
          />
          {!isValid && <Form.Control.Feedback type="invalid">Invalid email format</Form.Control.Feedback>}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={()=>setModalShow(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
        Start with the Assessment
        </Button>
      </Modal.Footer>
    </Modal>
      <Row className='col-md-8 mx-auto'>
        <Col className="text-center">
          <Row className='d-flex justify-content-between'>
          <img src={`${CDN_URL}/CaremeLogo/final%20logo%20with%20slogan%20copy.png`} className='img-fluid' style={{maxHeight:'200px'}} />
          <img src={'https://portal.naanmudhalvan.tn.gov.in/img/logo.d52ff4c5.png'} className='img-fluid' style={{maxHeight:'200px'}} />
          </Row>
          <h2 className='_text_color header_font_ pt-4'>Welcome to CareMe Health -  Wellbeing Assessment</h2>
          <p style={{fontSize:'16px'}} className='text_p_ text-left _text_color'>This assessment is designed to evaluate your current state of wellbeing and identify areas that may require attention and improvement.Taking this assessment can provide valuable insights into your mental, emotional, and physical health. Your wellbeing is important to us, and we encourage you to participate in the assessment.</p>
          <p className='_text_color pt-3 h5 header_font_'>Please select the language you are comfortable with for the assessment</p>
          <Button style={{background:'#215352',border:'1px solid #215352',color:'#fff'}} className="btn font-weight-bold btn-gray m-2" onClick={() => handleLanguageChange('en')} >
            English
          </Button>
          <Button style={{background:'#215352',border:'1px solid #215352',color:'#fff'}} onClick={() => handleLanguageChange('ta')} className="m-2 font-weight-bold">
            தமிழ்
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default GetuserLanguage;
